class EventEmitter {
    constructor() {
        this.events = {};
        this.ymapReady = false;
        this.scriptIsNotAttached = true;
        this.pluginInstalledGlobal = false;
    }

    $on(eventName, fn) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(fn);
        return () => {
            this.events[eventName] = this.events[eventName].filter((eventFn) => fn !== eventFn);
        };
    }

    $emit(eventName) {
        const event = this.events[eventName];
        if (event) {
            event.forEach((fn) => fn());
        }
    }
}

export const emitter = new EventEmitter();

export function ymapLoader(settings) {
    const readyCallback = (res) => () => {
        ymaps.ready({
            successCallback: () => {
                emitter.ymapReady = true;
                emitter.$emit('scriptIsLoaded');
                res(true);
            }
        })
    };
    return new Promise((res, rej) => {
        if (window.ymaps)
            return res(true);
        if (document.getElementById('vue-yandex-maps-script')) {
            emitter.$on('scriptIsLoaded', res);
            return;
        }
        const yandexMapScript = document.createElement('SCRIPT');
        const {
            apiKey = '',
            lang = 'ru_RU',
            version = '2.1',
            coordorder = 'latlong',
            debug = false,
            enterprise = false,
        } = settings;
        const mode = debug ? 'debug' : 'release';
        const settingsPart = `lang=${lang}${apiKey && `&apikey=${apiKey}`}&mode=${mode}&coordorder=${coordorder}`;
        const link = `https://${enterprise ? 'enterprise.' : ''}api-maps.yandex.ru/${version}/?${settingsPart}`;
        yandexMapScript.onload = readyCallback(res);
        yandexMapScript.onerror = rej;

        yandexMapScript.setAttribute('src', link);
        yandexMapScript.setAttribute('async', '');
        yandexMapScript.setAttribute('defer', '');
        yandexMapScript.setAttribute('id', 'vue-yandex-maps-script');
        document.head.appendChild(yandexMapScript);
        emitter.scriptIsNotAttached = false;
    });
}
