import { defineStore } from 'pinia'
import { formatAttrTree } from "../utils/form";
import { prefixUrl } from "../utils/helper";
import {
    ExplainQuery,
    ExplainResult,
    SearchQuery,
    DocumentInfo,
} from "@ruscorpora/ruscorpora_api";
import { buildSpeakerInfoLine } from "../utils/search";

export const MODAL_HELP_TEXT = 'HELP-TEXT';
export const MODAL_FORMULA = 'FORMULA_MODAL';
export const WORD_MODAL = 'WORD_MODAL';

export const useModalStore = defineStore('modal', {
    state: () => {
        return {
            isActive: false,
            isLoading: false,
            type: '',
            body: '',
            config: {
                attrs: {},
                mode: '',
                selected: '',
                options: {}
            }
        }
    },
    actions: {
        hideModal() {
            this.isActive = false;
            this.type = '';
            this.body = '';
            this.config = {
                mode: '',
                attrs: {},
                selected: '',
                options: {}
            };
            this.isLoading = false;
        },
        returnFormula(data) {
            if (['OPTIONS', 'EDITABLE_OPTIONS'].includes(this.config.options.formulaRuleType)) {
                return data
            } else if (this.config.options.formulaRuleType === 'morph') {
                // Обработка словообразования
                const isAlternation = data['morph_alternation'] === "allomorph" ? '' : 'q'
                const morphText = data['morph_text'] || ''
                const morphMorpheme = data['morph_morpheme'] || ''
                const morphPosition = data['morph_position'] || ''
                return `${isAlternation}${morphText}_${morphMorpheme}_${morphPosition}`
            }
            return data;
        },
        async showHelpText(payload) {
            this.isActive = true;
            const isAsync = payload instanceof Promise;
            this.isLoading = isAsync;

            if (isAsync) {
                await payload.then(r => {
                    this.body = r
                    this.isLoading = false
                    this.type = MODAL_HELP_TEXT;
                })
            } else {
                this.body = payload
                this.type = MODAL_HELP_TEXT;
            }
        },
        async showFormulaModal(payload, corpus, wordIndex, value = "", extraTitle = "") {
            this.isActive = true;
            this.type = MODAL_FORMULA;
            this.config.extraTitle = extraTitle;
            this.config.options = {...payload, wordIndex: wordIndex, value: value, corpus: corpus};
            await this.fetchMetaAttr({...payload, corpus: corpus});
        },
        async showWordModal(payload, isExpand = false, title = "", docSource = {}, docInfo = {}, explainResult = null) {
            this.isActive = true;
            this.type = WORD_MODAL;
            this.isLoading = true;
            this.body = {
                query: payload,
                explain: explainResult === null ? await this.fetchExplain(payload) : explainResult,
                isExpand: isExpand,
                externalTitle: title,
                docSource: docSource,
                docInfo: docInfo
            };
            this.isLoading = false;
        },
        /**
         * Открыть модалку с репортом о слове
         * @param options {Object}
         * @param options.explainQuery {ExplainQuery}
         * @param options.searchQuery {SearchQuery}
         * @param options.homonymyStatus {String|undefined}
         * @param options.explainResult {ExplainResult|null}
         * @param options.customTitle {String}
         * @return {Promise<void>}
         */
        async showWordModalV2(options) {
            this.isActive = true;
            this.type = WORD_MODAL;
            this.isLoading = true;
            this.body = {
                explainQuery: options.explainQuery,
                explainResult: (options.explainResult === null || options.explainResult === undefined) ? await this.fetchExplain(options.explainQuery) : options.explainResult,
                customTitle: options.customTitle,
                searchQuery: options.searchQuery,
                documentInfo: options.homonymyStatus ? DocumentInfo.create({homonymyStatus: options.homonymyStatus}) : null,
            };
            this.isLoading = false;
        },
        /**
         * Открыть модалку с спикере
         * @param options {Object}
         * @param options.explainQuery {ExplainQuery}
         * @param options.speakerInfo {Object}
         * @param options.searchQuery {SearchQuery}
         * @param options.homonymyStatus {String|undefined}
         * @return {Promise<void>}
         */
        async showSpeakerInfo(options) {
            this.isActive = true;
            this.type = WORD_MODAL;
            this.isLoading = true;
            let modalTitle = ""
            if (options.speakerInfo.name) {
                modalTitle = `${options.speakerInfo.name}`
            }
            if (options.speakerInfo.role) {
                modalTitle = modalTitle.length ? `${options.speakerInfo.role} (${modalTitle})` : `${options.speakerInfo.role}`
            }
            this.body = {
                explainQuery: options.explainQuery,
                explainResult: await this.request(prefixUrl(`/api/speaker-as-explain?speaker=${encodeURIComponent(JSON.stringify(options.speakerInfo))}`, true))
                    .then(response => {
                        return ExplainResult.fromObject(response)
                    }),
                searchQuery: options.searchQuery,
                customTitle: modalTitle || options.speakerInfo.profession,
                documentInfo: options.homonymyStatus ? DocumentInfo.create({homonymyStatus: options.homonymyStatus}) : null,
                badSpeaker: buildSpeakerInfoLine(options.speakerInfo)
            };
            this.isLoading = false;
        },
        /**
         * Открыть модалку с информацией о доступности
         * @param options {Object}
         * @param options.explainQuery {ExplainQuery}
         * @param options.readabilityInfo {Object}
         * @param options.customTitle {Object}
         * @param options.searchQuery {SearchQuery}
         * @param options.homonymyStatus {String|undefined}
         * @return {Promise<void>}
         */
        async showReadabilityInfo(options) {
            this.isActive = true;
            this.type = WORD_MODAL;
            this.isLoading = true;
            
            this.body = {
                explainQuery: options.explainQuery,
                explainResult: await this.request(prefixUrl(`/api/readability-info-as-explain?readability-info=${encodeURIComponent(JSON.stringify(options.readabilityInfo))}`, true))
                    .then(response => {
                        return ExplainResult.fromObject(response)
                    }),
                customTitle: options.customTitle,
                searchQuery: options.searchQuery,
                badReadabilityInfo: options.customTitle
            };
            this.isLoading = false;
        },
        async fetchMetaAttr(config) {
            this.isLoading = true;
            if (config.hasOwnProperty('fields')) {
                this.config.mode = 'form'
                this.config.attrs = config['fields']
            } else if (config.formulaRuleType === 'map') {
                this.config.mode = 'map'
                const {
                    title,
                    options
                } = await this.request(prefixUrl(`/api/attr-values-map/${config['attrName']}?corpus=${encodeURIComponent(JSON.stringify(config['corpus']))}`, true))

                this.config.attrs = {
                    title: title,
                    options: options
                }
            } else {
                const attrUrl = config['attrUrl'] ?? `/api/attr-values/${config['attrName']}?corpus=${encodeURIComponent(JSON.stringify(config['corpus']))}`
                const data = await this.request(prefixUrl(attrUrl, true))
                this.config.mode = 'options'
                this.config.attrs = {
                    max_depth: data[0].max_depth,
                    title: data[0].title,
                    tree: formatAttrTree(data[0].children),
                    combine: data[0].combine,
                    attrName: config['attrName']
                }
            }
            this.isLoading = false

            return this.config
        },
        async fetchExplain(query) {
            return this.request(prefixUrl(`/api/explain?query=${encodeURIComponent(JSON.stringify(query.toJSON()))}`, true))
                .then(response => {
                    return ExplainResult.fromObject(response)
                })
        }
    },
})
