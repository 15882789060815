import { message } from "ant-design-vue";

export default {
    install(app, options = {}) {
        message.config({maxCount: 3});
        app.config.globalProperties.$message = message
        app.provide('message', message)
    }
}

