import {
    FormSectionValue,
    SearchFormValue,
    Date as DatePrimitive,
    CorpusType,
    FrontendSearchMode,
    SpeakerInfo,
    TranslateString
} from "@ruscorpora/ruscorpora_api";
import isNull from "lodash/isNull"
import isEqual from "lodash/isEqual"
import { MAX_INT_32, MIN_INT_32 } from "../constatns"
import { getKeyByValue, trimLastWord } from "./helper";
import { v4 as uuid } from 'uuid';
import { getDefaultFieldValue } from "./form";

export const buildConditionValue = (condition, safeEmpty = false) => {
    switch (condition.c) {
        case 'text':
            if (safeEmpty) return condition
            return condition.text.v ? condition : null
        case 'int':
            if (safeEmpty) return condition
            return Number.isInteger(condition.int.v) && condition.int.hasOwnProperty('v') ? condition : null
        case 'intRange':
            condition.intRange.begin = condition.intRange.begin === "" ? MIN_INT_32 : parseInt(condition.intRange.begin)
            condition.intRange.end = condition.intRange.end === "" ? MAX_INT_32 : parseInt(condition.intRange.end)

            if (safeEmpty) return condition
            if (condition.intRange.begin === MIN_INT_32 && condition.intRange.end === MAX_INT_32) {
                return null
            } else return condition
        case 'check':
            if (safeEmpty) return condition
            return condition.check.v ? condition : null
        case 'date':
            if (safeEmpty) return condition
            return condition.date?.v && DatePrimitive.encode(condition.date.v).len ? condition : null
        case 'dateRange':
            if (safeEmpty) return condition
            return (condition.dateRange.begin || condition.dateRange.end) ? condition : null
        default:
            console.error('Condition type is not support. Please check it')
    }

}

export const buildLexGrammQuery = (lexGramm, excludeEmpty = true) => {
    const sections = Array.isArray(lexGramm) ? lexGramm : [lexGramm]

    return SearchFormValue.create({
        sectionValues: sections.map(section => {
            return FormSectionValue.create({
                conditionValues: Object.values(section.extra ?? {}).map(condition => condition),
                subsectionValues: section.words.map(word => {
                    return FormSectionValue.create({
                        conditionValues: word.map(card => {
                            const conditions = Object.keys(card).filter(x => x !== 'cardId')
                                .map(field => buildConditionValue(card[field], !excludeEmpty))
                            return excludeEmpty ? conditions.filter(condition => !isNull(condition)) : conditions
                        }).flat()
                    })
                })
            })
        })
    })
}

export const buildExactQuery = (params) => {
    return SearchFormValue.create({
        sectionValues: [
            FormSectionValue.create({
                conditionValues: Object.values(params ?? {}).map(condition => condition),
            })
        ]
    })
}

export const defaultSubcorpusStructure = (initial = []) => {
    return SearchFormValue.create({
        sectionValues: [
            FormSectionValue.create({
                conditionValues: initial
            })
        ]
    })
}

export const buildSubcorpusQuery = (params, excludeEmpty = true) => {
    return defaultSubcorpusStructure(
        Object.values(params).map(condition => {
            return buildConditionValue(condition, !excludeEmpty)
        }).filter(condition => !isNull(condition))
    )
}

export const compareSubcorps = (first, second) => {
    return isEqual(first ? first.toJSON() : null, second ? second.toJSON() : null)
}

const prioritySearchModeKey = 'prioritySearchMode'
export const PrioritySearchMode = {
    set(mode) {
        localStorage.setItem(prioritySearchModeKey, mode)
    },
    get() {
        const exists = localStorage.getItem(prioritySearchModeKey)
        return exists ? parseInt(exists) : null
    }
}

/**
 * @param corpus {Corpus}
 * @param searchMode {FrontendSearchMode|null}
 */
export const buildGoalTarget = (corpus, searchMode = null) => {
    let corpusType = corpus.type
    switch (corpusType) {
        case CorpusType.MULTI:
            corpusType = CorpusType.PARA
            break
    }
    let target = `TARGET_${getKeyByValue(CorpusType, corpusType)}`

    if (searchMode === FrontendSearchMode.COLLOCATIONS) target = `${target}_COLLOCATION`
    if (searchMode === FrontendSearchMode.PARA_LEX_GRAMM) target = `${target}2`
    return target
}

export const allowShowCollocation = (stats, target = 1000000) => {
    const corporaCounter = stats?.subcorpus?.wordUsageCount || stats.corpus?.wordUsageCount

    return corporaCounter > target
}

export const computeLineBreaks = (words, isConcordance = true) => {
    let offset = 0
    let offsetCoefficient = 1

    return words.map(word => {
        offset += word.text.length
        // Просто перенос строки
        if (word.text.includes('\\//')) {
            word.text = word.text.replace('\\//', isConcordance ? '\n' + '<br>' : ' ')
            offset -= 4
        }

        // Перенос строки с переносом каретки (примерно)
        if (word.text.includes('\\/')) {
            offset -= 3
            word.text = word.text.replace('\\/', isConcordance ? '\n' + `<br>${new Array(Math.ceil(offset * 1.4 * offsetCoefficient)).fill('&nbsp;').join('')}` : ' ')
            offsetCoefficient += .2
        }
        return word
    })
}


export const prepareSnippetGroup = (snippetGroups, expand = false, forceShowTranslates = false, isConcordance = true) => {
    const addUuidToWords = (words) => {
        return words.map(word => {
            word.uuid = uuid()

            return word
        })
    }

    const firstSnippetGroupInNotBlockquote = () => {
        /** Кейс для которого написано это условие: в КВИК в соц.сетях прилетает сразу 2 группы сниппетов, что
         * приводит к тому, что отображается флаг языка как для параллельных корпусов. Этой проверкой отсекаются такие
         * сценарии */
        try {
            return !snippetGroups[0].snippets[0].sequences[0].blockquote
        } catch (e) {
            return false
        }
    }

    return snippetGroups.map((group, index) => {
        group.contextIsExpanded = expand
        group.uuid = uuid()
        group.loading = false
        group.snippets = group.snippets.map((snippet, snippet_index) => {
            snippet.sequences = snippet.sequences.map((sequence, seqIndex) => {
                let currentWords = addUuidToWords(trimLastWord(sequence.words, seqIndex + 1 === snippet.sequences.length))
                if (snippet.isVerse &&
                    (
                        (!sequence.lineInfo?.size && !sequence.lineInfo?.formula) ||
                        sequence.words.find(word => word.text.includes('<br/>')
                        )
                        || sequence.words.length > 50
                    )
                ) {
                    sequence.isProse = true
                }
                currentWords = computeLineBreaks(currentWords, isConcordance)

                // Добавления переноса в следующий сиквенс, для формирования структуры строф в стихах
                if (sequence.lineInfo) {
                    sequence.words.forEach((word, wordIndex) => {
                        if (word.text.endsWith('<br/>') && !word.text.endsWith('<br/><br/>')) {
                            if (sequence.words[wordIndex + 1] && wordIndex + 1 < sequence.words.length) {
                                sequence.words[wordIndex + 1].text = `<br/>${sequence.words[wordIndex + 1].text}`
                            } else if (wordIndex + 1 === sequence.words.length && snippet.sequences[seqIndex + 1] !== undefined) {
                                snippet.sequences[seqIndex + 1].words[0].text = `<br/>${snippet.sequences[seqIndex + 1].words[0].text}`
                            }
                        }
                    })
                }
                sequence.currentWords = currentWords
                sequence.uuid = uuid()
                sequence.speakerInfoLine = sequence.speakerInfo ? buildSpeakerInfoLine(SpeakerInfo.fromObject(sequence.speakerInfo)) : ""
                sequence.speakerInfoLineShort = sequence.speakerInfo ? buildSpeakerInfoLine(SpeakerInfo.fromObject(sequence.speakerInfo), true) : ""

                return sequence
            })
            snippet.uuid = uuid()
            if (group.snippets.length > 1 && (expand || forceShowTranslates)) {
                snippet.showLanguage = firstSnippetGroupInNotBlockquote()
            }

            return snippet
        })

        return group
    })
}


export const lexGrammQueryToForm = (searchFormValue, lexGrammConfig) => {
    const fieldWithCardsMapping = new Map();
    const sections = []

    lexGrammConfig.cards.forEach(card => {
        card.fields.forEach(field => {
            fieldWithCardsMapping.set(field.name, card);
        })
    })

    searchFormValue.sectionValues.forEach(sectionValue => {
        const words = []
        const extra = {}

        sectionValue.subsectionValues.forEach(subsectionValue => {
            const wordParams = new Map();

            // Обход параметров слов
            subsectionValue.conditionValues.forEach(condition => {
                const card = fieldWithCardsMapping.get(condition.fieldName);
                if (!card) return
                if (wordParams.has(card.cardId)) {
                    wordParams.set(card.cardId, {
                        ...wordParams.get(card.cardId),
                        [condition.fieldName]: condition
                    })
                } else {
                    wordParams.set(card.cardId, {
                        cardId: card.cardId,
                        [condition.fieldName]: condition
                    })
                }
            })
            words.push(Array.from(wordParams.values()))
        });

        (lexGrammConfig.beforeCards || [])
            .map(field => sectionValue.conditionValues.find(x => x.fieldName === field.name) ?? getDefaultFieldValue(field))
            .forEach(field => extra[field.fieldName] = field)

        sections.push({
            words,
            extra,
        })
    })

    return sections
}


/**
 *
 * @param speakerInfo {SpeakerInfo}
 * @param short {Boolean}
 */
export const buildSpeakerInfoLine = (speakerInfo, short = false) => {
    if (short) {
        /* Формирование короткой записи, если есть имя или роль. Иначе фолбэк срабатывает с полной версией */
        if (speakerInfo['name'] && speakerInfo['role']) {
            return `${speakerInfo['role']} (${speakerInfo['name']})`
        } else if (speakerInfo['name'] || speakerInfo['role']) {
            return speakerInfo['role'] || speakerInfo['name']
        }
    }
    const actor_fields = ['name', 'sex', 'age', 'birth', 'profession'];
    let actor_descr = actor_fields
        .filter((key) => key in speakerInfo)
        .map((key) => {
            return speakerInfo[key] instanceof TranslateString ? speakerInfo[key].humanReadable : speakerInfo[key]
        })
        .filter(x => x)
        .join(", ");

    let sp_descr = '';

    if ('role' in speakerInfo && speakerInfo['role'] && actor_descr) {
        sp_descr = `${speakerInfo['role']} (${actor_descr})`;
    } else if ('role' in speakerInfo && speakerInfo['role']) {
        sp_descr = `${speakerInfo['role']}`;
    } else if (actor_descr) {
        sp_descr = `${actor_descr}`;
    }

    return sp_descr
}


export const cardIsValidForFirstWord = (card) => {
  const stopCards = [
    {
      noty: window.gettext('Условие «расстояние» для первого слова было удалено (не соответствует условию)'),
      fields: ['dist']
    },
    {
      fields: ['lexical_function', 'lexical_to_word', 'lexical_direction', 'lexical_preposition']
    },
    {
      fields: ['syntax_link', 'syntax_to_word', 'syntax_direction']
    },
    {
      fields: ['coref_relation', 'coref_to_word']
    },
    {
      fields: ['temporal_relation', 'temporal_direction', 'temporal_to_word']
    },
  ]
  const fieldsFromCard = card.fields.map(x => x.name)
  const isStop = stopCards.find(entry => fieldsFromCard.every(fieldName => entry.fields.includes(fieldName)))

  return [isStop === undefined, isStop]
}
