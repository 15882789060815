<template>
  <div class="dropdown-button" role="menubar" ref="menubar">
    <a-dropdown :trigger="['click']" @visibleChange="onHandleVisibleChange" :get-popup-container="()=>menubar">
      <div class="dropdown-button__button">
        <slot name="button" />
        <the-button class="dropdown-button__toggle" :class="{'dropdown-button__toggle--active': opened}" type="colored"
                    :aria-label="submenuLabel" size="medium" mode="icon" aria-haspopup="true" :aria-expanded="opened"
                    :id="componentId" role="menuitem">
          <icon name="c-arrow" />
        </the-button>
      </div>
      <template #overlay>
        <ul class="dropdown-button__list" :aria-label="submenuLabel" role="menu" :aria-labelledby="componentId"
            ref="menu">
          <li role="menuitem" v-for="option of options" :key="option.value"
              :style="{'font-weight': option.isActive ? 'bold': 'normal'}">
            <button @click="onHandleMenuClick($event, option)">{{ option.label }}</button>
          </li>
        </ul>
      </template>
    </a-dropdown>
  </div>
</template>

<script>
import TheButton from "./TheButton";
import Icon from "./Icon";
import { v4 as uuid } from 'uuid';
import { ref, nextTick } from "vue";

export default {
  name: "DropDownButton",
  components: {Icon, TheButton},
  props: {
    options: {
      type: Array
    },
    submenuLabel: {
      type: String
    }
  },
  emits: ['menu-click'],
  setup(props, {emit}) {
    const onHandleMenuClick = ($event, value) => {
      $event.stopPropagation()
      emit('menu-click', value)
    }

    const onHandleVisibleChange = (visible) => {
      opened.value = visible

      if (visible) {
        nextTick(() => {
          menu.value.querySelector('button').focus()
        })
      }
    }

    const componentId = uuid()
    const opened = ref(false)
    const menu = ref(null)
    const menubar = ref(null)

    return {
      onHandleMenuClick,
      onHandleVisibleChange,
      componentId,
      opened,
      menu,
      menubar
    }
  }
}
</script>

<style lang="scss">
.dropdown-button {
  --button-border-color   : #435480;
  --button-bg-active      : #222D4E;
  --dropdown-bg           : #CDE5F3;
  --dropdown-color        : #6173A6;
  --dropdown-color-active : var(--deep-blue-hover);
  --dropdown-bg-active    : #E1F1F9;

  &__button {
    display     : flex;
    align-items : flex-end;

    button {
      &:first-child {
        border-bottom-right-radius : 0;
        border-top-right-radius    : 0;
        flex-shrink                : 1 !important;
        flex-grow                  : 1;
      }
    }

    .icon-c-arrow {
      width  : rem-calc(18);
      height : rem-calc(10);
    }
  }

  &__toggle {
    border                    : 1px solid var(--button-border-color);
    border-bottom-left-radius : 0;
    border-top-left-radius    : 0;
    width                     : rem-calc(48);
    flex-shrink               : 0;

    @include breakpoint(medium up) {
      width : rem-calc(56);
    }

    &:hover, &--active {
      background-color : var(--button-bg-active) !important;
    }
  }

  &__list {
    border-radius : rem-calc(10);
    background    : var(--dropdown-bg);
    font-size     : rem-calc(18);
    line-height   : rem-calc(1.5);
    padding       : rem-calc(13 0);

    &:focus-visible {
      outline        : 2px solid var(--deep-blue-hover);
      outline-offset : rem-calc(4);
    }

    button {
      position   : relative;
      color      : var(--dropdown-color);
      padding    : rem-calc(4 30);
      transition : ease-in .2s;
      display    : block;
      width      : 100%;
      text-align : left;

      &:hover, &:focus-visible {
        color      : var(--dropdown-color-active);
        background : var(--dropdown-bg-active);
      }
    }
  }
}
</style>
