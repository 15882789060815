<template>
  <router-view />
  <ModalsContainer />
</template>

<script>
import { ModalsContainer } from 'vue-final-modal'

export default {
  name: "App",
  components: {
    ModalsContainer
  }
}
</script>

<style scoped>

</style>
