import { prefixUrl } from "../utils/helper";
const warn = 'django localization is not defined'
export const getText = (arg) => {
    try {
        return window.gettext(arg)
    } catch (e) {
        console.warn(warn)
        return arg
    }
}
export const interpolate = (fmt, obj, named) => {
    try {
        return window.interpolate(fmt, obj, named)
    } catch (e) {
        console.warn(warn)
        return fmt
    }
}

export default {
    install(app, options = {}) {
        const async_gettext_dict = {}
        const ngettext = (singular, plural, count) => {
            try {
                return window.ngettext(singular, plural, count)
            } catch (e) {
                console.warn(warn)
                return singular
            }
        }


        const asyncGettext = async (msg, corpus = {}) => {
            if (corpus) corpus = JSON.stringify(corpus)
            const translKey = `${msg}${corpus}`
            if (async_gettext_dict.hasOwnProperty(translKey)) return async_gettext_dict[translKey]

            async_gettext_dict[translKey] = app.config.globalProperties.$request(prefixUrl(`/api/async-gettext?q=${msg}&corpus=${corpus}`, true), { priority: 'low' })
                .then(r => {
                    async_gettext_dict[translKey] = r.translate
                    return r.translate
                })
                .catch(() => msg)

            return async_gettext_dict[translKey]
        }

        app.config.globalProperties.$gettext = getText
        app.config.globalProperties.$ngettext = ngettext
        app.config.globalProperties.$interpolate = interpolate
        app.config.globalProperties.translate = getText
        app.provide('gettext', getText)
        app.provide('ngettext', ngettext)
        app.provide('interpolate', interpolate)
        app.provide('translate', getText)
        app.provide('asyncGettext', asyncGettext)
    }
}

