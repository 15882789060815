<template>
  <div class="search-result-fail">
    <div class="search-result-fail__body">
      <div class="search-result-fail__detail" v-html="message" />
      <div class="search-result-fail__action" v-if="link">
        <template v-if="link.modernNav">
          <router-link :to="link.modernNav">{{ link.text }}</router-link>
        </template>
        <template v-else>
          <a :href="link.url">{{ link.text }}</a>
        </template>
      </div>
    </div>
    <icon class="search-result-fail__icon" :name="iconPath" />
  </div>
</template>

<script>
import Icon from '../ui/Icon';

export default {
  name: "Fail",
  props: {
    message: {
      type: String,
      required: true
    },
    link: {
      type: [Object, null],
      default: null
    },
    status: {
      type: Number,
      default: 500
    }
  },
  components: {
    Icon
  },
  computed: {
    iconPath() {
      return this.status === 404 ? 'c-status-not-found' : 'c-status-fail'
    },
  }
}
</script>

<style scoped lang="scss">
.search-result-fail {
  display         : flex;
  align-items     : center;
  justify-content : center;
  position        : relative;
  min-height      : rem-calc(310);
  margin-top      : rem-calc(30);

  &__body {
    display        : flex;
    flex-direction : column;
    gap            : rem-calc(14);
    text-align     : center;
    position       : relative;
    z-index        : 1;
  }

  &__detail {
    font-size   : rem-calc(24);
    font-weight : 700;
    @include breakpoint(small up) {
      font-size : rem-calc(32);
    }
  }

  &__action {
    font-size : rem-calc(20);

    a {
      text-decoration : none;
      color           : var(--deep-blue);
      font-family     : var(--cursive-font);
      font-style      : italic;

      &:hover {
        text-decoration : underline;
      }
    }
  }

  &__icon {
    width     : rem-calc(310);
    height    : rem-calc(310);
    position  : absolute;
    left      : 50%;
    top       : 50%;
    transform : translate(-50%, -50%);
  }
}
</style>

<style lang="scss">
.search-result-fail {
  &__detail {
    .medium {
      font-size   : .9em;
      font-weight : 400;
    }

    small {
      font-size   : .7em;
      font-weight : 400;
    }
  }
}
</style>
