<template>
  <a-spin :spinning="loading">
    <div class="snippet" :class="{'is-expand': expand, 'snippet--compact': compact}" ref="snippet"
         @focusin="touched=true">
      <div v-if="!isSmallResolution || expand" class="translates origin origin-desktop">
        <div v-if="!expand" class="translates__lang">
          <div v-if="originSnippets.length > 1" class="no-copy">
            <v-select :options="originSnippetOptions"
                      :searchable="false"
                      :clearable="false"
                      v-model="activeOriginSnippet"
                      label="title">
              <template #selected-option="{title}">
                <h3 v-html="title"/>
              </template>
              <template #option="{title}">
                <h3 v-html="title"/>
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon style="height: 8px;width: 18px" name="c-arrow"/>
                </span>
              </template>
            </v-select>
          </div>
          <div v-else class="para-concordance-item__lang no-copy">
            <span v-html="activeOriginTitle + ':'"/>
          </div>
        </div>

        <div v-for="(snippet, index) of originSnippets" :key="snippet.uuid">
          <div v-show="index === activeOriginSnippet.key || expand" class="para-concordance-item">
            <div v-show="expand" class="para-concordance-item__lang">
              <span class="new-line-wrap"
                    v-html="snippet.langInfo.humanReadable + ':'"
                    :copy-content="`${snippet.langInfo.humanReadable}:`"/>
            </div>
            <div v-if="snippet.hasOwnProperty('mediaContent')">
              <media-content :media-content="snippet.mediaContent"/>
            </div>
            <p v-for="(sequence, index) in snippet.sequences" :key="sequence.uuid"
               :class="{'seq-with-actions': snippet.sequences.length === index + 1}">
              <sequence
                :sequence="sequence"
                :sequence-index="index"
                :show-context-expand-action="!snippet.completeContext"
                :lang-info="snippet.langInfo"
                :show-language="false"
                :source="docSource"
                :word-source="wordSource"
                :enable-result-actions="!compact && snippet.sequences.length === index + 1"
                @copy-context="handleCopyContext"
                @open-word-modal="handleOpenWordModal"
                @open-speaker-info="handleOpenSpeakerInfo(sequence.speakerInfo, expand, snippet.source)"
                @toggle-context="handleToggleContext(snippet.uuid)"
              />
            </p>
          </div>
        </div>
      </div>
      <div v-if="!expand" class="translates">
        <div v-if="translateSnippet.length" class="translates__lang">
          <div v-if="translateSnippet.length > 1" class="no-copy">
            <v-select @option:selected="handleSelectTranslate" :options="translatesOptions" label="title"
                      :searchable="false"
                      :clearable="false"
                      :append-to-body="true"
                      v-model="activeTranslate">
              <template #selected-option="{title}">
                <h3 v-html="title"/>
              </template>
              <template #option="{title}">
                <h3 v-html="title"/>
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <icon style="height: 8px;width: 18px" name="c-arrow"/>
                </span>
              </template>
            </v-select>
          </div>
          <div v-else class="para-concordance-item__lang no-copy">
            <span v-html="activeTranslateTitle + ':'"/>
          </div>
        </div>
        <div class="swiper-container">
          <swiper
            @swiper="onSwiperInit"
            @slideChange="touched=true"
            :allowSlideNext="true"
            :allowSlidePrev="true"
            :allowTouchMove="true"
            :breakpoints="swiperBreakpoints"
            :modules="swiperModules"
            :pagination="swiperPaginationConf"
            :slides-per-view="1"
            :speed="400"
            :auto-height="true"
            :auto-resize="true"
            :space-between="50"
          >
            <template v-if="isSmallResolution">
              <swiper-slide v-for="snippet of originSnippets" :key="snippet.uuid">
                <div class="origin para-concordance-item">
                  <div class="para-concordance-item__lang no-copy new-line-wrap">
                    <span v-html="snippet.langInfo.humanReadable + ':'"
                          :copy-content="`${snippet.langInfo.humanReadable}:`"/>
                  </div>
                  <div v-if="snippet.hasOwnProperty('mediaContent')">
                    <media-content :media-content="snippet.mediaContent"/>
                  </div>
                  <p v-for="(sequence, index) in snippet.sequences" :key="sequence.uuid"
                     :class="{'seq-with-actions': snippet.sequences.length === index + 1}">
                    <sequence
                      :sequence="sequence"
                      :sequence-index="index"
                      :show-context-expand-action="!snippet.completeContext"
                      :lang-info="snippet.langInfo"
                      :show-language="false"
                      :source="docSource"
                      :word-source="wordSource"
                      :enable-result-actions="!compact && snippet.sequences.length === index + 1"
                      @copy-context="handleCopyContext"
                      @open-word-modal="handleOpenWordModal"
                      @open-speaker-info="handleOpenSpeakerInfo(sequence.speakerInfo, expand, snippet.source)"
                      @toggle-context="handleToggleContext(snippet.uuid)"
                    />
                  </p>
                </div>
              </swiper-slide>
            </template>
            <swiper-slide v-for="(snippet, i) in translateSnippet"
                          :key="snippet.uuid">
              <div v-if="i === 0 || touched" class="para-concordance-item">
                <strong v-show="expand || isSmallResolution" class="para-concordance-item__lang">
                  <span v-html="snippet.langInfo.humanReadable + ':'" class="new-line-wrap"
                        :copy-content="`${snippet.langInfo.humanReadable}:`"/>
                </strong>

                <div>
                  <div v-if="snippet.hasOwnProperty('mediaContent')">
                    <media-content :media-content="snippet.mediaContent"/>
                  </div>
                  <p v-for="(sequence, index) in snippet.sequences" :key="sequence.uuid"
                     :class="{'seq-with-actions': snippet.sequences.length === index + 1}">
                    <sequence
                      :sequence="sequence"
                      :sequence-index="index"
                      :show-context-expand-action="!snippet.completeContext"
                      :lang-info="snippet.langInfo"
                      :show-language="false"
                      :source="docSource"
                      :word-source="wordSource"
                      :enable-result-actions="!compact && snippet.sequences.length === index + 1"
                      @copy-context="handleCopyContext"
                      @open-word-modal="handleOpenWordModal"
                      @open-speaker-info="handleOpenSpeakerInfo(sequence.speakerInfo, expand, snippet.source)"
                      @toggle-context="handleToggleContext(snippet.uuid)"
                    />
                  </p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pag-wrapper" :id="pageId"/>
        </div>
      </div>
      <div v-else class="snippet__expand-sentences">
        <div class="para-concordance-item" v-for="snippet in translateSnippet" :key="snippet.uuid">
          <strong v-if="expand || isSmallResolution" class="para-concordance-item__lang">
            <span v-html="snippet.langInfo.humanReadable + ':'" class="new-line-wrap"
                  :copy-content="`${snippet.langInfo.humanReadable}:`"/>
          </strong>

          <div v-if="snippet.hasOwnProperty('mediaContent')">
            <media-content :media-content="snippet.mediaContent"/>
          </div>

          <p v-for="(sequence, index) in snippet.sequences" :key="sequence.uuid"
             :class="{'seq-with-actions': snippet.sequences.length === index + 1}">
            <sequence
              :sequence="sequence"
              :sequence-index="index"
              :show-context-expand-action="!snippet.completeContext"
              :lang-info="snippet.langInfo"
              :show-language="false"
              :source="docSource"
              :word-source="wordSource"
              :enable-result-actions="!compact && snippet.sequences.length === index + 1"
              @copy-context="handleCopyContext"
              @open-word-modal="handleOpenWordModal"
              @open-speaker-info="handleOpenSpeakerInfo(sequence.speakerInfo, expand, snippet.source)"
              @toggle-context="handleToggleContext(snippet.uuid)"
            />
          </p>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { computed, ref } from "vue";
import Word from "./Word.vue";
import ResultActions from "../ui/ResultActions.vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/scss';
import { Pagination } from 'swiper';
import 'swiper/scss/pagination';
import vSelect from 'vue-select';
import Icon from "../ui/Icon.vue";
import { IS_SMALL_RESOLUTION, useRootStore } from "../../stores/root";
import { Spin } from "ant-design-vue";
import "ant-design-vue/es/spin/style/index.css";
import { v4 as uuid } from "uuid"
import Sequence from "./components/Sequence.vue";
import MediaContent from "../ui/MediaContent.vue";

export default {
  name: "ParaConcordanceSnippet",
  components: {
    MediaContent,
    Sequence,
    Icon,
    ResultActions,
    Word,
    Swiper,
    SwiperSlide,
    vSelect,
    'a-spin': Spin
  },
  props: {
    data: {
      type: Object
    },
    expand: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    docSource: {
      type: Object
    },
    wordSource: {
      type: [Object, null]
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  emits: ['toggle-expand-context', 'open-word-modal', 'copy-context', 'open-speaker-info'],
  setup(props, {emit}) {
    const snippetLeftSide = computed(() => Math.max(props.data.leftSnippets, 1))
    const rootStore = useRootStore()
    const originSnippets = computed(() => {
      return props.data.snippets.slice(0, snippetLeftSide.value)
    })
    const activeOriginSnippet = ref()
    const translateSnippet = computed(() => {
      return props.data.snippets.slice(snippetLeftSide.value)
    })
    const handleToggleContext = (uuid) => {
      emit('toggle-expand-context', uuid)
    }
    const pageId = `pager-${uuid()}`
    const snippet = ref()
    const touched = ref(false)

    const swiperInstance = ref()
    const activeTranslate = ref()
    const activeTranslateTitle = computed(() => {
      return activeTranslate.value ? activeTranslate.value.title : ""
    })
    const activeOriginTitle = computed(() => {
      return activeOriginSnippet.value ? activeOriginSnippet.value.title : ""
    })
    const translatesOptions = computed(() => {
      if (translateSnippet.value.length < 1) return []

      return translateSnippet.value.map((snippet, index) => {
        return {
          title: snippet.langInfo.humanReadable,
          key: index
        }
      })
    })
    const originSnippetOptions = computed(() => {
      if (originSnippets.value.length < 1) return []

      return originSnippets.value.map((snippet, index) => {
        return {
          title: snippet.langInfo.humanReadable,
          key: index
        }
      })
    })

    const isSmallResolution = computed(() => rootStore[IS_SMALL_RESOLUTION])

    const handleSelectTranslate = (el) => {
      swiperInstance.value.slideTo(el.key)
    }

    const onSwiperInit = (swiper) => {
      swiperInstance.value = swiper
    };

    const handleOpenWordModal = (data) => {
      emit('open-word-modal', {
        wordSourceParams: data,
        isExpanded: props.expand,
        diapSource: props.docSource
      })
    }

    const handleOpenSpeakerInfo = (speakerInfo, isExpanded, diapSource) => {
      emit('open-speaker-info', {
        speakerInfo,
        isExpanded,
        diapSource: isExpanded ? diapSource : props.docSource,
      })
    }

    activeTranslate.value = translatesOptions.value[0]
    activeOriginSnippet.value = originSnippetOptions.value[0]

    const handleCopyContext = () => {
      emit('copy-context', snippet.value)
    }

    return {
      originSnippets,
      translateSnippet,
      translatesOptions,
      activeTranslate,
      activeTranslateTitle,
      originSnippetOptions,
      activeOriginSnippet,
      activeOriginTitle,
      swiperModules: [Pagination],
      swiperBreakpoints: {
        768: {
          pagination: {
            enabled: false,
            loop: true
          },
          allowTouchMove: false,
          speed: 1,
          dynamicBullets: false,
          dynamicMainBullets: 0,
          navigation: {
            enabled: false
          },
          loop: false,
        }
      },
      swiperPaginationConf: {
        clickable: true,
        enabled: true,
        el: `#${pageId}`,
        dynamicBullets: true,
        dynamicMainBullets: 6,
      },
      pageId,
      isSmallResolution,
      snippet,

      onSwiperInit,
      handleOpenWordModal,
      handleSelectTranslate,
      handleToggleContext,
      handleCopyContext,
      handleOpenSpeakerInfo,
      touched,
    }
  }
}
</script>

<style scoped lang="scss">
.snippet {
  &.is-expand {
    background-color           : var(--light-mint);
    display                    : block;
    border-bottom-left-radius  : 10px;
    border-bottom-right-radius : 10px;

    .origin-desktop {
      .para-concordance-item {
        @include breakpoint(medium up) {
          padding-top    : 15px;
          padding-bottom : 15px;
        }
      }

      border-right : 0;
    }
  }


  @include breakpoint(medium up) {
    display               : grid;
    grid-template-columns : 1fr 1fr;
  }

  &__expand-sentences {
    .para-concordance-item {
      padding-top    : 10px;
      padding-bottom : 10px;
      @include breakpoint(medium up) {
        padding-top    : 15px;
        padding-bottom : 15px;
      }
    }
  }
}

.para-concordance-item {
  font-size   : 15px;
  line-height : 1.5;
}

.origin-desktop {
  border-right : 1px solid var(--blue);
}

.translates {
  min-width : 0;

  &__lang {
    padding       : 14px 20px 0;
    font-size     : 15px;
    margin-bottom : -14px;
    display       : none;

    @include breakpoint(medium up) {
      padding       : 24px 20px 0;
      margin-bottom : -24px;
      z-index       : 1;
      position      : relative;
      display       : block;
    }
  }
}

.para-concordance-item {
  padding : 14px 20px;
  @include breakpoint(medium up) {
    padding : 24px 20px;
  }

  &__lang {
    font-weight : 600;
    /*white-space : nowrap;

    & > span {
      text-overflow : ellipsis;
      overflow      : hidden;
      min-width     : 0;
      max-width     : 100%;
      display       : block;
    }*/

    @include breakpoint(medium up) {
      min-height : 30px;
      .is-expand & {
        min-height : unset;
      }
    }
  }

  p.seq-with-actions {
    span:nth-last-child(2) {
      padding-right : rem-calc(66);

      .snippet--compact & {
        padding-right : 0;
      }
    }
  }

  .result-actions {
    margin-left : rem-calc(-57);
  }
}
</style>
<style lang="scss">
.para-concordance-item {
  &__lang {
    i {
      font-weight : 400;
      font-family : var(--cursive-font);
      font-style  : italic;
      color       : var(--dark-gray);
    }
  }
}

.snippet {
  .swiper-container {
    position : relative;
    z-index  : 0;
  }

  .swiper-pag-wrapper {
    display         : none;
    justify-content : center;
    //gap             : 5px;
    overflow        : hidden;
    transform       : none !important;
    left            : 0 !important;
    width           : 100% !important;
    margin-bottom   : 30px;

    &.swiper-pagination-clickable {
      display : flex;
    }

    &.swiper-pagination-disabled {
      display : none !important;
    }
  }


  .swiper-wrapper {
    position      : relative;
    min-width     : 100%;
    width         : auto !important;
    margin-bottom : rem-calc(1) !important;
  }

  .swiper-pagination {
    position : absolute;
    bottom   : rem-calc(0) !important;

  }

  .swiper-pagination-bullet {
    width            : 0;
    height           : rem-calc(10) !important;
    border-radius    : 8px !important;
    margin-left      : 3px;
    margin-right     : 3px;
    background-color : #9bced4;
    position         : static;
    display          : none;

    &.swiper-pagination-bullet-active-main {
      width   : rem-calc(62);
      display : block;
    }

    &.swiper-pagination-bullet-active-next, &.swiper-pagination-bullet-active-prev {
      width   : rem-calc(56);
      display : block;
    }

    &.swiper-pagination-bullet-active-next-next, &.swiper-pagination-bullet-active-prev-prev {
      width   : rem-calc(50);
      display : block;
    }


    &.swiper-pagination-bullet-active {
      // активная метка
    }
  }

  .swiper-pagination-bullet-active-next {
    //transform        : scale(1) !important;
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    // background-color : #9bced4 !important;
  }

  .swiper-pagination-bullet-active-prev {
    //transform : scale(1) !important;
  }

  .swiper-pagination-bullet-active-next-next {
    //    transform : scale(0) !important;
  }

  .swiper-pagination-bullets {
    display       : flex !important;
    //gap           : rem-calc(6);
    border-radius : 8px !important;
    flex-shrink   : 0 !important;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    //margin : 0 0 !important;
  }

  .swiper-pagination-bullet-active, .swiper-pagination-bullet-active-main {
    //background-color : #9EC7DE !important;

  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    //background-color : #9EC7DE !important;
  }

  .swiper-pagination-bullet-active-main {
    //background-color : #EAF1EF !important;
    //padding : rem-calc(0 5) !important;
  }

  .swiper-pagination-bullet {
    //background-color : transparent !important;
  }

  .vs__dropdown-option {
    padding : 3px 9px;
  }
}
</style>
