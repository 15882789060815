<template>
  <VueFinalModal v-model="computedVisible"
                 @opened="init"
                 @closed="onClose"
                 class="modal-container modal-container-sm-from-bottom"
                 @keydown.enter.prevent="handlePressEnter"
                 content-class="modal-content">
    <div class="simple-keyboard-modal">
      <input class="simple-keyboard-value" type="text" v-model="value" @focusin="handleFocusIn"
             @focusout="handleFocusOut" @input="handleUseInput" @keydown.enter.prevent="handleFocusOut">
      <div v-show="showKeyboard" :class="keyboardClass"></div>
    </div>
  </VueFinalModal>
</template>

<script>
import "simple-keyboard/build/css/index.css";
import { VueFinalModal } from 'vue-final-modal'
import { reachGoal } from "../../utils/yandex-metrika"

export default {
  name: "SimpleKeyboard",
  emits: [
    'on-key-press',
    'on-change',
    'on-close',
  ],
  inject: ["gettext"],
  components: {
    VueFinalModal
  },
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    input: {
      type: String
    },
    layouts: {
      type: Object
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  data: function () {
    const $gettext = this.gettext;

    return {
      keyboard: null,
      isShow: false,
      value: '',
      locale: {
        backspace: $gettext('Удалить'),
        caps: $gettext('Заглавные'),
        enter: $gettext('Ввод'),
        space: $gettext('Пробел'),
        eu_layout: $gettext('Европейская'),
        ru_layout: $gettext('Русская')
      },
      showKeyboard: true
    }
  },
  methods: {
    init() {
      const computedLayout = {}

      this.layouts.map(i => {
        if (i.hasOwnProperty('lowercaseData')) {
          computedLayout[i.name] = i.lowercaseData.trim().split('\n')
        }
        if (i.hasOwnProperty('uppercaseData')) {
          computedLayout[`${i.name}Caps`] = i.uppercaseData.trim().split('\n')
        }
      })

      import("simple-keyboard").then(({default: Keyboard}) => {
        this.value = this.input;
        this.keyboard = new Keyboard(this.keyboardClass, {
          onChange: this.onChange,
          onKeyPress: this.onKeyPress,
          layout: computedLayout,
          display: {
            "{bksp}": this.locale.backspace,
            "{enter}": this.locale.enter,
            "{shift}": this.locale.eu_layout,
            "{space}": this.locale.space,
            "{caps}": this.locale.caps
          }
        });

        this.keyboard.setInput(this.input)
      })
    },
    onClose() {
      this.keyboard.destroy();
      this.keyboard = null;
      this.$emit('on-close');
    },
    onChange(input) {
      this.value = input;
    },
    onKeyPress(button) {
      this.$emit("on-key-press", button);

      if (button === "{shift}" || button === "{lock}") this.handleShift();
      if (button === "{caps}") this.handleCapsLock();
      if (button === "{enter}") {
        this.$emit('on-close')
        this.$emit('on-change', this.value);
        reachGoal("TARGET_KEYBOARD")
      }
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftName = currentLayout === "default" ? this.locale.ru_layout : this.locale.eu_layout;
      const shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
        display: {
          "{bksp}": this.locale.backspace,
          "{enter}": this.locale.enter,
          "{shift}": shiftName,
          "{space}": this.locale.space,
          "{caps}": this.locale.caps
        }
      });
    },
    handleCapsLock() {
      const currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = '';

      if (currentLayout === "default") shiftToggle = "defaultCaps"
      if (currentLayout === "defaultCaps") shiftToggle = "default"
      if (currentLayout === "shift") shiftToggle = "shiftCaps"
      if (currentLayout === "shiftCaps") shiftToggle = "shift"

      this.keyboard.setOptions({
        layoutName: shiftToggle
      });
    },
    handleFocusIn() {
      const isTouch = (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));

      if (isTouch && window.innerWidth < 960) {
        this.showKeyboard = false
      }

      document.addEventListener('focusout', this.documentFocusOutHandler)
    },
    handleFocusOut() {
      //e.stopPropagation();
      this.showKeyboard = true;
      document.activeElement?.blur();
      document.removeEventListener('focusout', this.documentFocusOutHandler)
    },
    handleUseInput() {
      this.keyboard.setInput(this.value)
    },
    documentFocusOutHandler(e) {
      // Трюк, чтобы поймать события скрытия системной клавиатуры (IOS)
      if (e.relatedTarget === null) {
        this.handleFocusOut()
      }
    },
    handlePressEnter(event) {
      if (event.keyCode !== 13) return

      event.preventDefault()
      event.stopPropagation()
      this.onKeyPress("{enter}")
    }
  },
  watch: {
    input(input) {
      if (this.keyboard) {
        this.keyboard.setInput(input);
      }
    }
  },
  computed: {
    computedVisible: {
      set(value) {
        this.$emit('update:modelValue', value)
      },
      get() {
        return this.visible
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hg-theme-default {
  font-size   : rem-calc(16);
  font-family : inherit;
}

.simple-keyboard-value {
  width         : 100%;
  border        : 4px solid #ececec;
  padding       : 1em;
  font-size     : 1.8em;
  margin-bottom : 0.5em;
  border-radius : 5px;
  box-sizing    : border-box;

  input {

  }
}

.simple-keyboard-modal {
  background-color : var(--white);
  @include breakpoint(medium up) {
    width : rem-calc(800);
  }
}
</style>

<style lang="scss">
.simple-keyboard-modal {
  @include breakpoint(small up) {
    padding : rem-calc(30);
  }

  @include breakpoint(small down) {
    width : 100vw;
    .hg-theme-default .hg-button {
      width         : auto;
      margin        : 0 !important;
      padding       : rem-calc(4);
      border-radius : 0;
      min-width     : rem-calc(12);
    }
    .hg-row {
      margin-bottom : 0 !important;
    }
  }
}

.hg-functionBtn {
  font-family : var(--main-font);
}
</style>
