<template>
  <div class="player" :class="{'player--expand': expand}">
    <div class="player__status">
      <button :disabled="failed" class="player__play" type="button" @click="togglePlay">
        <svg v-if="playStatus === 'paused'" width="34" height="34" viewBox="0 0 34 34" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <circle cx="17" cy="17" r="16" stroke="#485C99" />
          <path d="M14.6001 23.4V10.6L20.6001 17.4L14.6001 23.4Z" fill="#485C99" />
        </svg>
        <svg v-else width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="17" cy="17" r="16" stroke="#485C99" />
          <rect x="13" y="11" width="1" height="12" fill="#485C99" />
          <rect x="20" y="11" width="1" height="12" fill="#485C99" />
        </svg>
      </button>

      <input v-if="!failed" class="player__progress" type="range" min="0" :max="duration" step="1" :value="seek"
             @mousedown="mouseDownOnSlider = true" @mouseup="mouseDownOnSlider = false" @change="handleChangeProgress">
    </div>
    <div class="player__foot">
      <div class="player__foot-link">
        <slot name="download"></slot>
      </div>
      <span v-if="!failed" class="player__foot-time" v-text="`${humanStatus} / ${humanDuration}`" />
      <span v-else class="player__foot-failed">failed</span>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler';
import { computed, onUnmounted, ref } from "vue";

function secondsToHms(d) {
  d = Math.ceil(Number(d));
  const m = Math.floor(d % 3600 / 60).toLocaleString('en-US', {minimumIntegerDigits: 2});
  const s = Math.floor(d % 3600 % 60).toLocaleString('en-US', {minimumIntegerDigits: 2});

  return `${m}:${s}`;
}

export default {
  name: "AudioPlayer",
  props: {
    src: {
      type: String
    },
    expand: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const player = new Howl({
      src: [props.src],
      preload: "metadata",
    });
    const playStatus = ref("paused")
    const duration = ref(0)
    const seek = ref(0)
    let progressHandler = undefined
    const mouseDownOnSlider = ref()
    const failed = ref(false)
    const createProgressHandler = () => {
      stopProgressHandle()

      return setInterval(() => {
        updateSeek()
      }, 1000)
    }

    const stopProgressHandle = () => {
      if (progressHandler) {
        clearInterval(progressHandler)
        progressHandler = null
      }
    }

    const handleChangeProgress = (event) => {
      player.seek(Number(event.target.value))
    }

    const updateSeek = () => {
      if (mouseDownOnSlider.value) return
      Math.ceil(seek.value = player.seek())
    }

    player.once('load', () => {
      duration.value = player.duration()
      updateSeek()
    })
    player.on('play', () => {
      updateSeek()
      progressHandler = createProgressHandler()
    })
    player.on('end', () => {
      stopProgressHandle()
      playStatus.value = "paused"
    })
    player.on('stop', () => {
      stopProgressHandle()
      playStatus.value = "paused"
    })
    player.on('loaderror', () => {
      failed.value = true
    })

    const togglePlay = () => {
      if (playStatus.value === "paused") {
        playStatus.value = "playing"
        player.play()
      } else {
        playStatus.value = "paused"
        player.pause()
      }
    }

    const humanDuration = computed(() => secondsToHms(duration.value))
    const humanStatus = computed(() => secondsToHms(seek.value))

    onUnmounted(() => {
      player.unload()
    })

    return {
      playStatus,
      humanDuration,
      humanStatus,
      duration,
      seek,
      mouseDownOnSlider,
      failed,

      handleChangeProgress,
      togglePlay
    }
  }
}
</script>

<style scoped lang="scss">
.player {
  width            : 320px;
  max-width        : 100%;
  background-color : var(--light-mint);
  padding          : 20px;
  gap              : 8px;
  display          : flex;
  flex-direction   : column;
  border-radius    : 5px;

  &__status {
    display     : flex;
    gap         : 10px;
    align-items : center;
  }

  &__play {
    width       : 32px;
    height      : 32px;
    flex-shrink : 0;

    svg {
      display : block;
      width   : 100%;
      height  : 100%;
      margin  : 0;
    }
  }

  &__progress {
    height             : 19px;
    -webkit-appearance : none;
    margin             : 0;
    width              : 100%;
    background         : var(--light-mint);

    &:focus {
      outline : none;
    }

    &::-webkit-slider-runnable-track {
      width         : 100%;
      height        : 2px;
      cursor        : pointer;
      animate       : 0.2s;
      box-shadow    : 0 0 0 rgba(0, 0, 0, .6);
      background    : rgba(0, 0, 0, .6);
      border-radius : 2px;
      border        : 0 solid rgba(0, 0, 0, .6);
    }

    &::-webkit-slider-thumb {
      box-shadow         : 0 0 0 rgba(0, 0, 0, .6);
      border             : 1px solid #485C99;
      height             : 12px;
      width              : 12px;
      border-radius      : 12px;
      background         : #EAF1EF;
      cursor             : pointer;
      -webkit-appearance : none;
      margin-top         : -5.5px;
    }

    &:focus::-webkit-slider-runnable-track {
      background : rgba(0, 0, 0, .6);
    }

    &::-moz-range-track {
      width         : 100%;
      height        : 2px;
      cursor        : pointer;
      animate       : 0.2s;
      box-shadow    : 0 0 0 rgba(0, 0, 0, .6);
      background    : rgba(0, 0, 0, .6);
      border-radius : 2px;
      border        : 0 solid rgba(0, 0, 0, .6);
    }

    &::-moz-range-thumb {
      box-shadow    : 0 0 0 rgba(0, 0, 0, .6);
      border        : 1px solid #485C99;
      height        : 12px;
      width         : 12px;
      border-radius : 12px;
      background    : var(--light-mint);
      cursor        : pointer;
    }

    &::-ms-track {
      width        : 100%;
      height       : 2px;
      cursor       : pointer;
      animate      : 0.2s;
      background   : transparent;
      border-color : transparent;
      color        : transparent;
    }

    &::-ms-fill-lower {
      background    : rgba(0, 0, 0, .6);
      border        : 0 solid rgba(0, 0, 0, .6);
      border-radius : 2px;
      box-shadow    : 0 0 0 rgba(0, 0, 0, .6);
    }

    &::-ms-fill-upper {
      background    : rgba(0, 0, 0, .6);
      border        : 0 solid rgba(0, 0, 0, .6);
      border-radius : 2px;
      box-shadow    : 0 0 0 rgba(0, 0, 0, .6);
    }

    &::-ms-thumb {
      margin-top    : 1px;
      box-shadow    : 0 0 0 rgba(0, 0, 0, .6);
      border        : 1px solid var(--deep-blue-hover);
      height        : 12px;
      width         : 12px;
      border-radius : 12px;
      background    : #EAF1EF;
      cursor        : pointer;
    }

    &:focus::-ms-fill-lower {
      background : rgba(0, 0, 0, .6);
    }

    &:focus::-ms-fill-upper {
      background : rgba(0, 0, 0, .6);
    }
  }

  &__foot {
    display         : flex;
    justify-content : space-between;
    font-size       : 12px;

    @include breakpoint(medium up) {
      font-size : 14px;
    }

    &-time {
      color : var(--deep-blue-hover);
    }

    &-failed {
      color : #d9653d;
    }
  }

  &--expand {
    width : unset;

    @include breakpoint(small up) {
      flex-direction : row;
      padding-bottom : 40px;
      align-items    : center;

      .player {
        &__status {
          flex-grow : 1;
        }

        &__foot-link {
          position : absolute;
          bottom   : 20px;
          left     : 20px;
        }
      }
    }
  }
}
</style>
