<script setup>
import TheButton from "./TheButton.vue";
import Icon from "./Icon.vue";

const props = defineProps({
  message: {
    type: String
  },
  showRegButton: {
    type: Boolean
  },
  gettext: {
    type: Function
  }
})

const emit = defineEmits(['close'])
const $gettext = (msg) => props.gettext(msg)
const handleClose = () => {
  emit('close')
}

const openLoginModal = () => {
  handleClose();

  window.dispatchEvent(new CustomEvent('open-login'))
}
</script>

<template>
  <div class="warning-message">
    <div class="warning-message__text" v-text="message" />
    <button class="warning-message__close" @click="handleClose">
      <icon name="c-cross" class="icon-c-cross" />
    </button>
    <div v-if="showRegButton" class="warning-message__button">
      <the-button type="colored" @click="openLoginModal">
        {{ $gettext('Войти') }}
      </the-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.warning-message {
  position       : relative;
  display        : flex;
  flex-direction : column;
  gap            : 12px;
  max-width      : 300px;
  padding-right  : 20px;

  &__text {
    font-family : var(--main-font);
    font-size   : 13px;
    font-weight : 400;
    line-height : 1.5;
    display     : flex;
    align-items : flex-start;
    text-align  : left;
  }

  &__close {
    position : absolute;
    right    : 0;
    top      : 0;

    .icon-c-cross {
      width  : 16px;
      height : 16px;
    }
  }

  &__button {
    display     : flex;
    align-items : flex-start;
  }
}
</style>
