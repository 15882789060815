<template>
  <div>
    <the-header/>

    <div class="container">
      <div class="corpus-inner-content">
        <div v-if="configIsReady" class="row">
          <div class="col">
            <ul role="tablist" class="corpus-search-tabs">
              <li class="corpus-search-tab">
                <div class="corpus-search-tab__header" role="tab"
                     :aria-selected="activeSearchMode === EXACT" aria-controls="exact-search-panel">
                  <div class="corpus-search-tab__title">
                    <button :aria-label="`Выбрать: ${exact.title}`" @click="setSearchMode(EXACT)">
                      {{ exact.title }}
                    </button>
                    <round-button v-if="exact.helpText" aria-label="Подсказка" color="dark"
                                  @click="modal.showHelpText(exact.helpText)" size="medium">
                      <icon name="c-question"/>
                    </round-button>
                  </div>
                  <round-button :aria-label="`Выбрать: ${exact.title}`" :active="activeSearchMode === EXACT"
                                @click="setSearchMode(EXACT)" color="dark" size="medium"
                                class="corpus-search-tab__toggle-desktop">
                    <icon name="c-arrow"></icon>
                  </round-button>
                  <round-button :aria-label="`Выбрать: ${exact.title}`" :active="activeSearchMode === EXACT"
                                @click="setSearchMode(EXACT)" color="dark" size="medium" :outline="true"
                                class="corpus-search-tab__toggle-mobile">
                    <icon name="c-arrow"></icon>
                  </round-button>
                </div>
                <transition :duration="{ enter: 350, leave: 0 }">
                  <div class="corpus-search-tab__body" v-if="activeSearchMode === EXACT" role="tabpanel"
                       id="exact-search-panel">
                    <div class="corpus-search-tab__annotation" v-if="exact.subtitle ">{{ exact.subtitle }}</div>
                    <div class="row">
                      <div class="col-12 col-sm-9 col-md-8">
                        <div class="exact-form-search-fields">
                          <template v-for="field of exact.fields" :key="`exact_field_${field.name}`">
                            <dynamic-field :field="field" v-model="form[EXACT][field.name]"
                                           :extra-options="{text: {size: 'large'}}"/>
                          </template>
                        </div>
                      </div>
                      <div class="col-12 col-sm-3 d-f align-end">
                        <drop-down-button @menu-click="onSubmitForm(EXACT, $event)"
                                          class="corpus-search-tab__exact-search" :options="searchTypeList"
                                          :submenu-label="$gettext('Выбор режима поиска')">
                          <template #button>
                            <the-button aria-label="Искать" type="colored" size="medium" mode="expand"
                                        class="corpus-search-tab__exact-search" @click.stop="onSubmitForm(EXACT)">
                              {{ $gettext('Искать') }}
                            </the-button>
                          </template>
                        </drop-down-button>
                      </div>
                    </div>
                  </div>
                </transition>
              </li>
              <li v-if="hasCollocation" class="corpus-search-tab">
                <div class="corpus-search-tab__header" :aria-selected="activeSearchMode === COLLOCATION"
                     aria-controls="lexgramm-search-panel"
                     role="tab">
                  <div class="corpus-search-tab__title">
                    <button :aria-label="`${$gettext('Выбрать')}: ${collocation.title}`"
                            @click="setSearchMode(COLLOCATION)">
                      {{ collocation.title }}
                    </button>

                    <round-button v-if="collocation.helpText" :aria-label="$gettext('Подсказка')" color="dark"
                                  size="medium"
                                  @click="modal.showHelpText(collocation.helpText)">
                      <icon name="c-question"/>
                    </round-button>
                  </div>

                  <round-button :aria-label="`${$gettext('Выбрать')}: ${collocation.title}`"
                                :active="activeSearchMode === COLLOCATION"
                                @click="setSearchMode(COLLOCATION)" color="dark" size="medium"
                                class="corpus-search-tab__toggle-desktop">
                    <icon name="c-arrow"></icon>
                  </round-button>

                  <round-button :aria-label="`${$gettext('Выбрать')}: ${collocation.title}`"
                                :active="activeSearchMode === COLLOCATION"
                                @click="setSearchMode(COLLOCATION)" color="dark" size="medium" :outline="true"
                                class="corpus-search-tab__toggle-mobile">
                    <icon name="c-arrow"></icon>
                  </round-button>
                </div>
                <transition :duration="{ enter: 350, leave: 0 }">
                  <div class="corpus-search-tab__body" v-if="activeSearchMode === COLLOCATION"
                       role="tabpanel" id="collocation-search-panel">
                    <div class="corpus-search-tab__annotation" v-if="collocation.subtitle">
                      {{ collocation.subtitle }}
                    </div>
                    <collocation v-model="form[COLLOCATION]" ref="collocationRef"
                                 @validate="setValidFormStatus(COLLOCATION, $event)"/>
                    <div class="corpus-search-tab__to-search-wrap">
                      <the-button type="colored" size="medium" @click.stop="onSubmitForm(COLLOCATION)">
                        {{ $gettext('Искать') }}
                      </the-button>

                      <the-button type="dashed" size="medium" @click="handleResetCollocation">
                        {{ $gettext('Сбросить') }}
                      </the-button>
                    </div>
                  </div>
                </transition>
              </li>
              <li class="corpus-search-tab">
                <div class="corpus-search-tab__header" :aria-selected="activeSearchMode === LEX_GRAMM"
                     aria-controls="lexgramm-search-panel"
                     role="tab">
                  <div class="corpus-search-tab__title">
                    <button :aria-label="`${$gettext('Выбрать')}: ${lex.title}`" @click="setSearchMode(LEX_GRAMM)">
                      {{ lex.title }}
                    </button>

                    <round-button v-if="lex.helpText" :aria-label="$gettext('Подсказка')" color="dark" size="medium"
                                  @click="modal.showHelpText(lex.helpText)">
                      <icon name="c-question"/>
                    </round-button>
                  </div>

                  <round-button :aria-label="`${$gettext('Выбрать')}: ${exact.title}`"
                                :active="activeSearchMode === LEX_GRAMM"
                                @click="setSearchMode(LEX_GRAMM)" color="dark" size="medium"
                                class="corpus-search-tab__toggle-desktop">
                    <icon name="c-arrow"></icon>
                  </round-button>

                  <round-button :aria-label="`${$gettext('Выбрать')}: ${exact.title}`"
                                :active="activeSearchMode === LEX_GRAMM"
                                @click="setSearchMode(LEX_GRAMM)" color="dark" size="medium" :outline="true"
                                class="corpus-search-tab__toggle-mobile">
                    <icon name="c-arrow"></icon>
                  </round-button>
                </div>
                <transition :duration="{ enter: 350, leave: 0 }">
                  <div class="corpus-search-tab__body" v-if="activeSearchMode === LEX_GRAMM" ref="lexGrammWords"
                       role="tabpanel" id="lexgramm-search-panel">
                    <div class="corpus-search-tab__annotation" v-if="lex.subtitle">{{ lex.subtitle }}</div>
                    <div v-if="lex.beforeCards" class="corpus-search-tab__before-cards">
                      <div v-for="(field, index) of lex.beforeCards" :key="index">
                        <dynamic-field :field="field" v-model="form[LEX_GRAMM].extra[field.name]"/>
                      </div>
                    </div>
                    <lex-gramm :params="words" :cards="lex.cards" @delete-word="onDeleteWordHandler"
                               @add-word="onAddWordHandler"
                               @validate="setValidFormStatus(LEX_GRAMM, $event)"
                               @update-field="onUpdateField" @delete-condition="onDeleteConditionHandler"
                               @add-condition="onAddConditionHandler"/>
                    <div class="corpus-search-tab__to-search-wrap">
                      <drop-down-button @menu-click="onSubmitForm(LEX_GRAMM, $event)"
                                        :options="searchTypeList"
                                        :submenu-label="$gettext('Выбор режима поиска')">
                        <template #button>
                          <the-button type="colored" size="medium" @click.stop="onSubmitForm(LEX_GRAMM)">
                            {{ $gettext('Искать') }}
                          </the-button>
                        </template>
                      </drop-down-button>
                      <the-button type="dashed" size="medium" @click="handleResetLexGramm">
                        {{ $gettext('Сбросить') }}
                      </the-button>
                    </div>
                  </div>
                </transition>
              </li>
              <li v-if="hasParaLexGramm" class="corpus-search-tab">
                <parallel-lex-gramm
                  v-model="form[FSM.PARA_LEX_GRAMM]"
                  ref="parallelLexGrammRef"
                  @change-search-mode="setSearchMode"
                  @submit="onSubmitForm(FSM.PARA_LEX_GRAMM, {value: SRT.PARALLEL_CONCORDANCE})"
                  :search-mode="activeSearchMode"
                  :search-form="paraLexGrammForm"
                />
              </li>
            </ul>

            <div class="corpus-search-grant" v-html="currentCorpus.grant_info"/>
          </div>
        </div>
        <loader v-else/>
      </div>
      <help-text-modal/>
      <formula-modal/>
    </div>
  </div>
</template>

<script>
import TheHeader from "../components/TheHeader";
import OverflowDropdown from "../components/ui/OverflowDropdown";
import TheButton from "../components/ui/TheButton";
import RoundButton from "../components/ui/RoundButton";
import Icon from "../components/ui/Icon";
import Concordance from "../components/results/Concordance";
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { useModalStore } from '../stores/modal';
import { CORPUS_INFO, usePortraitStore } from "../stores/portrait";
import {
  ALL_STAT_FIELDS,
  AVAILABLE_RESULT_TYPES,
  CURRENT_CORPUS_CONFIG,
  GRAPHIC_FIELDS,
  READY,
  SEARCH_ACTIONS,
  SEARCH_STATE,
  SETTINGS,
  STAT_FIELDS,
  useSearchStore,
} from '../stores/search';
import { useRootStore } from '../stores/root';
import HelpTextModal from "../components/ui/HelpTextModal";
import TheInput from "../components/ui/form/TheInput";
import { configToForm, getDefaultFieldValue } from "../utils/form";
import LexGramm from "../components/ui/form/LexGramm";
import FormulaModal from "../components/ui/FormulaModal";
import { CorpusType, FrontendSearchMode, SearchQuery, SearchResultType, TemporalUnit } from '@ruscorpora/ruscorpora_api'
import { encodeSearchQuery } from "../utils/http";
import {
  buildExactQuery,
  buildGoalTarget,
  buildLexGrammQuery,
  lexGrammQueryToForm,
  PrioritySearchMode,
  cardIsValidForFirstWord,
} from "../utils/search";
import { getDefaultSettings, getPrioritySavedResultType } from "../utils/structure";
import Loader from "../components/ui/Loader";
import { reachGoal } from "../utils/yandex-metrika";
import { corpusToString } from "../utils/helper";
import DropDownButton from "../components/ui/DropDownButton";
import DynamicField from "../components/ui/DynamicField";
import Collocation from "../components/ui/form/Collocation.vue"
import intersection from "lodash/intersection";
import { useRoute, useRouter } from "vue-router";
import ParallelLexGramm from "../components/search-form/ParallelLexGramm.vue";

export default {
  name: "CorpusSearch",
  components: {
    ParallelLexGramm,
    Collocation,
    DynamicField,
    DropDownButton,
    Loader,
    FormulaModal,
    LexGramm,
    TheInput,
    HelpTextModal,
    Concordance,
    Icon,
    TheButton,
    OverflowDropdown,
    TheHeader,
    RoundButton
  },
  setup() {
    const activeSearchMode = ref(null);
    const modal = useModalStore();
    const searchStore = useSearchStore();
    const rootStore = useRootStore();
    const portraitStore = usePortraitStore();
    const query = computed(() => searchStore[SEARCH_STATE].query)
    searchStore.initState()
    rootStore.setRouteName('search')
    rootStore.init()
    portraitStore.init(query.value.corpus)

    const route = useRoute()
    const router = useRouter()
    const lexGrammConfig = computed(() => searchStore.lex)
    const exactConfig = computed(() => searchStore.exact)
    const collocationConfig = computed(() => searchStore.collocation)
    const paraLexGrammForm = computed(() => searchStore.paraLexGramm)
    const configIsReady = computed(() => searchStore[READY])
    const $gettext = inject('gettext');
    const asyncGettext = inject('asyncGettext');
    const message = inject('message');
    const lexGrammWords = ref(null);
    const currentCorpus = computed(() => portraitStore[CORPUS_INFO]);
    const availableResultTypes = computed(() => {
      return searchStore[AVAILABLE_RESULT_TYPES]
        .filter(x => ![SearchResultType.META, SearchResultType.COLLOCATION].includes(x))
    })
    const collocationRef = ref(null)
    const parallelLexGrammRef = ref(null)

    const form = ref({
      [FrontendSearchMode.EXACT]: {},
      [FrontendSearchMode.LEX_GRAMM]: ref({
        extra: {},
        words: []
      }),
      [FrontendSearchMode.COLLOCATIONS]: ref([
        [],
        []
      ]),
      [FrontendSearchMode.PARA_LEX_GRAMM]: ref([]),
    })
    const invalidState = ref({
      [FrontendSearchMode.LEX_GRAMM]: false,
      [FrontendSearchMode.COLLOCATIONS]: false,
      [FrontendSearchMode.PARA_LEX_GRAMM]: false,
    })

    const verifyWordListAsToFirstWordCondition = (showNoty = true) => {
      form.value[FrontendSearchMode.LEX_GRAMM].words[0].forEach(formCard => {
        const similarCard = {
          fields: Object.keys(formCard).filter(x => x !== 'cardId').map(x => Object({name: x}))
        }
        const [isValid, cardInfo] = cardIsValidForFirstWord(similarCard)

        if (!isValid) {
          onDeleteConditionHandler({word: 0, cardId: formCard['cardId']})
          if (showNoty && cardInfo.noty) message.info(cardInfo.noty, 3)
        }
      })
    }

    const onAddWordHandler = (payload) => {
      const newWord = configToForm(payload.cards, false);

      if (payload.index === form.value[FrontendSearchMode.LEX_GRAMM].words.length - 1 || !form.value[FrontendSearchMode.LEX_GRAMM].words.length) {
        form.value[FrontendSearchMode.LEX_GRAMM].words.push(newWord)
      } else {
        form.value[FrontendSearchMode.LEX_GRAMM].words.splice(payload.index + 1, 0, newWord)
      }
      nextTick(() => {
        if (!lexGrammWords.value) return
        const words = lexGrammWords.value.querySelectorAll('.lex-word')
        const targetWord = Array.from(words)[payload.index + 1]
        if (!targetWord) return;
        targetWord.querySelector('.lex-condition input').focus()
      })
    }
    const onAddConditionHandler = (payload) => {
      const newCondition = configToForm([payload['card']], true)[0]

      const cardIdAsNumber = (parseInt(payload.card.cardId.split('-')[1])) ?? 0
      const newConditionPosition = new Int32Array([
        ...form.value[FrontendSearchMode.LEX_GRAMM].words[payload['word']].map(x => parseInt(x.cardId.split('-')[1])),
        cardIdAsNumber
      ]).sort().findIndex(x => x === cardIdAsNumber)
      form.value[FrontendSearchMode.LEX_GRAMM].words[payload['word']].splice(newConditionPosition, 0, newCondition)
    }
    const onDeleteWordHandler = (index) => {
      form.value[FrontendSearchMode.LEX_GRAMM].words.splice(index, 1)
      verifyWordListAsToFirstWordCondition()
      searchStore.deleteOptionsWord(index)

      nextTick(() => {
        Array.from(lexGrammWords.value.querySelectorAll('.lex-word'))[Math.max(index - 1, 0)]
          .querySelector('.lex-condition input').focus()
      })
    }
    const onDeleteConditionHandler = (payload) => {
      form.value[FrontendSearchMode.LEX_GRAMM].words[payload.word] = form.value[FrontendSearchMode.LEX_GRAMM].words[payload.word].filter(x => x['cardId'] !== payload['cardId'])
    }
    const onUpdateField = (payload) => {
      form.value[FrontendSearchMode.LEX_GRAMM].words[payload.word].find(x => x['cardId'] === payload['cardId'])[payload.name] = payload.value
    }

    const onSubmitForm = (type, resultType) => {
      document.body.dispatchEvent(new Event('validate'))

      if (!resultType) {
        resultType = getPrioritySavedResultType(
          searchStore[AVAILABLE_RESULT_TYPES],
          searchStore[ALL_STAT_FIELDS],
          type,
          query.value.corpus
        )
      }

      new Promise((resolve, reject) => {
        if (type === FrontendSearchMode.EXACT && !form.value[FrontendSearchMode.EXACT].req.text.v) {
          message.error($gettext('Пожалуйста, введите слово или фразу'))
          return reject()
        }

        if (type === FrontendSearchMode.LEX_GRAMM && invalidState.value[FrontendSearchMode.LEX_GRAMM]) {
          message.error($gettext('Пожалуйста, исправьте ошибки'))
          return reject()
        }

        if (type === FrontendSearchMode.PARA_LEX_GRAMM && invalidState.value[FrontendSearchMode.PARA_LEX_GRAMM]) {
          message.error($gettext('Пожалуйста, исправьте ошибки'))
          return reject()
        }

        if (type === FrontendSearchMode.COLLOCATIONS) {
          if (invalidState.value[FrontendSearchMode.COLLOCATIONS]) {
            message.error($gettext('Пожалуйста, исправьте ошибки'))
            return reject()
          }

          const keyFormValues = form.value[2][0].map(x => Object.values(x).filter(i => typeof i !== 'string')).flat()
          let isMinimalFilledForm = false
          let i = 0
          while (i < keyFormValues.length && !isMinimalFilledForm) {
            const val = keyFormValues[i].text.v.trim()
            switch (keyFormValues[i].fieldName) {
              case "lex":
              case "form":
                if (val && val !== "*") isMinimalFilledForm = true
                break
              default:
                if (val) isMinimalFilledForm = true
                break
            }
            i++;
          }

          if (!isMinimalFilledForm) {
            asyncGettext('Заполните одно из полей в секции ключ').then(trans_message => {
              message.error(trans_message)
            })

            return reject()
          }
        }

        return resolve()
      })
        .then(() => {
          const {
            params: defaultParams,
            freqParams
          } = getDefaultSettings(searchStore.config.value, false, query.value.corpus)
          
          const newQuery = SearchQuery.create({
            corpus: query.value.corpus,
            resultType: [resultType.value],
            subcorpus: query.value.subcorpus,
            params: {
              ...defaultParams,
              ...resultType.isChart ? {
                statFields: ['created'],
                statTemporalUnit: searchStore.config.value.statTemporalUnits[0] ?? TemporalUnit.UNIT_UNDEFINED
              } : {},
              ...resultType.isStats ? {statFields: searchStore[STAT_FIELDS]} : {},
              ...resultType?.extra ?? {}
            }
          })

          if (resultType.value === SearchResultType.NGRAM) {
            if (!newQuery.params.ngramSize.length) {
              const ngramSize = getNgramSize()
              // Валидный размер нграм
              if (0 < ngramSize && ngramSize <= 5) newQuery.params.ngramSize.push(ngramSize)
              else {
                newQuery.resultType = [SearchResultType.CONCORDANCE]
              }
            }
          }

          if (type === FrontendSearchMode.EXACT) {
            newQuery.exactForm = buildExactQuery(form.value[FrontendSearchMode.EXACT])
            if (resultType.value === SearchResultType.FREQUENCY) {
              newQuery.params.frequencyFields = [freqParams.field, freqParams.dist]
            }
          } else if (type === FrontendSearchMode.LEX_GRAMM) {
            if (invalidState.value[FrontendSearchMode.LEX_GRAMM]) {
              message.error($gettext('Пожалуйста, исправьте ошибки'))
              return
            }

            newQuery.lexGramm = buildLexGrammQuery(form.value[FrontendSearchMode.LEX_GRAMM], false)
            if (resultType.value === SearchResultType.FREQUENCY) {
              newQuery.params.frequencyFields = new Array(newQuery.lexGramm.sectionValues[0].subsectionValues.length * 2 - 1)
                .fill("")
                .map((x, index) => index % 2 === 0 ? freqParams.field : freqParams.dist)
            }
          } else if (type === FrontendSearchMode.COLLOCATIONS) {
            newQuery.collocation = buildLexGrammQuery({
              words: form.value[FrontendSearchMode.COLLOCATIONS]
            }, false)
            // выставление дефолтной сортировки для коллокаций (есть всегда)
            newQuery.params.sort = 'dice'
          } else if (type === FrontendSearchMode.PARA_LEX_GRAMM) {
            newQuery.paraLexGramm = buildLexGrammQuery(
              form.value[FrontendSearchMode.PARA_LEX_GRAMM].map(x => {
                return {
                  extra: x.extra,
                  words: x.words
                }
              }),
              false
            )
          } else {
            throw Error('Search type in not setup')
          }

          localStorage.setItem(
            `default_search_mode_${corpusToString(query.value.corpus, '_', true)}_${type}`,
            JSON.stringify({
              resultType: resultType.value,
              isChart: Boolean(resultType.isChart),
              isStats: Boolean(resultType.isStats)
            })
          )

          reachGoal(buildGoalTarget(query.value.corpus, type))
          PrioritySearchMode.set(type)

          searchStore[SEARCH_ACTIONS.CLEAR_EXPLAINS](true, false)
          router.push({
            name: 'search',
            query: {
              search: encodeSearchQuery(newQuery)
            }
          })
        })
        .catch(() => void (0))
    }

    const handleResetLexGramm = () => {
      form.value[FrontendSearchMode.LEX_GRAMM].words = []
      form.value[FrontendSearchMode.LEX_GRAMM].extra = {}
      onAddWordHandler({index: 0, cards: lexGrammConfig.value.cards})
      verifyWordListAsToFirstWordCondition(false)
      lexGrammConfig.value.beforeCards.forEach(condition => {
        form.value[FrontendSearchMode.LEX_GRAMM].extra[condition.name] = getDefaultFieldValue(condition)
      })
    }

    const handleResetCollocation = () => {
      collocationRef.value.reset()
    }

    const fetchStats = () => {
      if (query.value.subcorpus) {
        const _query = SearchQuery.create({
          corpus: query.value.corpus,
          subcorpus: query.value.subcorpus,
          params: getDefaultSettings(searchStore.config.value, false, query.value.corpus).params,
          resultType: [SearchResultType.META]
        })

        searchStore.performSearch(_query);
      } else {
        searchStore.fetchStats(query.value.corpus);
      }
    }

    const setSearchMode = (searchMode) => {
      if (activeSearchMode.value === searchMode) activeSearchMode.value = null
      else activeSearchMode.value = searchMode
    }

    searchStore.$onAction(
      ({
         name,
         after
       }) => {
        after((result) => {
          if (name === 'performSearch') {
            rootStore.setCounters(
              result['payload']['corpStats'],
              query.value.subcorpus ? result['payload']['queryResults'][0]['queryStats'] : {}
            )
          }

          if (name === 'fetchStats') rootStore.setCounters(result['payload']['corpStats'])
        })
      }
    )

    Promise.all([
      portraitStore.portraitDescription(query.value.corpus),
      searchStore.initSearch(query.value.corpus)
    ]).then(([portrait, result]) => {
      const lexGrammCards = result['lex']['cards'];

      /** Наполнение данным из запроса. Для каждого типа свой сценарий */

      if (query.value.lexGramm && query.value.lexGramm.sectionValues.length) {
        const filledLexGrammForm = lexGrammQueryToForm(query.value.lexGramm, result['lex'])[0]

        form.value[FrontendSearchMode.LEX_GRAMM] = {
          words: filledLexGrammForm.words,
          extra: filledLexGrammForm.extra
        }

      } else {
        onAddWordHandler({
          index: 0, cards: lexGrammCards
        })
        verifyWordListAsToFirstWordCondition(false)

        result['lex']['beforeCards'].forEach(condition => {
          form.value[FrontendSearchMode.LEX_GRAMM].extra[condition.name] = getDefaultFieldValue(condition)
        })
      }

      if (query.value.collocation) {
        query.value.collocation.sectionValues[0].subsectionValues
          .forEach((x, index) => {
            const availableFieldNames = x.conditionValues.map(x => x.fieldName)

            const sectionForm =
              configToForm(result.collocation.sections[index].cards, true)
                .filter(fieldsCard => {
                  const cardKeys = Object.keys(fieldsCard).filter(x => x !== 'cardId')
                  return intersection(availableFieldNames, cardKeys).length > 0
                })
                .map(fieldsCard => {
                  Object.keys(fieldsCard).filter(x => x !== 'cardId')
                    .forEach(fieldName => {
                      fieldsCard[fieldName] = x.conditionValues.find(x => x.fieldName === fieldName)
                    })
                  return fieldsCard
                })


            form.value[FrontendSearchMode.COLLOCATIONS][index].push(...sectionForm)
          })
      }

      // Fill exact form
      const exactConditions = query.value.exactForm?.sectionValues[0].conditionValues ?? []
      result.exact.fields.forEach(exactField => {
        form.value[FrontendSearchMode.EXACT][exactField.name] = exactConditions.find(x => x.fieldName === exactField.name) ?? getDefaultFieldValue(exactField)
      })

      if (result.paraLexGramm) {
        if (query.value.paraLexGramm && query.value.paraLexGramm.sectionValues.length) {
          form.value[FrontendSearchMode.PARA_LEX_GRAMM] = query.value.paraLexGramm.sectionValues.map((form, index) => {
            return {
              ...lexGrammQueryToForm({
                sectionValues: [form]
              }, result["paraLexGramm"].forms[index])[0],
              langId: `lang-${index}`
            }
          })
        } else {
          nextTick(() => {
            parallelLexGrammRef.value.initForm()
          })
        }
      }

      fetchStats();


      if (query.value.lexGramm) {
        activeSearchMode.value = FrontendSearchMode.LEX_GRAMM
      } else if (query.value.exactForm) {
        activeSearchMode.value = FrontendSearchMode.EXACT
      } else if (query.value.collocation) {
        activeSearchMode.value = FrontendSearchMode.COLLOCATIONS
      } else if (query.value.paraLexGramm) {
        activeSearchMode.value = FrontendSearchMode.PARA_LEX_GRAMM
      } else {
        let prioritySearchMode = null

        switch (query.value.corpus.type) {
          case CorpusType.MURCO:
            prioritySearchMode = FrontendSearchMode.PARA_LEX_GRAMM
            break
          default:
            prioritySearchMode = PrioritySearchMode.get() ?? FrontendSearchMode.LEX_GRAMM
        }

        if (prioritySearchMode === FrontendSearchMode.COLLOCATIONS && !searchStore.collocation) {
          prioritySearchMode = FrontendSearchMode.LEX_GRAMM
        }

        if (prioritySearchMode === FrontendSearchMode.PARA_LEX_GRAMM && !searchStore.paraLexGramm) {
          prioritySearchMode = FrontendSearchMode.LEX_GRAMM
        }

        activeSearchMode.value = prioritySearchMode
      }
    })


    const keyPressEnterHandler = (event) => {
      if (event.keyCode !== 13 && activeSearchMode.value !== null) return
      onSubmitForm(activeSearchMode.value)
      event.preventDefault()
    }
    onMounted(() => {
      document.body.addEventListener('keypress', keyPressEnterHandler)
      reachGoal(`${buildGoalTarget(query.value.corpus)}_FORM`)
    })

    onUnmounted(() => {
      document.body.removeEventListener('keypress', keyPressEnterHandler)
    })

    const getNgramSize = () => {
      if (!availableResultTypes.value.includes(SearchResultType.NGRAM)) return 0
      
      if (activeSearchMode.value === FrontendSearchMode.EXACT) {
        return form.value[FrontendSearchMode.EXACT]?.req.text.v ? form.value[FrontendSearchMode.EXACT].req.text.v.trim().replace(/ {1,}/g, " ").split(' ').length : 1
      } else if (activeSearchMode.value === FrontendSearchMode.LEX_GRAMM) {
        return form.value[FrontendSearchMode.LEX_GRAMM].words.length
      }
      return 0
    }

    const searchTypeList = computed(() => {
      if (activeSearchMode.value === null) return []

      const nextResultType = getPrioritySavedResultType(
        searchStore[AVAILABLE_RESULT_TYPES],
        searchStore[ALL_STAT_FIELDS],
        activeSearchMode.value,
        query.value.corpus
      )

      const nextNgramSize = getNgramSize()

      const searchResultTypesList = [
        {label: $gettext('Конкорданс'), value: SearchResultType.CONCORDANCE},
        {label: $gettext('KWIC'), value: SearchResultType.KWIC},
        {
          label: $gettext('График по годам'),
          value: SearchResultType.STATS,
          isChart: true
        },
        {
          label: $gettext('Статистика'),
          value: SearchResultType.STATS,
          isStats: true
        },
        {
          label: $gettext('Частотность'),
          value: SearchResultType.FREQUENCY,
        },
        {label: $gettext('1-граммы'), value: SearchResultType.NGRAM, ngramSize: 1, extra: {ngramSize: [1]}},
        {label: $gettext('2-граммы'), value: SearchResultType.NGRAM, ngramSize: 2, extra: {ngramSize: [2]}},
        {label: $gettext('3-граммы'), value: SearchResultType.NGRAM, ngramSize: 3, extra: {ngramSize: [3]}},
        {label: $gettext('4-граммы'), value: SearchResultType.NGRAM, ngramSize: 4, extra: {ngramSize: [4]}},
        {label: $gettext('5-граммы'), value: SearchResultType.NGRAM, ngramSize: 5, extra: {ngramSize: [5]}},
      ]

      const searchResultTypesListMarkedActive = searchResultTypesList
        .filter(resultType => {
          switch (resultType.value) {
            case SearchResultType.CONCORDANCE:
              return availableResultTypes.value.includes(SearchResultType.CONCORDANCE)
            case SearchResultType.KWIC:
              return availableResultTypes.value.includes(SearchResultType.KWIC)
            case SearchResultType.FREQUENCY:
              return availableResultTypes.value.includes(SearchResultType.FREQUENCY)
            case SearchResultType.STATS:
              if (resultType.isChart) return availableResultTypes.value.includes(SearchResultType.STATS) && searchStore[GRAPHIC_FIELDS].length
              else if (resultType.isStats) return availableResultTypes.value.includes(SearchResultType.STATS) && searchStore[STAT_FIELDS].length
              break
            case SearchResultType.NGRAM:
              const available = availableResultTypes.value.includes(SearchResultType.NGRAM)
              if (!available || activeSearchMode.value === null) return false

              return resultType.ngramSize >= nextNgramSize
          }
        })
        .map(resultType => {
          if (resultType.value === nextResultType.value) {
            switch (nextResultType.value) {
              case SearchResultType.NGRAM:
                resultType.isActive = resultType.ngramSize === nextNgramSize
                break
              case SearchResultType.STATS:
                if (resultType.isChart) resultType.isActive = nextResultType.isChart
                if (resultType.isStats) resultType.isActive = nextResultType.isStats
                break
              default:
                resultType.isActive = true
            }
          }
          return resultType
        })

      if (!searchResultTypesListMarkedActive.find(x => x.isActive) && searchResultTypesListMarkedActive.length) {
        searchResultTypesListMarkedActive[0].isActive = true
      }

      return searchResultTypesListMarkedActive
    })

    const setValidFormStatus = (key, state) => {
      invalidState.value[key] = state
    }

    const hasCollocation = computed(() => {
      return searchStore.hasOwnProperty('collocation') && searchStore.collocation
    })
    const hasParaLexGramm = computed(() => {
      return searchStore.hasOwnProperty('paraLexGramm') && searchStore.paraLexGramm
    })

    watch(
      () => route.query.search,
      () => {
        searchStore[SEARCH_ACTIONS.REFRESH]()
      }, {
        immediate: true
      })
    return {
      exact: exactConfig,
      lex: lexGrammConfig,
      collocation: collocationConfig,
      activeSearchMode,
      setSearchMode,
      modal,
      form,
      search: searchStore,
      CURRENT_CORPUS_CONFIG,
      configToForm: configToForm,
      words: computed(() => form.value[FrontendSearchMode.LEX_GRAMM].words),
      onAddWordHandler,
      onDeleteWordHandler,
      onDeleteConditionHandler,
      onUpdateField,
      onAddConditionHandler,
      onSubmitForm,
      handleResetLexGramm,
      handleResetCollocation,
      configIsReady,
      lexGrammWords,
      searchTypeList,
      currentCorpus,
      EXACT: FrontendSearchMode.EXACT,
      LEX_GRAMM: FrontendSearchMode.LEX_GRAMM,
      COLLOCATION: FrontendSearchMode.COLLOCATIONS,
      FSM: FrontendSearchMode,
      SRT: SearchResultType,
      setValidFormStatus,
      hasCollocation,
      hasParaLexGramm,
      paraLexGrammForm,
      collocationRef,
      parallelLexGrammRef,
    }
  },
}
</script>

<style scoped lang="scss">
.corpus-search-tab {
  margin-bottom : rem-calc(32);

  &__header {
    display         : flex;
    font-size       : rem-calc(18);
    gap             : rem-calc(10);
    justify-content : space-between;
    font-weight     : 500;
    align-items     : center;
    @include breakpoint(small up) {
      justify-content : flex-start;
      font-size       : rem-calc(24);
      gap             : rem-calc(18);
      font-weight     : 400;
    }
  }

  &__title {
    display         : flex;
    justify-content : flex-start;
    gap             : rem-calc(10);
    flex-grow       : 1;
    align-items     : center;
    @include breakpoint(small up) {
      justify-content : space-between;
      flex-grow       : 0;
      gap             : rem-calc(18);
    }
  }

  &__annotation {
    font-size     : rem-calc(14);
    margin-bottom : rem-calc(27);
    @include breakpoint(small up) {
      font-size : rem-calc(16);
    }
  }

  &__toggle {
    &-mobile {
      @include breakpoint(small up) {
        display : none;
      }
    }

    &-desktop {
      display : none;
      @include breakpoint(small up) {
        display : flex;
      }
    }
  }

  &__exact-search {
    width      : 100%;
    margin-top : rem-calc(16);
    @include breakpoint(medium up) {
      margin-top : 0;
    }
  }

  &__to-search-wrap {
    margin-top     : rem-calc(40);
    width          : 100%;
    display        : flex;
    gap            : rem-calc(20);
    flex-direction : column;

    @include breakpoint(small up) {
      margin-top     : rem-calc(80);
      flex-direction : row;
      .button {
        width : rem-calc(272);
      }
    }
  }

  &__body {
    padding-top : rem-calc(19);
  }

  &__before-cards {
    padding-bottom : rem-calc(10);
    display        : flex;
    flex-direction : column;
    gap            : rem-calc(15);
  }

  &:last-child {
    margin-bottom : 0;
  }
}

.corpus-inner-content {
  position       : relative;
  min-height     : rem-calc(410);
  padding-bottom : rem-calc(80);
  @include breakpoint(medium up) {
    min-height     : rem-calc(510);
    padding-bottom : rem-calc(140);
  }
}

.corpus-search-grant {
  padding-top : rem-calc(40);
  font-size   : rem-calc(14);
  line-height : 1.5;
  opacity     : .6;

  @include breakpoint(small up) {
    font-size : rem-calc(16);
  }
}

.exact-form-search-fields {
  display        : flex;
  flex-direction : column;
  gap            : 15px;
}
</style>
