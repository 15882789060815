<template>
  <div class="the-header the-header--corpus" :class="{'is-compact': isCompactModeOfSections}">
    <div class="container">
      <div class="the-header__wrapper">
        <div class="the-header__name">{{ $gettext('Корпус') }}</div>
        <transition-group>
          <div class="the-header__actions" v-if="!isCompactModeOfSections || isPortrait">
            <round-button :aria-label="$gettext('Сменить корпус')" @click="toggleCorpusSelect"
                          :active="openedCorpusSelector"
                          :mobile-compact="true" class="the-header__corpus-selector" color="white">
              <template v-slot:label>
                <span class="corpus-name">
                  <span class="corpus-name__parent" v-if="currentCorp.parent_title"
                        v-text="currentCorp.parent_title" />
                  <span>{{ currentCorp.title }}</span>
                </span>
              </template>
              <icon name="c-arrow"></icon>
            </round-button>
          </div>
          <div class="the-header__stats" v-if="!isCompactModeOfSections">
            <ul class="the-header__stats-counter">
              <li v-if="counters.corpus.textCount">{{ textCounter }}</li>
              <li v-if="showSentenceCount">{{ sentenceCounter }}</li>
              <li v-if="counters.corpus.wordUsageCount">{{ wordUsageCounter }}</li>
            </ul>
            <div v-if="plainTagList" class="the-header__stats-tags" v-text="plainTagList" />
          </div>
        </transition-group>
        <div class="the-header__last">
          <div></div>
          <div class="the-header__controls">
            <the-tooltip v-if="currentCorp.slug" :title="$gettext('Об этом корпусе')">
              <router-link :to="portraitUrl">
                <round-button :aria-label="$gettext('Об этом корпусе')" color="white" fill>
                  <icon name="info" />
                </round-button>
              </router-link>
            </the-tooltip>
            <the-tooltip v-if="currentCorp.slug && routeName !== 'word-portrait'" :title="currentCorp.i18n.goto_portrait_label">
              <router-link :to="{name: 'word-portrait', params: {id: currentCorp.slug}}">
                <round-button :aria-label="currentCorp.i18n.goto_portrait_label" color="white" fill>
                  <icon name="word-portrait" />
                </round-button>
              </router-link>
            </the-tooltip>
            <the-tooltip :title="copyTooltipMessage"
                         @visible-change="($event)=> !$event ? copyTooltipMessage = defaultCopyMessage : void (0)">
              <round-button :aria-label="$gettext('Скопировать запрос')" color="white" @pointerup="handleCopyUrl" fill>
                <icon name="c-share"></icon>
              </round-button>
            </the-tooltip>
            <the-tooltip v-if="allowCompactMode" :title="toggleCompactModeMessage">
              <round-button color="white" @click="rootStore.toggleCompactMode" :active="!compactMode"
                            :aria-label="toggleCompactModeMessage" fill>
                <icon name="c-arrow"></icon>
              </round-button>
            </the-tooltip>
            <the-tooltip v-if="isPortrait" :title="$gettext('Перейти к поиску')">
              <router-link v-if="!currentCorp.is_old" :to="searchUrl">
                <round-button color="white" :aria-label="$gettext('Перейти к поиску')" fill>
                  <icon name="c-search"></icon>
                </round-button>
              </router-link>
              <round-button v-else color="white" :aria-label="$gettext('Перейти к поиску')"
                            :href="currentCorp.url" target="_blank" fill>
                <icon name="c-search"></icon>
              </round-button>
            </the-tooltip>
          </div>
        </div>
        <transition>
          <nav class="corpus-select" v-if="openedCorpusSelector && !isCompactModeOfSections"
               :class="{'corpus-select--loader': changeCorpInProcessing}">
            <ul v-if="tagList.length" class="tag-list">
              <li v-for="tag in tagList" :key="tag.slug">
                <button class="tag-list__el" @click="toggleSelectTag(tag.slug)"
                        :class="{'active': selectedTags.includes(tag.slug)}">
                  {{ tag.title }}
                </button>
              </li>
            </ul>
            <ul class="corpus-select__primary">
              <li v-for="(corpus) in corpusList" :key="corpus.id">
                <change-corp-button :data="corpus" @change-corp="handleChangeCorp" :is-portrait="isPortrait"
                                    :model-value="selectedTags" />
                <ul v-if="corpus.children && corpus.children.length" class="corpus-select__second">
                  <li v-for="(child_corpus) in corpus.children" :key="child_corpus.id">
                    <change-corp-button :data="child_corpus" @change-corp="handleChangeCorp"
                                        :is-portrait="isPortrait" :model-value="selectedTags" />
                  </li>
                </ul>
              </li>
            </ul>
            <div class="corpus-select__close" v-if="!changeCorpInProcessing">
              <round-button aria-label="Закрыть окно" :outline="true" size="large" @click="closeCorpusSelect">
                <icon name="cross" />
              </round-button>
            </div>
            <loader class="corpus-select__loader" v-if="changeCorpInProcessing" />
          </nav>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import RoundButton from "../ui/RoundButton";
import Icon from "../ui/Icon";
import { computed, inject, ref } from "vue";
import { numberFilter } from "../../filters/number";
import {
  useRootStore,
  THE_HEADER_IS_COMPACT_KEY,
  COUNTERS,
  CORPS_TREE,
  IS_SMALL_RESOLUTION, CORPS_TAGS,
} from "../../stores/root";
import TheTooltip from "../ui/form/TheTooltip";
import { clipboardWrite } from "../../../utils/clipboard";
import ChangeCorpButton from "./Components/ChangeCorpButton";
import Loader from "../ui/Loader";
import { CORPUS_INFO, CURRENT_CORPUS, usePortraitStore } from "../../stores/portrait";
import { encodeSearchQuery } from "../../utils/http";
import { CorpusType, SearchQuery } from "@ruscorpora/ruscorpora_api";
import { QUERY, useSearchStore } from "../../stores/search";
import { useRoute } from "vue-router";
import { reachGoal } from "../../utils/yandex-metrika";

export default {
  name: "CorpusHeader",
  components: {Loader, ChangeCorpButton, TheTooltip, Icon, RoundButton},
  props: {
    isPortrait: {
      type: Boolean,
      default: false
    },
    allowCompactMode: {
      type: Boolean,
      default: true
    },
    sticky: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const openedCorpusSelector = ref(false);
    const rootStore = useRootStore()
    const searchStore = useSearchStore()
    const portraitStore = usePortraitStore()
    const counters = computed(() => rootStore[COUNTERS])
    const compactMode = computed(() => rootStore[THE_HEADER_IS_COMPACT_KEY] && !props.isPortrait)
    const isSmallResolution = computed(() => rootStore[IS_SMALL_RESOLUTION])
    const corpusList = computed(() => rootStore[CORPS_TREE])
    const tagList = computed(() => rootStore[CORPS_TAGS])
    const isCompactModeOfSections = computed(() => compactMode.value && isSmallResolution.value)
    const request = inject('request')
    const $ngettext = inject('ngettext')
    const $gettext = inject('gettext')
    const $interpolate = inject('interpolate')
    const changeCorpInProcessing = ref(false)
    const currentCorp = computed(() => portraitStore[CORPUS_INFO])
    const selectedTags = ref([])
    const toggleSelectTag = (tag) => {
      if (selectedTags.value.includes(tag)) {
        selectedTags.value = selectedTags.value.filter(x => x !== tag)
      } else {
        selectedTags.value.push(tag)
      }
    }
    const route = useRoute()

    const openCorpusSelect = () => {
      openedCorpusSelector.value = true
      changeCorpInProcessing.value = true

      rootStore.getCorpsTree().then(() => {
        changeCorpInProcessing.value = false
      })
    }

    const closeCorpusSelect = () => {
      openedCorpusSelector.value = false
    }
    const toggleCorpusSelect = () => {
      openedCorpusSelector.value ? closeCorpusSelect() : openCorpusSelect()
    }

    const copyLinkInProgress = ref(false)

    const defaultCopyMessage = $gettext('Копировать ссылку на запрос')
    const successCopyMessage = $gettext('Ссылка на запрос скопирована')

    const copyTooltipMessage = ref(defaultCopyMessage)

    const handleCopyUrl = async () => {
      if (copyLinkInProgress.value) return
      copyLinkInProgress.value = true
      const makeShortUrl = async () => {
        const response = await request('/short-url', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({url: `${window.location.pathname}${window.location.search}`})
        }).finally(() => {
          copyLinkInProgress.value = false
        })

        return new Blob([`${window.location.protocol}//${window.location.host}/s/${response.shortly_url}`], {type: 'text/plain'})
      }


      clipboardWrite(makeShortUrl()).then(() => {
        copyTooltipMessage.value = successCopyMessage;
      })
      reachGoal('TARGET_SHARE')
    }

    const defaultExpandMessage = $gettext('Развернуть параметры запроса')
    const activeExpandedMessage = $gettext('Свернуть параметры запроса')

    const toggleCompactModeMessage = computed(() => compactMode.value ? defaultExpandMessage : activeExpandedMessage)


    const textCounter = computed(() => {
      const message = $ngettext('%(count)s документ', '%(count)s документа', counters.value.corpus.textCount)
      return $interpolate(message, {count: numberFilter(counters.value.corpus.textCount)}, true)
    })
    const sentenceCounter = computed(() => {
      const message = $ngettext('%(count)s sentence', '%(count)s sentences', counters.value.corpus.sentenceCount)
      return $interpolate(message, {count: numberFilter(counters.value.corpus.sentenceCount)}, true)
    })
    const wordUsageCounter = computed(() => {
      const message = $ngettext('%(count)s слово', '%(count)s слова', counters.value.corpus.wordUsageCount)
      return $interpolate(message, {count: numberFilter(counters.value.corpus.wordUsageCount)}, true)
    })
    const handleChangeCorp = (visible) => {
      changeCorpInProcessing.value = visible
      if (!visible) closeCorpusSelect()
    }

    const plainTagList = computed(() => {
      return currentCorp.value.tags.map(x => x.title).join(', ')
    })

    const currentSearchQuery = computed(() => {
      if (!searchStore[QUERY] || !portraitStore[CURRENT_CORPUS]) return {}
      return encodeSearchQuery(
        SearchQuery.create({
          corpus: portraitStore[CURRENT_CORPUS],
          subcorpus: searchStore[QUERY].subcorpus,
          params: searchStore[QUERY].params
        })
      )
    })

    const portraitUrl = computed(() => {
      return {
        name: 'portrait-about',
        params: {id: currentCorp.value.slug},
        query: !currentCorp.value.is_old ? {
          search: currentSearchQuery.value
        } : null
      }
    })
    const searchUrl = computed(() => {
      return {
        name: 'forms',
        query: !currentCorp.value.is_old ? {
          search: currentSearchQuery.value
        } : null
      }
    })

    const routeName = computed(() => route.name)
    const showSentenceCount = computed(() => {
      return counters.value.corpus.sentenceCount && [CorpusType.SYNTAX].includes(portraitStore[CURRENT_CORPUS].type)
    })

    return {
      openedCorpusSelector,
      isCompactModeOfSections,
      rootStore,
      counters,
      compactMode,
      corpusList,
      toggleCorpusSelect,
      closeCorpusSelect,
      copyTooltipMessage,
      handleCopyUrl,
      textCounter,
      sentenceCounter,
      showSentenceCount,
      wordUsageCounter,
      toggleCompactModeMessage,
      handleChangeCorp,
      changeCorpInProcessing,
      currentCorp,
      defaultCopyMessage,
      plainTagList,
      tagList,
      selectedTags,
      toggleSelectTag,

      portraitUrl,
      searchUrl,
      routeName,
    }
  }
}
</script>


<style lang="scss" scoped>
.corpus-select {
  width          : 100%;
  line-height    : 1.5;
  order          : 4;
  padding-top    : rem-calc(20);
  padding-bottom : rem-calc(53);

  @include breakpoint(medium up) {
    position : relative;
  }


  &__primary {
    & > li {
      font-size     : rem-calc(20);
      margin-bottom : rem-calc(28);

      & > a, & > button {
        font-weight : 700;
      }

      @include breakpoint(medium up) {
        margin-bottom : rem-calc(15);
      }

      &:last-child {
        margin-bottom : 0;
      }
    }

    a, button {
      color           : #fff;
      text-decoration : none;

      @include breakpoint(medium up) {
        color : var(--black);
        &:hover {
          text-decoration : underline;
        }
      }

      &.disabled {
        pointer-events : none;
        opacity        : .6;
      }
    }
  }

  &__second {
    display               : grid;
    grid-template-columns : repeat(2, #{rem-calc(145)});
    padding-left          : rem-calc(43);
    gap                   : rem-calc(14 3);
    margin-top            : rem-calc(13);

    li {
      font-size : rem-calc(16);
    }

    @include breakpoint(medium up) {
      padding-left : rem-calc(57);
      display      : flex;
      flex-wrap    : wrap;
      margin-top   : rem-calc(10);
      gap          : rem-calc(9 30);
    }
  }

  &__close {
    display : none;
    @include breakpoint(medium up) {
      display  : block;
      position : absolute;
      right    : rem-calc(33);
      top      : rem-calc(33);
    }
  }

  &__loader {
    position  : fixed;
    left      : 50%;
    top       : 50vh;
    transform : translate(-50%, -50%);
    margin    : 0;
    z-index   : 2;
    @include breakpoint(medium up) {
      position : absolute;
      top      : 50%;
    }
  }

  &--loader {
    &::before {
      position   : absolute;
      inset      : rem-calc(-13) calc(var(--container-gap) * -1) rem-calc(-15);
      background : var(--white);
      opacity    : .4;
      content    : '';
      z-index    : 1;
      @include breakpoint(medium up) {
        inset : 0;
      }
    }
  }

  @include breakpoint(medium up) {
    position                   : absolute;
    background-color           : #D1E4F1;
    left                       : rem-calc(180);
    padding                    : rem-calc(40 47);
    top                        : rem-calc(-18);
    right                      : 0;
    width                      : auto;
    z-index                    : 1;
    border-bottom-left-radius  : rem-calc(10);
    border-bottom-right-radius : rem-calc(10);
  }
  @include breakpoint(large up) {
    left : rem-calc(230 + 55);
  }
}

.tag-list {
  padding-bottom : rem-calc(15);

  @include breakpoint(medium up) {
    padding-right : rem-calc(40);
  }

  &__el {
    border         : 1px solid var(--blue);
    max-width      : 100%;
    overflow       : hidden;
    text-overflow  : ellipsis;
    white-space    : nowrap;
    vertical-align : middle;
    border-radius  : 5px;
    padding        : rem-calc(12 18);
    color          : var(--blue);
    font-size      : rem-calc(14);
    line-height    : 1;

    &:hover {
      background-color : rgba(255, 255, 255, 0.4);
      text-decoration  : none !important;
    }

    &.active {
      color            : #000000 !important;
      background-color : white;
      border-color     : white;
    }

    @include breakpoint(small up) {
      border-color : var(--pale-blue);
      color        : var(--pale-blue);
    }
  }

  li {
    display       : inline-block;
    max-width     : 100%;
    overflow      : hidden;
    text-overflow : ellipsis;
    margin        : rem-calc(0 5 5 0);
  }
}
</style>

<style lang="scss">
.corpus-select {
  min-height : rem-calc(200);

  .icon-cross {
    width  : rem-calc(20);
    height : rem-calc(20);
  }
}

.corpus-name {
  display     : flex;
  align-items : center;
  padding     : 1px rem-calc(7) 1px 1px;
  @include breakpoint(medium up) {
    flex-direction : column;
    align-items    : flex-start;
    line-height    : 1.1;
  }

  &__parent {
    font-size : 1em;

    &:after {
      content      : ':';
      margin-right : 3px;
    }

    @include breakpoint(medium up) {
      font-size : .7em;
      &:after {
        display : none;
      }
    }
  }
}
</style>
