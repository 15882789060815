<template>
  <div class="collocation-form">
    <div class="collocation-box" v-for="(section, index) of collocationSections" :key="index">
      <div class="collocation-box__header">
        {{ section.title }}
      </div>
      <div class="collocation-box__cards">
        <cards-form v-model="collocationModel[index]" :search-cards="section.cards" :special-word-key="index"
                    @validate="handleError(index, $event)" />
      </div>
    </div>
  </div>
</template>

<script>
// Компонент для рендера формы поиска коллокаций
import CardsForm from "./cards-forms/CardsForm.vue";
import { useSearchStore } from "../../../stores/search";
import { computed, readonly, ref } from "vue";
import { configToForm } from "../../../utils/form";

export default {
  name: "Collocation",
  components: {CardsForm},
  props: {
    modelValue: {
      type: Array
    }
  },
  emits: ['validate', 'update:modelValue'],
  setup(props, {emit}) {
    const searchStore = useSearchStore();
    const errorState = ref([])

    const collocationSections = computed(() => readonly(searchStore.collocation.sections))

    const collocationModel = computed({
      get() {
        return props.modelValue
      },
      set(newValue) {
        // pass
      }
    })

    const handleError = (index, isValid) => {
      errorState.value[index] = isValid

      emit('validate', errorState.value.filter(x => x === false).length > 0)
    }

    const reset = () => {
      errorState.value = []
      emit(
        'update:modelValue',
        collocationSections.value.map(section => configToForm(section.cards))
      )
      emit('validate', true)
    }

    return {
      collocationSections,
      collocationModel,
      handleError,
      reset
    }
  }
}
</script>

<style scoped lang="scss">
.collocation-box {
  width          : var(--real-viewport);
  display        : flex;
  flex-direction : column;
  gap            : rem-calc(8);
  max-width      : 100%;

  &__header {
    font-size       : 16px;
    font-weight     : 700;
    display         : flex;
    justify-content : space-between;

    @include breakpoint(small up) {
      font-size   : 22px;
      font-weight : 400;
    }
  }

  &__actions {
    display : flex;
    gap     : rem-calc(8);
  }
}

.collocation-form {
  display        : flex;
  gap            : rem-calc(30);
  flex-direction : column;
  @include breakpoint(medium up) {
    flex-direction : row;
    align-items    : flex-start;
  }
}
</style>
