<template>
  <component v-once @click="showWordModal" :is="renderComponent" />
</template>

<script>
import { computed, h, inject, createVNode } from 'vue'
import { WordType, WordSource, FontAlias } from '@ruscorpora/ruscorpora_api'
import isEqual from "lodash/isEqual";
import TheTooltip from "../ui/form/TheTooltip";

export default {
  name: "Word",
  props: {
    payload: {
      type: Object,
      required: true
    },
    highlight: {
      type: [Object, null],
      default: null
    },
    docSource: {
      type: Object,
    },
    langInfo: {
      type: [Object, undefined]
    },
  },
  emits: ['open-word-modal'],
  setup(props, {emit}) {
    const asyncGettext = inject("asyncGettext")
    const showWordModal = () => {
      if (props.payload.type === WordType.WORD) {
        emit('open-word-modal', props.payload.source)
      }
    }
    const unknownUnicodeSymbol = '�'

    const isHiddenObsc = computed(() => {
      return props.payload['displayParams'].obsc && !props.payload['displayParams'].hit
    })
    const subFonts = ['hye', 'kor', 'hin', 'zho', 'jpn']

    const objectClass = () => {
      return {
        'hit': props.payload['displayParams'].hit || false,
        'emm': props.payload['displayParams'].emm,
        'italic': props.payload['displayParams'].italic,
        'bold': props.payload['displayParams'].bold,
        'sup': props.payload['displayParams'].sup,
        'sub': props.payload['displayParams'].sub,
        'underline': props.payload['displayParams'].underline,
        'obsc': isHiddenObsc.value,
        'word': props.payload.type === WordType.WORD,
        'plain': props.payload.type === WordType.PLAIN,
        'highlight': props.highlight && props.payload.type === WordType.WORD && isEqual(WordSource.create({
          ...props.payload.source,
          docSource: props.docSource
        }), props.highlight) || false,
        'old-rus-font': props.payload['displayParams'].fontAlias === FontAlias.OLD_RUS_ORTHLIB_FONT,
        'mid-rus-font': props.payload['displayParams'].fontAlias === FontAlias.MID_RUS_ORTHLIB_CIV_FONT,
        'birchbark-font': props.payload['displayParams'].fontAlias === FontAlias.BIRCHBARK_FONT,
        'ignore-cursive': (props.payload['displayParams'].hit || props.payload['displayParams'].italic) && props.langInfo && (subFonts.includes(props.langInfo.lang) && !props.langInfo.transliteration)
      }
    }

    const objectStyles = () => {
      return {
        'font-style': props.payload['displayParams'].hit && !(props.langInfo && subFonts.includes(props.langInfo.lang) && !props.langInfo.transliteration) ? 'italic' : 'normal',
      }
    }

    const insertBetween = (arr, elem) => {
      const result = [];
      for (let i = 0; i < arr.length; i++) {
        result.push(arr[i]);
        if (i !== arr.length - 1) {
          result.push(elem);
        }
      }
      return result;
    }

    const chunks = props.payload.text.split(unknownUnicodeSymbol)
    const renderComponent = h('span', {
      class: objectClass(),
      style: objectStyles()
    }, [
      insertBetween(
        chunks.map(x => createVNode('i', {innerHTML: x})),
        h(TheTooltip, {title: () => asyncGettext("Unidentified private symbol")}, () => h('i', '👻'))
      )
    ])

    return {
      renderComponent,
      showWordModal,
      objectClass,
      objectStyles,
    }
  }
}
</script>
