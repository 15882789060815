export const svguse = () => {
    let ajax = new XMLHttpRequest();

    const SVG_PATH = window?.App?.SVG_PATH ?? 'assets/sprite.svg';

    const getSVG = () => {
        ajax.open('GET', SVG_PATH, true);
        ajax.send();
        ajax.onload = function (e) {
            let div = document.createElement('div');
            div.classList.add('hide-svg');
            div.innerHTML = ajax.responseText;
            document.body.insertBefore(div, document.body.childNodes[0]);
        };
    }

    if (!document.body.querySelector('.hide-svg')) {
        getSVG();
    }
}
