<template>
  <span class="result-actions">
    <the-tooltip :title="copyTooltipMessage" ref="copyTooltip" @visible-change="handleChangeOpenCopy">
      <button class="result-actions__copy result-actions__action" @click="handleCopyContextAction"
              :aria-label="defaultCopyMessage">
        <icon name="copy-outline"/>
      </button>
    </the-tooltip>
    <template v-if="showExpandAction">
      <the-tooltip v-if="expandContextTooltip" :title="expandContextTooltip" :light="true">
        <button class="result-actions__context result-actions__action" @click="handleExpandContextAction">
          <icon name="context"/>
        </button>
      </the-tooltip>
      <button v-else class="result-actions__context result-actions__action" @click="handleExpandContextAction"
              :aria-label="expandLabelMessage">
        <icon name="context"/>
      </button>
    </template>
    <template v-if="syntaxStructure">
      <the-tooltip v-if="syntaxStructure.regular" :title="$gettext('Показать структуру')">
        <button class="result-actions__syntax result-actions__action" @click="showSyntaxTree(syntaxStructure.regular)">
          <icon name="syntax-tree-regular"/>
        </button>
      </the-tooltip>
      <the-tooltip v-if="syntaxStructure.split" :title="$gettext('Показать структуру с разделенными оборотами')">
        <button class="result-actions__syntax result-actions__action" @click="showSyntaxTree(syntaxStructure.split)">
          <icon name="syntax-tree-split"/>
        </button>
      </the-tooltip>
    </template>
  </span>
</template>

<script>
import Icon from "./Icon";
import { computed, defineAsyncComponent, inject, ref } from 'vue'
import TheTooltip from "./form/TheTooltip";
import { SyntaxStructure } from "@ruscorpora/ruscorpora_api";
import { useModal, useModalSlot, VueFinalModal } from "vue-final-modal";

export default {
  name: "ResultActions",
  components: {TheTooltip, Icon},
  emits: ['toggle-context', 'copy-context'],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    expandContextTooltip: {
      type: String,
      default: ""
    },
    showExpandAction: {
      type: Boolean,
      default: true
    },
    syntaxStructure: {
      type: SyntaxStructure,
      default: null
    }
  },
  setup(props, {emit}) {
    const copyTooltip = ref(null);
    const $gettext = inject('gettext');
    const isExpand = ref(false);
    const modalInstance = ref(null)

    const defaultCopyMessage = $gettext('Скопировать пример')
    const successCopyMessage = $gettext('Пример скопирован')
    const defaultExpandMessage = $gettext('Расширить контекст')
    const activeExpandMessage = $gettext('Сузить контекст')

    const copyTooltipMessage = ref(defaultCopyMessage)
    const expandLabelMessage = computed(() => isExpand.value ? defaultExpandMessage : activeExpandMessage)

    const handleCopyContextAction = () => {
      emit('copy-context');
      copyTooltipMessage.value = successCopyMessage
    }

    const handleExpandContextAction = () => {
      isExpand.value = !isExpand.value
      emit('toggle-context')
    }

    const handleChangeOpenCopy = (visible) => {
      if (!visible) {
        copyTooltipMessage.value = defaultCopyMessage
      }
    }

    if (props.syntaxStructure) {
      modalInstance.value = useModal({
        component: VueFinalModal,
        attrs: {
          class: "modal-container modal-container--white-overlay",
          "content-class": "modal-content",
        },
        slots: {
          default: useModalSlot({
            component: defineAsyncComponent(() => import("../ui/ModalMedia.vue")),
            attrs: {
              mediaContent: {},
              onClose() {
                modalInstance.value.close()
              }
            }
          })
        }

      })

    }
    const showSyntaxTree = (url) => {
      modalInstance.value.patchOptions({
        slots: {
          default: {
            attrs: {
              mediaContent: {
                url: url
              }
            }
          }
        }
      })

      modalInstance.value.open()
    }

    return {
      copyTooltip,
      copyTooltipMessage,
      defaultCopyMessage,
      expandLabelMessage,
      handleCopyContextAction,
      handleExpandContextAction,
      handleChangeOpenCopy,
      showSyntaxTree,
    }
  }
}
</script>

<style scoped lang="scss">
.result-actions {
  display     : inline-flex;
  align-items : center;
  gap         : rem-calc(16);
  transform   : translateY(rem-calc(4));
  transition  : ease-in .1s;
  opacity     : .4;

  &__icon {
    position : relative;

    &::before {
      left     : rem-calc(-8);
      right    : rem-calc(-8);
      bottom   : rem-calc(-8);
      top      : rem-calc(-8);
      position : absolute;
      content  : '';
    }
  }

  &__action {
    position : relative;

    &::before {
      position : absolute;
      left     : rem-calc(-4);
      right    : rem-calc(-4);
      bottom   : rem-calc(-5);
      top      : rem-calc(-5);
      content  : '';
    }
  }
}
</style>
