<script setup>
import { inject } from "vue";

const asyncGettext = inject('asyncGettext')
import TheTooltip from "./form/TheTooltip.vue";

const msg = () => asyncGettext('Нейрополе')
</script>

<template>
  <the-tooltip :title="msg" :mouse-enter-delay=".2" :over-zindex="true">
    <span class="neural-badge" ref="wrap">
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="31.7 41.5 146.6 127">
        <path fill="#4382ff" d="m105 41.5-73.3 127h146.6z"></path>
        <path fill="#bbe5ec" d="m140.9 146.9 37.4 21.6-73.3-127v43.2z"></path>
        <path fill="#2f1fc4" d="M105 84.7V41.5l-73.3 127 37.4-21.6z"></path>
        <path fill="#fefefe" d="m105 84.7-7.9 13.6 43.8 48.6z"></path>
        <path fill="#7ed8e6" d="M140.9 146.9 97.1 98.3l-28 48.6z"></path>
      </svg>
    </span>
  </the-tooltip>
</template>

<style scoped lang="scss">
.neural-badge {
  margin-left    : -20px;
  display        : inline-block;
  width          : 18px;
  height         : 18px;
  vertical-align : super;
  margin-bottom  : -7px;
  margin-top     : -5px;

  svg {
    display : block;
    width   : 100%;
    height  : 100%;
  }
}
</style>
