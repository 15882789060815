<script setup>
import { computed, inject, ref } from "vue";
import TheTable from "../ui/TheTable.vue";
import { TableValue } from "@ruscorpora/ruscorpora_api";
import { arrayToReadyForCopy } from "../../../utils/clipboard";
import Icon from "../ui/Icon.vue";
import TheButton from "../ui/TheButton.vue";

const props = defineProps({
  gestures: {type: [TableValue, Array]}
})

const $gettext = inject('gettext')

const columnOrderDict = [
  'gesture_actorname',
  'gesture_actorsex',
  'gesture_activeorgan',
  'gesture_gesturename',
  'gesture_gesturemeaning',
]

const columns = computed(() => {
  return props.gestures
      .filter(x => x.rowNumber === 0)
      .sort((a, b) => columnOrderDict.indexOf(a.columnLabel.v) - columnOrderDict.indexOf(b.columnLabel.v))
      .map(val => {
        return {
          type: 'text',
          text: val.columnLabel.humanReadable,
          key: val.columnLabel.v
        }
      })
})

/* преобразуем плоский список значений в массив объектов */
const rows = computed(() => {
  const chunks = {};

  /* делим массив на строки по rowNumber */
  props.gestures.forEach(obj => {
    const rowNumber = obj.rowNumber;
    if (!chunks[rowNumber]) {
      chunks[rowNumber] = [];
    }
    chunks[rowNumber].push(obj);
  });

  return Object.values(chunks)
      .map(chunk => {
        return chunk.reduce((res, tableValue) => {
          res[tableValue.columnLabel.v] = tableValue.strValue
          return res
        }, Object.create({}))
      })
})

const hiddenCopyData = computed(() => {
  const data = [
    columns.value.map(col => {
      return {
        val: col.text,
        style: {
          color: 'gray',
          border: '1px dashed gray',
          background: '#EAF1EF',
          padding: '3px'
        }
      }
    }),
    ...rows.value.map(row => {
      return columns.value.map(col => {
        return {
          val: row[col.key],
          style: {
            border: '1px dashed gray',
            padding: '3px'
          }
        }
      })
    })]

  const table = arrayToReadyForCopy(data, 'text/table_html')
  table.style.border = '1px dashed gray';
  table.style.borderCollapse = 'collapse';

  const plainText = arrayToReadyForCopy(data, 'text/ascii')

  return [
    plainText,
    table.outerHTML
  ]
})

const initialLayoutIsMobile = Boolean(window.innerWidth < 768)
const isOpen = ref(!initialLayoutIsMobile)
</script>

<template>
  <div class="gestures">
    <h3 v-if="!initialLayoutIsMobile" class="gestures-title">{{ $gettext('Жесты') }}:</h3>
    <the-button v-else @click="isOpen = !isOpen">
      <div class="gestures-title__show-table" :class="{'is-open': isOpen}">
        {{ !isOpen ? $gettext('Показать жесты') : $gettext('Скрыть жесты') }}
        <icon name="c-arrow" />
      </div>
    </the-button>
    <div class="gestures-table" :class="{'is-open': isOpen}">
      <div class="gestures-table__inner">
        <div class="gestures-table__inner-content">
          <the-table :columns="columns" :rows="rows" class="light" />
        </div>
      </div>
    </div>
  </div>

  <p style="display:none">
    <span :copy-content="'<br /><br />' + $gettext('Жесты') + '\n<br />'" />
    <span :copy-content="hiddenCopyData[0]" :copy-html-content="hiddenCopyData[1]" />
  </p>
</template>

<style scoped lang="scss">
.gestures-table {
  margin-bottom      : 8px;
  display            : grid;
  grid-template-rows : 0fr;
  transition         : ease-in .2s;
  overflow           : hidden;

  &.is-open {
    grid-template-rows : 1fr;
  }

  &__inner {
    min-height : 0;
    min-width  : 0;

    &-content {
      margin-top : 8px;
      width      : 100%;
      overflow-x : auto;
    }
  }
}

.gestures-title {
  font-size     : 13px;
  line-height   : 1;
  margin-bottom : 0;
  font-weight   : 700;
  @include breakpoint(medium up) {
    font-size : 15px;
  }

  &__show-table {
    display     : flex;
    align-items : center;
    gap         : rem-calc(8);
    white-space : nowrap;

    &.is-open {
      .icon-wrapper {
        transform : rotate(180deg);
      }
    }
  }
}

.gestures {
  margin-top : 18px;
}
</style>
