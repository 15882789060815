import { h } from "vue"
import WarningMessage from "../components/ui/WarningMessage.vue";

class RequestManager {
    constructor() {
        this.activeRequests = new Map();
        this.nextRequestId = 1;
    }

    addRequest(controller, requestId) {
        this.activeRequests.set(requestId, controller);
        return requestId;
    }

    removeRequest(requestId) {
        this.activeRequests.delete(requestId);
    }

    cancelRequest(requestId) {
        const controller = this.activeRequests.get(requestId);
        if (controller) {
            controller.abort();
            this.removeRequest(requestId);
        }
    }

    cancelAllRequests() {
        this.activeRequests.forEach(controller => {
            controller.abort();
        });
        this.activeRequests.clear();
    }
}

const requestManager = new RequestManager();

export function request(url, options, timeout = 5000, ignore501 = false, login = false, rawOutput = false, outputWithId = false) {
    const requestParams = {referrerPolicy: "no-referrer", ...options}

    requestParams.headers = {
        ...requestParams.headers
    }
    if (window.ACCOUNT?.user?.accessToken && login) {
        requestParams.headers['Authorization'] = `Bearer ${window.ACCOUNT.user.accessToken}`
    }

    const controller = new AbortController();
    const { signal } = controller;

    const requestId = requestManager.addRequest(controller, requestManager.nextRequestId++);

    const fetchPromise = fetch(url, {...requestParams, signal})

    const content = this
    const $gettext = content.$gettext;
    const $interpolate = content.$interpolate;
    const $ngettext = content.$ngettext;
    const message = content.$message;

    const fetchTimeout = setTimeout(() => {
        message.warning({
            content: $gettext('Запрос выполняется дольше обычного. Пожалуйста, дождитесь ответа'),
        })
    }, timeout)

    const result = fetchPromise.then(response => {
            if (!response.ok) {
                return Promise.reject(response)
            }
            if (response.headers.get('Content-Type').includes('application/json') && !rawOutput) return response.json()
            if (response.headers.get('Content-Type').includes('application/x-protobuf') && !rawOutput) return response.arrayBuffer()
            return response
        })
        .catch(async error => {
            if (error.code === 20) return Promise.reject({code: 20})
            if (!error.status) return Promise.reject({status: null})
            const detail = await error.json()

            if (error.status === 429) {
                const formatString = $gettext('Вы выполняли это действие слишком часто. Попробуйте через %(count)s %(second)s')

                message.warning({
                    content: $interpolate(formatString, {
                        count: error.headers.get('retry-after'),
                        second: $ngettext("секунда", "секунды", parseInt(error.headers.get('retry-after')))
                    }, true)
                })
            }

            if ([401, 403].includes(error.status)) {
                const key = new Date().getTime().toString()

                message.warning({
                    duration: '4',
                    key: key,
                    icon: () => '',
                    content: (
                        h(WarningMessage, {
                            message: detail.detail,
                            //showRegButton: error.status === 401,
                            gettext: $gettext,
                            onClose: () => {
                                message.destroy(key)
                            }
                        })
                    )
                })
            }

            if (error.status === 501 && !ignore501) message.warning($gettext('При выполнении запроса произошла ошибка'))

            return Promise.reject({status: error.status, ...detail})
        })
        .finally(() => {
            clearTimeout(fetchTimeout)
            requestManager.removeRequest(requestId);
        })

    if (outputWithId) return {requestPromise: result, requestId: requestId}

    return result
}


export default {
    install(app, options = {}) {
        const _req = request.bind({
            $gettext: app.config.globalProperties.$gettext,
            $interpolate: app.config.globalProperties.$interpolate,
            $ngettext: app.config.globalProperties.$ngettext,
            $message: app.config.globalProperties.$message,
        })
        app.config.globalProperties.$request = _req
        app.config.globalProperties.$requestManager = requestManager

        app.provide('request', _req)
        app.provide('requestManager', requestManager)

    }
}
