<script>
import { ModalsContainer, useModal, useModalSlot, VueFinalModal } from 'vue-final-modal'
import { computed, defineAsyncComponent, inject, onMounted, onUnmounted, ref } from "vue";
import { prefixUrl } from "../utils/helper";
import Icon from "../components/ui/Icon.vue";

export default {
  name: "App",
  components: {
    Icon,
    ModalsContainer
  },
  setup() {
    const visible = ref(true)
    const translatesIsFetched = ref(false)
    const translates = ref({})
    const request = inject('request')
    const loggedIn = ref(window.ACCOUNT?.user?.is_authenticated ?? false)
    const user = ref(window.ACCOUNT?.user ?? null)

    const modalInstance = useModal({
      component: VueFinalModal,
      attrs: {
        class: "modal-container",
        "content-class": "modal-content",
        modelValue: visible
      },
      slots: {
        default: useModalSlot({
          component: defineAsyncComponent(() => import("../components/auth/LoginForm.vue")),
          attrs: {
            onClose() {
              modalInstance.close()
            },
            translates: translates,
            loading: computed(() => !translatesIsFetched.value),
          }
        })
      }
    })
    const goLogin = () => {
      modalInstance.open()
      if (!translatesIsFetched.value) {
        request(
            prefixUrl('/api/accounts/rest-login', true),
            {
              method: 'get',
              headers: {'Content-Type': 'application/json'}
            }
        )
            .then(r => {
              translates.value = r.i18n
              translatesIsFetched.value = true
            })
      }
    }
    const goLogout = () => {
      request(
          prefixUrl('/api/accounts/rest-logout', true),
          {
            method: 'post',
            headers: {'Content-Type': 'application/json'}
          }
      )
          .then(r => {
            if (window.ACCOUNT.goHomeAfter || window.location.href.includes('accounts')) {
              return window.location.href = window.ACCOUNT.goHomeAfter
            }

            window.location.reload()
          })
    }

    const container = ref()

    const openLoginExternalHandler = () => {
      goLogin()
    }

    onMounted(() => {
      if (container.value.closest('#vue-auth-app')) {
        window.addEventListener('open-login', openLoginExternalHandler)
      }
    })

    onUnmounted(() => window.removeEventListener('open-login', openLoginExternalHandler))

    return {
      goLogin,
      goLogout,
      loggedIn,
      user,
      container
    }
  }
}
</script>

<template>
  <div class="login-status-container" ref="container">
    <template v-if="loggedIn">
      <div class="round-wrapper">
        <div class="user-info" :title="user.email">
          <icon name="user" class="user-icon" />
          <span class="user-info__username">{{ user.email }}</span>
        </div>
        <button type="button" @click="goLogout">
          {{ $gettext('Выйти') }}
        </button>
      </div>
    </template>
    <template v-else>
      <button @click="goLogin" class="go-login round-wrapper" type="button">{{ $gettext('Войти') }}</button>
    </template>
  </div>
  <ModalsContainer />
</template>

<style scoped lang="scss">
.login-status-container {
  display         : flex;
  align-items     : center;
  justify-content : center;
}

.round-wrapper {
  border-radius : 25px;
  background    : var(--light-mint);
  line-height   : 14px;
  padding       : 8px 16px;
  font-size     : 12px;
  font-weight   : 500;
  display       : flex;
  align-items   : center;
  gap           : 23px;

  button {
    font-size : 1em;
  }

  & > * {
    position : relative;

    &::before {
      position   : absolute;
      top        : 0;
      bottom     : 0;
      right      : -12px;
      background : var(--black);
      opacity    : .15;
      content    : "";
      width      : 1px;
    }

    &:last-child {
      &::before {
        display : none;
      }
    }
  }
}

.user-info {
  display     : flex;
  gap         : 8px;
  align-items : center;
  font-weight : 700;

  &__username {
    max-width     : 160px;
    overflow      : hidden;
    text-overflow : ellipsis;
    white-space   : nowrap;
  }
}

.user-icon {
  width  : 12px;
  height : 15px;
}

.go-login {
  border-bottom : 0 !important;

  &:hover {
    background : var(--light-blue-hover);
  }
}
</style>
