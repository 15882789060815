<script setup>
import Icon from "../../ui/Icon.vue";
import TheTooltip from "../../ui/form/TheTooltip.vue";
import { computed, inject } from "vue";
import { SearchResultType } from "@ruscorpora/ruscorpora_api";
import { prepareExplainToView } from "../../../utils/helper";

/* props */
const props = defineProps({
  queryExplains: {type: Object},
  isSmallResolution: {type: Boolean, default: false},
  kwicPosition: {type: Number, default: 0},
  resultType: {type: Number, default: 0}
})

const emit = defineEmits(['change-kwic-pos'])

const queryExplains = computed(() => props.queryExplains)

const requestExplainValues = computed(() => {
  const explanations = []

  if (!queryExplains.value || !queryExplains.value.conditionExplanations.length) return explanations

  return [prepareExplainToView({conditionExplanations: queryExplains.value.conditionExplanations}, props.resultType === SearchResultType.COLLOCATION)]
})

const plainExplainValues = computed(() => {
  const explanations = []

  if (!queryExplains.value) return explanations

  return queryExplains.value.sectionExplanations
      .filter(sectionExplanation => sectionExplanation.subsectionExplanations.length)
      .map(sectionExplanation => {
        return sectionExplanation.subsectionExplanations.map(explanation => {
          return prepareExplainToView(explanation, props.resultType === SearchResultType.COLLOCATION)
        })
      })
})

const queryIsKWIC = computed(() => props.resultType === SearchResultType.KWIC);

const getLengthOfStringWithoutTags = (strInputCode) => {
  try {
    return strInputCode.replace(/(<([^>]+)>)/gi, "").length
  } catch (e) {
    return strInputCode.length
  }
}
</script>

<template>
  <ul v-if="requestExplainValues.length" class="the-header__conditions the-header__conditions--column"
      :class="{'the-header__conditions--touchable': queryIsKWIC}">
    <li v-for="(explanation) of requestExplainValues"
        class="the-header__condition the-header__condition--no-touch">
      <the-tooltip v-if="getLengthOfStringWithoutTags(explanation.short) || isSmallResolution" :title="explanation.detail">
        <span v-html="explanation.short"></span>
        <icon name="noty" extra-class="the-header__conditions-noty" />
      </the-tooltip>
      <span v-else>
        <span v-html="explanation.short"></span>
        <icon name="noty" extra-class="the-header__conditions-noty" />
      </span>
    </li>
  </ul>

  <ul v-for="(explanationGroup, explanationGroupIndex) of plainExplainValues" :key="explanationGroupIndex"
      class="the-header__conditions the-header__conditions--column"
      :class="{
        'the-header__conditions--touchable': queryIsKWIC,
        'the-header__conditions--with-marker': explanationGroup.length && explanationGroup[0].lang !== null,
        'the-header__conditions--with-marker--long': explanationGroup.length && explanationGroup[0].lang !== null && explanationGroup[0].lang.val.length > 3,
      }">
    <li v-for="(explanation, index) of explanationGroup"
        :key="`exl_group_${explanationGroupIndex}_${index}`"
        @click="emit('change-kwic-pos', explanationGroupIndex + index)"
        class="the-header__condition"
        :class="{'the-header__condition--is-active': kwicPosition === index && queryIsKWIC}">
      <the-tooltip v-if="getLengthOfStringWithoutTags(explanation.short) > 30 || isSmallResolution" :title="explanation.detail">
        <span v-html="explanation.short"></span>
        <icon v-if="kwicPosition === index && queryIsKWIC" name="c-check"
              extra-class="the-header__conditions-check" />
        <icon name="noty" extra-class="the-header__conditions-noty" />
      </the-tooltip>
      <span v-else>
        <span v-html="explanation.short"></span>
        <icon v-if="kwicPosition === index && queryIsKWIC" name="c-check"
              extra-class="the-header__conditions-check" />
        <icon name="noty" extra-class="the-header__conditions-noty" />
      </span>
    </li>
    <li v-if="explanationGroup.length && explanationGroup[0].lang !==null"
        class="the-header__condition-lang-marker">
      <the-tooltip :title="explanationGroup[0].lang.tooltip" :mouse-enter-delay=".3">
        {{ explanationGroup[0].lang.val }}
      </the-tooltip>
    </li>
  </ul>
</template>

<style scoped lang="scss">
.the-header {
  &__conditions {
    position : relative;
    --size: 32px;

    &--with-marker {
      padding-left : var(--size);

      &--long {
        --size: 66px;
      }

      @include breakpoint(medium up) {
        padding-left : 0;
      }
    }
  }

  &__condition-lang-marker {
    --marker-color : #9EC7DE;
    position       : absolute;
    left           : 0;
    display        : flex;
    height         : 100%;
    width          : var(--size);
    align-items    : center;
    color          : var(--marker-color);
    font-size      : 12px;
    font-family    : var(--cursive-font);
    font-style     : italic;

    @include breakpoint(medium up) {
      left : calc(var(--size) * -1);
    }

    &::before {
      width         : 10px;
      height        : 100%;
      left          : calc(var(--size) - 10px);
      top           : 0;
      content       : "";
      border-left   : 2px solid currentColor;
      border-radius : 5px 0 0 5px;
      position      : absolute;
    }
  }
}
</style>
