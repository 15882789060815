<template>
  <component :is="renderTag"
             :class="classList"
             v-bind="attrs"
             class="button">
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: "TheButton",
  props: {
    ariaLabel: {
      type: String,
      required: false
    },
    type: {
      type: String,
      default: 'default',
      validate: (x) => {
        return ['dashed', 'outline', 'colored', 'default', 'white', 'accent-default'].includes(x)
      }
    },
    mode: {
      type: [String, Array],
      default: 'default',
      validate: (x) => {
        return ['default', 'icon', 'expand', 'text'].includes(x)
      }
    },
    size: {
      type: String,
      default: 'default',
      validate: (x) => {
        return ['tiny', 'small', 'medium', 'medium-large', 'large', 'large-v2'].includes(x)
      }
    },
    href: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLink() {
      return this.href.length
    },
    typeClass() {
      return `button--color-${this.type}`
    },
    modeClass() {
      if (Array.isArray(this.mode)) {
        const clsObject = {}
        this.mode.forEach(x => {
          clsObject[`button--mode-${x}`] = true
        })
        return clsObject
      }
      return {
        [`button--mode-${this.mode}`]: true
      }
    },
    sizeClass() {
      return this.size
    },
    classList() {
      return {
        [this.typeClass]: true,
        [this.sizeClass]: true,
        ...this.modeClass
      }
    },
    renderTag() {
      if (this.plain) return (<span />)
      return this.isLink ? (<a />) : (<button />)
    },
    attrs() {
      const dynamicAttrs = {}

      if (this.href) dynamicAttrs.href = this.href
      else dynamicAttrs['type'] = 'button'
      if (this.disabled) dynamicAttrs.disabled = this.disabled
      if (this.ariaLabel) dynamicAttrs['aria-label'] = this.ariaLabel

      return dynamicAttrs
    }
  }
}
</script>
