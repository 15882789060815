import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import isArray from "lodash/isArray";
import { FrontendSearchState } from '@ruscorpora/ruscorpora_api';
import { prefixUrl } from "./helper";

const Buffer = require('buffer/').Buffer

export const bufferToBase64 = function (buffer) {
    return new Buffer.from(buffer).toString('base64')
}

export const baseUrl = () => {
    return process.env.BASE_URL || ''
}

export const encodeSearchQuery = (query, previousParams = null, docSource = null, wordSource = null, graphic = null) => {
    const searchState = FrontendSearchState.create({
        query: query,
        docSource: docSource,
        wordSource: wordSource,
        previousParams: previousParams,
        graphic: graphic
    })

    const encodedQuery = FrontendSearchState.encode(searchState).finish()
    return bufferToBase64(encodedQuery);
}

export const base64toBuffer = (base64) => {
    if (!base64) return null

    try {
        return new Buffer(base64, 'base64')
    } catch (e) {
        return null
    }

}

export function updateURLParameter(params = {}, pathname = '', mode = '') {
    const url = new URL(window.location.href);
    const search_params = new URLSearchParams();
    params = Object.assign({}, {search: ''}, params)

    Object.keys(params).filter(key => {
            if (typeof params[key] === 'string') {
                return params[key] !== ""
            } else if (isObject(params[key])) {
                return !isEmpty(params[key])
            } else if (isArray(params[key])) {
                return !isEmpty(params[key])
            }
            return true
        }
    ).forEach(key => {
        search_params.set(key, params[key]);
    })

    url.search = search_params.toString();

    if (pathname && url.pathname !== pathname) {
        url.pathname = pathname
    }

    if (mode === 'history') {
        history.pushState(null, null, url.toString());
    } else if (mode === 'replace') {
        history.replaceState(null, null, url.toString());
    } else if (mode === 'build') {
        return url.toString()
    } else {
        window.location.href = url.toString() + window.location.hash;
    }
}

export const getModulePath = (name) => {
    let modulePath = ''
    switch (name) {
        case 'subcorpus':
            modulePath = '/subcorpus'
            break
        case 'search':
            modulePath = '/search'
            break
        case 'results':
            modulePath = '/results'
            break
        case 'full-text':
            modulePath = '/full-text'
            break
        default:
            modulePath = '/search'
    }

    return prefixUrl(modulePath)
}

export const buildQueryUrl = (path, params) => {
    return updateURLParameter(params, path, 'build')
}
