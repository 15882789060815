import { defineStore } from 'pinia'
import {
    Corpus,
    CorpusType,
    SearchParams,
    PaginationParams,
    SearchQuery,
    SearchResult,
    SearchResultType,
    SearchFormValue,
    FrontendFormConfig,
    FrontendSearchState,
    SearchConfig,
    ConditionValue,
    TextConditionValue
} from '@ruscorpora/ruscorpora_api'
import { DefaultKWSZ, DefaultPagination } from "../utils/structure";
import set from 'lodash/set'
import unset from 'lodash/unset'
import cloneDeep from 'lodash/cloneDeep'
import { base64toBuffer, bufferToBase64, encodeSearchQuery, updateURLParameter } from "../utils/http";
import { ROUTE, COUNTERS, useRootStore } from "./root";
import { CORPUS_INFO, usePortraitStore } from "./portrait";
import { buildConditionValue, buildExactQuery } from "../utils/search";

export const CURRENT_CORPUS = 'currentCorpusGetter';
const CORPUS_CONFIGS_LIST = 'corpus_configs_list';
export const CURRENT_CORPUS_CONFIG = 'current_corpus_config';
export const QUERY = 'query';
export const RESULT_TYPE = 'resultType';
export const SEARCH_STATE = 'searchState';
export const QUERY_EXPLAINS = 'queryExplains';
export const SUBCORPUS_EXPLAINS = 'subcorpusExplains';
export const QUERY_EXPLAINS_LENGTH = 'queryExplainsLength';
export const READY = 'searchIsReady'
export const SORTING = 'availableSortingGetter'
export const STAT_FIELDS = 'statFieldsGetter'
export const ALL_STAT_FIELDS = 'allStatFieldsGetter'
export const GRAPHIC_FIELDS = 'graphicFieldsGetter'
export const CONFIG = 'configGetter'
export const AVAILABLE_RESULT_TYPES = 'availableResultTypesGetter'
export const SETTINGS = 'settingsGetter'
const SAVED_OPTIONS = 'saved_options'
export const SAVED_SUBCORPUS = 'saved_subcorpus'
export const NO_DIACRITIC = 'NO_DIACRITIC_GETTER'
import { getCookie } from "../../modules/cookie";
import { corpusToString, prefixUrl } from "../utils/helper";
import { RIGHT_CONTEXT_SORTING } from "../constatns";
import { computed } from "vue";

const updateSavedConfigs = (config) => {
    const configsList = JSON.parse(localStorage.getItem(CORPUS_CONFIGS_LIST)) || [];
    configsList.push(config)
    localStorage.setItem(CORPUS_CONFIGS_LIST, JSON.stringify(configsList))
}
export const SEARCH_ACTIONS = {
    REFRESH: 'refreshState',
    CLEAR_EXPLAINS: 'clearExplains',
    CLEAR_STATS: 'clearStats',
    RESET_SUBCORPUS: 'resetSubcorpus',
    LEX_GRAMM_DELETE_WORD: 'lex_gramm_delete_word',
    LEX_GRAMM_ADD_WORD: 'lex_gramm_add_word',
}
const cachedResponses = {}
export const useSearchStore = defineStore('search', {
    state: () => {
        return {
            'lex': {},
            'exact': {},
            collocation: null,
            paraLexGramm: null,
            'subcorpus': [],
            [SEARCH_STATE]: FrontendSearchState.create({
                query: {
                    subcorpus: null
                },
                graphic: null
            }),
            [QUERY_EXPLAINS]: [],
            [SUBCORPUS_EXPLAINS]: [],
            [READY]: false,
            fetching: false,
            config: SearchConfig.create(),
            previousRequestId: null,
            searchInProgress: false,
            loadedFonts: []
        }
    },
    getters: {
        'config': () => {
            const store = usePortraitStore()

            return computed(() => store[CORPUS_INFO].config)
        },
        [CURRENT_CORPUS]: (state) => state[SEARCH_STATE].query.corpus,
        [QUERY]: (state) => state[SEARCH_STATE].query,
        [QUERY_EXPLAINS_LENGTH]: (state) => {
            return state[QUERY_EXPLAINS].reduce((section_acc, section) => {
                return section_acc + section.sectionExplanations.reduce((acc, subsection) => acc + subsection.subsectionExplanations.length, 0)
            }, 0)
        },
        [RESULT_TYPE]: (state) => state[SEARCH_STATE].query.resultType[0],
        'routeName': () => {
            const rootStore = useRootStore()

            return rootStore[ROUTE]
        },
        [SORTING]: (state) => state.config.value?.sortings ?? [],
        [SETTINGS]: (state) => state.config.value?.settings ?? [],
        [ALL_STAT_FIELDS]: (state) => state.config.value?.statFields ?? [],
        [STAT_FIELDS]: (state) => state.config.value?.statFields.filter(x => x !== 'created') ?? [],
        [GRAPHIC_FIELDS]: (state) => state.config.value?.statFields.filter(x => x === 'created') ?? [],
        [AVAILABLE_RESULT_TYPES]: (state) => state.config.value?.availableResultTypes ?? [],
        [NO_DIACRITIC]: (state) => Boolean(state[SEARCH_STATE]?.query?.params?.noDiacritic || false),
    },
    actions: {
        async initSearch(corpus) {
            const {forms} = await this.getCorpusConfig(corpus)
            //await this.fetchConfig(corpus, true)

            const formConfig = FrontendFormConfig.fromObject(forms)
            this.lex = formConfig.lexGramm;
            this.paraLexGramm = formConfig.paraLexGramm;
            this.collocation = formConfig.collocation;
            this.exact = formConfig.exact;
            this.subcorpus = formConfig.subcorpus;
            this.forms = formConfig;
            this[READY] = true

            return {
                lex: this.lex,
                exact: this.exact,
                subcorpus: this.subcorpus,
                collocation: this.collocation,
                paraLexGramm: this.paraLexGramm,
            }
        },
        cleanUpSearchQuery(searchQuery) {
            const query = cloneDeep(searchQuery)

            // Condition for subcorpus
            if ([SearchResultType.META].includes(query.resultType[0])) {
                delete query.lexGramm
                delete query.paraLexGramm
                delete query.exactForm
                delete query.collocation
            }

            if ([SearchResultType.FULL_TEXT].includes(query.resultType[0])) {
                delete query.collocation
            }

            // Condition non KWIC
            if (query.resultType[0] !== SearchResultType.KWIC) {
                delete query.params.kwsz
                delete query.params.kwpos
            } else {
                // Выставление значение kwpos
                // Если пользователь вручную не установил kwpos, но выбрал одну из сортировок по правому контексту,
                // необходимо передавать -1
                if (!query.params.hasOwnProperty('kwpos')) {
                    query.params.kwpos = RIGHT_CONTEXT_SORTING.includes(query.params.sort) ? -1 : 0
                }
            }

            if (query.resultType[0] !== SearchResultType.FREQUENCY) {
                delete query.params.frequencyFields
                delete query.params.frequencyThreshold
                delete query.params.frequencyRawData
            }

            if (!query.resultType.includes(SearchResultType.STATS) && !query.resultType.includes(SearchResultType.GROUPED_STATS)) {
                //delete query.params.statTemporalUnit
                delete query.params.statFields
            }

            // Clear empty lexGramm
            if (query.hasOwnProperty('lexGramm')) {
                query.lexGramm.sectionValues.map(sectionValue => {
                    return sectionValue.subsectionValues.map(subsectionValue => {
                        return subsectionValue.conditionValues = subsectionValue.conditionValues.filter(conditionValue => {
                            return buildConditionValue(conditionValue)
                        })
                    })
                })
            }
            // Clear empty paraLexGramm
            if (query.hasOwnProperty('paraLexGramm')) {
                query.paraLexGramm.sectionValues.map(sectionValue => {
                    return sectionValue.subsectionValues.map(subsectionValue => {
                        return subsectionValue.conditionValues = subsectionValue.conditionValues.filter(conditionValue => {
                            return buildConditionValue(conditionValue)
                        })
                    })
                })
            }

            // Conditions for allExamples
            if (query.params.hasOwnProperty('docSource')) {
                // Clear params
                if ([
                    SearchResultType.CONCORDANCE,
                    SearchResultType.PARALLEL_CONCORDANCE
                ].includes(query.resultType[0])) {
                    query.params.pageParams = PaginationParams.create({page: query.params.pageParams.page})
                } else if (query.resultType[0] === SearchResultType.KWIC) {
                    query.params.pageParams = PaginationParams.create({
                        page: query.params.pageParams.page,
                        snippetsPerPage: query.params.pageParams.snippetsPerPage
                    })
                }
            }

            delete query.params.downloadAll
            return query
        },
        async fetchStats(corpus) {
            return this.request(prefixUrl(`/api/stats?corpus=${encodeURIComponent(JSON.stringify(corpus.toJSON()))}`, true))
                .then(res => {
                    return {
                        status: 200,
                        payload: SearchResult.fromObject(res)
                    }
                })
        },
        async fetchConfig(corpus, setState = true) {
            const query = encodeURIComponent(JSON.stringify(corpus.toJSON()))
            return this.request(prefixUrl(`/api/search/config?corpus=${query}&rev=${window.App.REV}`, true))
                .then(res => {
                    return SearchConfig.fromObject(res)
                    //if (setState) this.config = searchConfig
                    return searchConfig
                })
        },
        async performSearch(searchQuery, ignoreRates = false, ignoreExplains = false) {
            const payload = this.cleanUpSearchQuery(searchQuery)
            const ctx = this

            if (this.previousRequestId !== null && !ignoreRates) {
                this.requestManager.cancelRequest(this.previousRequestId)
                this.previousRequestId = null
            }
            this.searchInProgress = true

            console.log(payload)

            const {requestPromise, requestId} = this.request(
                prefixUrl(`/api/search?query=${encodeURIComponent(bufferToBase64(SearchQuery.encode(payload).finish()))}&output=pb`, true),
                {},
                5000,
                true,
                false,
                false,
                true
            );

            this.previousRequestId = requestId;

            return requestPromise
                .then(res => {
                    const searchResult = SearchResult.decode(new Uint8Array(res))

                    if (!ignoreExplains) {
                        this[QUERY_EXPLAINS] = searchResult.queryResults.map(x => x['queryExplain'])
                        this[SUBCORPUS_EXPLAINS] = searchResult.queryResults.map(x => x['subcorpusExplain'])
                    }

                    return {
                        status: 200,
                        payload: searchResult,
                        exact: searchQuery.hasOwnProperty('exactForm') && searchQuery.exactForm !== null,
                        lexGramm: searchQuery.hasOwnProperty('lexGramm') && searchQuery.lexGramm !== null,
                    }
                })
                .catch(error => {
                    if (error.code === 20) {
                        return Promise.reject({
                            status: error.code,
                            payload: 'abort'
                        })
                    }
                    return {
                        status: error.status,
                        payload: error.detail
                    }
                })
                .finally(() => {
                    this.searchInProgress = false
                })
        },
        restoreSubcorpus(corpus) {
            const existSavedSubcorpus = getCookie(`subcorpus_${corpusToString(corpus, '_')}`)

            return existSavedSubcorpus ? SearchFormValue.decode(base64toBuffer(existSavedSubcorpus)) : null
        },
        [SEARCH_ACTIONS.REFRESH]() {
            const url = new URL(window.location.href)
            const getParams = new URLSearchParams(url.search)
            const searchParam = getParams.get('search')
            const decodedSearchParam = base64toBuffer(getParams.get('search'))
            const searchState = searchParam && decodedSearchParam ? FrontendSearchState.decode(decodedSearchParam) : null
            if (searchState) this[SEARCH_STATE] = searchState
        },
        [SEARCH_ACTIONS.CLEAR_EXPLAINS](query = true, subcorpus = true) {
            console.info('reset explains')
            if (subcorpus) this[SUBCORPUS_EXPLAINS] = []
            if (query) this[QUERY_EXPLAINS] = []
        },
        [SEARCH_ACTIONS.CLEAR_STATS]() {
            const rootStore = useRootStore()

            rootStore[COUNTERS].subcorpus = {}
            rootStore[COUNTERS].query = {}
        },
        [SEARCH_ACTIONS.RESET_SUBCORPUS]() {
            /* THIS METHOD NOTHING CHANGE; LIKE EVENT BUSS*/
            return Promise.resolve()
        },
        initState(noRestore = false, externalCorpora = Corpus.create({type: CorpusType.MAIN})) {
            const url = new URL(window.location.href)
            const getParams = new URLSearchParams(url.search)
            const searchParam = getParams.get('search')
            const decodedSearchParam = base64toBuffer(getParams.get('search'))

            const searchState = searchParam && decodedSearchParam ? FrontendSearchState.decode(decodedSearchParam) :
                FrontendSearchState.create({
                    query: SearchQuery.create({
                        corpus: externalCorpora,
                        params: SearchParams.create({
                            pageParams: DefaultPagination(),
                            kwsz: DefaultKWSZ()
                        }),
                        resultType: [],
                    })
                })

            if (searchState.query.exact.length) {
                searchState.query.exactForm = buildExactQuery({
                    req: ConditionValue.create({
                        fieldName: 'req',
                        text: TextConditionValue.create({v: searchState.query.exact[0]})
                    })
                })
                delete searchState.query.exact
                return updateURLParameter({'search': encodeSearchQuery(searchState.query)})
            }
            // Restore subcorpus when saved exist and current is empty. Excluding results page
            if (!searchState.query.subcorpus && this.routeName !== 'results' && !noRestore) {
                const existSavedSubcorpus = this.restoreSubcorpus(searchState[QUERY].corpus)

                if (existSavedSubcorpus) {
                    searchState.query.subcorpus = existSavedSubcorpus
                    const currentParams = {}
                    getParams.forEach((value, name) => {
                        if (name === 'search') return
                        currentParams[name] = value
                    })
                    updateURLParameter(
                        {
                            ...currentParams,
                            'search': encodeSearchQuery(searchState.query)
                        },
                        '',
                        //'replace'
                    )
                }
            }

            if (searchState.query.corpus && searchState.query.corpus.type === CorpusType.PARA) this.loadFont(searchState.query.corpus.lang)
            this[SEARCH_STATE] = searchState
        },
        updateQuery(prop, value) {
            set(this[SEARCH_STATE].query, prop, value)
        },
        deletePropFromQuery(prop) {
            unset(this[SEARCH_STATE].query, prop)
        },
        saveOptionsTree(prop, payload, wordIndex = 0) {
            const encodedCorpus = this[CURRENT_CORPUS] ? bufferToBase64(Corpus.encode(this[SEARCH_STATE].query.corpus).finish()) : 'unknown'
            const lsOptions = localStorage.getItem(SAVED_OPTIONS)
            let extractedOptions = lsOptions ? JSON.parse(lsOptions) : {}

            if (extractedOptions instanceof Array) extractedOptions = {}
            if (!extractedOptions[encodedCorpus]) extractedOptions[encodedCorpus] = []

            const savedCorpusProps = extractedOptions[encodedCorpus]
            if (!savedCorpusProps[wordIndex]) savedCorpusProps[wordIndex] = {}

            const word = savedCorpusProps[wordIndex]
            word[prop] = payload

            localStorage.setItem(SAVED_OPTIONS, JSON.stringify(extractedOptions));
        },
        deleteOptionsWord(wordIndex) {
            const encodedCorpus = bufferToBase64(Corpus.encode(this[SEARCH_STATE].query.corpus).finish())
            const lsOptions = localStorage.getItem(SAVED_OPTIONS)
            let extractedOptions = lsOptions ? JSON.parse(lsOptions) : {}

            if (extractedOptions instanceof Array) extractedOptions = {}
            if (!extractedOptions[encodedCorpus]) extractedOptions[encodedCorpus] = []

            const savedCorpusProps = extractedOptions[encodedCorpus]
            if (!savedCorpusProps[wordIndex]) savedCorpusProps.push({})
            savedCorpusProps.splice(wordIndex, 1)

            localStorage.setItem(SAVED_OPTIONS, JSON.stringify(extractedOptions));
        },
        moveSavedOptions(to) {
            const fromEncodedCorpus = bufferToBase64(Corpus.encode(this[SEARCH_STATE].query.corpus).finish())
            const toEncodedCorpus = bufferToBase64(Corpus.encode(to).finish())

            const lsOptions = localStorage.getItem(SAVED_OPTIONS)
            let extractedOptions = lsOptions ? JSON.parse(lsOptions) : {}
            if (!extractedOptions[fromEncodedCorpus]) return

            extractedOptions[toEncodedCorpus] = extractedOptions[fromEncodedCorpus]
            localStorage.setItem(SAVED_OPTIONS, JSON.stringify(extractedOptions));
        },
        extractOptionsTree(prop, wordIndex = 0) {
            const encodedCorpus = this[CURRENT_CORPUS] ? bufferToBase64(Corpus.encode(this[SEARCH_STATE].query.corpus).finish()) : 'unknown'
            const lsOptions = localStorage.getItem(SAVED_OPTIONS);
            const extractedOptions = lsOptions ? JSON.parse(lsOptions) : {};

            try {
                if (extractedOptions[encodedCorpus][wordIndex]) {
                    return extractedOptions[encodedCorpus][wordIndex][prop]
                }
            } catch (e) {
                console.log('invalid saved params')
                return undefined
            }

            return undefined
        },
        async saveSubcorpus(subcorpusFormValue) {
            const payload = SearchQuery.create({
                subcorpus: subcorpusFormValue,
                corpus: this[QUERY].corpus,
                resultType: []
            })

            return this.request(prefixUrl('/api/save-subcorpus', true), {
                method: 'post',
                body: SearchQuery.encode(payload).finish(),
                headers: {'Content-Type': 'application/x-protobuf'}
            })
        },
        async getCorpusConfig(corpus) {
            return this.fetchCorpusConfig(corpus)
        },
        async fetchCorpusConfig(corpus) {
            const corpusName = corpusToString(corpus, '_', true)
            if (!cachedResponses.hasOwnProperty(corpusName)) {
                cachedResponses[corpusName] = await this.request(prefixUrl(`/api/search/forms?corpus=${encodeURIComponent(JSON.stringify(corpus.toJSON()))}&rev=${window.App.REV}`, true))
            }
            //updateSavedConfigs(config);
            return {
                'corpus': corpus,
                'forms': cachedResponses[corpusName]
            };
        },
        async fetchCorpusConfigMany(corpusList = []) {
            const url = new URL(prefixUrl('/api/search/forms-many', true), window.location.origin)
            url.searchParams.set('rev', window.App.REV)

            corpusList.forEach(corpus => {
                url.searchParams.append('corpus', JSON.stringify(corpus.toJSON()))
            })

            return await this.request(url.toString()).then(response => {
                return response.map(entry => {
                    return {
                        corpus: Corpus.fromObject(entry.corpus),
                        forms: entry.forms
                    }
                })
            })
        },
        loadFont(lang) {
            const FontsByLangs = {
                'hye': 'noto-sans-armenian',
                'kor': 'noto-sans-kr',
                'zho': 'noto-sans-sc',
                'hin': 'noto-sans-devanagari',
                'jpn': 'noto-sans-jp',
            }

            if (this.loadedFonts.includes(lang) || !FontsByLangs[lang]) {
                return;
            }
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = `${window.location.origin}/static/assets/fonts/v2/css/${FontsByLangs[lang]}.css`;
            document.head.appendChild(link);

            // Добавляем загруженный шрифт в массив
            this.loadedFonts.push(lang);
        },
        [SEARCH_ACTIONS.LEX_GRAMM_DELETE_WORD](word) {
            return word
        },
        [SEARCH_ACTIONS.LEX_GRAMM_ADD_WORD](word) {
            return word
        },
    },
})
