import {
    PaginationParams,
    SearchParams,
    SearchResultType,
    Corpus,
    CorpusType,
    FrontendSearchMode,
    ConditionValue,
    TextConditionValue,
    SearchConfig,
} from '@ruscorpora/ruscorpora_api';
import set from "lodash/set"
import get from "lodash/get"
import has from "lodash/has"
import isBoolean from "lodash/isBoolean"
import cloneDeep from "lodash/cloneDeep"
import { newSeed, toCamelCase, corpusToString } from "./helper";
import { configToForm, getDefaultFieldValue } from "./form";
import { buildLexGrammQuery } from "./search";

export const DefaultPagination = () => {
    return PaginationParams.create({
        page: 0,
        docsPerPage: 10,
        snippetsPerPage: 50,
        snippetsPerDoc: 10
    })
}

export const DefaultKWSZ = () => {
    return 10
}
/**
 * @typedef {Object} Result
 * @property {SearchResultType|number} value
 * @property {boolean?} isChart
 */
/**
 * Ищет предпочтительный режим поиска, опираясь на сохраненный тип поиска и доступные для этого корпуса
 * @param {Array.<SearchResultType>} availableResultTypes
 * @param {Array.<string>} statFields
 * @param {FrontendSearchMode} searchMode
 * @param {Corpus} corpus
 * @returns {Result}
 */
export const getPrioritySavedResultType = (availableResultTypes, statFields, searchMode, corpus) => {
    if (searchMode === FrontendSearchMode.COLLOCATIONS) return {value: SearchResultType.COLLOCATION}
    if (searchMode === FrontendSearchMode.PARA_LEX_GRAMM) return {value: SearchResultType.PARALLEL_CONCORDANCE}
    const {
        resultType: savedPriorityResultType,
        isChart: savedPriorityIsChart,
        isStats: savedPriorityIsStats
    } = JSON.parse(localStorage.getItem(`default_search_mode_${corpusToString(corpus, '_', true)}_${searchMode}`) ?? "{}")

    if (savedPriorityResultType && availableResultTypes.includes(savedPriorityResultType)) {
        // Особенная обработка запросов статистики и графиков
        if (savedPriorityResultType === SearchResultType.STATS) {
            const isChart = savedPriorityIsChart && statFields.includes('created')
            const isStats = Boolean(savedPriorityIsStats && statFields.filter(x => x !== 'created').length)

            return {
                value: (isChart || isStats) ? savedPriorityResultType : SearchResultType.CONCORDANCE,
                isChart: isChart,
                isStats: isStats
            }
        } else {
            return {
                value: savedPriorityResultType
            }
        }
    }

    return {value: SearchResultType.CONCORDANCE}
}

/**
 * Метод генерирует дефолтные параметры из конфига корпуса
 * @param searchConfig {SearchConfig[]}
 * @param refreshSeed {Boolean}
 * @param restoreUserSettingsForCorpora {Corpus|null}
 * @returns Object
 */
export const getDefaultSettings = (searchConfig, refreshSeed = false, restoreUserSettingsForCorpora = null) => {
    const settings = searchConfig.settings;
    const results = {
        sampling: 0
    }
    const userSettings = {params: {}}

    if (restoreUserSettingsForCorpora !== null) {
        const restoredUserSettings = JSON.parse(localStorage.getItem(`userSettings_${corpusToString(restoreUserSettingsForCorpora, '_', true)}`) || "{}")
        if ("exportAllResults" in restoredUserSettings && isBoolean(restoredUserSettings["exportAllResults"])) {
            userSettings["downloadAll"] = restoredUserSettings["exportAllResults"]
        }
        delete restoreUserSettingsForCorpora.exportAllResults
        Object.assign(userSettings, restoredUserSettings)
        Object.assign(results, userSettings.params)
    }
    const getNormalizedSettingName = (settingName) => {
        if (settingName === 'allow_download_all') return "download_all"
        return settingName
    }

    settings.forEach(setting => {
        const camelCaseName = toCamelCase(getNormalizedSettingName(setting.name))
        let value = get(userSettings.params, camelCaseName)

        value = value ?? setting[setting.c].default
        if (setting.c === 'int') value = Number(value)

        set(results, camelCaseName, value)
    })

    const freqParams = {}

    if (results.hasOwnProperty('frequencyFields')) {
        const frequencyFields = get(results, 'frequencyFields')

        /* хак для поддержки ранее сохраненных значений */
        if (Array.isArray(frequencyFields)) {
            set(freqParams, 'field', frequencyFields[0] || get(userSettings.params, 'frequencyFields').default)
        } else {
            set(freqParams, 'field', frequencyFields)
        }

        const defaultDistValue = "on"
        set(freqParams, 'dist', get(results, 'frequencyDist') || defaultDistValue)

        /* Проверки на валидность параметра */
        if (!searchConfig.frequencyFields.filter(x => x !== 'off').includes(freqParams['field'])) {
            set(freqParams, 'field', searchConfig.frequencyFields[0])
        }
        if (!searchConfig.frequencyDist.includes(freqParams['dist'])) set(freqParams, 'dist', defaultDistValue)
    }

    delete results.frequencyFields
    delete results.frequencyDist

    if (refreshSeed) results.seed = newSeed()
    set(results, 'pageParams.page', 0)

    return {
        params: SearchParams.fromObject(results),
        freqParams: freqParams
    }
}

/**
 * @param settings {Setting[]}
 * @param corpus {Corpus}
 * @param params {SearchParams}
 */
export const saveUserParams = (corpus, settings, params) => {
    const userSettings = {
        params: {}
    }
    const bannlistSettings = ["allow_download_all", "allow_sampling"]

    if (params.hasOwnProperty('sampling')) {
        userSettings.params.sampling = get(params, 'sampling')
    }

    settings.forEach(setting => {
        if (setting.name in bannlistSettings) return

        if (has(params, setting.name)) {
            set(userSettings.params, setting.name, get(params, setting.name))
        } else {
            set(userSettings.params, setting.name, setting[setting.c].default)
        }
    })

    userSettings.params.downloadAll = params.downloadAll ?? false

    delete userSettings.params.allow_download_all
    delete userSettings.params.allow_sampling

    localStorage.setItem(`userSettings_${corpusToString(corpus, '_', true)}`, JSON.stringify(userSettings))
}

export const saveOneUserParam = (corpus, field, value) => {
    const userSettings = JSON.parse(localStorage.getItem(`userSettings_${corpusToString(corpus, '_', true)}`) || "{}")

    set(userSettings, `params.${field}`, value)

    localStorage.setItem(`userSettings_${corpusToString(corpus, '_', true)}`, JSON.stringify(userSettings))
}

/**
 *
 * @param formSectionValue {FormSectionValue}
 * @param lexGramForm {FrontendLexGramm}
 * @param concatOriginLex {Array<String>} Опция для переноса значения из коллокаций в лексико-грамматический поиск
 * @param skipWhenNoFound {Boolean} Флаг, определяющий ли удалять поля из формы, если их нет в конфиге
 * @return {SearchFormValue}
 */
export const convertQuerySearchToLexGram = (formSectionValue, lexGramForm, concatOriginLex = null, skipWhenNoFound = false) => {
    const lexGrammQuery = {
        words: [],
        extra: {}
    }
    const availableFieldsList = []
    if (skipWhenNoFound) availableFieldsList.push(...lexGramForm.cards.map(x => x.fields.map(y => y.name)).flat())

    const beforeCards = lexGramForm.beforeCards || []

    beforeCards
        .map(field => formSectionValue.conditionValues.find(condition => {
            const fieldNameIsMatch = condition.fieldName === field.name

            if (condition.c === 'text' && field.type === 'radio') {
                return fieldNameIsMatch && field.options.map(opt => opt.value).includes(condition.text.v)
            }
            return fieldNameIsMatch
        }) ?? getDefaultFieldValue(field))
        .forEach(field => lexGrammQuery.extra[field.fieldName] = field)

    if (concatOriginLex !== null) {
        // Добавление параметра со снятой омонимией
        lexGrammQuery.extra.disambmod = ConditionValue.create({
            fieldName: 'disambmod',
            text: TextConditionValue.create({v: 'main'})
        })
    }
    formSectionValue.subsectionValues.forEach((subsection, index) => {
        const conditionValues = cloneDeep(subsection.conditionValues)

        const processedConditionValues = new Map()
        conditionValues.forEach(({fieldName}) => {
            if (skipWhenNoFound && !availableFieldsList.includes(fieldName)) {
                return processedConditionValues.set(fieldName, 1)
            }

            processedConditionValues.set(fieldName, 0)
        })

        const formCards = configToForm(lexGramForm.cards, false, conditionValues)
            .filter(formCard => {
                // Удаление условия dist на первое слово, если оно есть
                const doProcessFormCard = !Object.keys(formCard).filter(key => key === 'dist').length && index === 0 || index > 0

                if (!doProcessFormCard) {
                    Object.keys(formCard).filter(key => key !== 'cardId').forEach(key => {
                        processedConditionValues.set(key, 1)
                    })
                }
                return doProcessFormCard
            })
            .map(formCard => {
                const res = {}
                Object.keys(formCard).map(key => {
                    res[key] = conditionValues.find(x => x.fieldName === key) ?? formCard[key]
                    processedConditionValues.set(key, 2)

                    if (key === "lex" && concatOriginLex !== null) {
                        let lexValue = res[key].text.v

                        if (lexValue) {
                            if (lexValue !== concatOriginLex[index]) res[key].text.v = `(${lexValue}) & ${concatOriginLex[index]}`
                        } else {
                            res[key].text.v = concatOriginLex[index]
                        }
                    }
                })
                return res
            })

        processedConditionValues.forEach((val, key) => {
            if (val === 0) {
                const restoreFormCard = {
                    [key]: conditionValues.find(x => x.fieldName === key)
                }
                try {
                    restoreFormCard['cardId'] = lexGramForm.cards.find(x => x.fields.find(i => i.name === key)).cardId
                } catch (e) {
                    console.warn('fail restore field')
                }

                formCards.push(restoreFormCard)
            }
        })

        lexGrammQuery.words.push(formCards
            .map(card => {
                const newKey = card['cardId'] ? parseInt(card['cardId'].split('-')[1]) : Infinity

                return {
                    key: newKey,
                    origin: card
                }
            })
            .sort((a, b) => a.key - b.key)
            .map(card => card.origin))
    })

    return buildLexGrammQuery(lexGrammQuery, false)
}

/**
 * @param formSectionValue {FormSectionValue}
 * @param collocationSections {Array<FrontendCollocationSection>}
 * @return {SearchFormValue}
 */
export const querySearchToCollocation = (formSectionValue, collocationSections) => {
    const collocationQuery = []

    formSectionValue.subsectionValues.forEach((subsection, index) => {
        const conditionValues = cloneDeep(subsection.conditionValues)

        const formCards = configToForm(collocationSections[index].cards, false, conditionValues)
            .filter(formCard => {
                // Удаление условия dist на первое слово, если оно есть
                return !Object.keys(formCard).filter(key => key === 'dist').length && index === 0 || index > 0
            })
            .map(formCard => {
                const res = {}
                Object.keys(formCard).map(key => {
                    res[key] = conditionValues.find(x => x.fieldName === key) ?? formCard[key]
                })
                return res
            })

        collocationQuery.push(formCards)
    })

    return buildLexGrammQuery({words: collocationQuery}, false)
}
