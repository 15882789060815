import { defineStore } from 'pinia'
import { Corpus, CorpusType } from "@ruscorpora/ruscorpora_api";
import { CURRENT_CORPUS, useSearchStore } from "./search";
import { bufferToBase64 } from "../utils/http";
import { prefixUrl } from "../utils/helper";

export const THE_HEADER_IS_COMPACT_KEY = 'theHeaderCompactMode';
export const COUNTERS = 'counters'
export const CURRENT_CORP = 'current_corp'
export const ROUTE = 'route'
export const CORPUS_LIST = 'corpus_list'
export const IS_SMALL_RESOLUTION = 'is_small_resolution'
export const CORPS_TREE = 'corpsTree'
export const CORPS_TAGS = 'corpsTags'
export const SURVEYS = 'surveys'

export const useRootStore = defineStore('root', {
    state: () => {
        return {
            headerCompactState: false,
            [COUNTERS]: {
                corpus: {},
                subcorpus: {},
                query: {},
            },
            [ROUTE]: '',
            [IS_SMALL_RESOLUTION]: false,
            [CORPUS_LIST]: [],
            inited: false,
            locale: window?.App?.LOCALE ?? "ru",
            corpsLoaded: false,
            [CORPS_TREE]: [],
            [CORPS_TAGS]: [],
            [SURVEYS]: [],
        }
    },
    getters: {
        [THE_HEADER_IS_COMPACT_KEY]: state => state.headerCompactState,
        [CURRENT_CORP]: (state) => {
            const searchStore = useSearchStore()
            const encodedCurrentCorp = bufferToBase64(Corpus.encode(searchStore[CURRENT_CORPUS]).finish())

            if (!state[CORPUS_LIST]) return {}

            return state[CORPUS_LIST]
                .map(corp => {
                    return [corp, ...corp.child ?? []]
                })
                .flat()
                .filter(corp => corp.hasOwnProperty('corpus'))
                .map(corp => {
                    corp.encodedCorpus = bufferToBase64(Corpus.encode(corp.corpus).finish())
                    return corp
                })
                .find(corp => corp.encodedCorpus === encodedCurrentCorp)
        }
    },
    actions: {
        toggleCompactMode() {
            this.headerCompactState = !this[THE_HEADER_IS_COMPACT_KEY];
            localStorage.setItem(THE_HEADER_IS_COMPACT_KEY, this[THE_HEADER_IS_COMPACT_KEY]);
        },
        setCorpusCounter(data) {
            this[COUNTERS].corpus = data
        },
        setSubCorpusCounter(data) {
            this[COUNTERS].subcorpus = data
        },
        setQueryCounter(data) {
            this[COUNTERS].query = data
        },
        setCounters(corpus, subcorpus = {}, query = {}) {
            this.setCorpusCounter(corpus)
            this.setSubCorpusCounter(subcorpus)
            this.setQueryCounter(query)
        },
        setRouteName(value) {
            this[ROUTE] = value
        },
        init() {
            if (this.inited) return
            this.inited = true
            this.fetchFeedbacks()

            const resizeHandler = () => {
                this[IS_SMALL_RESOLUTION] = window.innerWidth < 768

                const vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
                document.documentElement.style.setProperty('--window-height', `${window.innerHeight}px`);
            }

            window.addEventListener('resize', resizeHandler, {passive: true});

            resizeHandler();

            if (this[IS_SMALL_RESOLUTION]) {
                if (localStorage.getItem(THE_HEADER_IS_COMPACT_KEY)) {
                    this.headerCompactState = localStorage.getItem(THE_HEADER_IS_COMPACT_KEY) === "true"
                } else {
                    this.headerCompactState = true
                }
            } else {
                this.headerCompactState = localStorage.getItem(THE_HEADER_IS_COMPACT_KEY) === "true"
            }
        },
        async getCorpsTree() {
            if (this.corpsLoaded) return

            return this.request(prefixUrl(`/api/corps-tree`, true), {priority: 'high'})
                .then(({menu, tags}) => {
                    this[CORPS_TREE] = menu
                    this[CORPS_TAGS] = tags
                    this.corpsLoaded = true
                    return {menu, tags}
                })
        },
        fetchFeedbacks() {
            this.request(prefixUrl('/api/feedback-list', true)).then(({surveys}) => {
                this[SURVEYS] = surveys
            })
        }
    },
})
