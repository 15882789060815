import '../css/app.scss';

import { createApp } from 'vue';
import { createRouter, createWebHistory } from "vue-router";
import CorpusSearch from './app/views/CorpusSearch';
import * as Sentry from "@sentry/vue";
//import SelectSubCorpus from './app/views/SelectSubCorpus';
//import CorpusResults from './app/views/CorpusResults';
//import PlayBook from "./app/views/PlayBook";
import { createVfm } from 'vue-final-modal'
import vSelect from 'vue-select';
import localizationPlugin from "./app/plugins/localization";
import messagePlugin from "./app/plugins/message";
import requestPlugin from "./app/plugins/request";
import smartCaptchaPlugin from "./app/plugins/smartCaptchaPlugin";
import { RequestPiniaPlugin } from "./app/plugins/piniaRequestPlugin";
import { GettextPiniaPlugin } from "./app/plugins/piniaGettextPlugin";
import { createPinia } from 'pinia'
import 'vue-select/dist/vue-select.css';
import Checkbox from "./app/components/ui/form/Checkbox";
import { Dropdown, Menu } from "ant-design-vue";
import "ant-design-vue/es/message/style/index.css"
import { svguse } from "./app/svg-use";
import 'vue-final-modal/style.css'
import App from "./app/views/App.vue"
import Auth from "./app/views/Auth.vue"
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"

const routes = [
    {path: '/search', component: CorpusSearch, name: 'forms'},
    {
        path: '/subcorpus/:id',
        name: 'subcorpus-portrait',
        component: () => import('./app/views/SubCorpusPortrait.vue'),
        children: [
            {
                path: '',
                name: 'subcorpus-docs-alias',
                redirect: to => {
                    return {name: 'subcorpus-docs', params: {id: to.params.id}}
                },
            },
            {
                path: 'docs',
                component: () => import('./app/views/subcorpus-portrait/DocsView.vue'),
                name: 'subcorpus-docs',
            },
            {
                path: 'frequency-dictionary',
                component: () => import('./app/views/subcorpus-portrait/FrequencyDictionaryView.vue'),
                name: 'subcorpus-frequency-dictionary'
            },
            {
                path: 'stats',
                component: () => import('./app/views/subcorpus-portrait/Stats.vue'),
                name: 'subcorpus-portrait-stats',
                children: [
                    {
                        path: '',
                        component: () => import('./app/views/subcorpus-portrait/stats/Current.vue'),
                        name: 'subcorpus-portrait-stats-graph',
                        props: {queries: []}
                    },
                    {
                        path: 'chrono',
                        component: () => import('./app/views/subcorpus-portrait/stats/Chronological.vue'),
                        name: 'subcorpus-portrait-stats-chrono',
                        props: {queries: []}
                    },
                ]
            }
        ]
    },
    {path: '/subcorpus', component: () => import('./app/views/SelectSubCorpus'), name: 'select-subcorpus'},
    {path: '/results', component: () => import('./app/views/CorpusResults'), name: 'search'},
    {path: '/explore', component: () => import('./app/views/Explore'), name: 'explore'},
    {path: '/word/:id', component: () => import('./app/views/WordPortraitView.vue'), name: 'word-portrait'},
    {path: '/full-text', component: () => import('./app/views/FullText'), name: 'full-text'},
    {path: '/exercise', component: () => import('./app/views/publications/ExersiseList.vue')},
    {path: '/site-search', component: () => import('./app/views/StaticSearch.vue'), name: 'static-search'},
    {path: '/page/corpora-public/', component: () => import('./app/views/publications/PublicationList.vue'), name: 'publications'},
    {path: '/publications', component: () => import('./app/views/publications/PublicationList.vue'), name: 'publications-t'},
    {path: '/license-content/:slug', component: () => import('./app/views/static-pages/LicenseContent.vue'), name: 'license-content'},
    {
        path: '/corpus/:id',
        component: () => import('./app/views/Portrait.vue'),
        name: 'corpus-portrait',
        children: [
            {
                path: '',
                component: () => import('./app/views/portrait/AboutView.vue'),
                name: 'portrait-about'
            },
            {
                path: 'frequency-dictionary',
                component: () => import('./app/views/portrait/FrequencyDictionaryView.vue'),
                name: 'portrait-frequency-dictionary'
            },
            {
                path: 'publications',
                component: () => import('./app/views/portrait/Publications.vue'),
                name: 'portrait-publications'
            },
            {
                path: 'stats',
                component: () => import('./app/views/portrait/Stats.vue'),
                name: 'portrait-stats',
                children: [
                    {
                        path: '',
                        component: () => import('./app/views/portrait/Stats/Current.vue'),
                        name: 'portrait-stats-graph',
                        props: {queries: []}
                    },
                    {
                        path: 'chrono',
                        component: () => import('./app/views/portrait/Stats/Chronological.vue'),
                        name: 'portrait-stats-chrono',
                        props: {queries: []}
                    }
                ]
            },
            {
                path: 'data/:slug',
                component: () => import('./app/views/portrait/DataTable.vue'),
                name: 'portrait-tables'
            },
        ]
    },
    {
        path: '/accounts',
        component: () => import('./app/views/accounts/Base.vue'),
        name: 'account',
        children: [
            {
                path: 'reset-password/key/:slug/',
                component: () => import('./app/views/accounts/ChangePassword.vue'),
                name: 'change-password'
            },
            {
                path: 'token-fail',
                name: 'token-fail',
                component: () => import('./app/views/accounts/StatusPage.vue'),
            }
        ],
    },
    {
        path: '/compare/:id',
        component: () => import('./app/views/Compare.vue'),
        name: 'compare',
        children: [
            {
                path: '',
                name: 'req-compare',
                component: () => import('./app/views/compare/ReqCompare.vue'),
                props: {queries: []}
            },
            {
                name: 'chart-compare',
                path: 'chart',
                component: () => import('./app/views/compare/ChartCompare.vue'),
                props: true,
                children: [
                    {
                        path: 'freq',
                        name: 'compare-freq-year',
                        component: () => import('./app/views/compare/FreqYear.vue'),
                        props: {queries: []}
                    },
                    {
                        path: 'ratio',
                        name: 'compare-ratio-year',
                        component: () => import('./app/views/compare/RatioYear.vue'),
                        props: {queries: []}
                    }
                ]
            }
        ]
    },


]

const router = createRouter({
    history: createWebHistory(window?.App?.LOCALE === 'en' ? '/en' : null),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.name === from.name && [
            'subcorpus-stats',
            'portrait-stats',
            'portrait-stats-graph',
            'portrait-stats-chrono',
            'subcorpus-portrait-stats-graph',
            'subcorpus-portrait-stats-chrono',
        ].includes(to.name)) {
            if (savedPosition) {
                return savedPosition
            }
            return null
        }
        return {
            top: 0,
            behavior: 'smooth',
        }
    },
})


router.beforeResolve((to, from, next) => {
    if (to.name !== from.name) vueApp.config.globalProperties.$requestManager.cancelAllRequests()

    next()
})

const vfm = createVfm()
document.querySelector('.ld').classList.add('app')
const pinia = createPinia()
pinia.use(RequestPiniaPlugin)
pinia.use(GettextPiniaPlugin)
const vueApp = createApp(App)
vueApp.use(pinia);
vueApp.use(localizationPlugin);
vueApp.use(messagePlugin);
vueApp.use(requestPlugin);
vueApp.use(smartCaptchaPlugin);
vueApp.use(vfm);
vueApp.use(Dropdown);
vueApp.use(Menu);
vueApp.use(router);
vueApp.component('v-select', vSelect);
vueApp.component('infinite-loading', InfiniteLoading);
vueApp.component('checkbox', Checkbox);

svguse()

const AuthApp = createApp(Auth)
AuthApp.use(pinia)
AuthApp.use(vfm)
AuthApp.use(requestPlugin)
AuthApp.use(smartCaptchaPlugin)
AuthApp.use(localizationPlugin)
AuthApp.mount('#vue-auth-app');
const AuthAppM = createApp(Auth)
AuthAppM.use(pinia)
AuthAppM.use(vfm)
AuthAppM.use(requestPlugin)
AuthAppM.use(smartCaptchaPlugin)
AuthAppM.use(localizationPlugin)
AuthAppM.mount('#vue-auth-app-mobile');
// vue-auth-app

Sentry.init({
    app: vueApp,
    dsn: "https://4a5c9ffeafa69ca4bd49ef67bd9ddf62@o4506546439192576.ingest.sentry.io/4506546442797056",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["ruscorpora.ru", "test.ruscorpora.ru"],
        }),
    ],
    tracesSampleRate: 0.05, //  Capture 0.5% of the transactions,
    enabled: process.env.NODE_ENV === "production"
});

vueApp.mount('#rnc-spa');
