<template>
  <div class="card">
    <div v-for="(field, index) of card.fields" :key="index">
      <the-input v-bind="field" :model-value="field.value.text.v" v-if="field.type === 'text'"
                 :label-style="index === 0 ? 'strong': ''"
                 :special-word-key="specialWordKey"
                 @update:model-value="handleChangeTextField($event, field.name)"
                 @validate="$emit('validate', {cardId: card.cardId, state: $event})">
        <template v-slot:additional-control v-if="canDelete(field.required)">
          <round-button :aria-label="$gettext('Удалить условие')" color="dark" size="small"
                        @click="deleteConditionHandler">
            <icon name="c-cross" />
          </round-button>
        </template>
      </the-input>

      <int-range v-else-if="field.type === 'int-range'" v-model="field.value.intRange" v-bind="field"
                 :label-style="index === 0 ? 'strong': ''"
                 @validate="$emit('validate', {cardId: card.cardId, state: $event})">
        <template v-slot:additional-control v-if="canDelete(field.required)">
          <round-button :aria-label="$gettext('Удалить условие')" color="dark" size="small"
                        @click="deleteConditionHandler">
            <icon name="c-cross" />
          </round-button>
        </template>
      </int-range>

      <radio v-else-if="field.type === 'radio'"
             :model-value="field.value"
             @update:modelValue="handleChangeTextField(null, null, $event)"
             :options="field.options"
             :label-style="index === 0 ? 'strong': ''" v-bind="field" :inline="false"
      />
    </div>
  </div>
</template>

<script>
import TheInput from "../TheInput.vue";
import RoundButton from "../../RoundButton.vue";
import Icon from "../../Icon.vue";
import { ConditionValue, TextConditionValue } from "@ruscorpora/ruscorpora_api";
import IntRange from "../IntRange.vue";
import Radio from "../Radio.vue";

export default {
  name: "Card",
  components: {Radio, IntRange, Icon, RoundButton, TheInput},
  props: {
    card: {
      type: Object
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    specialWordKey: {
      type: Number,
      default: 0
    }
  },
  emits: ['update-field', 'delete-condition', 'validate'],
  setup(props, {emit}) {
    const deleteConditionHandler = () => {
      emit('delete-condition', props.card.cardId)
    }

    const handleChangeTextField = (value, fieldName, payload = null) => {
      if (payload !== null) {
        return emit('update-field', payload)
      }
      const newValue = ConditionValue.create({
        fieldName: fieldName,
        text: TextConditionValue.create({
          v: value
        })
      })

      emit('update-field', newValue)
    }

    const canDelete = (fieldIsRequired) => {
      return props.allowDelete && !fieldIsRequired
    }

    return {
      deleteConditionHandler,
      handleChangeTextField,
      canDelete
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  background     : var(--light-mint);
  border-radius  : rem-calc(10);
  padding        : rem-calc(24 12);
  display        : flex;
  flex-direction : column;
  gap            : 10px;
  @include breakpoint(small up) {
    padding : rem-calc(24);
  }
}
</style>
