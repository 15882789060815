<template>
  <div>
    <corpus-header />
    <subcorpus-header />
    <query-header />
  </div>
</template>
<script>
import CorpusHeader from "./headers/CorpusHeader";
import QueryHeader from "./headers/QueryHeader";
import SubcorpusHeader from "./headers/SubcorpusHeader";

export default {
  name: "TheHeader",
  components: {
    SubcorpusHeader,
    QueryHeader,
    CorpusHeader,
  }
}
</script>
