/**
 * @param value
 * @param maxDecimal
 * @return {number|string}
 */
export const numberFilter = (value, maxDecimal = 0) => {
    const lang = window.App.LOCALE ?? 'ru';
    let locale = {"en": "en-EN", "ru": "ru-RU"}[lang]
    try {
        const val = parseFloat(value)
        const formatted = new Intl.NumberFormat(locale, {maximumFractionDigits: maxDecimal}).format(val)
        if (val > 999 && val < 10000 && lang === "ru") {
            return formatted.replace(' ', '')
        }
        return formatted
    } catch (e) {
        return value
    }
}
export const intword2 = (value, maxDecimal = 1) => {
    const lang = window.App.LOCALE ?? 'ru';
    const thousands = {"en": "K", "ru": " тыс"}[lang]
    const millions = {"en": "M", "ru": " млн"}[lang]
    let locale = {"en": "en-EN", "ru": "ru-RU"}[lang]

    const val = Number(value);

    if (val < 1000) return val
    else if (1000 <= val && val < 1000000) {
        return `${(val / 1000).toLocaleString(locale, {maximumFractionDigits: maxDecimal})}${thousands}`
    } else if (1000000 <= val && val < 10000000) {
        return `${(val / 1000000).toLocaleString(locale, {maximumFractionDigits: maxDecimal})}${millions}`.replace('.', ',')
    } else return `${Math.floor(val / 1000000)}${millions}`
}
