<template>
  <div class="concordance-title"
       :class="{'concordance-title--compact': compact}">
    <div class="phrase" :class="{'highlight': docSource && docSource === docInfo.source.docId}">
      <span @click="onShowInfoPopup">
        <template v-if="index !== null">{{ index }}.&nbsp;</template>{{ docInfo.title }}
      </span>&nbsp;<span class="homonymy-list" ref="homonymyStatusRef">
        <span class="open-media" v-if="isShowMedia">
          <button type="button" @click="openMediaModal">
            <svg v-if="docInfo.mediaContent.type === 2" width="18" height="18" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#9EC7DE"/>
              <path
                d="M10.7622 7.38464C11.0358 7.57273 11.2578 7.81662 11.4108 8.097C11.5637 8.37738 11.6433 8.68655 11.6433 9.00003C11.6433 9.3135 11.5637 9.62267 11.4108 9.90306C11.2578 10.1834 11.0358 10.4273 10.7622 10.6154"
                stroke="#1C1C1C" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M11.9517 6.1731C12.4116 6.5138 12.7826 6.94449 13.0374 7.43363C13.2922 7.92277 13.4245 8.45796 13.4245 9.00002C13.4245 9.54208 13.2922 10.0773 13.0374 10.5664C12.7826 11.0555 12.4116 11.4862 11.9517 11.8269"
                stroke="#1C1C1C" stroke-linecap="round" stroke-linejoin="round"/>
              <path
                d="M6.79714 10.2115H5.91602C5.79918 10.2115 5.68712 10.169 5.6045 10.0933C5.52188 10.0175 5.47546 9.9148 5.47546 9.80769V8.19231C5.47546 8.0852 5.52188 7.98248 5.6045 7.90674C5.68712 7.83101 5.79918 7.78846 5.91602 7.78846H6.79714L8.3391 5.97115C8.37761 5.90259 8.4416 5.84892 8.5195 5.81983C8.59741 5.79074 8.6841 5.78815 8.76391 5.81254C8.84372 5.83692 8.91138 5.88667 8.95466 5.95279C8.99795 6.01891 9.01401 6.09705 8.99994 6.17308V11.8269C9.01401 11.9029 8.99795 11.9811 8.95466 12.0472C8.91138 12.1133 8.84372 12.1631 8.76391 12.1875C8.6841 12.2118 8.59741 12.2093 8.5195 12.1802C8.4416 12.1511 8.37761 12.0974 8.3391 12.0288L6.79714 10.2115Z"
                fill="#1C1C1C"/>
            </svg>
            <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#9EC7DE"/>
              <path d="M10.9879 9.05384L8.11548 11.2979V6.51053L10.9879 9.05384Z" fill="#1C1C1C" stroke="black"
                    stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
          </button>
        </span>
        <span class="homonymy-status" v-for="(status, index) of homonymyStatuses" :key="index">
          <the-tooltip :title="status.message">
            <icon :name="status.icon"></icon>
          </the-tooltip>
        </span>
        <slot name="extra-actions"/>
      </span>
    </div>
  </div>
</template>

<script>
import TheTooltip from "../../ui/form/TheTooltip";
import Icon from "../../ui/Icon";
import {computed, defineAsyncComponent, onMounted, ref} from "vue";
import {
  DocSource,
  ExplainQuery,
  ExplainResultType,
  DocumentInfo,
  ExplainResult,
  ExplainParsing, SearchQuery
} from "@ruscorpora/ruscorpora_api";
import {CURRENT_CORPUS, useSearchStore} from "../../../stores/search";
import {useModalStore} from "../../../stores/modal";
import {getHomonymyStatuses} from "../../../utils/helper";
import {VueFinalModal, useModal, useModalSlot} from 'vue-final-modal'
import { DefaultPagination } from "../../../utils/structure";

export default {
  name: "ConcordanceTitle",
  components: {Icon, TheTooltip},
  props: {
    docInfo: {
      type: DocumentInfo,
      required: true
    },
    docSource: {
      type: String,
    },
    index: {
      type: [Number, String, null],
      default: 0
    },
    compact: {
      type: Boolean,
      default: false
    },
    isExpand: {
      type: Boolean,
      default: false
    },
    showMedia: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const modalStore = useModalStore()
    const searchStore = useSearchStore()
    const homonymyStatusRef = ref(null)

    const onShowInfoPopup = () => {
      const explainQuery = ExplainQuery.create({
        corpus: searchStore[CURRENT_CORPUS],
        resultType: ExplainResultType.DOC_INFO,
        docSource: DocSource.create({
          docId: props.docInfo.source.docId
        })
      })

      modalStore.showWordModalV2({
        explainQuery,
        searchQuery: SearchQuery.fromObject({...searchStore.query.toJSON()}),
        homonymyStatus: props.docInfo.homonymyStatus,
        customTitle: props.docInfo.title,
        explainResult: props.docInfo.docExplainInfo && ExplainResult.encode(props.docInfo.docExplainInfo).finish().length > 3 ? props.docInfo.docExplainInfo : null
      })
    }

    const homonymyStatuses = computed(() => {
      return getHomonymyStatuses(props.docInfo.homonymyStatus)
    })

    const isShowMedia = computed(() => {
      return props.showMedia && props.docInfo.hasOwnProperty('mediaContent')
    })

    onMounted(() => {
      homonymyStatusRef.value.closest('.phrase').style.setProperty('--homonymy-width', `${homonymyStatusRef.value.clientWidth}px`)
    })

    const modalInstance = useModal({
      component: VueFinalModal,
      attrs: {
        class: "modal-container modal-container--white-overlay",
        "content-class": "modal-content",
      },
      slots: {
        default: useModalSlot({
          component: defineAsyncComponent(() => import("../../ui/ModalMedia.vue")),
          attrs: {
            mediaContent: props.docInfo.mediaContent,
            onClose() {
              modalInstance.close()
            }
          }
        })
      }
    })

    const openMediaModal = () => {
      modalInstance.open()
    }

    return {
      homonymyStatuses,
      isShowMedia,
      homonymyStatusRef,

      onShowInfoPopup,
      openMediaModal
    }
  }
}
</script>

<style scoped lang="scss">
.concordance-title {
  font-size   : rem-calc(18);
  font-weight : 700;
  font-family : $main-font;
  position    : relative;
  flex-grow   : 1;
  flex-shrink : 1;


  &:not(&--compact) {
    overflow : hidden;
  }

  .phrase {
    & > span:first-child {
      padding-right : calc(var(--homonymy-width) + 8px);
      word-break    : break-word;

      &:hover {
        cursor : pointer;
        color  : var(--coral)
      }
    }

    @include breakpoint(medium up) {
      display : inline;
    }

    &.highlight {
      background : var(--tooltop-color);

      & > span:first-child {
        color : var(--black) !important;

        &:hover {
          color : var(--black)
        }
      }
    }
  }

  .homonymy-list {
    display     : inline-flex;
    gap         : 3px;
    margin-left : calc(-1 * var(--homonymy-width));
    transform   : translateY(#{rem-calc(5)});
  }

  @include breakpoint(medium up) {
    font-size   : rem-calc(24);
    line-height : 1.3;
  }

  &--compact {
    font-size   : rem-calc(14);
    font-weight : 400;

    @include breakpoint(medium up) {
      font-size : rem-calc(14);
    }

    .phrase {
      color : var(--gray);

      & > span:first-child {
        color : var(--gray);

        &:hover {
          color : var(--coral)
        }
      }
    }
  }
}

.open-media {
  flex-shrink  : 0;
  display      : inline-flex;
  position     : relative;
  margin-right : 9px;

  button {
    width  : 18px;
    height : 18px;
  }

  &:after {
    position         : absolute;
    right            : -7px;
    top              : 2px;
    height           : 14px;
    width            : 1px;
    background-color : #000;
    opacity          : .25;
    content          : "";
  }
}
</style>
