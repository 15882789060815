<template>
  <div class="container">
    <div class="search-result-list" :class="{'content-offset': !noOffset}">
      <div class="row">
        <div class="col" :class="{'col-lg-10': concordanceComponent === 'ConcordanceItem' && !compact}">
          <div id="concordance">
            <div v-for="docGroup in docGroups" :key="docGroup.uuid" :class="{'grid-concordance': docGroup.showLetter && !compact}">
              <span class="grid-concordance__group-index" v-if="docGroup.showLetter && !compact" v-text="docGroup.groupIndex"/>
              <ul class="concordance-list">
                <component :is="renderComponent" v-for="item in docGroup.docs"
                           :payload="item.document"
                           :key="item.uuid"
                           :compact="compact"
                           :class="{'end-multidocs-group': item.endMultiDocsGroup}"
                           :search-params="searchParams"
                           :force-show-translates="forceShowTranslates"
                           :available-full-text-mode="availableFullTextResult"
                           :index="item.docCounter"
                           :corpus="corpus"
                           :search-query="searchQuery"
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../ui/Icon";
import ResultActions from "../ui/ResultActions";
import ConcordanceItem from "./ConcordanceItem";
import ParaConcordanceItem from "./ParaConcordanceItem.vue";
import {computed, nextTick, onBeforeMount, onMounted, ref} from "vue";
import {useSearchStore, SEARCH_STATE, RESULT_TYPE} from "../../stores/search";
import {usePortraitStore, CORPUS_INFO} from "../../stores/portrait";
import {v4 as uuid} from "uuid"
import {
  Corpus,
  Document as DocumentPB,
  DocumentGroup,
  SearchParams,
  SearchResultType,
  CorpusType,
  SearchQuery,
} from "@ruscorpora/ruscorpora_api";

export default {
  name: "Concordance",
  components: {ResultActions, Icon, ConcordanceItem, ParaConcordanceItem},
  props: {
    /** type of Array<DocumentGroup>*/
    queryResults: {
      type: Array,
      default: () => {
        return []
      }
    },
    startIndex: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: Infinity
    },
    forceShowTranslates: {
      type: Boolean,
      default: false
    },
    noOffset: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean, // отключает шапки документов, РСШ и копирование контекстов
      default: false
    },
    searchParams: {
      type: SearchParams
    },
    corpusConfig: {
      type: Object
    },
    corpus: {
      type: Corpus
    },
    searchQuery: {
      type: SearchQuery,
      default: null,
      required: false
    },
  },
  setup(props) {
    const searchStore = useSearchStore()
    const portraitStore = usePortraitStore()
    const query = computed(() => searchStore[SEARCH_STATE].query)
    const isAllExamplesMode = computed(() => query.value.params.hasOwnProperty('docSource'));
    const isMetaResult = computed(() => SearchResultType.META === searchStore[RESULT_TYPE])
    const isFullTextResult = computed(() => SearchResultType.FULL_TEXT === searchStore[RESULT_TYPE])

    const concordanceComponent = computed(() => {
      const useParaConcordance = props.corpusConfig?.use_para_concordance ?? portraitStore[CORPUS_INFO].use_para_concordance ?? false

      if (useParaConcordance) {
        if (searchStore[RESULT_TYPE] === SearchResultType.META) {
          return 'ConcordanceItem'
        }

        if (searchStore[RESULT_TYPE] === SearchResultType.FULL_TEXT && [CorpusType.BIRCHBARK, CorpusType.MULTIPARC].includes(query.value.corpus.type)) {
          return 'ConcordanceItem'
        }

        return 'ParaConcordanceItem'
      }

      return 'ConcordanceItem'
    })
    const availableFullTextResult = computed(() => {
      return portraitStore[CORPUS_INFO].config.availableResultTypes.includes(SearchResultType.FULL_TEXT) && [SearchResultType.META].includes(searchStore[RESULT_TYPE])
    })

    const renderComponent = ref('ConcordanceItem')

    /** @returns [null, String, Number]*/
    const buildDocCounter = (index, groupIndex, showLetter = false) => {
      if (isAllExamplesMode.value || isFullTextResult.value) return null
      const numberOfDocument = groupIndex + props.startIndex + 1
      if (showLetter) return 'abcdefghijklmnopqrstuvwxyz'[index]
      return numberOfDocument
    }

    const docGroups = computed(() => {
      return props.queryResults.slice(0, props.limit).map((value, groupIndex, slicedArray) => {
        const docGroup = DocumentGroup.create({...value.toJSON()})
        const showLetter = (!isMetaResult.value && !isFullTextResult.value && (props.corpusConfig ?? portraitStore[CORPUS_INFO])?.corpus?.type === CorpusType.MULTIPARC_RUS) ?? false

        return {
          uuid: uuid(),
          showLetter,
          groupIndex: showLetter ? `${groupIndex + props.startIndex + 1}.` : null,
          docs: docGroup.docs.map((document, docIndex) => {
            return {
              docCounter: buildDocCounter(docIndex, groupIndex, showLetter),
              endMultiDocsGroup: showLetter && Boolean(docIndex + 1 === docGroup.docs.length) && Boolean(groupIndex < slicedArray.length - 1),
              uuid: uuid(),
              document: DocumentPB.fromObject({...document})
            }
          })
        }
      })
    })
    onBeforeMount(() => {
      renderComponent.value = concordanceComponent.value
    })

    onMounted(async () => {
      const scrollY = window.scrollY
      await nextTick()
      window.scroll(0, scrollY)
    })

    return {
      docGroups,
      isAllExamplesMode,

      concordanceComponent,
      renderComponent,
      availableFullTextResult
    }
  },
}
</script>

<style lang="scss" scoped>
.grid-concordance {
  display : flex;
  gap     : 5px;
  @include breakpoint(medium up) {
    gap : 25px;
  }

  &__group-index {
    font-size   : rem-calc(18);
    font-weight : 700;
    line-height : 1.4;
    align-self  : flex-start;
    opacity     : .75;
    @include breakpoint(medium up) {
      font-size   : rem-calc(24);
      line-height : 1.3;
    }
  }
}

.concordance-list {
  min-width : 0;
  width     : 100%;
}
</style>
