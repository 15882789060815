<template>
  <div class="radio-group" :class="{'radio-group--vertical': !inline}">
    <div v-if="label" class="radio-group__label" :class="labelClassObject">{{ translate(label) }}</div>
    <div class="radio-group__items">
      <label class="radio-input" :for="`option-${uuid}-${option.value}`" v-for="option in options" :key="option">
        <input type="radio" :value="option.value" :id="`option-${uuid}-${option.value}`" class="visually-hidden"
               :name="uuid" @change="changeModelValue(option.value)" :checked="checkedValue === option.value" />
        <span class="radio-input__indicator"></span>
        {{ translate(option.title) }}
      </label>
    </div>
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid';
import { ConditionValue, TextConditionValue } from "@ruscorpora/ruscorpora_api";

export default {
  name: "Radio",
  emits: ['update:modelValue'],
  props: {
    options: Array,
    label: String,
    inline: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: ConditionValue
    },
    name: {
      type: String
    },
    labelStyle: {
      type: String,
      default: '',
      validate: (val) => {
        return ['strong', 'inline'].includes(val)
      }
    },
  },
  data() {
    return {
      uuid: ''
    }
  },
  methods: {
    changeModelValue(value) {
      this.$emit('update:modelValue',
        ConditionValue.create({
          fieldName: this.name,
          text: TextConditionValue.create({v: value})
        }))
    }
  },
  computed: {
    checkedValue() {
      return this.modelValue?.text?.v ?? ""
    },
    labelClassObject() {
      return {
        [`radio-group__label--${this.labelStyle}`]: this.labelStyle.length
      }
    },
  },
  mounted() {
    this.uuid = uuid()
  }
}
</script>

<style scoped lang="scss">
.radio-group {
  font-size : rem-calc(16);

  &__label {
    margin-bottom : rem-calc(8);

    &--strong {
      font-weight : 700;
    }
  }

  &__items {
    display        : flex;
    flex-wrap      : wrap;
    gap            : rem-calc(10 12);
    flex-direction : column;

    @include breakpoint(small up) {
      gap            : rem-calc(30);
      flex-wrap      : nowrap;
      flex-direction : row;

      .radio-group--vertical & {
        gap            : rem-calc(12);
        flex-direction : column;
      }
    }
  }
}

.radio-input {
  position     : relative;
  padding-left : rem-calc(24);
  cursor       : pointer;

  input {
    &:checked + .radio-input {
      &__indicator {
        background-color : var(--black) !important;
        border-color     : var(--black) !important;
      }
    }

    &:focus-visible + .radio-input {
      &__indicator {
        outline        : 2px solid var(--deep-blue-hover);
        outline-offset : rem-calc(4);
      }
    }
  }

  &__indicator {
    position      : absolute;
    left          : 0;
    top           : 50%;
    transform     : translateY(-50%);
    width         : rem-calc(16);
    height        : rem-calc(16);
    border        : 2px solid var(--black);
    content       : '';
    border-radius : 50%;
  }

  &:hover {
    .radio-input {
      &__indicator {
        border-color     : var(--deep-blue-hover);
        background-color : var(--light-blue-hover);
      }
    }
  }
}
</style>
