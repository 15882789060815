<script setup>
import { computed, inject, onMounted, onUnmounted, ref } from "vue";
import { IntConditionValue } from "@ruscorpora/ruscorpora_api";
import RoundButton from "../RoundButton.vue";
import Icon from "../Icon.vue";
import { useModalStore } from "../../../stores/modal";
import { SEARCH_ACTIONS, useSearchStore } from "../../../stores/search";

const props = defineProps({
  label: {
    type: String,
  },
  modelValue: {
    type: Object
  },
  helpText: {
    type: String
  },
  currentWord: {
    type: Number,
    default: 0
  }
})

const emit = defineEmits(['update:modelValue', 'validate'])
const modalStore = useModalStore()
const searchStore = useSearchStore()

const state = computed({
  get() {
    return props.modelValue.hasOwnProperty('v') ? props.modelValue.v : undefined
  },
  set(val) {
    emit('update:modelValue', IntConditionValue.create({v: val}))
  }
})

const message = inject('message')
const gettext = inject('gettext')
const showHelpText = (val) => modalStore.showHelpText(val)
const lexGrammForm = inject('lexGrammForm')
const wordCount = computed(() => lexGrammForm.getWordCount())
const options = computed(() => {
  return Array(wordCount.value).fill().map((value, index) => {
    return {
      label: `${gettext('Слово')} ${index + 1}`,
      value: index + 1
    }
  }).filter(entry => entry.value !== props.currentWord + 1)
})

const isValid = ref(true)

searchStore.$onAction(
  ({
     name,
     after
   }) => {
    after((result) => {
      const wordIndex = result + 1
      const currentWord = props.currentWord + 1
      if (name === SEARCH_ACTIONS.LEX_GRAMM_DELETE_WORD) {
        if (state.value === wordIndex) {
          state.value = null
          return
        }

        if (state.value && wordIndex < currentWord && state.value > wordIndex) {
          state.value = state.value - 1
        }
      }

      if (name === SEARCH_ACTIONS.LEX_GRAMM_ADD_WORD) {
        if (state.value && wordIndex < state.value) {
          state.value = state.value + 1
        }
      }
    })
  }
)

const checkValidate = (value) => {
  isValid.value = Boolean(value)

  if (!isValid.value) {
    message.error(gettext('Необходимо заполнить поле'))
  }
  emit('validate', isValid.value)
  return isValid.value
}

const handleExternalValidate = () => {
  checkValidate(state.value)
}

onMounted(() => {
  document.body.addEventListener('validate', handleExternalValidate)
})

onUnmounted(() => {
  document.body.removeEventListener('validate', handleExternalValidate)
})

</script>

<template>
  <div class="the-input" :class="{'the-input--invalid': !isValid}">
    <div class="the-input__head">
      <div class="the-input__label" v-text="label"/>
      <div class="the-input__actions">
        <div class="the-input__buttons">
          <round-button
            v-if="helpText"
            :aria-label="$gettext('Подсказка')"
            color="dark"
            size="small"
            @click="showHelpText(helpText)">
            <icon name="c-question"/>
          </round-button>
        </div>
      </div>
    </div>
    <div class="the-input__field">
      <v-select
        v-model="state"
        :reduce="option => option.value"
        class="vs--fluid custom-v-select computable-word-select"
        :placeholder="$gettext('Выберите слово')"
        :clearable="false"
        :append-to-body="true"
        :options="options">
        <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon name="c-dropdown-indicator"/>
            </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<style scoped lang="scss">
.custom-v-select {
  width : 100%;
}
</style>

<style lang="scss">
.computable-word-select {
  .vs__dropdown-toggle {
    border-bottom : 0 !important;
  }
}
</style>
