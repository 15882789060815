<template>
  <span class="icon-wrapper" :class="{[extraClass]: extraClass}">
    <svg :class="bindClass">
      <use v-bind="bindAttrs"></use>
    </svg>
  </span>
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: {
      type: String
    },
    iconStyle: {
      type: String
    },
    extraClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    bindClass() {
      return {
        [`icon-${this.name}`]: true
      }
    },
    bindAttrs() {
      return {
        [`xlink:href`]: `#i-${this.name}`
      }
    }
  }
}
</script>

<style lang="scss">
.icon-wrapper {
  display         : flex;
  width           : 100%;
  height          : 100%;
  align-items     : center;
  justify-content : center;
  flex-shrink     : 0;

  svg {
    max-width  : 100%;
    max-height : 100%;
  }
}
</style>
