<template>
  <div class="search-form-tab">
    <div class="search-form-tab__header" :aria-selected="searchModeIsActive"
         :aria-controls="domId"
         role="tab">
      <div class="search-form-tab__title">
        <button @click="handleChangeSearchMode"
                :aria-label="`${$gettext('Выбрать')}: ${title}`">
          {{ title }}
        </button>

        <round-button v-if="helpText"
                      @click="showHelpText(helpText)"
                      color="dark"
                      size="medium"
                      :aria-label="$gettext('Подсказка')">
          <icon name="c-question" />
        </round-button>
      </div>

      <round-button @click="handleChangeSearchMode"
                    color="dark"
                    size="medium"
                    class="search-form-tab__toggle-desktop"
                    :aria-label="`${$gettext('Выбрать')}: ${title}`"
                    :active="searchModeIsActive">
        <icon name="c-arrow" />
      </round-button>

      <round-button @click="handleChangeSearchMode"
                    color="dark"
                    size="medium"
                    class="search-form-tab__toggle-mobile"
                    :aria-label="`${$gettext('Выбрать')}: ${title}`"
                    :active="searchModeIsActive"
                    :outline="true">
        <icon name="c-arrow" />
      </round-button>
    </div>
    <transition :duration="{ enter: 350, leave: 0 }">
      <div v-if="searchModeIsActive" class="search-form-tab__body" ref="lexGrammWords" role="tabpanel" :id="domId">
        <div v-if="subtitle" class="search-form-tab__annotation" v-html="subtitle" />

        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import RoundButton from "../ui/RoundButton.vue";
import Icon from "../ui/Icon.vue";
import { FrontendSearchMode } from "@ruscorpora/ruscorpora_api";
import { computed } from "vue";
import { useModalStore } from "../../stores/modal";

export default {
  name: "SearchFormTab",
  components: {Icon, RoundButton},
  props: {
    searchMode: {
      type: [Number, null]
    },
    activeSearchMode: {
      type: [Number, null]
    },
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    helpText: {
      type: String
    },
    domId: {
      type: String
    }
  },
  emits: ["change-search-mode"],
  setup(props, {emit}) {
    const modalStore = useModalStore()

    const searchModeIsActive = computed(() => {
      return Boolean(props.searchMode === props.activeSearchMode && props.activeSearchMode !== null)
    })

    const handleChangeSearchMode = () => {
      emit("change-search-mode", props.searchMode)
    }

    return {
      handleChangeSearchMode,
      showHelpText: modalStore.showHelpText,
      searchModeIsActive
    }
  }
}
</script>

<style scoped lang="scss">
.search-form-tab {
  margin-bottom : rem-calc(32);

  &__header {
    display         : flex;
    font-size       : rem-calc(18);
    gap             : rem-calc(10);
    justify-content : space-between;
    font-weight     : 500;
    align-items     : center;
    @include breakpoint(small up) {
      justify-content : flex-start;
      font-size       : rem-calc(24);
      gap             : rem-calc(18);
      font-weight     : 400;
    }
  }

  &__title {
    display         : flex;
    justify-content : flex-start;
    gap             : rem-calc(10);
    flex-grow       : 1;
    align-items     : center;
    @include breakpoint(small up) {
      justify-content : space-between;
      flex-grow       : 0;
      gap             : rem-calc(18);
    }
  }

  &__annotation {
    font-size     : rem-calc(14);
    margin-bottom : rem-calc(27);
    @include breakpoint(small up) {
      font-size : rem-calc(16);
    }
  }

  &__toggle {
    &-mobile {
      @include breakpoint(small up) {
        display : none;
      }
    }

    &-desktop {
      display : none;
      @include breakpoint(small up) {
        display : flex;
      }
    }
  }

  &__exact-search {
    width      : 100%;
    margin-top : rem-calc(16);
    @include breakpoint(medium up) {
      margin-top : 0;
    }
  }

  &__to-search-wrap {
    margin-top     : rem-calc(40);
    width          : 100%;
    display        : flex;
    gap            : rem-calc(20);
    flex-direction : column;

    @include breakpoint(small up) {
      margin-top     : rem-calc(80);
      flex-direction : row;
      .button {
        width : rem-calc(272);
      }
    }
  }

  &__body {
    padding-top : rem-calc(19);
  }

  &__before-cards {
    padding-bottom : rem-calc(10);
    display        : flex;
    flex-direction : column;
    gap            : rem-calc(15);
  }

  &:last-child {
    margin-bottom : 0;
  }
}
</style>
