<template>
  <component :is="renderTag"
             :aria-label="ariaLabel"
             :class="classObj"
             v-bind="attrs"
             class="round-button">
    <span class="round-button__label" v-if="hasLabelSlot"><slot name="label"></slot></span>
    <span class="round-button__icon">
      <slot></slot>
    </span>
  </component>
</template>

<script>
export default {
  name: "RoundButton",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    fill: {
      type: Boolean
    },
    ariaLabel: {
      type: String,
      required: true
    },
    mobileCompact: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean
    },
    border: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'default',
      validate: (x) => {
        return ['small', 'medium', 'large'].includes(x)
      }
    },
    color: {
      type: String,
      default: '',
      validate: (x) => {
        return ['dark', 'white', 'blue'].includes(x)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      required: false,
      default: ''
    },
    target: {
      type: String,
      required: false,
      default: ''
    },
    mainFont: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasLabelSlot() {
      return !!this.$slots.label
    },
    classObj() {
      return {
        'round-button--fill': this.fill,
        'round-button--outline': this.outline,
        [`round-button--${this.color}`]: this.color.length,
        'round-button--is-active': this.active,
        'round-button--mobile-compact': this.mobileCompact,
        'round-button--border': this.border,
        [this.size]: true,
        'main-font': this.mainFont,
      }
    },
    isLink() {
      return this.href.length
    },
    renderTag() {
      return this.isLink ? (<a />) : (<button />)
    },
    attrs() {
      const dynamicAttrs = {}

      if (this.href) dynamicAttrs.href = this.href
      if (this.target) dynamicAttrs.target = this.target
      if (this.disabled) dynamicAttrs.disabled = this.disabled

      return dynamicAttrs
    }
  }
}
</script>

<style scoped>

</style>
