<template>
  <div class="wrapper" :key="key" ref="wrapper" />
</template>

<script setup>
import { MediaContent } from "@ruscorpora/ruscorpora_api";
import { onMounted, ref } from "vue";
import { v1 as uuid } from "uuid"

const props = defineProps({
  mediaContent: {
    type: MediaContent
  }
})
const key = uuid()
const ready = ref(false)
const wrapper = ref(null)

onMounted(() => {
  const iframe = document.createElement('iframe');
  iframe.style.height = '100%'
  iframe.style.width = '100%'
  iframe.setAttribute('allowfullscreen', true)
  const html = `<body style="margin: 0; width: 100%; height: 100%"><video controls style="width: 100%; height: 100%" poster="${props.mediaContent.poster}" src="${props.mediaContent.url}" preload="metadata"></video></body>`
  iframe.src = 'data:text/html;charset=utf-8,' + encodeURI(html);
  wrapper.value.appendChild(iframe)
})
</script>

<style scoped>
.wrapper {
  aspect-ratio: 16 / 9;
  background-color: var(--black);

  iframe {
    width: 100%;
    height: 100%;
  }
}

.video {
  width: 100%;
  height: 100%;
}
</style>
