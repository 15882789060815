<template>
  <component :is="renderComponent" v-bind="attrs" @update:modelValue="handleModelUpdate" :modelValue="val" />
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import { ConditionValue, TextConditionValue } from "@ruscorpora/ruscorpora_api"

export default {
  name: "DynamicField",
  props: {
    field: {
      type: Object,
      required: true
    },
    modelValue: {
      type: ConditionValue
    },
    extraOptions: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup(props, {emit}) {
    const componentName = computed(() => {
      switch (props.field.type) {
        case "radio":
          return "form/Radio.vue"
        case "int":
        case "text":
          return "form/TheInput.vue"
      }
    })
    const renderComponent = defineAsyncComponent(() => import(`../../components/ui/${componentName.value}`))

    const attrs = computed(() => {
      const computedAttrs = {
        ...props.field,
        name: props.field.name,
        label: props.field.label,
        fontAlias: props.field.fontAlias
      }

      if (props.extraOptions[props.field.type]) {
        Object.assign(computedAttrs, props.extraOptions[props.field.type])
      }

      switch (props.field.type) {
        case "radio":
          computedAttrs.options = props.field.options
          computedAttrs.inline = false
          break
        case "int":
          computedAttrs.type = "number"
          break
        case "text":
          break
      }

      return computedAttrs
    })

    const handleModelUpdate = (value) => {
      if (props.field.type === 'text') {
        emit('update:modelValue', ConditionValue.create({
          text: TextConditionValue.create({
            v: value,
          }),
          fieldName: props.field.name
        }))
      } else {
        emit('update:modelValue', value)
      }
    }

    const val = computed(() => {
      if (props.field.type === 'text') {
        return props.modelValue?.text?.v
      }
      return props.modelValue
    })
    return {
      renderComponent,
      attrs,
      val,

      handleModelUpdate
    }
  }
}
</script>

<style scoped>

</style>
