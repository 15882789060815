<template>
  <div class="cards-form">
    <card v-for="(card, index) of filledForm" :key="index" :card="card" :allow-delete="allowDeleteCondition"
          :special-word-key="specialWordKey"
          @validate="handleError"
          @delete-condition="handleDeleteCondition"
          @update-field="handleChangeFieldCondition(card.cardId, $event)" />

    <v-select v-if="hasAvailableCards"
              :options="availableCards"
              :clearable="false"
              :placeholder="$gettext('добавить условие')"
              :append-to-body="true"
              v-model="availableCardsFilter"
              label="title"
              class="vs--fluid custom-v-select"
              @option:selected="handleAddCondition($event.cardId)">
      <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon name="c-dropdown-indicator" />
            </span>
      </template>
    </v-select>
  </div>
</template>

<script>
import IntRange from "./../IntRange.vue";
import RoundButton from "../../RoundButton.vue";
import Icon from "../../Icon.vue";
import Checkbox from "./../Checkbox.vue";
import TheInput from "./../TheInput.vue";
import { ref, computed } from "vue";
import { configToForm, fillForm } from "../../../../utils/form";
import Card from "./Card.vue";
import { FrontendSearchMode } from "@ruscorpora/ruscorpora_api";

export default {
  name: "CardsForm",
  components: {Card, TheInput, Checkbox, Icon, RoundButton, IntRange},
  props: {
    searchCards: {
      type: Array
    },
    modelValue: {
      type: Array
    },
    specialWordKey: {
      type: Number,
      default: 0
    }
  },
  emits: ['validate', 'update:modelValue'],
  setup(props, {emit}) {
    const errorState = ref({})

    const handleDeleteCondition = (cardId) => {
      emit('update:modelValue', props.modelValue.filter(card => card.cardId !== cardId))
    }

    const handleAddCondition = (cardId) => {
      const cardIdAsNumber = (parseInt(cardId.split('-')[1])) ?? 0
      const newValues = [...props.modelValue]

      const conditionPosition = new Int32Array([
        ...newValues.map(x => parseInt(x.cardId.split('-')[1])),
        cardIdAsNumber
      ]).sort().findIndex(x => x === cardIdAsNumber)

      const condition = configToForm([props.searchCards.find(card => card.cardId === cardId)], true)[0]

      newValues.splice(conditionPosition, 0, condition)
      emit('update:modelValue', newValues)
      availableCardsFilter.value = null
    }

    if (!props.modelValue.length) {
      emit('update:modelValue', configToForm(props.searchCards, false))
    }

    const filledForm = computed(() => {
      return fillForm(props.modelValue, props.searchCards)
    })

    const allowDeleteCondition = computed(() => filledForm.value.length > 1)

    const handleChangeFieldCondition = (cardId, condition) => {
      emit('update:modelValue', props.modelValue.map(card => {
        if (card.cardId === cardId) card[condition.fieldName] = condition
        return card
      }))
    }

    const availableCardsFilter = ref(null)

    const availableCards = computed(() => {
      return props.searchCards
        .filter(card => !props.modelValue.find(x => x.cardId === card.cardId))
        .map(x => {
          return {
            cardId: x.cardId,
            title: x.fields[0].label
          }
        })
    })

    const hasAvailableCards = computed(() => {
      return availableCards.value.length > 0
    })

    const handleError = ({cardId, state}) => {
      errorState.value[cardId] = state

      emit('validate', Object.values(errorState.value).filter(x => !x).length === 0)
    }

    return {
      handleDeleteCondition,
      handleChangeFieldCondition,
      filledForm,
      allowDeleteCondition,
      hasAvailableCards,
      availableCards,
      handleAddCondition,
      availableCardsFilter,
      handleError
    }
  }
}
</script>

<style scoped lang="scss">
.cards-form {
  display        : flex;
  flex-direction : column;
  gap            : rem-calc(8);
}
</style>
