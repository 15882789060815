<script setup>
import TheTooltip from "../../ui/form/TheTooltip.vue";
import { ReadabilityInfo } from '@ruscorpora/ruscorpora_api';
import { computed, inject } from "vue";
const interpolate = inject("interpolate");
const $gettext = inject("gettext");
const { readabilityInfo } = defineProps({
    readabilityInfo: {
        type: ReadabilityInfo,
        required: true
    }
})

const emits = defineEmits(['open'])
const readabilityInfoShort = computed(() => interpolate($gettext('%(reader_age)s лет'), { reader_age: readabilityInfo.level }, true))
const readabilityInfoTooltip = computed(() => interpolate($gettext('Фрагмент предназначен для детей в возрасте %(reader_age)s'), { reader_age: interpolate($gettext('%(reader_age)s лет'), { reader_age: readabilityInfo.level }, true) }, true))
</script>

<template>
    <button class="base-readability-badge-style" :copy-content="`[${readabilityInfoShort}] `" @click="emits('open', readabilityInfoShort)" aria-label="Открыть информацию о доступности отрывка">
        <TheTooltip :mouse-enter-delay=".2" :title="readabilityInfoTooltip">
            <i v-text="readabilityInfoShort" />
        </TheTooltip>
    </button>
</template>

<style lang="scss" scoped>
.base-readability-badge-style {
    color: var(--black);
    font-size: rem-calc(10);
    line-height: 1.4;
    padding: rem-calc(3 5);
    background-color: var(--light-blue-accent);
    border-radius: 2px;
    transform: translateY(rem-calc(-2));
    margin-bottom: rem-calc(-2);
    display: inline-block;
    margin-right: 4px;
    cursor: pointer;

    @include breakpoint(medium up) {
        font-size: rem-calc(12);
    }
}
</style>