<template>
  <div class="the-input" :class="inputClassObject">
    <div v-if="showHead" class="the-input__head">
      <label v-if="label" :for="domID" class="the-input__label" :class="labelClassObject">
        <span :class="{'is-neural-label':isNeural}" v-text="translate(label)"/>
        <neural-badge v-if="isNeural"/>
      </label>
      <div v-if="showActions" class="the-input__actions">
        <button v-if="hasPopup" class="the-input__choice" @click="onOpenFormulaModal" :disabled="disabled">
          {{ openPopupMessage }}
        </button>
        <span v-if="hasPopup && ['EDITABLE_OPTIONS', 'map'].includes(popupConfig.formulaRuleType)">
          / {{ $gettext('ввести другое') }}
        </span>
        <div v-if="showButtons" class="the-input__buttons">
          <round-button v-if="keyboard" :aria-label="$gettext('Виртуальная клавиатура')" color="dark" size="small"
                        @click="visibleKeyboard = true">
            <icon name="c-keyboard"/>
          </round-button>
          <round-button v-if="hasHalpText" :aria-label="$gettext('Подсказка')" color="dark" size="small"
                        @click="showHelpText(computedHelpText)">
            <icon name="c-question"/>
          </round-button>
          <slot name="additional-control"/>
        </div>
      </div>
    </div>
    <div class="the-input__field">
      <slot name="tags" v-if="existsTags"></slot>
      <input
        :id="domID"
        :type="type"
        class="the-input__input"
        :class="inputTagClassObject"
        @input="updateValue"
        @blur="changeValue"
        :value="computedModelValue"
        :disabled="disabled"
        :placeholder="placeholder"
        :autocomplete="autocomplete.length? 'off': 'on'"
        @focus="handleFocusIn"
        @focusout="handleFocusOut"
      >
    </div>
    <simple-keyboard v-if="keyboard" :visible="visibleKeyboard" :input="modelValue" @on-close="onCloseKeyboard"
                     :keyboard-class="`keyboard-${domID}`"
                     :class="fontAliasClass"
                     :layouts="keyboard.layouts"
                     @on-change="onChangeValueFromKeyboard"/>
    <transition>
      <div class="the-input__suggest-list" v-if="suggest.enabled" ref="suggestDOM" :class="fontAliasClass">
        <ul v-if="suggest.list.length">
          <li v-for="item of suggest.list" :key="item" @click="applySuggest(item)">
            {{ item }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import RoundButton from "../RoundButton";
import Icon from "../Icon";
import { useModalStore } from '../../../stores/modal';
import SimpleKeyboard from "../SimpleKeyboard";
import { v4 as uuid } from 'uuid';
import { debounce } from "debounce";
import Loader from "../Loader";
import { useSearchStore, CURRENT_CORPUS } from "../../../stores/search";
import { computed, inject, ref, watch, useSlots } from "vue"
import { FontAlias, Corpus } from "@ruscorpora/ruscorpora_api";
import { decodeBase64, encodeBase64, getFontFamilyClass } from "../../../utils/helper";
import NeuralBadge from "../NeuralBadge.vue";

const BRACKETS_REGEXP = /\(([^()]*)\)/

export default {
  name: "TheInput",
  components: {NeuralBadge, Loader, SimpleKeyboard, RoundButton, Icon},
  props: {
    type: {
      type: String,
      default: 'text'
    },
    modelValue: {
      type: [String, Number],
    },
    label: {
      type: [String, null],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    labelStyle: {
      type: String,
      default: '',
      validate: (val) => {
        return ['strong', 'inline', 'inside'].includes(val)
      }
    },
    size: {
      type: String,
      default: '',
      validate: (val) => {
        return ['large', 'small', 'tiny'].includes(val)
      }
    },
    keyboard: {
      type: [Object, null],
      default: () => {
        return null
      },
      required: false
    },
    helpText: {
      type: [String, Function],
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    },
    attrPopup: {
      type: [Object, null],
      default: null,
      required: false
    },
    fieldsPopup: {
      type: [Object, null],
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: [Number, String],
      default: ''
    },
    maxNumberSize: {
      type: Number,
      default: 0
    },
    minNumberSize: {
      type: Number,
      default: 0
    },
    direction: {
      type: String,
      default: 'row',
    },
    specialWordKey: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ""
    },
    parentError: {
      type: Boolean,
      default: false
    },
    zeroNotAllowed: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    fontAlias: {
      type: Number
    },
    validateRules: {
      type: Array,
      default: () => []
    },
    clearWhenDisable: {
      type: Boolean,
      default: false
    },
    isNeural: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'focus', 'focusout', 'validate', 'blur', 'set-meaning'],
  setup(props, {emit}) {
    const modalStore = useModalStore()
    const searchStore = useSearchStore();
    const suggestDOM = ref(null);
    const request = inject('request');
    const $gettext = inject('gettext');
    const message = inject('message');
    const interpolate = inject('interpolate');
    const silentValue = ref("")
    const slots = useSlots()

    const hasPopup = computed(() => !!(props.attrPopup || props.fieldsPopup))
    const popupConfig = computed(() => props.attrPopup || props.fieldsPopup)
    const onlySelect = computed(() => hasPopup.value && popupConfig.value.onlySelect)

    const openPopupMessage = computed(() => {
      if (!hasPopup.value) return ""
      if (popupConfig.value?.formulaRuleType === 'map') return $gettext('указать на карте')
      return $gettext('выбрать')
    })
    const onOpenFormulaModal = () => {
      const config = {...popupConfig.value}

      if (props.fieldsPopup) {
        config.formulaRuleType = props.name
      }

      modalStore.showFormulaModal(config, searchStore[CURRENT_CORPUS], props.specialWordKey, props.modelValue, props.label)

      const unsubscribe = modalStore.$onAction(
        ({
           name,
           after
         }) => {
          after((result) => {
            if (name === 'returnFormula') {
              emit('update:modelValue', result)
              silentValue.value = result;
            }

            if (name === 'hideModal' || name === 'returnFormula') {
              unsubscribe()
            }
          })
        }
      )
    }

    const hasHalpText = computed(() => {
      return props.helpText instanceof Function || props.helpText.length > 0
    })
    const computedModelValue = computed(() => {
      if (props.name.endsWith('_regexp')) return decodeBase64(props.modelValue)
      return props.modelValue
    })

    const computedHelpText = computed(() => {
      if (props.helpText instanceof Function) {
        return props.helpText()
      }
      return props.helpText
    })

    const showButtons = computed(() => {
      return [hasHalpText.value, props.keyboard, slots['additional-control'] !== undefined].some(x => x)
    })
    const showActions = computed(() => {
      return [showButtons.value, hasPopup.value].some(x => x)
    })
    const showHead = computed(() => {
      return [showActions.value, props.label].some(x => x)
    })
    return {
      showHelpText: modalStore.showHelpText,
      suggestDOM,
      onOpenFormulaModal,
      request,
      hasHalpText,
      computedHelpText,
      hasPopup,
      message,
      interpolate,
      computedModelValue,
      silentValue,
      popupConfig,
      openPopupMessage,
      showButtons,
      showActions,
      showHead,
      onlySelect,
    }
  },
  data() {
    return {
      domID: null,
      visibleKeyboard: false,
      enabledSuggest: false,
      suggest: {
        list: [],
        loading: false,
        notFound: false,
        enabled: false
      },
      valid: true,
      hasErrorByRules: {
        minSize: false,
        maxSize: false,
        zeroNotAllowed: false,
        required: false,
        custom: false
      }
    }
  },
  mounted() {
    this.domID = uuid();
    document.body.addEventListener('validate', () => this.getValidateValue(this.modelValue))
  },
  computed: {
    fontAliasClass() {
      if (!this.fontAlias) return {}
      return getFontFamilyClass(this.fontAlias, true)
    },
    labelClassObject() {
      return {
        [`the-input__label--${this.labelStyle}`]: this.labelStyle.length
      }
    },
    inputClassObject() {
      return {
        [`the-input--${this.size}`]: this.size.length,
        [`the-input--label-${this.labelStyle}`]: this.labelStyle.length,
        [`the-input--disabled`]: this.disabled,
        [`the-input--number`]: this.type === 'number',
        [`the-input--invalid`]: !this.valid || this.parentError,
        [`the-input--${this.direction}`]: true,
      }
    },
    inputTagClassObject() {
      return {...this.fontAliasClass}
    },
    existsTags() {
      return !!this.$slots.tags
    }
  },
  methods: {
    checkValidate(value, noEmitError = false) {
      let isValid = true
      let newValue = value
      let maxNumberSize = !!this.maxNumberSize;
      let minNumberSize = !!this.minNumberSize;

      if (this.required && !newValue) {
        isValid = false
        if (!noEmitError) {
          if (!this.hasErrorByRules.required) {
            this.message.error(this.$gettext('Необходимо заполнить поле'))
          }
          this.hasErrorByRules.required = true
        }
      } else {
        this.hasErrorByRules.required = false
      }
      if (maxNumberSize && this.maxNumberSize < parseInt(newValue)) {
        isValid = false
        if (!noEmitError) {
          if (!this.hasErrorByRules.maxSize) {
            const formatString = this.$gettext('Значение должно быть не более %(count)s')
            this.message.error(this.interpolate(formatString, {count: this.maxNumberSize}, true))
          }
          this.hasErrorByRules.maxSize = true
        }
      } else {
        this.hasErrorByRules.maxSize = false
      }
      if (minNumberSize && this.minNumberSize > parseInt(newValue || 0)) {
        isValid = false
        if (!noEmitError) {
          if (!this.hasErrorByRules.minSize) {
            const formatString = this.$gettext('Значение должно быть не менее %(count)s')
            this.message.error(this.interpolate(formatString, {count: this.minNumberSize}, true))
          }
          this.hasErrorByRules.minSize = true
        }
      } else {
        this.hasErrorByRules.minSize = false
      }

      if (this.zeroNotAllowed && this.type === "number" && parseInt(newValue) === 0) {
        isValid = false
        if (!noEmitError) {
          if (!this.hasErrorByRules.zeroNotAllowed) {
            this.message.error(this.$gettext('Значение не может быть равно 0'))
          }
          this.hasErrorByRules.zeroNotAllowed = true
        }
      } else {
        this.hasErrorByRules.zeroNotAllowed = false
      }

      if (this.validateRules.length) {
        const externalValidate = this.validateRules
          .map(rule => rule(newValue))
          .filter(validateResult => validateResult.invalid)


        if (externalValidate.length) {
          isValid = false
          if (!noEmitError) {
            if (!this.hasErrorByRules.custom) {
              this.message.error(externalValidate.map(x => x.message).join('\n'))
            }

            this.hasErrorByRules.custom = true
          }
        } else {
          this.hasErrorByRules.custom = false
        }
      }

      return isValid
    },
    getValidateValue(value) {
      if (value === undefined || isNaN(value)) return value
      //if (!value && !this.required) return value
      let newValue = value
      let maxLength = !isNaN(parseInt(this.maxLength));
      if (maxLength) newValue = value.toString().slice(0, parseInt(this.maxLength));

      const isValid = this.checkValidate(newValue)
      this.valid = isValid
      this.$emit('validate', isValid)
      return this.type === "number" ? newValue : newValue.toString()
    },
    updateValue(event) {
      const val = this.name.endsWith('_regexp') ? encodeBase64(event.target.value) : event.target.value
      this.silentValue = val;
      this.$emit('update:modelValue', val);

      if (this.checkValidate(val, true)) {
        this.valid = true
        this.$emit('validate', true)
      }
      if (this.suggest.enabled) this.fetchSuggestList()
    },
    changeValue(event) {
      const val = this.name.endsWith('_regexp') ? encodeBase64(event.target.value) : event.target.value
      const newValue = this.getValidateValue(val);
      event.target.value = this.name.endsWith('_regexp') ? decodeBase64(newValue) : newValue;
      this.$emit('update:modelValue', newValue);
      this.$emit('blur');
    },
    onCloseKeyboard() {
      this.visibleKeyboard = false;
    },
    onChangeValueFromKeyboard(val) {
      this.$emit('update:modelValue', val);
    },
    handleFocusIn() {
      if (this.onlySelect) {
        if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
        this.onOpenFormulaModal()
        return
      }

      if (this.autocomplete.length) {
        this.suggest.enabled = true
        this.$nextTick(() => {
          this.setSuggestPosition()
        })
        window.addEventListener('resize', this.setSuggestPosition, {passive: true})
        window.addEventListener('scroll', this.setSuggestPosition, {passive: true})
      }
      this.$emit('focus')
    },
    setSuggestPosition() {
      if (this.suggestDOM === null) return
      const parentRect = this.$el.getBoundingClientRect();
      this.suggestDOM.style.top = `${parentRect.top + parentRect.height + 8}px`
      this.suggestDOM.style.left = `${parentRect.left + 4}px`
      this.suggestDOM.style.width = `${parentRect.width - 8}px`
    },
    handleFocusOut($event) {
      if (this.autocomplete.length) {
        this.suggest.enabled = false;
        window.removeEventListener('resize', this.setSuggestPosition)
        window.removeEventListener('scroll', this.setSuggestPosition)
      }
      this.$emit('focusout', $event)
    },
    fetchSuggestList: debounce(function () {
      if (this.modelValue.length < 2) {
        this.suggest.list = [];
        return
      }
      this.suggest.loading = true

      const suggestUrl = new URL(this.autocomplete, window.location.origin)
      suggestUrl.searchParams.set('q', this.modelValue)

      this.request(suggestUrl.toString())
        .then(r => {
          this.suggest.list = r['data']
        })
        .catch(() => void (0))
        .finally(() => this.suggest.loading = false)
    }, 200),
    applySuggest(value) {
      let newValue = value
      if (newValue.match(BRACKETS_REGEXP) !== null) {
        const meaning = newValue.match(BRACKETS_REGEXP).pop()
        newValue = newValue.replace(`(${meaning})`, "").trim()
        this.$emit('set-meaning', meaning)
      }
      this.$emit('update:modelValue', newValue)

      this.$nextTick(() => {
        this.suggest.list = []
      })
    },
    validateTrigger() {
      return this.checkValidate(this.modelValue)
    }
  },
  watch: {
    modelValue(value) {
      if (this.checkValidate(value, true)) {
        this.valid = true
        this.$emit('validate', true)
      }
    },
    disabled(val) {
      if (!this.clearWhenDisable) return

      if (val) {
        this.$emit('update:modelValue', "")
      } else {
        this.$emit('update:modelValue', this.silentValue)
      }
    }
  }
}
</script>

<style lang="scss">
.the-input {
  position  : relative;
  $this     : &;
  font-size : rem-calc(14);
  @include breakpoint(small up) {
    font-size : rem-calc(16);
  }

  &__label {
    flex-grow : 1;

    &--strong {
      font-weight : 700;
    }
  }

  &__head {
    display         : flex;
    justify-content : space-between;
    margin-bottom   : rem-calc(8);
    align-items     : center;
    min-height      : 14px;
  }

  &__field {
    border           : 1px solid var(--black-border);
    border-radius    : rem-calc(6);
    padding          : rem-calc(8 15);
    font-size        : rem-calc(18);
    color            : var(--black);
    background-color : transparent;
    width            : 100%;
    display          : flex;
    flex-wrap        : wrap;
    box-sizing       : border-box;

    &:focus-within {
      border-color     : var(--deep-blue-hover);
      background-color : var(--light-blue-hover);
    }
  }

  &__input {
    background : transparent;
    border     : 0;
    flex-grow  : 1;
    max-width  : 100%;

    &::placeholder {
      opacity : .8;
    }
  }

  &__actions {
    display : flex;
    gap     : rem-calc(8);
  }

  &__choice {
    text-decoration : underline;
    line-height     : 1;

    &:hover {
      text-decoration : none;
    }
  }

  &__buttons {
    display     : flex;
    gap         : rem-calc(8);
    align-items : flex-end;
  }

  &--large {
    .the-input {
      &__field {
        font-size     : rem-calc(14);
        padding       : rem-calc(0 15);
        border-radius : rem-calc(6);
        height        : rem-calc(48);
        @include breakpoint(medium up) {
          font-size : rem-calc(20);
          padding   : rem-calc(0 15);
          height    : rem-calc(56);
        }
      }
    }
  }

  &--small {
    .the-input {
      &__field {
        font-size     : rem-calc(12);
        padding       : rem-calc(0 6);
        border-radius : rem-calc(4);
        height        : rem-calc(32);
        @include breakpoint(medium up) {
          font-size : rem-calc(16);
          padding   : rem-calc(0 6);
          height    : rem-calc(32);
        }
      }
    }

    &#{$this}--label-inline {
      .the-input {
        &__field {
          width : rem-calc(66);
        }
      }
    }
  }

  &--tiny {
    .the-input {
      &__field {
        font-size     : rem-calc(12);
        padding       : rem-calc(0 6);
        border-radius : rem-calc(4);
        height        : rem-calc(28);
        @include breakpoint(medium up) {
          font-size : rem-calc(16);
          padding   : rem-calc(0 6);
          height    : rem-calc(28);
        }
      }
    }

    &#{$this}--label-inline {
      .the-input {
        &__field {
          width : rem-calc(40);
        }
      }
    }
  }

  &--row {
    flex-direction : row;
  }

  &--row-reverse {
    display         : flex;
    flex-direction  : row-reverse;
    justify-content : flex-end;

    label {
      margin-left : rem-calc(10);
    }
  }

  &--disabled {
    pointer-events : none;
    opacity        : .6;
  }

  &--label-inline {
    display : flex;

    .the-input {
      &__head {
        margin-bottom : 0;
        margin-right  : rem-calc(8);
      }
    }
  }

  &--label-inside {
    .the-input {
      &__field {
        padding-left  : 0;
        padding-right : 0;
        border        : 0;
      }

      &__head {
        position  : absolute;
        right     : rem-calc(15);
        top       : 50%;
        transform : translateY(-50%);
      }
    }
  }

  &__suggest-list {
    position : fixed;
    z-index  : 10;

    ul {
      background    : var(--white);
      border-radius : rem-calc(8);
      padding       : rem-calc(4 8);
      font-size     : rem-calc(18);
    }

    li {
      cursor        : pointer;
      max-width     : rem-calc(300);
      margin-bottom : rem-calc(10);

      &:last-child {
        margin-bottom : 0;
      }

      &:hover {
        color : var(--deep-blue-hover);
      }
    }

    &__loader {
      display         : flex;
      height          : 40px;
      justify-content : center;
      align-items     : center;
    }
  }

  &--number {
    #{$this}__input {
      padding : 0;
    }
  }

  &--invalid {
    #{$this} {
      &__field {
        border-color     : rgba(var(--invalid-color), 1);
        background-color : rgba(var(--invalid-color), 0.1);
      }

      &__input {
        color : rgba(var(--invalid-color), 1);
      }
    }
  }

  &--tree-with-vals {
    /* для подкорпуса с выбранным тегами */
    .the-input {
      &__field {
        padding-top    : 5px;
        padding-bottom : 2px;
      }

      &__input {
        width : 0;
      }
    }
  }

  .is-neural-label {
    padding-right : 25px;
  }
}
</style>
