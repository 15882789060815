export const checkConditionMixin = (isSmallResolution, wrapper) => {
    if (!isSmallResolution || !wrapper) return

    const buttonWidth = 30;

    wrapper.querySelectorAll('.the-header__conditions li').forEach(element => {
        const textField = element.querySelector('span');
        if (textField.scrollWidth > textField.clientWidth || textField.scrollWidth > textField.clientWidth + buttonWidth) {
            element.classList.add('clip')
        } else {
            element.classList.remove('clip')
        }
    });
}
