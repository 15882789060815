<template>
  <div>
    <vue-final-modal v-model="visibility"
                     class="modal-container"
                     content-class="modal-content"
                     @opened="handleOpenModal"
                     @closed="handleCloseModal"
                     @click-outside="handleCloseModal">
      <div class="formula-modal"
           :class="{'formula-modal--compact': config.mode === 'form' && config.options.formulaRuleType !== 'orpho'}">
        <round-button size="large" :outline="true" :aria-label="$gettext('Закрыть')" class="formula-modal__close"
                      @click="handleCloseModal">
          <icon name="cross" />
        </round-button>
        <div v-if="!isLoading && ready">
          <div class="formula-modal__header">
            <div class="formula-modal__header-control">
              <div v-if="['form', 'map'].includes(config.mode) || config.attrs.max_depth < MAX_TREE_DEPTH_TO_SPLITTING">
                {{ header }}
              </div>
              <div v-else>
                <v-select :options="treeListOptions"
                          :searchable="false"
                          :clearable="false"
                          label="title"
                          v-model="selectedTreeList"
                          class="custom-v-select vs--large vs--no-color">
                  <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <icon name="c-arrow" />
                      </span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="formula-modal__header-second-control">
              <div class="formula-modal__header-select-all" v-if="config.mode === 'options'">
                <the-button aria-label="Выбрать все значения" @click="onSelectAllHandler">
                  {{ $gettext('Выбрать все') }}
                </the-button>
              </div>
              <div class="formula-modal__header-inverse" v-if="config.mode === 'options'">
                <button @click="onInvertHandler">{{ $gettext('Инвертировать выбор') }}</button>
              </div>
            </div>
          </div>
          <div class="formula-modal__body">
            <div v-if="config.mode === 'options'">
              <div v-if="computedFormula.length" class="formula-modal__preview">{{ computedFormula }}</div>
              <div class="formula-modal__options"
                   :class="computedOptionsClass">
                <tree v-for="(item, index_item) of treeLevelList.treeList"
                      :key="`item_${selectedTreeList.key}_${index_item}`"
                      v-model:checkedKeys="selectedValue[selectedTreeList.key][index_item]"
                      v-model:expandedKeys="expandedKeys" :auto-expand-parent="autoExpandParent"
                      :tree-data="item" :default-expand-all="!isSmallResolution" :selectable="false" :checkable="true"
                      @expand="onExpand" :check-strictly="true" @check="onTreeCheckUpdate">
                  <template v-slot:switcherIcon="{expanded}">
                    <div class="ant-tree-switcher-arrow" :class="{'ant-tree-switcher-arrow--open': expanded}">
                      <icon name="c-arrow" />
                    </div>
                  </template>
                  <template #title="{ dataRef }">
                    <span class="formula-modal__tree-item-title">
                      {{ dataRef.title }}
                
                      <template v-if="dataRef.help_text_url">
                        <round-button class="formula-modal__tree-open-link" aria-label=""
                          color="dark"
                          size="small"
                          @click.prevent.stop="openInNewTab(dataRef.help_text_url)">
                        <icon name="c-question" />
                      </round-button>
                      </template>
                    </span>
                  </template>
                </tree>
              </div>
            </div>
            <div v-else-if="config.mode === 'form'" class="formula-modal__form">
              <template v-if="config.options.formulaRuleType === 'orpho'">
                <div v-if="computedFormula.length" class="formula-modal__preview">{{ computedFormula }}</div>
                <div class="orpho-form">
                  <div v-for="item in config.options.fields" :key="item.name" class="orpho-form__item">
                    <button type="button" v-for="option in item.options" :key="option.value" class="orpho-form__option"
                            @click="selectOrphoVal(option.value)">
                      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="7.5" cy="7.5" r="7" stroke="#2D3B60" />
                        <line x1="7.5" y1="4" x2="7.5" y2="11" stroke="#2D3B60" />
                        <line x1="4" y1="7.5" x2="11" y2="7.5" stroke="#2D3B60" />
                      </svg>
                      <span>
                      <span class="orpho-form__option-title">{{ option.title }}</span>
                      <span>{{ option.value }}</span>
                    </span>
                    </button>
                  </div>
                </div>
              </template>
              <div v-else v-for="(field, index) of config.attrs" :key="index">
                <the-input v-bind="field" v-if="field.type === 'text'" v-model="form[field.name]" />
                <radio v-bind="field" v-if="field.type === 'radio'"
                       @update:modelValue="form[field.name] = $event.text.v" />
                <checkbox v-bind="field" v-if="field.type === 'check-group'" v-model="form[field.name]" />
              </div>
            </div>
            <div v-else-if="config.mode === 'map'" class="formula-modal__map">
              <div v-if="Array.isArray(form) && form.length" class="formula-modal__preview"
                   v-text="form.join(' | ')" />
              <select-subcorpus-on-map :points="config.attrs.options" v-model="form" />
            </div>
          </div>
          <div class="formula-modal__buttons">
            <the-button aria-label="Применить" type="colored" @click="onApplyHandler">
              {{ $gettext('Применить') }}
            </the-button>
            <the-button aria-label="Очистить форму" @click="onResetFormHandler" type="dashed">
              {{ $gettext('Очистить') }}
            </the-button>
            <div class="formula-modal__footnote" v-if="footnote" v-html="footnote" />
          </div>
        </div>
        <div v-else class="formula-modal__loading">
          <loader />
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref } from "vue";
import { useModalStore, MODAL_FORMULA } from "../../stores/modal";
import Icon from "../ui/Icon";
import RoundButton from "./RoundButton";
import Checkbox from "./form/Checkbox";
import Loader from "./Loader";
import { storeToRefs } from 'pinia';
import TheButton from "./TheButton";
import { handleTreeCheck, extractValuesFromTree, handleFormulaRuleTypes } from "../../utils/form";
import TheInput from "./form/TheInput";
import Radio from "./form/Radio";
import { Tree } from "ant-design-vue"
import slugify from 'slugify'
import { useSearchStore, CURRENT_CORPUS } from "../../stores/search";
import { IS_SMALL_RESOLUTION, useRootStore } from "../../stores/root";
import cloneDeep from "lodash/cloneDeep";
import { MAX_TREE_DEPTH_TO_SPLITTING } from "../../constatns";
import { corpusToString } from "../../utils/helper";
import { VueFinalModal } from "vue-final-modal";
import SelectSubcorpusOnMap from "./form/SelectSubcorpusOnMap.vue";
import { openInNewTab } from "../../utils/helper";

const makeDefaultCheckGroupValue = () => {
  return {
    checked: [],
    halfChecked: []
  }
}

export default {
  name: "FormulaModal",
  components: {
    SelectSubcorpusOnMap,
    VueFinalModal, Radio, TheInput, TheButton, Loader, RoundButton, Icon, Checkbox, Tree
  },
  setup() {
    const store = useModalStore();
    const searchStore = useSearchStore();
    const rootStore = useRootStore();
    const $gettext = inject('gettext')
    const asyncGettext = inject('asyncGettext')
    const message = inject('message')

    const visibility = computed({
      get() {
        return store.isActive && store.type === MODAL_FORMULA
      },
      set() {
        handleCloseModal();
      }
    });
    const {isLoading, config} = storeToRefs(store);
    const defaultHeader = computed(() => config.value.extraTitle);
    const header = computed(() => config.value.attrs?.title || defaultHeader.value)
    const selected = ref('')
    const ready = ref(false);
    const isSmallResolution = computed(() => rootStore[IS_SMALL_RESOLUTION])

    const selectedValue = ref({})
    const expandedKeys = ref([])
    const autoExpandParent = ref(false)

    const allValues = computed(() => {
      const results = {}
      const values = extractValuesFromTree(config.value.attrs)

      Object.keys(values).forEach(key => {
        results[key] = values[key].map(x => {
          return {
            checked: [...x.flat()],
            halfChecked: []
          }
        })
      })
      return results
    })

    const onInvertHandler = () => {
      const newValue = {}

      Object.keys(allValues.value).forEach(key => {
        newValue[key] = allValues.value[key].map((item, index) => {
          const currentHalfChecked = [...(selectedValue.value[key][index]?.halfChecked || [])]

          return {
            checked: item.checked
                .filter(x => !selectedValue.value[key][index]?.checked?.includes(x))
                .filter(x => !currentHalfChecked.includes(x)),
            halfChecked: currentHalfChecked
          }
        })
      })

      selectedValue.value = {...newValue};
      computeFormula();
    }

    const onSelectAllHandler = () => {
      const newObject = {}

      Object.keys(allValues.value).forEach(key => {
        newObject[key] = allValues.value[key].map(group => {
          group.checked = [...group.checked, ...group.halfChecked]
          group.halfChecked = []
          return group
        })
      })
      selectedValue.value = {...newObject}
      computeFormula();
    }

    const treeLevelList = computed(() => {
      if (config.value.attrs.max_depth >= MAX_TREE_DEPTH_TO_SPLITTING) {
        selectedTreeList.value = {
          title: selectedLevel.value.title,
          key: selectedLevel.value.key
        }

        return {
          header: selectedLevel.value.title || defaultHeader.value,
          dataKey: selectedLevel.value.key,
          treeList: selectedLevel.value.children.map(item => [item])
        }
      } else {
        selectedTreeList.value = {
          title: config.value.attrs.title,
          key: config.value.attrs.title
        }

        return {
          header: config.value.attrs.title || defaultHeader.value,
          dataKey: config.value.attrs.title,
          treeList: config.value.attrs.tree.map(item => [item])
        }
      }
    })

    const onApplyHandler = () => {
      if (config.value.mode === 'form') {
        if (config.value.options.formulaRuleType === 'orpho') {
          store.returnFormula(computedFormula.value)
        } else {
          store.returnFormula(form.value)
        }
      } else if (config.value.mode === 'map') {
        store.returnFormula((form.value ?? []).join(' | '))
      } else {
        searchStore.saveOptionsTree(config.value.attrs.title, selectedValue.value, config.value.options.wordIndex)
        store.returnFormula(computedFormula.value)
      }

      store.hideModal()
    }

    const onResetFormHandler = () => {
      if (config.value.mode === 'form') {
        form.value = {}
        computedFormula.value = ""
      } else if (config.value.mode === 'map') {
        form.value = []
      } else {
        Object.keys(selectedValue.value).forEach(key => {
          selectedValue.value[key] = selectedValue.value[key].map(() => [])
        })
        computedFormula.value = ""
      }
      if (isSmallResolution.value) {
        expandedKeys.value = []
      }
    }

    const form = ref({})

    const selectedTreeList = ref({});

    const treeListOptions = ref([]);

    const selectedLevel = computed(() => {
      return config.value.attrs.tree.find(item => item.key === selectedTreeList.value['key']) || config.value.attrs.tree[0]
    });

    const computedFormula = ref('')

    store.$onAction(
        ({
           name,
           after
         }) => {
          after((result) => {
            const undefinedValue = $gettext('Не удалось интерпретировать значение. Возможные причины: вы перешли по чужой ссылке или отредактировали формулу')
            if (name === 'fetchMetaAttr' && result['mode'] === 'options') {
              if (!isSmallResolution.value) {
                expandedKeys.value = Object.values(allValues.value).flat().map(x => x.checked).flat()
              }

              if (result['attrs']['max_depth'] >= MAX_TREE_DEPTH_TO_SPLITTING) {
                result['attrs']['tree'].forEach(x => {
                  selectedValue.value[x.key] = Array(x.children?.length || 0).fill(makeDefaultCheckGroupValue())
                })

                treeListOptions.value = result['attrs']['tree'].map(item => {
                  return {
                    title: item.title,
                    key: item.key
                  }
                })
              } else {
                selectedValue.value[result['attrs']['title']] = Array(result['attrs']['tree'].length || 0).fill(makeDefaultCheckGroupValue())
              }

              if (config.value.options.value) {
                const savedValues = searchStore.extractOptionsTree(config.value.attrs.title, config.value.options.wordIndex);
                let formula = ""
                try {
                  formula = savedValues ? computeFormulaFunc(savedValues) : ""
                } catch (e) {
                  formula = false
                }

                if (config.value.options.value === formula) {
                  selectedValue.value = savedValues;
                  computedFormula.value = formula;
                } else {
                  message.warning({
                    content: undefinedValue,
                    duration: 4
                  })
                }
              }

              ready.value = true;
            }

            if (name === 'fetchMetaAttr' && result['mode'] === 'form') {
              if (config.value.options.value && config.value.options.formulaRuleType === 'orpho') {
                const vals = config.value.options.value.split(']').filter(x => x).map(x => x + ']')
                const availableVals = config.value.options.fields.map(x => x.options).flat().map(x => x.value)
                if (!vals.every(x=> availableVals.includes(x))) {
                  message.warning({
                    content: undefinedValue,
                    duration: 4
                  })
                } else {
                  computedFormula.value = config.value.options.value
                }
              }
              ready.value = true;
            }

            if (name === 'fetchMetaAttr' && result['mode'] === 'map') {
              ready.value = true;
              let valIsValid = true;

              (config.value.options.value || '').split('|')
                  .map(x => x.trim())
                  .forEach(x => {
                    if (!valIsValid) return

                    if (!config.value.attrs.options.find(point => point.name === x)) {
                      valIsValid = false
                    }
                  })

              if (!valIsValid && config.value.options.value) {
                message.warning({
                  content: $gettext('Не удалось интерпретировать значение. Возможные причины: вы перешли по чужой ссылке или отредактировали формулу'),
                  duration: 4
                })
                form.value = []
              } else {
                form.value = (config.value.options.value || '').split('|').map(x => x.trim()).filter(x => x)
              }
            }
          })
        }
    )

    const handlePressEnter = (event) => {
      if (event.keyCode !== 13) return
      event.preventDefault()
      event.stopPropagation()
      onApplyHandler()
    }

    const handleOpenModal = () => {
      document.body.addEventListener('keypress', handlePressEnter, true)
    }

    const handleCloseModal = () => {
      store.hideModal();

      ready.value = false;
      selectedValue.value = {};
      expandedKeys.value = [];
      computedFormula.value = "";
      document.body.removeEventListener('keypress', handlePressEnter, true)
    }

    const computedOptionsClass = computed(() => {
      const classList = new Map()
      const basicClassName = `options-${config.value.attrs.attrName}`
      let deepClassName = ""
      classList.set(basicClassName, true)

      if (config.value.attrs.max_depth >= MAX_TREE_DEPTH_TO_SPLITTING) {
        deepClassName = `options-${config.value.attrs.attrName}-${slugify(selectedTreeList.value?.key || '', {remove: /[*+~.()&'"!:@]/g})}`.toLocaleLowerCase()
        classList.set(deepClassName, true) 
      }

      if (!searchStore[CURRENT_CORPUS]) {
        classList.set(`options-${config.value.attrs.attrName}`, true)

        return Object.fromEntries(classList.entries());
      }

      classList.set(`${basicClassName}-${corpusToString(searchStore[CURRENT_CORPUS], '-', true)}`, Boolean(searchStore[CURRENT_CORPUS].lang))
      classList.set(`${basicClassName}-${corpusToString({ type: searchStore[CURRENT_CORPUS].type }, '-', true)}`, true)

      if (deepClassName) {
        classList.set(`${deepClassName}-${corpusToString(searchStore[CURRENT_CORPUS], '-', true)}`, Boolean(searchStore[CURRENT_CORPUS].lang))
        classList.set(`${deepClassName}-${corpusToString({ type: searchStore[CURRENT_CORPUS].type }, '-', true)}`, true)
      }
      
      return Object.fromEntries(classList.entries());
    })

    const computeFormulaFunc = (values) => {
      const payload = {}
      for (const [key, value] of Object.entries(cloneDeep(values))) {
        payload[key] = value.reduce((acc, current) => {
          const currentChecked = current.checked || []
          return [...acc, ...currentChecked]
        }, [])
      }
      return handleFormulaRuleTypes(payload, cloneDeep(config.value.attrs))
    }

    const computeFormula = () => {
      computedFormula.value = computeFormulaFunc(selectedValue.value)
    }

    const footnote = computed(() => {
      if (!searchStore[CURRENT_CORPUS]) return false
      const _message = `Сноска для ${config.value.attrs.attrName} в ${corpusToString(searchStore[CURRENT_CORPUS], '-', true)}`

      if ($gettext(_message) === _message) {
        return false
      }

      return $gettext(_message)
    })

    const onExpand = (keys) => {
      expandedKeys.value = keys;
      autoExpandParent.value = false;
    }

    const selectOrphoVal = (val) => {
      if ((computedFormula.value.match(/[\]]/g) ?? []).length >= 3) {
        return asyncGettext('Не более 3 значений').then(r => {
          message.warning({
            content: r,
            duration: 4
          })
        })
      }

      computedFormula.value += val
    }
    return {
      store,
      isLoading,
      visibility,
      config,
      header,
      selected,
      selectedValue,
      expandedKeys,
      onInvertHandler,
      onSelectAllHandler,
      onApplyHandler,
      onResetFormHandler,
      form,
      ready,
      treeLevelList,
      selectedTreeList,
      treeListOptions,
      computedFormula,
      handleCloseModal,
      onTreeCheckUpdate: (checkedKeys, event) => {
        if (event.checked && !expandedKeys.value.includes(event.node.key) && isSmallResolution.value) expandedKeys.value = [...expandedKeys.value, event.node.key]
        handleTreeCheck(checkedKeys, event);
        computeFormula();
      },
      computedOptionsClass,
      isSmallResolution,
      footnote,
      MAX_TREE_DEPTH_TO_SPLITTING,
      onExpand,
      autoExpandParent,
      handleOpenModal,
      selectOrphoVal,
      openInNewTab: openInNewTab
    }
  }
}
</script>

<style scoped lang="scss">
.one-item {
  max-height : 80vh;
}

.formula-modal {
  padding          : rem-calc(20);
  background-color : var(--light-blue);
  width            : 100vw;
  max-width        : 100vw;
  position         : relative;
  min-height       : rem-calc(200);
  height           : 100vh;
  height           : calc(var(--vh, 1vh) * 100);
  overflow-y       : auto;
  box-sizing       : border-box;

  &__header {
    display        : flex;
    align-items    : center;
    gap            : rem-calc(15);
    padding-bottom : rem-calc(20);
    border-bottom  : 1px solid var(--black);
    margin-bottom  : rem-calc(20);
    flex-wrap      : wrap;
    @media (max-width : 1280px) and (max-height : 600px) {
      padding-top    : 0 !important;
      margin-bottom  : 0 !important;
      padding-bottom : rem-calc(4) !important;
    }

    &-control {
      font-size     : rem-calc(36);
      padding-right : rem-calc(40);
    }

    &-inverse {
      font-size : rem-calc(16);
    }

    &-second-control {
      display     : flex;
      align-items : center;
      gap         : rem-calc(15);
      width       : 100%;
      @include breakpoint(small up) {
        width : auto;
      }
    }
  }

  &__buttons {
    display     : flex;
    gap         : rem-calc(16);
    padding-top : rem-calc(50);
    flex-wrap   : wrap;
  }

  &__body {
    font-size   : rem-calc(18);
    line-height : 1.5;
  }

  &__close {
    position : absolute;
    right    : rem-calc(13);
    top      : rem-calc(17);
    @include breakpoint(small up) {
      right : rem-calc(30);
      top   : rem-calc(35);
    }
  }

  &__loading {
    pointer-events  : none;
    position        : absolute;
    left            : 0;
    top             : 0;
    right           : 0;
    bottom          : 0;
    display         : flex;
    align-items     : center;
    justify-content : center;
  }

  &__form {
    display        : flex;
    flex-direction : column;
    gap            : rem-calc(20);
  }

  &__options {
    //max-height : 80vh;
  }

  &__preview {
    background    : #DBEDF7;
    font-size     : rem-calc(14);
    line-height   : 1.5;
    padding       : rem-calc(15 18);
    border        : 1px solid var(--black);
    margin-bottom : rem-calc(20);
    border-radius : rem-calc(8);
  }

  &__footnote {
    font-size   : rem-calc(14);
    line-height : 1.5;
    width       : 100%;
    opacity     : .4;

    @include breakpoint(small up) {
      font-size : rem-calc(16);
      width     : auto;
    }
  }

  /*&::after {
    position         : absolute;
    bottom           : rem-calc(60);
    left             : 0;
    right            : 0;
    height           : 1px;
    content          : '';
    background-color : var(--black);
  }*/

  @include breakpoint(small up) {
    border-radius : rem-calc(10);
    width         : rem-calc(1440);
    max-width     : 90vw;
    height        : auto;
    padding       : rem-calc(30);

    /*&::after {
      display : none;
    }*/


    &__body {
      padding-top : rem-calc(30);
    }

    &--compact {
      width : rem-calc(600);

      .formula-modal {
        &__header {
          padding-top : 0;
        }

        &__buttons {
          padding-bottom : 0;
        }
      }
    }
  }
}

.orpho-form {
  display        : flex;
  flex-direction : column;
  gap            : 15px 80px;

  @include breakpoint(medium up) {
    flex-direction : row;

    &__item {
      &:first-child {
        padding-right : 80px;
        border-right  : 1px dashed black;
      }

      &:nth-child(2) {
        column-count : 2;
      }
    }
  }

  &__option {
    display       : flex;
    gap           : 7px;
    color         : var(--black);
    font-size     : 12px;
    text-align    : left;
    margin-bottom : 10px;
    white-space   : nowrap;

    svg {
      flex-grow  : 0;
      margin-top : 2px;
    }

    & > span {
      display        : flex;
      flex-direction : column;
      align-items    : flex-start;
    }

    span span:last-child {
      opacity : .5;
    }

    &-title {
      font-size : 14px;
      color     : var(--deep-blue-hover);
    }

    &:first-child {
      column-span : all;

      svg {
        margin-top : 4px;
      }

      .orpho-form {
        &__option {
          &-title {
            font-size   : 16px;
            font-weight : 600;
          }
        }
      }
    }

    &:nth-child(n + 2) {
      margin-left : 20px;
    }

    &:hover {
      .orpho-form {
        &__option {
          &-title {
            text-decoration : underline;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ant-tree-list {

}

.one-item-wrapper {
  height     : 100%;
  align-self : start;
  font-size  : rem-calc(12) !important;

  @media (max-width : 1280px) and (max-height : 600px) {
    font-size : rem-calc(10) !important;
  }

  &:nth-child(1) {
    grid-area : one;
  }
}

.formula-modal {
  .ant-tree-indent-unit {
    width : rem-calc(24) !important;
  }

  @media (min-width : 1024px) and (min-height : 600px) {
    .ant-tree-indent-unit {
      width : 0 !important;
    }

    .ant-tree-indent-unit + .ant-tree-indent-unit {
      width : rem-calc(20) !important;
    }
  }

  .formula-modal__options .ant-tree-treenode:first-child {
    margin-bottom : rem-calc(2);

    .ant-tree-title {
      font-size : rem-calc(18);
      @include breakpoint(small up) {
        font-size : rem-calc(24);
      }
    }
  }

  .formula-modal__options .ant-tree-treenode:last-child.ant-tree-treenode-leaf-last {

    @media (min-width : 1024px) and (min-height : 600px) {
      font-size      : rem-calc(12);
      padding-bottom : 0 !important;

    }
  }

  .ant-tree-treenode > .ant-tree-switcher {
    position : relative;

    .icon-c-arrow {
      width  : rem-calc(16);
      height : rem-calc(10);
    }

    &::before {
      inset    : -5px;
      position : absolute;
      content  : "";
    }
  }

  /*.ant-tree-treenode > .ant-tree-switcher {
    display  : block !important;
    position : absolute;
    right    : 0 !important;
    left     : auto !important;
  }*/

  @media (min-height : 600px) and (min-width : 1100px) {
    .ant-tree-treenode > .ant-tree-switcher {
      display : none !important;
    }
  }
  &__tree-item-title {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
</style>
