<script setup>
import Icon from "../../ui/Icon.vue";
import TheTooltip from "../../ui/form/TheTooltip.vue";
import ResultActions from "../../ui/ResultActions.vue";
import Word from "../Word.vue";
import {
  computed
} from "vue";
import {
  SyntaxStructure,
  WordSequence
} from "@ruscorpora/ruscorpora_api";
import ReadabilityInfo from "./ReadabilityInfo.vue";


const props = defineProps({
  previousSnippetContinuation: {
    type: Boolean,
  },
  sequence: {
    type: [
      WordSequence,
      Object,
    ]
  },
  sequenceIndex: {
    type: [Number, String],
    default: 0
  },
  source: {
    type: Object
  },
  wordSource: {
    type: Object
  },
  langInfo: {
    type: Object
  },
  showContextExpandAction: {
    type: Boolean,
  },
  showLanguage: {
    type: Boolean,
  },
  enableResultActions: {
    type: Boolean,
    default: true
  },
  syntaxStructure: {
    type: SyntaxStructure,
    default: null
  }
})

const showPreviousSnippetContinuation = computed(() => props.previousSnippetContinuation && props.sequenceIndex === 0)
const emit = defineEmits(['open-speaker-info', 'open-readability-info', 'open-word-modal', 'toggle-context', 'copy-context'])
const hasReadabilityInfo = computed(() => {
  return Object.values(props.sequence?.readabilityInfo ?? {}).some(val => val)
})
</script>

<template>
  <icon
    v-if="showPreviousSnippetContinuation"
    class="prev-snippet-continue copy-ignore"
    name="previous-snippet-continue"/>

  <template v-if="showLanguage && sequenceIndex === 0">
    <span class="translate new-line-wrap concordance__lang-info"
          v-html="langInfo.humanReadable"
          :copy-content="langInfo.humanReadable"/>
  </template>

  <ReadabilityInfo v-if="hasReadabilityInfo" :readability-info="props.sequence.readabilityInfo" class="readability-info" @open="emit('open-readability-info', $event)" :class="{'from-new-line': sequenceIndex > 0}" />

  <span
    v-if="sequence.speakerInfoLine"
    class="speaker-info"
    @click="emit('open-speaker-info')"
    :class="{'from-new-line': sequenceIndex > 0}"
    :copy-content="`[${sequence.speakerInfoLine}] `">
    <the-tooltip :title="sequence.speakerInfoLine" :mouse-enter-delay=".3">
      <i v-text="sequence.speakerInfoLineShort"/>
    </the-tooltip>
  </span>

  <word
    v-for="(word) in sequence.currentWords"
    :doc-source="source"
    @open-word-modal="emit('open-word-modal', $event)"
    :key="word.uuid"
    :payload="word"
    :highlight="wordSource"
    :lang-info="langInfo"/>

  <result-actions
    v-if="enableResultActions"
    @toggle-context="emit('toggle-context')"
    @copy-context="emit('copy-context')"
    :syntax-structure="syntaxStructure"
    :show-expand-action="showContextExpandAction"/>
</template>

<style scoped lang="scss">
.result-actions {
  margin-left : -70px;
}

.poem-layout {
  .result-actions {
    overflow : hidden;
  }

  @include breakpoint(large up) {
    .result-actions {
      overflow : visible;
    }
  }
}

.concordance-item {
  &__body {
    @include breakpoint(medium up) {
      &:hover .result-actions {
        opacity : 1;
      }
    }

    p {
      &.seq-with-actions {
        span:nth-last-child(2) {
          padding-right : rem-calc(100);

          .concordance-item--compact & {
            padding-right : 0;
          }
        }
      }
    }
  }

  @include breakpoint(medium up) {
    &__group:not(.is-active) {
      .readability-info {
        position    : absolute;
        left        : auto;
        right       : calc(100% + rem-calc(7));
        top         : rem-calc(4);
        transform   : none;
        margin      : 0;
        font-size   : rem-calc(12);
        white-space : nowrap;
      }
    }
  }
}

.speaker-info {
  position     : relative;
  font-size    : 10px;
  line-height  : 1.5;
  display      : inline-flex;
  margin-right : 4px;
  z-index      : 0;
  cursor       : pointer;

  i {
    color         : var(--black);
    padding       : 0 4px;
    display       : inline-block;
    overflow      : hidden;
    text-overflow : ellipsis;
    max-width     : min(295px, 80vw);
    white-space   : nowrap;
  }

  &::before {
    inset            : -1px 0;
    border-radius    : 2px;
    position         : absolute;
    z-index          : -1;
    background-color : var(--light-blue-accent);
    content          : "";
  }
}

.prev-snippet-continue {
  margin-bottom : 13px;
  width         : 24px;
  height        : 12px;
  @include breakpoint(medium up) {
    margin-top    : -10px;
    margin-bottom : 7px;
  }
}

.para-concordance-item {
  @include breakpoint(medium up) {
    &:hover .result-actions {
      opacity : 1;
    }
  }

  p.seq-with-actions {
    span:nth-last-child(2) {
      padding-right : rem-calc(66);

      .snippet--compact & {
        padding-right : 0;
      }
    }
  }

  .result-actions {
    margin-left : rem-calc(-57);
  }
}

.translate {
  font-size   : rem-calc(18);
  font-weight : 500;
  display     : block;

  @include breakpoint(medium up) {
    font-size : rem-calc(20);
  }
}
</style>
<style lang="scss">
.concordance__lang-info {
  i {
    color       : rgba(63, 63, 70, 1);
    font-family : var(--cursive-font);
    font-style  : italic;
    font-weight : 400;
  }
}
</style>
