<template>
  <component :is="componentTag" :class="{'is-active': isActive, 'disabled': isDisabled}" v-bind="attrs"
             @click="changeCorp">
    {{ data.title }}
  </component>
</template>

<script>
import { computed } from "vue";
import { useSearchStore, SEARCH_STATE, SEARCH_ACTIONS } from "../../../stores/search";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import intersection from "lodash/intersection";
import {
  FrontendFormConfig,
  FrontendSearchState,
  SearchQuery,
  SearchResultType,
  SearchFormValue,
  Corpus,
  SearchParams,
  TemporalUnit, CorpusType,
} from "@ruscorpora/ruscorpora_api";
import { base64toBuffer, encodeSearchQuery, getModulePath, updateURLParameter } from "../../../utils/http";
import { convertQuerySearchToLexGram, getDefaultSettings, querySearchToCollocation } from "../../../utils/structure";
import { getCookie } from "../../../../modules/cookie";
import { useRoute, useRouter } from 'vue-router'
import { corpusToString, newSeed, openInNewTab } from "../../../utils/helper";
import { CURRENT_CORPUS, PORTRAIT_ACTIONS, usePortraitStore } from "../../../stores/portrait";
import { getDefaultFieldValue } from "../../../utils/form";
import { buildExactQuery } from "../../../utils/search";

export default {
  name: "ChangeCorpButton",
  props: {
    data: {
      type: Object
    },
    isPortrait: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array
    }
  },
  emits: ['change-corp'],
  setup(props, {emit}) {
    const searchStore = useSearchStore()
    const portraitStore = usePortraitStore()
    const route = useRoute()
    const router = useRouter()
    const params = computed(() => props.data.is_group ? props.data.children[0] : props.data)
    const isLink = computed(() => !params.value.hasOwnProperty('corpus'))
    const corp = computed(() => Corpus.fromObject(params.value.corpus))
    const isActive = computed(() => {
      return !props.data.is_group && isEqual(portraitStore[CURRENT_CORPUS], corp.value)
    })
    const isDisabled = computed(() => {
      return !!(props.modelValue.length && !intersection(props.modelValue, params.value.tags.map(x => x.slug)).length);
    })
    const componentTag = computed(() => {
      if (props.isPortrait) return (<button/>)
      return isLink.value ? (<a/>) : (<button/>)
    })

    const compareRouteNames = ['compare', 'req-compare', 'chart-compare', 'compare-freq-year', 'compare-ratio-year']
    const attrs = computed(() => {
      const dynamicAttrs = {}

      if (props.isPortrait) {
        return dynamicAttrs
      }

      if (isLink.value) {
        dynamicAttrs.href = params.value.url
        dynamicAttrs.target = '_blank'
      }

      return dynamicAttrs
    })

    const clearSyntaxParams = (lexGrammQuery) => {
      lexGrammQuery.sectionValues = lexGrammQuery.sectionValues.map(sectionValue => {
        sectionValue.subsectionValues = sectionValue.subsectionValues.map((subsectionValue) => {
          subsectionValue.conditionValues = subsectionValue.conditionValues.filter(conditionValue => {
            return ![
              "syntax_link",
              "syntax_to_word",
              "syntax_direction",
              "lexical_function",
              "lexical_direction",
              "lexical_to_word",
              "lexical_preposition",
            ].includes(conditionValue.fieldName)
          })

          return subsectionValue
        })

        return sectionValue
      })

      return lexGrammQuery
    }

    const getNextSearchQuery = async (params) => {
      const targetCorpusObj = Corpus.fromObject(params.corpus)
      if (params.is_old) return {searchQuery: null}

      return Promise.all([
        searchStore.getCorpusConfig(targetCorpusObj),
        portraitStore.portrait(targetCorpusObj, false),
      ])
        .then(([formsConfig, searchConfig]) => {
          const searchState = FrontendSearchState.fromObject(cloneDeep(searchStore[SEARCH_STATE]))
          const targetCorpFormConfig = FrontendFormConfig.fromObject(formsConfig.forms)
          const targetSearchConfig = searchConfig.config
          const existSavedSubcorpus = getCookie(`subcorpus_${corpusToString(targetCorpusObj, '_')}`)

          const targetCorporaParams = getDefaultSettings(targetSearchConfig, true).params

          let searchQuery = SearchQuery.create({
            resultType: searchState.query.resultType ?? [],
            corpus: targetCorpusObj,
            subcorpus: existSavedSubcorpus ? SearchFormValue.decode(base64toBuffer(existSavedSubcorpus)) : null,
            params: SearchParams.create(
              merge(
                {},
                targetCorporaParams.toJSON(),
                searchState.query.params ? searchState.query.params.toJSON() : {}
              )
            )
          })

          delete searchQuery.params.docSource
          // замена pos на gramm при переходе к другим корпусам
          searchQuery.params.frequencyFields = searchQuery.params.frequencyFields.map(x => x === "pos" ? "gramm" : x)

          let needSwitchToConcordance = !targetSearchConfig.availableResultTypes.includes(searchQuery.resultType[0])

          if (!needSwitchToConcordance && searchQuery.resultType[0] === SearchResultType.STATS) {
            // переход между режимами "график"
            if (isEqual(searchQuery.params.statFields, ['created'])) {
              if (targetSearchConfig.statFields.includes('created')) {
                searchQuery.params.statFields = ['created']
                searchQuery.params.statTemporalUnit = targetSearchConfig.statTemporalUnits[0] || TemporalUnit.YEAR
              } else {
                needSwitchToConcordance = true
              }
            } else {
              searchQuery.params.statFields = targetSearchConfig.statFields.filter(x => x !== 'created')
              if (!searchQuery.params.statFields.length) needSwitchToConcordance = true
            }
          }
          
          debugger

          if (searchQuery.resultType[0] === SearchResultType.FULL_TEXT) {
            delete searchQuery.params.sort
            delete searchQuery.params.expdiap
          }

          if (needSwitchToConcordance) {
            searchQuery.resultType = [SearchResultType.CONCORDANCE]
            delete searchQuery.params.statFields
            delete searchQuery.params.ngramSize
            delete searchQuery.params.sort
            delete searchQuery.params.seed
          }

          searchQuery.params.pageParams.page = 0

          /* Обработка LexGramm формы */
          if (searchState.query.lexGramm) {
            searchQuery.lexGramm = convertQuerySearchToLexGram(searchState.query.lexGramm.sectionValues[0], targetCorpFormConfig.lexGramm, null, true)

            if (searchState.query.corpus.type === CorpusType.SYNTAX || searchQuery.corpus.type === CorpusType.SYNTAX) {
              /* Переход или "в" или "из" синтаксического */
              searchQuery.lexGramm = clearSyntaxParams(searchQuery.lexGramm)
            }
          }
          /* Обработка Exact формы */
          else if (searchState.query.exactForm) {
            const exactConditions = searchState.query.exactForm?.sectionValues[0].conditionValues ?? []
            const newExact = {}

            targetCorpFormConfig.exact.fields.forEach(exactField => {
              newExact[exactField.name] = exactConditions.find(x => x.fieldName === exactField.name) ?? getDefaultFieldValue(exactField)
            })
            searchQuery.exactForm = buildExactQuery(newExact)
          }
          /* Обработка Collocation формы */
          else if (searchState.query.collocation && SearchResultType.COLLOCATION === searchState.query.resultType[0]) {
            if (needSwitchToConcordance) {
              // Переход в лексико-грамматический поиск, если в целевом нет коллокаций
              searchQuery.lexGramm = convertQuerySearchToLexGram(searchState.query.collocation.sectionValues[0], targetCorpFormConfig.lexGramm, null, true)

              /* Если переходим из коллокаций в синтагрус, сбрасываем значения полей */
              if (searchQuery.corpus.type === CorpusType.SYNTAX) {
                searchQuery.lexGramm = clearSyntaxParams(searchQuery.lexGramm)
              }
              delete searchQuery.params.sort
            } else {
              searchQuery.collocation = querySearchToCollocation(searchState.query.collocation.sectionValues[0], targetCorpFormConfig.collocation.sections)
            }
          }
          /* Обработка ParaLexGramm формы*/
          else if (searchState.query.paraLexGramm) {
            searchQuery.resultType = [SearchResultType.PARALLEL_CONCORDANCE]
            delete searchState.query.paraLexGramm
          }

          if ([SearchResultType.KWIC, SearchResultType.CONCORDANCE, SearchResultType.META].includes(searchQuery.resultType[0])) {
            if (!targetSearchConfig.sortings.find(x => x.name === searchQuery.params.sort && x.applicableTo.includes(searchQuery.resultType[0]))) {
              searchQuery.params.sort = targetSearchConfig.sortings.find(x => x.applicableTo.includes(searchQuery.resultType[0])).name
            }
          }

          searchStore.moveSavedOptions(targetCorpusObj)

          return {searchQuery, searchConfig}
        })
    }
    const setNewRoute = (params) => {
      searchStore[SEARCH_ACTIONS.CLEAR_EXPLAINS]()
      router.push(params)
    }
    const changeCorp = async ($event) => {
      const baseRouteName = route.matched[0].name
      $event.preventDefault()

      if (isActive.value) {
        // Закрыть окно, если корпус уже активен
        emit('change-corp', false)
        return
      }

      if (props.isPortrait) {
        emit('change-corp', true)
        if (baseRouteName === 'word-portrait') {
          // Обработка переключений для портрета слова
          emit('change-corp', false)

          if (params.value.is_old) return openInNewTab(params.value.url)

          const newQuery = {}
          if (route.query.req) {
            newQuery.req = route.query.req
            newQuery.seed = newSeed()
          }
          return setNewRoute({
            name: route.name,
            params: {id: params.value.slug},
            query: newQuery
          })
        }

        const {searchQuery} = await getNextSearchQuery(params.value)

        const query = {}
        if (searchQuery !== null) query.search = encodeSearchQuery(searchQuery)

        if (baseRouteName === 'corpus-portrait') {
          emit('change-corp', false)
          return setNewRoute({
            name: route.name,
            params: {id: params.value.slug},
            query: query
          })
        }

        if (baseRouteName === 'subcorpus-portrait') {
          emit('change-corp', false)
          if (params.value.is_old) return openInNewTab(params.value.url)

          return setNewRoute({
            name: route.name,
            params: {id: params.value.slug},
            query: query
          })
        }
      }

      if (['forms', 'search', 'select-subcorpus', 'explore', 'full-text', ...compareRouteNames].includes(baseRouteName) && params.value.is_old) {
        emit('change-corp', false)
        return openInNewTab(params.value.url)
      }

      if (isLink.value) return

      if ([
        'full-text',
      ].includes(route.name) && [CorpusType.SYNTAX].includes(corp.value.type)) {
        return setNewRoute({
          name: 'forms',
          query: {search: encodeSearchQuery(SearchQuery.create({corpus: corp.value}))}
        })
      }
      emit('change-corp', true)
      const {searchQuery, searchConfig} = await getNextSearchQuery(params.value)
      const isMetaResults = searchQuery.resultType.includes(SearchResultType.META) && window.location.pathname.includes('results')
      const isParaLexGrammResults = searchQuery.resultType.includes(SearchResultType.PARALLEL_CONCORDANCE) && window.location.pathname.includes('results')

      if (route.name === 'explore') {
        delete searchQuery.subcorpus

        updateURLParameter({
          'search': encodeSearchQuery(searchQuery),
          'req': new URLSearchParams(window.location.search).get('req') || ''
        }, isMetaResults ? getModulePath('subcorpus') : null)
      } else if (compareRouteNames.includes(route.name)) {
        if (!searchConfig.config.availableResultTypes.includes(SearchResultType.STATS) || !searchConfig.config.statFields.includes('created')) {
          /* фукнционал сравнения не поддерживается корпусом */
          setNewRoute({
            name: 'forms',
            query: {search: encodeSearchQuery(searchQuery)}
          })
        } else {
          setNewRoute({
            name: 'req-compare',
            params: {id: searchConfig.slug},
            query: {ids: searchConfig.saved_queries.length > 1 ? searchConfig.saved_queries.map(x => x.hash_sum) : []}
          })
        }
        return emit('change-corp', false)
      } else if (route.name === 'forms') {
        delete searchQuery.lexGramm
        delete searchQuery.paraLexGramm
        delete searchQuery.collocation
        delete searchQuery.exactForm

        updateURLParameter({
          search: encodeSearchQuery(searchQuery)
        })
      } else if (route.name === 'full-text') {
        searchQuery.resultType = [SearchResultType.META]

        const queryHasNonEmptySearchForm = ['exactForm', 'lexGramm', 'paraLexGramm', 'collocation'].some((x) => searchQuery[x])
        let newRouteName = 'select-subcorpus';

        if (queryHasNonEmptySearchForm) {
          newRouteName = 'forms'
        } else if (searchQuery.subcorpus) {
          newRouteName = 'search'
        }

        setNewRoute({
          name: newRouteName,
          query: {search: encodeSearchQuery(searchQuery)}
        })
        emit('change-corp', false)
      } else {
        portraitStore[PORTRAIT_ACTIONS.SET_PORTRAIT](searchConfig)
        searchStore[SEARCH_ACTIONS.CLEAR_STATS]()

        if (isParaLexGrammResults) {
          /* Пробрасывание из параллельной выдачи в форму нового корпуса*/
          setNewRoute({
            name: 'forms',
            query: {
              search: encodeSearchQuery(searchQuery)
            }
          })
        } else {
          setNewRoute({
            name: isMetaResults ? 'select-subcorpus' : route.name,
            query: {
              search: encodeSearchQuery(searchQuery)
            }
          })
        }


        emit('change-corp', false)
      }
    }
    return {
      componentTag,
      attrs,
      changeCorp,
      isActive,
      isDisabled
    }
  }
}
</script>

<style scoped>
.is-active {
  text-decoration: underline !important;
  font-weight: 700;
}
</style>
