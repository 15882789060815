<template>
  <transition @after-enter="checkConditionReqNoty">
    <div class="the-header the-header--alt the-header--subcorpus" v-if="!compactMode" ref="wrapper">
      <div class="container">
        <div class="the-header__wrapper">
          <div class="the-header__name">{{ $gettext('Подкорпус') }}</div>
          <div v-if="isSubcorpusRoute" class="the-header__actions">
            <div class="the-header__actions-items">
              <the-button type="white" @click="cancelEditSubcorpus">
                {{ $gettext('Отмена') }}
              </the-button>
              <the-button v-if="existsSubcorpus" :aria-label="$gettext('Очистить подкорпус')" type="dashed"
                          @click="resetSubcorpus(true)">
                {{ $gettext('Очистить') }}
              </the-button>
            </div>
          </div>
          <div v-else class="the-header__actions">
            <div class="the-header__actions-items">
              <router-link v-if="existsSubcorpus && (!isMetaResultType || isSearchRoute || isPortrait)"
                           :to="{name: 'select-subcorpus', query: subcorpusUrl}">
                <the-button :aria-label="$gettext('Изменить подкорпус')" type="white" :plain="true">
                  {{ $gettext('Изменить') }}
                </the-button>
              </router-link>
              <router-link v-else-if="existsSubcorpus && isMetaResultType && isResultsRoute"
                           :to="{name: 'select-subcorpus', query: subcorpusUrl}">
                <the-button type="white" :plain="true">
                  {{ $gettext('Изменить') }}
                </the-button>
              </router-link>
              <the-button v-if="existsSubcorpus" :aria-label="$gettext('Очистить подкорпус')" type="dashed"
                          @click="resetSubcorpus">
                {{ $gettext('Очистить') }}
              </the-button>

              <router-link v-if="!existsSubcorpus" :to="{name: 'select-subcorpus', query: subcorpusUrl}">
                <the-button :aria-label="$gettext('Задать подкорпус')" type="white" :plain="true">
                  {{ $gettext('Задать') }}
                </the-button>
              </router-link>
            </div>
            <div class="the-header__portrait-mobile-link" v-if="currentCorp.slug && existsSubcorpus && !isPortrait">
              <the-tooltip placement="topRight"
                           :title="$gettext('Об этом подкорпусе')">
                <router-link :to="subcorpusPortraitPath">
                  <round-button :aria-label="$gettext('Об этом подкорпусе')" color="white" fill>
                    <icon name="info" />
                  </round-button>
                </router-link>
              </the-tooltip>
            </div>
          </div>
          <div class="the-header__stats">
            <ul class="the-header__stats-counter" v-show="showCounters">
              <li v-if="existsSubcorpus && Number.isInteger(counters.subcorpus.textCount)">{{ textCounter }}</li>
              <li v-if="existsSubcorpus && showSentenceCount">{{ sentenceCounter }}</li>
              <li v-if="existsSubcorpus && counters.subcorpus.wordUsageCount">{{ wordUsageCounter }}</li>
            </ul>
          </div>
          <div class="the-header__last">
            <ul class="the-header__conditions">
              <li v-for="(explanation, index) in plaintExplainValues" :key="index"
                  class="the-header__condition" :class="{'the-header__condition--accent': explanation.mark}">
                <the-tooltip v-if="explanation.text.length > 30 || isSmallResolution" :title="explanation.text">
                  {{ explanation.text }}
                  <icon name="noty" extra-class="the-header__conditions-noty" />
                </the-tooltip>
                <span v-else>
                  {{ explanation.text }}
                  <icon name="noty" extra-class="the-header__conditions-noty" />
                </span>
              </li>
            </ul>
            <div class="the-header__portrait-desktop-link">
              <the-tooltip v-if="currentCorp.slug && existsSubcorpus && !isPortrait"
                           :title="$gettext('Об этом подкорпусе')">
                <router-link :to="subcorpusPortraitPath">
                  <round-button :aria-label="$gettext('Об этом подкорпусе')" color="white" fill>
                    <icon name="info" />
                  </round-button>
                </router-link>
              </the-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from "../ui/Icon";
import TheButton from "../ui/TheButton";
import { COUNTERS, IS_SMALL_RESOLUTION, THE_HEADER_IS_COMPACT_KEY, ROUTE, useRootStore } from "../../stores/root";
import {
  RESULT_TYPE,
  SEARCH_STATE,
  SUBCORPUS_EXPLAINS,
  SETTINGS,
  useSearchStore, SEARCH_ACTIONS
} from "../../stores/search";
import { computed, ref, watchEffect, inject } from "vue";
import {
  base64toBuffer,
  encodeSearchQuery,
  bufferToBase64
} from "../../utils/http";
import { checkConditionMixin } from "../../mixins/headers";
import { numberFilter } from "../../filters/number";
import { FrontendPreviousParams, SearchFormValue, SearchParams, SearchResultType, CorpusType } from "@ruscorpora/ruscorpora_api";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { DefaultPagination, getDefaultSettings } from "../../utils/structure";
import TheTooltip from "../ui/form/TheTooltip";
import { buildExplanationToView, corpusToString } from "../../utils/helper";
import { deleteCookie, getCookie } from "../../../modules/cookie"
import { compareSubcorps } from "../../utils/search";
import RoundButton from "../ui/RoundButton.vue";
import { CORPUS_INFO, usePortraitStore, CURRENT_CORPUS } from "../../stores/portrait";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "SubcorpusHeader",
  components: {RoundButton, TheTooltip, TheButton, Icon},
  props: {
    isPortrait: {
      type: Boolean,
      default: false
    },
    beforeRouteChangeHook: {
      type: Function,
      default: (opt) => opt
    }
  },
  emits: ['reset-subcorpus'],
  setup(props, {emit}) {
    const rootStore = useRootStore();
    const searchStore = useSearchStore();
    const portraitStore = usePortraitStore();
    const compactMode = computed(() => rootStore[THE_HEADER_IS_COMPACT_KEY] && !props.isPortrait);
    const counters = computed(() => rootStore[COUNTERS]);
    const isSmallResolution = computed(() => rootStore[IS_SMALL_RESOLUTION]);
    const wrapper = ref(null);
    const query = computed(() => searchStore[SEARCH_STATE].query)
    const router = useRouter()
    const route = useRoute()
    const existsSubcorpus = computed(() => {
      return query.value.subcorpus !== null && SearchFormValue.encode(query.value.subcorpus).len > 0 && query.value.subcorpus?.sectionValues[0]?.conditionValues.length
    })
    const showCounters = computed(() => {
      try {
        return counters.value.subcorpus.textCount > 0 || !searchStore.searchInProgress
      } catch (e) {
        return false
      }
    })
    const subcorpusUrl = computed(() => {
      const encodedSearchQuery = encodeSearchQuery(
          query.value,
          route.name === 'search' && !isMetaResultType.value ? FrontendPreviousParams.create({
            searchParams: query.value.params,
            searchResultType: searchStore[RESULT_TYPE]
          }) : null
      )

      return {search: encodedSearchQuery}
    })

    const isSubcorpusRoute = computed(() => route.name === 'select-subcorpus')
    const isSearchRoute = computed(() => route.name === 'forms')
    const isResultsRoute = computed(() => route.name === 'search')
    const isMetaResultType = computed(() => searchStore[RESULT_TYPE] === SearchResultType.META)
    const queryExplains = computed(() => searchStore[SUBCORPUS_EXPLAINS].length ? searchStore[SUBCORPUS_EXPLAINS][0] : null)
    const plaintExplainValues = computed(() => {
      if (!queryExplains.value) return []
      const savedSubcorpConditions = savedSubcorpus.value ? [...savedSubcorpus.value.sectionValues[0].conditionValues] : []
      const currentSubcorpConditions = query.value.subcorpus ? [...query.value.subcorpus.sectionValues[0].conditionValues] : []
      const subcorpsIsEqual = compareSubcorps(query.value.subcorpus, savedSubcorpus.value)

      return queryExplains.value.sectionExplanations.map(sectionExplanation => {
        return sectionExplanation.subsectionExplanations.map(explanation => {
          return explanation.conditionExplanations.map(conditionExplanation => {
            const plainExplain = buildExplanationToView(conditionExplanation)
            const valueFromSaved = savedSubcorpConditions.find((cond) => cond.fieldName === conditionExplanation.fieldName)
            const valueFromCurrent = currentSubcorpConditions.find((cond) => cond.fieldName === conditionExplanation.fieldName)

            return {
              mark: !subcorpsIsEqual && (!valueFromSaved || (valueFromCurrent && !isEqual(valueFromSaved.toJSON(), valueFromCurrent.toJSON()))),
              text: plainExplain
            }
          })
        }).flat()
      }).flat()
      /*      return queryExplains.value.sectionExplanations[0].subsectionExplanations.map(explanation => {
              return explanation.conditionExplanations.map(conditionExplanation => {
                const plainExplain = buildExplanationToView(conditionExplanation)
                const valueFromSaved = savedSubcorpConditions.find((cond) => cond.fieldName === conditionExplanation.fieldName)
                const valueFromCurrent = currentSubcorpConditions.find((cond) => cond.fieldName === conditionExplanation.fieldName)

                return {
                  mark: !subcorpsIsEqual && (!valueFromSaved || (valueFromCurrent && !isEqual(valueFromSaved.toJSON(), valueFromCurrent.toJSON()))),
                  text: plainExplain
                }
              })
            }).flat()*/
    })

    watchEffect(() => {
      checkConditionMixin(isSmallResolution.value, wrapper.value)
    })

    const savedSubcorpus = computed(() => {
      const existSavedSubcorpus = getCookie(`subcorpus_${corpusToString(query.value.corpus, '_')}`)

      return existSavedSubcorpus ? SearchFormValue.decode(base64toBuffer(existSavedSubcorpus)) : null
    })

    const resetSubcorpusToPreviousValue = (restoreSubcorpus = false, goTo = 'auto') => {
      const previousParams = searchStore[SEARCH_STATE].previousParams
      const searchQuery = cloneDeep(query.value)
      const newUrl = {
        name: '',
        params: {},
        query: {}
      }

      if (router.options.history.state.back) {
        const resolve = router.resolve(router.options.history.state.back)

        if (resolve.name) {
          goTo = resolve.name
          newUrl.name = resolve.name
          newUrl.params = resolve.params
        }
      }

      if (previousParams) {
        if (previousParams.searchResultType === SearchResultType.META) goTo = 'select-subcorpus'

        searchQuery.params = {
          ...previousParams.searchParams,
          pageParams: getDefaultSettings(searchStore.config.value, true, query.value.corpus).params.pageParams
        }
        searchQuery.resultType = [previousParams.searchResultType]
      } else {
        searchQuery.resultType = [goTo === 'full-text' ? SearchResultType.FULL_TEXT : SearchResultType.CONCORDANCE]
        searchQuery.params = getDefaultSettings(searchStore.config.value, true, query.value.corpus).params
      }

      if (restoreSubcorpus) {
        searchQuery.subcorpus = savedSubcorpus.value
      }

      if (goTo === 'auto' && !newUrl.name) {
        newUrl.name = previousParams ? 'search' : 'forms'
      } else {
        newUrl.name = goTo
      }

      newUrl.query.search = encodeSearchQuery(searchQuery)

      if (newUrl.name === 'select-subcorpus') {
        newUrl.name = 'forms'
        newUrl.params = null
      }

      // Возврат к поиску, но поиска нет (редирект на формы)
      if (newUrl.name === 'search' && !searchQuery.exactForm && !searchQuery.lexGramm && !searchQuery.collocation) {
        newUrl.name = 'forms'
        newUrl.params = null
      }
      // Возврат к поиску, если был произведен сброс со страницы "документ"
      if (newUrl.name === 'full-text' && !previousParams) {
        newUrl.name = 'forms'
        newUrl.params = null
      }
      router.push(props.beforeRouteChangeHook(newUrl))
    }

    const resetSubcorpus = (force = false) => {
      searchStore[SEARCH_ACTIONS.CLEAR_EXPLAINS]()
      emit('reset-subcorpus')
      searchStore[SEARCH_ACTIONS.RESET_SUBCORPUS]()

      if (savedSubcorpus.value !== null) {
        // Есть сохраненный подкорпус. Значения текущего (временного) не совпадает
        if (
            bufferToBase64(SearchFormValue.encode(query.value.subcorpus).finish()) !==
            bufferToBase64(SearchFormValue.encode(savedSubcorpus.value).finish())
        ) {
          return resetSubcorpusToPreviousValue(true, route.name)
        }
      }

      if (isMetaResultType.value && isSubcorpusRoute.value && !force) {
        resetSubcorpusToPreviousValue(true)
      } else {
        // Full reset subcorpus. Think about move this to action of the SearchStore
        const cleanedQuery = cloneDeep(query.value)
        delete cleanedQuery.subcorpus
        deleteCookie(`subcorpus_${corpusToString(cleanedQuery.corpus, '_')}`)
        query.value.subcorpus = null

        router.push(
            props.beforeRouteChangeHook({
              name: cleanedQuery.resultType[0] === SearchResultType.META && route.name === 'search' ? 'select-subcorpus' : route.name,
              query: {search: encodeSearchQuery(cleanedQuery)},
              params: route.params
            })
        )
      }
    }

    const cancelEditSubcorpus = (restore = false) => {
      resetSubcorpusToPreviousValue(restore)
    }

    const $ngettext = inject('ngettext')
    const $interpolate = inject('interpolate')

    const textCounter = computed(() => {
      const message = $ngettext('%(count)s документ', '%(count)s документа', counters.value.subcorpus.textCount)
      return $interpolate(message, {count: numberFilter(counters.value.subcorpus.textCount)}, true)
    })
    const sentenceCounter = computed(() => {
      const message = $ngettext('%(count)s sentence', '%(count)s sentences', counters.value.subcorpus.sentenceCount)
      return $interpolate(message, {count: numberFilter(counters.value.subcorpus.sentenceCount)}, true)
    })
    const wordUsageCounter = computed(() => {
      const message = $ngettext('%(count)s слово', '%(count)s слова', counters.value.subcorpus.wordUsageCount)
      return $interpolate(message, {count: numberFilter(counters.value.subcorpus.wordUsageCount)}, true)
    })

    const currentCorp = computed(() => portraitStore[CORPUS_INFO])
    
    const subcorpusPortraitPath = computed(() => {
      if (!currentCorp.value.slug) return null

      const cleanedQuery = cloneDeep(searchStore.query)
      cleanedQuery.resultType = [SearchResultType.META]
      if (cleanedQuery.params) {
        delete cleanedQuery.params.docSource
        cleanedQuery.params = SearchParams.fromObject(cleanedQuery.params)
        if (cleanedQuery.params.pageParams === null) cleanedQuery.params.pageParams = DefaultPagination()
        cleanedQuery.params.pageParams.page = 0
      }

      return {
        name: 'subcorpus-docs',
        params: {
          id: currentCorp.value.slug
        },
        query: {
          search: encodeSearchQuery(cleanedQuery)
        }
      }
    })

    const showSentenceCount = computed(() => {
      return counters.value.subcorpus.sentenceCount && [CorpusType.SYNTAX].includes(portraitStore[CURRENT_CORPUS].type)
    })

    return {
      compactMode,
      wrapper,
      counters,
      existsSubcorpus,
      subcorpusUrl,
      resetSubcorpus,
      cancelEditSubcorpus,
      checkConditionReqNoty: () => {
        checkConditionMixin(isSmallResolution.value, wrapper.value)
      },
      isSubcorpusRoute,
      isSearchRoute,
      isResultsRoute,
      isMetaResultType,
      plaintExplainValues,
      textCounter,
      wordUsageCounter,
      isSmallResolution,
      currentCorp,
      subcorpusPortraitPath,
      showCounters,
      sentenceCounter,
      showSentenceCount,
    }
  }
}
</script>

<style scoped lang="scss">
.the-header {
  &__portrait-desktop-link {
    display : none;
    @include breakpoint(medium up) {
      display : initial;
    }
  }

  &__portrait-mobile-link {
    @include breakpoint(medium up) {
      display : none;
    }
  }

  &__actions {
    &-items {
      display        : flex;
      flex-direction : row;
      gap            : rem-calc(8 18);
    }

    @include breakpoint(small down) {
      flex-direction  : row;
      gap             : rem-calc(18);
      align-items     : center;
      justify-content : flex-end;
      &-items {
        flex-direction : column;
      }
    }
  }
}
</style>
