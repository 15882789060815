<template>
  <div class="loader" ref="loader" :class="{[size]: size !== ''}"></div>
</template>

<script>
import { onMounted, ref } from "vue";

export default {
  name: "Loader",
  props: {
    height: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const loader = ref(null)

    onMounted(() => {
      if (props.height) loader.value.style.height = `${props.height}px`
    })

    return {
      loader
    }
  }
}
</script>

<style scoped lang="scss">
.loader,
.loader:before,
.loader:after {
  background-color : var(--deep-blue-hover);
  animation        : load1 1s infinite ease-in-out;
  width            : 1em;
  height           : 4em;
}

.loader {
  color           : var(--deep-blue-hover);
  text-indent     : -9999em;
  margin          : 88px auto;
  position        : relative;
  font-size       : 11px;
  transform       : translateZ(0);
  animation-delay : -0.16s;
  opacity         : .5;
  pointer-events  : none;

  &.small {
    font-size : 3px;
  }
}

.loader:before,
.loader:after {
  position : absolute;
  top      : 0;
  content  : '';
}

.loader:before {
  left            : -1.5em;
  animation-delay : -0.32s;
}

.loader:after {
  left : 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow : 0 0;
    height     : 4em;
  }
  40% {
    box-shadow : 0 -2em;
    height     : 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow : 0 0;
    height     : 4em;
  }
  40% {
    box-shadow : 0 -2em;
    height     : 5em;
  }
}
</style>
