import { defineStore } from 'pinia'

import {
    Corpus,
    SearchConfig
} from '@ruscorpora/ruscorpora_api'

import { prefixUrl } from "../utils/helper";
import { bufferToBase64 } from "../utils/http";

export const CORPUS_INFO = 'corpusGetter'
export const CURRENT_CORPUS = 'currentCorpus'
export const SAVED_QUERIES = 'savedQueries'
const CORPUS_INFO_STATE = 'corpusInfo'
export const PORTRAIT_ACTIONS = {
    SET_PORTRAIT: 'setPortrait',
    ADD_QUERY_TO_COMPARE: 'addQueryToCompare',
    REMOVE_QUERY_FROM_COMPARE: 'removeQueryFromCompare',
    SET_COMPARE_QUERIES_LIST: 'setCompareQueriesList',
}
export const usePortraitStore = defineStore('portrait', {
    state: () => {
        return {
            [CORPUS_INFO_STATE]: {
                tags: [],
                config: null,
                slug: "",
                saved_queries: [],
                i18n: {}
            },
            [CURRENT_CORPUS]: null
        }
    },
    getters: {
        [CORPUS_INFO]: (state) => state[CORPUS_INFO_STATE],
        [SAVED_QUERIES]: (state) => state[CORPUS_INFO_STATE].saved_queries.map(x => x.hash_sum),
    },
    actions: {
        init(corpus) {
            this[CURRENT_CORPUS] = corpus
        },
        [PORTRAIT_ACTIONS.SET_PORTRAIT](portrait) {
            this.init(portrait.corpus)
            this[CORPUS_INFO_STATE] = portrait
        },
        [PORTRAIT_ACTIONS.ADD_QUERY_TO_COMPARE](id) {
            this[CORPUS_INFO_STATE].saved_queries.push({hash_sum: id})
        },
        [PORTRAIT_ACTIONS.REMOVE_QUERY_FROM_COMPARE](id) {
            this[CORPUS_INFO_STATE].saved_queries = this[CORPUS_INFO_STATE].saved_queries.filter(x => x.hash_sum !== id)
        },
        [PORTRAIT_ACTIONS.SET_COMPARE_QUERIES_LIST](ids = []) {
            this[CORPUS_INFO_STATE].saved_queries = ids.map(id => Object({hash_sum: id}))
        },
        async portraitDescription(corpus) {
            return this.request(prefixUrl(`/api/corps?corpus=${encodeURIComponent(bufferToBase64(Corpus.encode(corpus).finish()))}&full=true&rev=${window.App.REV}`, true), {}, 5000, false, true)
                .then(res => {
                    this[CORPUS_INFO_STATE] = {
                        ...res,
                        corpus: Corpus.fromObject(res.corpus),
                        config: SearchConfig.fromObject(res.config)
                    }
                    return this[CORPUS_INFO_STATE]
                })
        },
        async portrait(corpus, set = true) {
            return this.request(prefixUrl(`/api/corps?corpus=${encodeURIComponent(bufferToBase64(Corpus.encode(corpus).finish()))}&rev=${window.App.REV}`, true), {}, 5000, false, true)
                .then(res => {
                    const portrait = {
                        ...res,
                        corpus: Corpus.fromObject(res.corpus),
                        config: SearchConfig.fromObject(res.config)
                    }
                    if (set) this[PORTRAIT_ACTIONS.SET_PORTRAIT](portrait)

                    return portrait
                })
        },
        async portraitMany(corpusList = []) {
            const url = new URL(prefixUrl('/api/corps-many', true), window.location.origin)
            url.searchParams.set('rev', window.App.REV)
            corpusList.forEach(corpus => url.searchParams.append('corpus', bufferToBase64(Corpus.encode(corpus).finish())))

            return this.request(url.toString(), {}, 5000, false, true)
                .then(response => {
                    return response.map(portrait => {
                        return {
                            ...portrait,
                            corpus: Corpus.fromObject(portrait.corpus),
                            config: SearchConfig.fromObject(portrait.config)
                        }
                    })
                })
        },
        async cachedPortrait(corpus) {
            if (
                JSON.stringify(this[CURRENT_CORPUS]) === JSON.stringify(corpus)
                && this[CORPUS_INFO].config
                && JSON.stringify(this[CORPUS_INFO].corpus) === JSON.stringify(corpus)
            ) {
                return Promise.resolve(this[CORPUS_INFO])
            } else {
                return this.portrait(corpus, true)
            }
        },
        getAttrValues(corpus, attrName) {
            return this.request(prefixUrl(`/api/attr-values/${attrName}?corpus=${encodeURIComponent(JSON.stringify(corpus))}`, true))
        }
    },
})
