<template>
  <div class="int-range" :class="{'int-range--inline': inline}">
    <div v-if="label" class="int-range__head">
      <label class="int-range__label" :class="labelClassObject">
        {{ translate(label) }}
      </label>
      <div class="int-range__actions">
        <div class="int-range__buttons">
          <round-button
            v-if="hasHalpText"
            :aria-label="$gettext('Подсказка')"
            color="dark" size="small"
            @click="showHelpText(realHelpText)">
            <icon name="c-question"/>
          </round-button>
          <slot name="additional-control"/>
        </div>
      </div>
    </div>

    <div class="int-range__body">
      <div class="int-range__fields">
        <the-input :label="$gettext('от:')" type="number" v-model="beginComputedValue" :disabled="disabled"
                   :max-length="maxLength" :size="inline? 'small': ''" :label-style="inline? 'inline': ''"
                   :min-number-size="minValue" :max-number-size="maxValue" @blur="handleChangeValues"
                   :parent-error="childProvideErrors" @validate="childValidStatus[0] = $event;"
                   :zero-not-allowed="zeroNotAllowed"/>
        <the-input :label="$gettext('до:')" type="number" v-model="endComputedValue" :disabled="disabled"
                   :max-length="maxLength" :size="inline? 'small': ''" :label-style="inline? 'inline': ''"
                   :min-number-size="minValue" :max-number-size="maxValue" @blur="handleChangeValues"
                   :parent-error="childProvideErrors" @validate="childValidStatus[1] = $event;"
                   :zero-not-allowed="zeroNotAllowed"/>
      </div>
      <single-checkbox v-if="showMatching" :label="$gettext('Точное вхождение')" v-model="matchingComputedValue"
                       :value="true" :disabled="disabled"/>
    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref, watch } from 'vue'
import TheInput from "./TheInput";
import { IntRangeConditionValue, IntRangeMathingType, IntRangeCondition } from "@ruscorpora/ruscorpora_api"
import SingleCheckbox from "./SingleCheckbox";
import { MAX_INT_32, MIN_INT_32 } from "../../../constatns";
import RoundButton from "../RoundButton.vue";
import Icon from "../Icon.vue";
import { useModalStore } from "../../../stores/modal";

export default {
  components: {Icon, RoundButton, SingleCheckbox, TheInput},
  name: 'IntRange',
  props: {
    modelValue: {
      type: [Object, null, undefined],
      default: () => {
        return IntRangeConditionValue.create({
          begin: Infinity,
          end: Infinity
        })
      }
    },
    label: String,
    labelStyle: String,
    inline: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: [Number, String],
      default: ''
    },
    showMatching: {
      type: Boolean,
      default: false
    },
    intRangeOptions: {
      type: [Object, IntRangeCondition]
    },
    required: {
      type: Boolean,
      default: false
    },
    clearWhenDisable: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: [String, Function],
      default: ''
    },
  },
  emits: ['update:modelValue', 'validate'],
  setup(props, {emit}) {
    const minValue = computed(() => props.intRangeOptions?.min ?? MIN_INT_32)
    const maxValue = computed(() => props.intRangeOptions?.max ?? MAX_INT_32)
    const zeroNotAllowed = computed(() => props.intRangeOptions?.default?.zeroNotAllowed ?? false)
    const $gettext = inject('gettext')
    const message = inject('message')
    const modalStore = useModalStore()

    const createPayload = (data) => {
      const defaultData = {
        begin: props.modelValue.begin,
        end: props.modelValue.end
      }

      if (props.showMatching) defaultData.matching = matchingComputedValue.value
      const payload = Object.assign({}, defaultData, data)

      if (props.showMatching) {
        payload.matching = payload.matching ? IntRangeMathingType.INT_RANGE_INCLUDE : IntRangeMathingType.INT_RANGE_INTERSECT
      }
      const newValue = IntRangeConditionValue.create(payload)
      silentValue.value = IntRangeConditionValue.fromObject(newValue.toJSON())
      return newValue
    }
    const childValidStatus = ref([
      true, true
    ])
    const validState = ref({
      child: computed(() => childValidStatus.value.filter(x => !x).length > 0),
      beginOverEnd: false,
      required: false,
      errorEmitted: false
    })
    const silentValue = ref(props.modelValue)
    const isDisabled = computed(() => props.disabled)

    const hasError = computed(() => {
      return validState.value.child || validState.value.beginOverEnd || validState.value.required
    })
    const childProvideErrors = computed(() => {
      return validState.value.beginOverEnd || validState.value.required
    })
    const beginComputedValue = computed({
      get() {
        return props.modelValue.begin === MIN_INT_32 ? "" : props.modelValue.begin
      },
      set(value) {
        const begin = value === "" ? MIN_INT_32 : parseInt(value)
        emit('update:modelValue', createPayload({begin: begin}))

        if (checkValidate(true, begin)) {
          validState.value.beginOverEnd = false
          validState.value.required = false
          emit('validate', !hasError.value)
        }
      }
    })

    const endComputedValue = computed({
      get() {
        return props.modelValue.end === MAX_INT_32 ? "" : props.modelValue.end
      },
      set(value) {
        const end = value === "" ? MAX_INT_32 : parseInt(value)
        emit('update:modelValue', createPayload({end: end}))

        if (checkValidate(true, null, end)) {
          validState.value.beginOverEnd = false
          validState.value.required = false
          emit('validate', !hasError.value)
        }
      }
    })

    const matchingComputedValue = computed({
      get() {
        return props.modelValue.matching === IntRangeMathingType.INT_RANGE_INCLUDE
      },
      set(value) {
        emit('update:modelValue', createPayload({matching: value}))
      }
    })

    const labelClassObject = computed(() => {
      return {
        [`int-range__label--${props.labelStyle}`]: props.labelStyle?.length
      }
    })

    const checkValidate = (noEmitError = false, begin_value = null, env_value = null) => {
      let isValid = true

      if (begin_value === null) begin_value = props.modelValue.begin
      if (env_value === null) env_value = props.modelValue.end
      if (begin_value > env_value) {
        isValid = false
      }
      if (!noEmitError) validState.value.beginOverEnd = !isValid

      if (props.required) {
        if (begin_value === MIN_INT_32 && env_value === MAX_INT_32) {
          isValid = false
          if (!noEmitError) validState.value.required = !isValid
        }
      }
      return isValid
    }

    const handleChangeValues = () => {
      const isValid = checkValidate()

      if (!isValid) {
        if (validState.value.beginOverEnd && !validState.value.errorEmitted) {
          const formatString = $gettext('Начало интервала должно быть меньше конца')
          message.error(formatString)
          validState.value.errorEmitted = true
        }
        if (validState.value.required && !validState.value.errorEmitted) {
          const formatString = $gettext('Необходимо указать минимум одно значение')
          message.error(formatString)
          validState.value.errorEmitted = true
        }
      } else {
        validState.value.errorEmitted = false
      }

      emit('validate', !hasError.value)
    }

    onMounted(() => {
      document.body.addEventListener('validate', () => handleChangeValues())
    })

    watch(isDisabled, (val) => {
      if (!props.clearWhenDisable) return

      if (val) {
        emit('update:modelValue', IntRangeConditionValue.create({
          begin: minValue.value,
          end: maxValue.value,
          matching: IntRangeMathingType.INT_RANGE_INTERSECT
        }))
      } else {
        emit('update:modelValue', silentValue.value)
      }
    })

    const hasHalpText = computed(() => {
      return props.helpText instanceof Function || props.helpText.length > 0
    })

    const realHelpText = computed(() => {
      if (props.helpText instanceof Function) {
        return props.helpText()
      }
      return props.helpText
    })

    return {
      beginComputedValue,
      endComputedValue,
      matchingComputedValue,
      labelClassObject,
      minValue,
      maxValue,
      childProvideErrors,
      handleChangeValues,
      childValidStatus,
      zeroNotAllowed,

      hasHalpText,
      showHelpText: modalStore.showHelpText,
      realHelpText,
    }
  },
}

</script>

<style scoped lang="scss">
.int-range {
  font-size : rem-calc(16);

  &__label {
    &--strong {
      font-weight : 700;
    }
  }

  &__head {
    display         : flex;
    justify-content : space-between;
    margin-bottom   : rem-calc(4);
    align-items     : center;
  }

  &__body {
    display     : flex;
    width       : 100%;
    gap         : rem-calc(12);
    align-items : center;
  }

  &__fields {
    display         : flex;
    justify-content : space-between;
    gap             : rem-calc(18);
    max-width       : 100%;

    .the-input {
      max-width : calc(50% - #{rem-calc(9)});

      &__input {
        width   : 100%;
        padding : 0;
      }
    }
  }

  &__buttons {
    display     : flex;
    gap         : rem-calc(8);
    align-items : flex-end;
  }

  &--inline {
    display     : flex;
    gap         : rem-calc(8 12);
    align-items : center;

    .int-range {
      &__label {
        white-space : nowrap;
      }

      &__head {
        margin-bottom : 0;
      }
    }

    @include breakpoint(medium down) {
      flex-direction : column;
      align-items    : flex-start;
    }
  }
}
</style>
