<template>
  <div class="overflow-dropdown-wrap">
    <nav :aria-label="$gettext('Выбор режима выдачи')" ref="nav" class="overflow-dropdown"
         :class="{'overflow-dropdown--alt': altStyle, 'overflow-dropdown--grey':greyStyle}" v-if="!isSmallResolution">
      <ul class="overflow-dropdown__list" ref="primary-nav">
        <li v-for="(item) in primaryNav" :key="item.value" class="overflow-dropdown__item"
            :class="{'is-active': item.value === selected}">
          <button role="button" type="button" @click="onSelectHandler(item)">{{ item.name }}</button>
          <round-button v-if="item.help_text" :aria-label="$gettext('Подсказка')" color="dark" size="medium"
                        @click="showAsyncHelpText(item.help_text)">
            <icon name="c-question" />
          </round-button>
        </li>
      </ul>
      <a-dropdown :hidden="!visibleShowButton" :trigger="['click']" placement="bottomRight">
        <div ref="more-button" v-show="secondaryNav.length > 0">
          <the-button mode="icon" class="overflow-dropdown__show-toggle" :aria-label="$gettext('Открыть список')">
            <icon name="c-arrow"></icon>
          </the-button>
        </div>

        <template #overlay>
          <div ref="dropdown">
            <div class="ant-dropdown-menu ant-dropdown-menu--compact" @click="e => { e.stopPropagation()}">
              <div class="ant-dropdown-menu__body">
                <ul class="overflow-dropdown__list overflow-dropdown__list--second" ref="secondary-nav">
                  <li v-for="(item) in secondaryNav" :key="item.value" class="overflow-dropdown__item"
                      :class="{'is-active': item.value === selected}">
                    <button role="button" type="button" @click="handleSelectFromDropDown(item)">
                      {{ item.name }}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </template>
      </a-dropdown>
    </nav>
    <nav v-else class="overflow-dropdown-wrp">
      <v-select :options="optionsList"
                :disabled="optionsList.length < 2"
                label="name"
                :searchable="false"
                :clearable="false"
                v-model="computeSelected"
                class="custom-v-select vs vs--no-color">
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <icon name="c-arrow" />
          </span>
        </template>
      </v-select>
      <round-button v-if="hasHelpText" :aria-label="$gettext('Подсказка')" color="dark" size="medium"
                    @click="()=>showAsyncHelpText()">
        <icon name="c-question" />
      </round-button>
    </nav>
  </div>
</template>

<script>
import Icon from "./Icon";
import { computed, inject } from "vue";
import { IS_SMALL_RESOLUTION, useRootStore } from "../../stores/root";
import { useModalStore } from "../../stores/modal";
import RoundButton from "./RoundButton.vue";
import TheTooltip from "./form/TheTooltip.vue";
import TheButton from "./TheButton.vue";

export default {
  name: "OverflowDropdown",
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected: {
      type: [String, Number]
    },
    altStyle: {
      type: Boolean,
      default: false
    },
    greyStyle: {
      type: Boolean,
      default: false
    }
  },
  components: {TheButton, TheTooltip, RoundButton, Icon},
  data() {
    return {
      breaks: [],
      visibleShowButton: false,
      primaryFlags: []
    }
  },
  setup(props, {emit}) {
    const rootStore = useRootStore()
    const modalStore = useModalStore()
    const isSmallResolution = computed(() => rootStore[IS_SMALL_RESOLUTION])
    const asyncGetText = inject("asyncGettext")
    const computeSelected = computed({
      get() {
        return props.options.find(x => x.value === props.selected)
      },
      set(newValue) {
        emit('change', newValue);
      }
    })

    const hasHelpText = computed(() => computeSelected.value && computeSelected.value.help_text)
    const showAsyncHelpText = (val) => {
      asyncGetText(val || computeSelected.value.help_text).then(r => {
        modalStore.showHelpText(r)
      })
    }

    return {
      isSmallResolution,
      computeSelected,
      showAsyncHelpText,
      hasHelpText
    }
  },
  methods: {
    computeLayout() {
      if (this.isSmallResolution) return
      const navWidth = this.getNavWidth()

      const availableSpace = this.$refs.nav.clientWidth - Math.max(this.$refs["more-button"].clientWidth + 10, 50);

      if (navWidth > availableSpace) {
        this.breaks.push(navWidth);
        const notActivePrimaryNav = this.primaryNav.filter(x => x.value !== this.selected)
        this.setPrimary(notActivePrimaryNav[notActivePrimaryNav.length - 1].value, false)
        this.visibleShowButton = this.primaryNav.length > 1;
      } else {
        if (availableSpace > this.breaks[this.breaks.length - 1]) {
          this.setPrimary(this.secondaryNav[0].value, true)
          this.breaks.pop();
        }

        if (this.breaks.length < 1) {
          this.visibleShowButton = false;
        }
      }

      this.$nextTick(() => {
        if (this.getNavWidth() > availableSpace) {
          if (this.isSmallResolution) return
          this.computeLayout();
        }
      })
    },
    setPrimary(id, flag) {
      const optionIndex = this.optionsList.findIndex(x => x.value === id)
      this.primaryFlags[optionIndex] = flag
    },
    getNavWidth() {
      if (!this.$refs["primary-nav"]) return 0
      let navWidth = 0;
      this.$refs["primary-nav"].querySelectorAll('.overflow-dropdown__item').forEach((el) => {
        navWidth += (el.clientWidth + 10)
      });

      return navWidth
    },
    onResizeHandler() {
      this.$nextTick(() => {
        this.computeLayout()
      })
    },
    onSelectHandler(value) {
      this.$emit('change', value);
    },
    updateView(skip = false) {
      this.options.forEach((item) => this.primaryFlags.push(true))
      if (!skip) {
        this.$nextTick(this.computeLayout);
      } else {
        this.computeLayout()
      }
    },
    closeDropDown() {
      this.$refs['dropdown'].dispatchEvent(new Event('click'))
    },
    handleSelectFromDropDown(data) {
      this.closeDropDown();
      this.onSelectHandler(data);
      this.primaryFlags = []
      this.updateView(true);
    }
  },
  computed: {
    maxWidth() {
      return this.$refs.nav.clientWidth
    },
    primaryNav() {
      return this.optionsList.filter((x, index) => this.primaryFlags[index])
    },
    secondaryNav() {
      return this.optionsList.filter((x, index) => !this.primaryFlags[index])
    },
    optionsList() {
      return this.options.filter(x => {
        if (!x.hasOwnProperty('hidden')) return true
        if (typeof x.hidden === "boolean") return !x.hidden
        else return !x.hidden()
      })
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResizeHandler, {passive: true})
    this.updateView()
  },
  watch: {
    options() {
      this.updateView()
    },
    selected() {
      this.updateView()
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.onResizeHandler)
  }
}
</script>

<style scoped lang="scss">
.overflow-dropdown {
  width   : 100%;
  display : flex;
  //gap     : rem-calc(20);
  $this   : &;

  &__list {
    display   : flex;
    flex-wrap : nowrap;
    gap       : 10px;

    &--second {
      flex-direction : column;

      .overflow-dropdown {
        &__item {
          opacity : 1;

          &:hover {
            text-decoration : underline;
          }
        }
      }
    }
  }

  &__item {
    font-size   : rem-calc(18);
    opacity     : .5;
    white-space : nowrap;
    display     : flex;
    align-items : center;
    gap         : rem-calc(10);

    &.is-active {
      opacity : 1;
    }
  }

  &__show-toggle {
    padding     : rem-calc(10);
    margin-left : 10px;

    .icon-wrapper {
      pointer-events : none;
      width          : rem-calc(16);
      height         : rem-calc(8);
    }
  }

  &--alt {
    #{$this}__item {
      opacity : 1;
      padding : rem-calc(4 8);

      &.is-active {
        background    : var(--beige);
        border-radius : rem-calc(5);
      }
    }
  }

  &--grey {
    #{$this}__item {
      opacity : 1;
      padding : rem-calc(7 10);

      &:focus {
        padding          : rem-calc(7 10);
        background-color : #EAF1EF;
        border-radius    : rem-calc(5);
      }

      &.is-active {
        padding          : rem-calc(7 10);
        background-color : #EAF1EF;
        border-radius    : rem-calc(5);
      }

      &:active {
        padding          : rem-calc(7 10);
        background-color : #EAF1EF;
        border-radius    : rem-calc(5);
      }
    }
  }
}

.overflow-dropdown-wrap {
  flex-grow : 1;
}
</style>

<style lang="scss">
.overflow-dropdown {
  &__show-toggle {
    svg {
      color          : var(--black) !important;
      pointer-events : none;
    }
  }
}
</style>
