import findIndex from "lodash/findIndex";
import camelCase from "lodash/camelCase";
import { interpolate as $interpolate } from "../plugins/localization";
import { Corpus, CorpusType, IntRangeMathingType, WordType, FontAlias } from "@ruscorpora/ruscorpora_api"
import {
    format,
    isEqual,
    endOfYear,
    endOfMonth,
    startOfMonth,
    endOfDay,
    startOfDay,
    startOfYear,
    isSameYear,
    isSameMonth,
    isSameDay,
} from "date-fns"
import { HOMONYMY_STATUSES, TRANSLATES } from "../constatns";
import { protoDateAsNative } from "../components/results/chartUtils";

const Buffer = require('buffer/').Buffer

export const findAndReplace = (arr, find, replace) => {
    const _arr = [...arr]
    const i = findIndex(_arr, find);
    i >= 0 ? _arr.splice(i, 1, replace) : _arr.push(replace);
    return _arr
}

const dateRangeToHuman = (dateRangeConditionValue) => {
    const start = dateRangeConditionValue.begin ? startOfDay(protoDateAsNative(dateRangeConditionValue.begin)) : null
    const end = dateRangeConditionValue.end ? endOfDay(protoDateAsNative(dateRangeConditionValue.end)) : null
    const completeFormattedDates = `${start ? format(start, 'dd.MM.yyyy') : ''}-${end ? format(end, 'dd.MM.yyyy') : ''}`

    if ([start, end].every(x => x !== null)) {
        if (isSameDay(start, end)) return format(start, 'dd.MM.yyyy')

        if (isSameMonth(start, end) && isEqual(start, startOfMonth(start)) && isEqual(end, endOfMonth(end))) {
            /* один полный месяц */
            return format(start, 'MM.yyyy')
        }
        if (isSameYear(start, end) && isEqual(start, startOfYear(start)) && isEqual(end, endOfYear(end))) {
            /* один полный год */
            return format(start, 'yyyy')
        }
        if (isEqual(start, startOfYear(start)) && isEqual(end, endOfYear(end))) {
            /* диапазон полных лет */
            return `${format(start, 'yyyy')}-${format(end, 'yyyy')}`
        }
        if (isEqual(start, startOfMonth(start)) && isEqual(end, endOfMonth(end))) {
            /* диапазон полных месяцев */
            return `${format(start, 'MM.yyyy')}-${format(end, 'MM.yyyy')}`
        }
    } else if (start !== null && end === null) {
        if (isEqual(start, startOfYear(start))) {
            return `${format(start, 'yyyy')}-`
        }
        if (isEqual(start, startOfMonth(start))) {
            return `${format(start, 'MM.yyyy')}-`
        }
    } else if (start == null && end !== null) {
        if (isEqual(end, endOfYear(end))) {
            return `-${format(end, 'yyyy')}`
        }
        if (isEqual(end, endOfMonth(end))) {
            return `-${format(end, 'MM.yyyy')}`
        }
    }

    return completeFormattedDates
}

export const isFireFox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const prefixUrl = (path, prefixDefaultLanguage = false) => {
    const url = path.startsWith('/') ? path.slice(1) : path
    const locale = window?.App?.LOCALE ? window.App.LOCALE : 'ru'

    return locale === 'ru' && !prefixDefaultLanguage ? `/${url}` : `/${locale}/${url}`
}

const valStringToPlain = (token) => {
    return token.valValue.valString.v
}

const valBoolToPlain = (_token, explanation) => {
    return explanation.humanReadable
}

const valDateToPlain = (token) => {
    const date = new Date(token.year, token.month - 1, token.day)
    return format(date, "dd.MM.yyyy")
}

const valIntRangeToPlain = (token) => {
    const begin = token.valValue.valIntRange.begin
    const end = token.valValue.valIntRange.end
    const matching = token.valValue.valIntRange.matching

    if (begin === end) return begin
    return `${begin ? begin : ''}-${end ? end : ''}` + (matching === IntRangeMathingType.INT_RANGE_INCLUDE ? `, ${TRANSLATES.INT_RANGE_INCLUDE}` : '')
}
const valDateRangeToPlain = (token) => {
    const matching = token.valValue.valDateRange.matching
    return dateRangeToHuman(token.valValue.valDateRange) + (matching === IntRangeMathingType.INT_RANGE_INCLUDE ? `, ${TRANSLATES.INT_RANGE_INCLUDE}` : '')
}

export const explanationToPlain = (token, explanation, ignoreFonts = false) => {
    const conditionType = token.valValue.c
    const buildOutput = (val) => {
        if (!token.valValue.valString.fontAlias || ignoreFonts) return val
        return `<span class="${getFontFamilyClass(token.valValue.valString.fontAlias)}">${val}</span>`
    }
    
    if (conditionType === "valString") {
        return buildOutput(valStringToPlain(token))
    } else if (conditionType === "valBool") {
        return valBoolToPlain(token, explanation)
    } else if (conditionType === "valIntRange") {
        return valIntRangeToPlain(token)
    } else if (conditionType === "valDate") {
        return valDateToPlain(token.valValue.valDate.v)
    } else if (conditionType === "valDateRange") {
        return valDateRangeToPlain(token)
    }

    return "UNSUPPORTED"
}

export const buildExplanationToView = (conditionExplanation, ignoreFonts = false) => {
    if (!conditionExplanation.tokens.length) return ""

    return conditionExplanation.tokens.map(token => {
        return explanationToPlain(token, conditionExplanation, ignoreFonts)
    }).join('')
}

function removeTokensFromEnd(inputString, tokens = ['<br>', '<br/>', '<br />', ' ', '\n', '\t']) {
    let endIndex = inputString.length; // Инициализируем индекс конца строки

    // Пока есть что проверять
    while (endIndex > 0) {
        let foundToken = false; // Флаг для отслеживания найденного токена

        // Перебираем все токены
        for (const token of tokens) {
            if (inputString.endsWith(token, endIndex)) { // Если текущий токен найден
                endIndex -= token.length; // Сдвигаем индекс конца строки
                foundToken = true; // Устанавливаем флаг, что токен найден
                break; // Выходим из цикла, так как нашли токен
            }
        }

        // Если токен не найден, завершаем цикл
        if (!foundToken) {
            break;
        }
    }

    // Возвращаем строку без найденных токенов
    return inputString.slice(0, endIndex);
}

export const trimLastWord = (words, end = false, trimFirstFromRightContext = true) => {
    const filterWords = words
        .filter((x, index, arr) => {
            if (arr.length - 1 === index && x.type === WordType.PLAIN && (x.text === "" || x.text === " " || x.text === "\n")) return false
            if (index === 0 && x.text === ' ' && trimFirstFromRightContext) return false
            return x.text !== "" || (arr.length - 1 === index && x.text !== "")
        })

    if (filterWords[filterWords.length - 1] && filterWords[filterWords.length - 1].text.length > 1) {
        filterWords[filterWords.length - 1].text = filterWords[filterWords.length - 1].text.trimEnd()

        if (end) {
            const cleanedLastWord = removeTokensFromEnd(filterWords[filterWords.length - 1].text)
            if (cleanedLastWord === '') {
                filterWords.pop()
            } else {
                filterWords[filterWords.length - 1].text = cleanedLastWord
            }
        }
    }

    return filterWords
}

export const getRandomIntInclusive = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const newSeed = () => getRandomIntInclusive(1, Number.MAX_SAFE_INTEGER)

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}


export const getHomonymyStatuses = (code) => {
    return code.split('|').map(x => {
        return HOMONYMY_STATUSES[x]
    })
}

export const toCamelCase = (str) => {
    return str.split('.').map(token => camelCase(token)).join('.')
}

export const buildCorpusSlug = (corpus) => {
    let corpusIdParams = getKeyByValue(CorpusType, corpus.type).toLowerCase()
    if (corpus.lang) corpusIdParams = `${corpusIdParams}-${corpus.lang}`

    return corpusIdParams
}

export const openInNewTab = (href) => {
    Object.assign(document.createElement('a'), {
        target: '_blank',
        rel: 'noopener noreferrer',
        href: href,
    }).click();
}

export const saveBase64ToDisk = (base64Image, filename) => {
    fetch(base64Image)
        .then(async r => {
            return await r.blob()
        }).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
}

export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
}
export const decodeBase64 = (data) => {
    return new Buffer(data, 'base64').toString()
}

export const corpusSlugToObj = (slug) => {
    const [type, lang] = slug.split('-')
    return Corpus.create({ type: CorpusType[type.toUpperCase()], lang: lang ?? null })
}

export const corpusToString = (corpus, delimiter = '-', useName = false) => {
    const corpusName = useName ? getKeyByValue(CorpusType, corpus.type) : corpus.type.toString()
    if (corpus.lang) return `${corpusName}${delimiter}${corpus.lang}`
    else return corpusName
}

export const calculateClosestPage = (start, end, pageSize) => {
    const midpoint = Math.floor((start + end) / 2);
    return Math.floor(midpoint / pageSize) + 1;
}

/* Рассчитывает нужное окно строк для раскрытия полного текст. В первую очередь для поэтического */
export const adjustToWindow = (start, end, pageSize = 100) => {
    const pageNumber = Math.floor(start / pageSize);
    const windowStart = pageNumber * pageSize;
    const windowEnd = (pageNumber + 1) * pageSize;

    if (start >= windowStart && start <= windowEnd) {
        start = windowStart;
    }

    if (end >= windowStart && end <= windowEnd) {
        end = windowEnd;
    }

    return { start, end };
}

/* Предобработка эксплейнов для отображения */
export const prepareExplainToView = (explanation, isCollocation = false, ignoreFonts = false) => {
    const computedExplanation = {
        'short': [],
        'detail': [],
        'lang': null,
        'rawDict': {}
    }

    const keysOfFieldShouldAtEnd = ['dist']
    const keysOfFieldShouldAtStart = ['lex', 'form']

    explanation.conditionExplanations
        .sort((a, b) => {
            if (keysOfFieldShouldAtEnd.includes(b.fieldName)) return -1

            if (keysOfFieldShouldAtStart.includes(a.fieldName)) return -1

            return 0
        })
        .forEach(conditionExplanation => {
            if (conditionExplanation.fieldName === 'dist') {
                // Обработка расстояний до слова
                const dist = JSON.parse(conditionExplanation.tokens[0].valValue.valString.v)

                const distStringTemplates = isCollocation ? TRANSLATES.collocate_dist : TRANSLATES.dist
                let formatString = ""

                switch (true) {
                    case (!dist.hasOwnProperty('min') && dist.hasOwnProperty('max')):
                        formatString = distStringTemplates.onlyEnd;
                        break

                    case (dist.hasOwnProperty('min') && !dist.hasOwnProperty('max')):
                        formatString = distStringTemplates.onlyBegin;
                        break

                    case (dist.hasOwnProperty('min') && dist.hasOwnProperty('max')):
                        formatString = distStringTemplates.allValues;
                        break

                    case (dist.min === dist.max):
                        formatString = distStringTemplates.beginIsEqualEnd;
                        break
                }

                const interpolatedValue = `${$interpolate(formatString, dist, true)}`

                if (formatString) {
                    computedExplanation.detail.push(interpolatedValue)
                    // This regex strip all html tags
                    computedExplanation.short.push(interpolatedValue.replace(/(<([^>]+)>)/gi, ""))
                    computedExplanation.rawDict[conditionExplanation.fieldName] = {
                        humanReadable: conditionExplanation.humanReadable,
                        val: interpolatedValue
                    }
                }
            } else if (conditionExplanation.fieldName === 'human_group_lang') {
                computedExplanation.lang = {
                    val: conditionExplanation.tokens[0].valValue.valString.v,
                    tooltip: conditionExplanation.tokens[1].valValue.valString.v,
                }
            } else {
                const isLexForm = keysOfFieldShouldAtStart.includes(conditionExplanation.fieldName)

                let plainToken = buildExplanationToView(conditionExplanation, ignoreFonts)
                computedExplanation.short.push(plainToken)
                computedExplanation.rawDict[conditionExplanation.fieldName] = {
                    humanReadable: conditionExplanation.humanReadable,
                    val: plainToken
                }

                if (isLexForm) plainToken = `<b>${plainToken}</b>`
                computedExplanation.detail.push(plainToken)
            }
        })

    return {
        'short': computedExplanation.short.join(', '),
        'detail': computedExplanation.detail.join(', '),
        'lang': computedExplanation.lang,
        'rawDict': computedExplanation.rawDict
    }
}


export const completeExplainToView = (searchResult) => {
    const words = searchResult.queryExplain ? searchResult.queryExplain.sectionExplanations.map(sectionExplanation => {
        return sectionExplanation.subsectionExplanations.map(explanation => {
            /* каждое слово запроса */
            return prepareExplainToView(explanation)
        })
    }) : []

    const subcorpus = searchResult.subcorpusExplain ? searchResult.subcorpusExplain.sectionExplanations.map(sectionExplanation => {
        return sectionExplanation.subsectionExplanations.map(explanation => {
            return prepareExplainToView(explanation)
        })
    }) : []

    const beforeWords = searchResult.queryExplain?.conditionExplanations?.length ? [[prepareExplainToView({ conditionExplanations: searchResult.queryExplain.conditionExplanations })]] : []

    return {
        subcorpus,
        beforeWords,
        words,
    }
}

export function getObjectDiff(obj1, obj2) {
    const diff = []

    try {
        Object.keys(obj1).forEach(key => {
            if (Array.isArray(obj1[key])) return

            if (typeof obj1[key] === "object") {
                return diff.push(...getObjectDiff(obj1[key], obj2[key]))
            }
            if (obj1[key] !== obj2[key]) {
                diff.push(key)
            }
        })
    } catch (e) {
    }


    return diff
}

export const imageUtil = {
    base64SvgToBase64Png: (originalBase64, width, secondTry) => {
        return new Promise(resolve => {
            let img = document.createElement('img');
            img.onload = function () {
                if (!secondTry && (img.naturalWidth === 0 || img.naturalHeight === 0)) {
                    let svgDoc = imageUtil.base64ToSvgDocument(originalBase64);
                    let fixedDoc = imageUtil.fixSvgDocumentFF(svgDoc);
                    return imageUtil.base64SvgToBase64Png(imageUtil.svgDocumentToBase64(fixedDoc), width, true).then(result => {
                        resolve(result);
                    });
                }
                document.body.appendChild(img);
                let canvas = document.createElement("canvas");
                let ratio = (img.clientWidth / img.clientHeight) || 1;
                document.body.removeChild(img);
                canvas.width = width;
                canvas.height = width / ratio;
                let ctx = canvas.getContext("2d");
                ctx.fillStyle = "white";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                try {
                    let data = canvas.toDataURL('image/png');
                    resolve(data);
                } catch (e) {
                    resolve(null);
                }
            };
            img.src = originalBase64;
        });
    },
    fixSvgDocumentFF: (svgDocument) => {
        try {
            let widthInt = parseInt(svgDocument.documentElement.width.baseVal.value) || 500;
            let heightInt = parseInt(svgDocument.documentElement.height.baseVal.value) || 500;
            svgDocument.documentElement.width.baseVal.newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX, widthInt);
            svgDocument.documentElement.height.baseVal.newValueSpecifiedUnits(SVGLength.SVG_LENGTHTYPE_PX, heightInt);
            return svgDocument;
        } catch (e) {
            return svgDocument;
        }
    },
    svgDocumentToBase64: (svgDocument) => {
        try {
            let base64EncodedSVG = btoa(new XMLSerializer().serializeToString(svgDocument));
            return 'data:image/svg+xml;base64,' + base64EncodedSVG;
        } catch (e) {
            return null;
        }
    },
    base64ToSvgDocument: (base64) => {
        let svg = atob(base64.substring(base64.indexOf('base64,') + 7));
        svg = svg.substring(svg.indexOf('<svg'));
        let parser = new DOMParser();
        return parser.parseFromString(svg, "image/svg+xml");
    }
}

export function setLocalStorageWithExpiry(key, value, ttl = (3600 * 24 * 1000)) {
    const now = new Date()

    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export function getLocalStorageWithExpiry(key) {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

export const abortVoidHandler = (error) => {
    if (error.code === 20 || error.status === 20 || error.payload === "abort") return void (0);
    return Promise.reject(error)
}


export const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const getFontFamilyClass = (fontAlias, outputDict = false) => {
    let fontFamily = "";

    const buildOutput = (val) => {
        if (outputDict) {
            const res = {}
            if (val) res[val] = true
            return res
        } else {
            return Boolean(val) ? val : null
        }
    }

    if (!fontAlias) return buildOutput()

    switch (fontAlias) {
        case FontAlias.OLD_RUS_ORTHLIB_FONT:
            fontFamily = "old-rus-font"
            break
        case FontAlias.MID_RUS_ORTHLIB_CIV_FONT:
            fontFamily = "mid-rus-font"
            break
        case FontAlias.BIRCHBARK_FONT:
            fontFamily = "birchbark-font"
            break
    }

    return buildOutput(fontFamily)
}