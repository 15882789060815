import { intword2, numberFilter } from "../../filters/number";
import {
    differenceInYears,
    differenceInDays,
    differenceInMonths,
    startOfMonth,
    startOfYear,
    startOfDay,
    endOfDay,
    endOfMonth,
    endOfYear,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachYearOfInterval,

    sub,
    add,
    min,
    max,
    isWithinInterval,
    format,
    isAfter,
} from 'date-fns';

import {Date as DatePB, DateRange, GraphicFilterParams, TemporalUnit} from "@ruscorpora/ruscorpora_api";

export const differenceCase = {
    [TemporalUnit.YEAR]: {
        diff: differenceInYears,
        start: startOfYear,
        end: endOfYear,
        each: eachYearOfInterval,
        labelFormatter: 'yyyy',
        dataFormat: 'yyyy',
        name: 'years',
        initialDataZoomRange: 5000,
        minValueSpan: 3600 * 24 * 1000 * 365 * 2,
        minSpan: 5,
    },
    [TemporalUnit.MONTH]: {
        diff: differenceInMonths,
        start: startOfMonth,
        end: endOfMonth,
        each: eachMonthOfInterval,
        labelFormatter: 'MM.yyyy',
        dataFormat: 'mm.yyyy',
        name: 'months',
        initialDataZoomRange: 1200,
        minValueSpan: 3600 * 24 * 1000 * 54,
        minSpan: 5,
    },
    [TemporalUnit.DAY]: {
        diff: differenceInDays,
        start: startOfDay,
        end: endOfDay,
        each: eachDayOfInterval,
        labelFormatter: 'dd.MM.yyyy',
        dataFormat: 'dd.mm.yyyy',
        name: 'days',
        initialDataZoomRange: 1825,
        minValueSpan: 3600 * 24 * 1000 * 2,
        minSpan: 2,
    }
}

/**
 * @param datePB {DatePB}
 * @returns {Date}
 */
export const protoDateAsNative = (datePB) => {
    //return new Date(datePB.year, Math.max(datePB.month - 1, 0), datePB.day || 1, 0, 0, 0)
    const year = datePB.year.toString().padStart(4, '0')
    const month = Math.max(datePB.month, 1).toString().padStart(2, '0')
    const date = Math.max(datePB.day || 1).toString().padStart(2, '0')

    return new Date(Date.parse(`${year}-${month}-${date}`))
}
/**
 * @param date {Date}
 * @returns {DatePB}
 */
export const nativeDateAsProto = (date) => {
    return DatePB.create({year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()})
}

/**
 * @param str
 * @param {TemporalUnit} unit
 * @returns {Date}
 */
export const getDateFromString = (str = '', unit = TemporalUnit.YEAR) => {
    let year
    let month = 1
    let day = 1

    switch (unit) {
        case TemporalUnit.DAY:
            [day, month, year] = str.split('.').map(x => Number(x))
            break

        case TemporalUnit.MONTH:
            [month, year] = str.split('.').map(x => Number(x))
            break

        case TemporalUnit.YEAR:
        default:
            year = Number(str)
            break
    }

    return new Date(year, month - 1, day, 0, 0, 0)
}

export const interpolateDatasInArray = async (arr, unit = TemporalUnit.YEAR) => {
    const boundaries = {
        lower: null,
        upper: null,
        lowerWithCount: null,
        upperWithCount: null,
    }

    const docCountRange = {
        min: Infinity,
        max: -Infinity
    }

    const interpolatedArray = await Promise.all(arr.map(async ([values, index]) => {
        const dataset = new Map();

        for (const statValue of values) {
            const rangeDate = statValue.key.valString.v.split('-');
            const left = differenceCase[unit].start(getDateFromString(rangeDate[0], unit));
            const right = differenceCase[unit].end(getDateFromString(rangeDate[1] || rangeDate[0], unit));
            const diffInUnit = Math.max(differenceCase[unit].diff(right, left), 1);

            for (const dateUnit of differenceCase[unit].each({start: left, end: right})) {
                const hashOfDate = dateUnit.getTime();
                const record = dataset.get(hashOfDate) || {
                    averageTotalCount: 0,
                    averageDocCount: 0,
                    averageCount: 0,
                    statValue: statValue,
                };

                record.averageTotalCount += (statValue.totalCount ?? 0) / diffInUnit;
                record.averageCount += statValue.count / diffInUnit;
                record.averageDocCount += statValue.docCount / diffInUnit;

                dataset.set(hashOfDate, record);

                boundaries.lower = boundaries.lower === null ? dateUnit : min([differenceCase[unit].start(boundaries.lower), dateUnit]);
                boundaries.upper = boundaries.upper === null ? differenceCase[unit].end(dateUnit) : max([differenceCase[unit].end(boundaries.upper), differenceCase[unit].end(dateUnit)]);

                if (record.averageCount) {
                    boundaries.lowerWithCount = boundaries.lowerWithCount === null ? dateUnit : min([differenceCase[unit].start(boundaries.lowerWithCount), dateUnit]);
                    boundaries.upperWithCount = boundaries.upperWithCount === null ? differenceCase[unit].end(dateUnit) : max([differenceCase[unit].end(boundaries.upperWithCount), differenceCase[unit].end(dateUnit)]);
                }

                if (record.averageDocCount) {
                    docCountRange.min = Math.min(docCountRange.min, record.averageDocCount);
                    docCountRange.max = Math.max(docCountRange.max, record.averageDocCount);
                }
            }
        }

        const res = new Map([...dataset].map(([key, value]) => [key, {
            ...value,
            averageByMil: value.averageCount / value.averageTotalCount * 1000000
        }]).sort((a, b) => a[0] - b[0]));

        Object.defineProperty(res, 'datasetIndex', {
            get: function () {
                return index
            }
        });

        return res
    }));


    const dataIsUneven = await Promise.all([...interpolatedArray.values()].map(async dataset => {
        const entries = [...dataset.entries()].filter(entry => isWithinInterval(new Date(entry[0]), {
            start: boundaries.lowerWithCount,
            end: boundaries.upperWithCount
        }));
        if (!entries.length) return false
        const distInUnit = differenceCase[unit].diff(new Date(entries[entries.length - 1][0]), new Date(entries[0][0]));
        return (entries.length !== distInUnit + 1) || entries.some(entry => !entry[1].averageDocCount);
    }));

    return {
        boundaries,
        docCountRange,
        dataIsUneven: dataIsUneven.some(x => x),
        interpolated: interpolatedArray
    }
}

export const findSegmentIndex = (range, divisions, number) => {
    const [min, max] = range;
    const segmentSize = (max - min) / divisions;

    for (let i = 0; i < divisions; i++) {
        const segmentStart = min + i * segmentSize;
        const segmentEnd = segmentStart + segmentSize;

        if (number >= segmentStart && number <= segmentEnd) {
            return i;
        }
    }
    // Включая как нижнюю, так и верхнюю границы
    if (number === min) {
        return 0;
    }

    if (number === max) {
        return divisions - 1;
    }

    // Если число не входит ни в один из отрезков, вернем -1 или другое значение по вашему усмотрению.
    return -1;
}

export const generateTints = (baseColor, numTints, shiftAmount) => {
    const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
    const match = baseColor.match(rgbRegex);

    if (match) {
        const red = parseInt(match[1]);
        const green = parseInt(match[2]);
        const blue = parseInt(match[3]);

        const tints = [];
        for (let i = 0; i < numTints; i++) {
            // Рассчитываем новые значения каналов с учетом смещения
            const newRed = Math.max(red - i * shiftAmount, 0);
            const newGreen = Math.max(green - i * shiftAmount, 0);
            const newBlue = Math.max(blue - i * shiftAmount, 0);

            // Преобразуем новые значения обратно в формат RGB
            const tintColor = `rgb(${newRed}, ${newGreen}, ${newBlue})`;

            tints.push(tintColor);
        }
        return tints;
    } else {
        return [];
    }
}

function darkenColor(rgbColor) {
    const rgbValues = rgbColor.match(/\d+/g); // Извлекаем значения красного, зеленого и синего
    const darkenedValues = rgbValues.map(value => Math.max(0, value - Math.round(value * 0.3))); // Затемняем цвет

    return `rgb(${darkenedValues.join(', ')})`; // Возвращаем затемненный цвет в формате RGB
}

function makeDarkerColors(colors) {
    const darkenedColors = [];

    for (const color of colors) {
        darkenedColors.push(color); // Добавляем исходный цвет
        darkenedColors.push(darkenColor(color)); // Добавляем блеклый вариант цвета
    }

    return darkenedColors;
}

export const computeLegendOptions = () => {
    if (window.innerWidth >= 768) {
        return {
            right: 0,
            left: null
        }
    } else {
        return {
            left: 60,
            right: null
        }
    }
}

/**
 * Вычисляем сглаживание для интервала
 * @param dataset {Map}
 * @param left {Date}
 * @param right {Date}
 * @param unit {TemporalUnit}
 * @param smoothing {Number}
 * @param averageUnit {String}
 * @return Map
 */
export const computeSmoothingData = async (dataset, left, right, unit, smoothing, averageUnit = "averageByMil") => {
    const result = []
    // if (smoothing === 0) return dataset

    try {
        await Promise.all(differenceCase[unit].each({start: left, end: right}).map(async date => {
        /* Обработка сглаживания */
        let average = 0.0;
        const start = max([sub(date, {[differenceCase[unit].name]: smoothing}), left]);
        const finish = min([add(date, {[differenceCase[unit].name]: smoothing}), right]);

        // Обработка каждого элемента асинхронно
        await Promise.all(differenceCase[unit].each({start: start, end: finish}).map(async smoothingDate => {
            const timestamp = smoothingDate.getTime();

            if (dataset.has(timestamp)) {
                average += dataset.get(timestamp)[averageUnit];
            }
        }));

        average /= (differenceCase[unit].diff(finish, start) + 1);
        average = Math.round(average * 100000.0) / 100000.0;
        result.push([date.getTime(), average]);
    }));
    } catch (e) {
        debugger
    }

    return new Map(result)
}

/**
 * @param {Array} data
 * @param state
 * @param instance
 * @param searchConfig
 * @param docCountRange
 * @param i18n
 * @param {TemporalUnit} unit
 * @param {boolean} datasetIsOverInitialZoom
 * @param {string} averageByMil
 */
export const dataToOptions = async (data, state, instance, searchConfig, docCountRange, i18n, unit = TemporalUnit.YEAR, datasetIsOverInitialZoom = false, averageByMil="averageByMil") => {
    const smoothing = parseInt(state.smoothing);
    const heatmapChartHeight = 20;
    const left = differenceCase[unit].start(protoDateAsNative(state.boundaries.start ?? state.boundaries.begin));
    const right = differenceCase[unit].end(protoDateAsNative(state.boundaries.end));

    const colors = [
        "rgb(25, 118, 210)",
        "rgb(211, 47, 47)",
        "rgb(46, 125, 50)",
        "rgb(245, 124, 0)",
        "rgb(0, 208, 124)",
    ]

    const grid = [
        {
            id: 'main',
            left: 45,
            right: 15,
            top: 15,
            bottom: 50,
            containLabel: true
        }
    ]

    const getYAxis = () => {
        const axisList = [
            {
                axisLabel: {
                    formatter: value => {
                        return value < 100000 ? `${numberFilter(value, value < 10 ? 1 : 0)}`.padStart(9) : `${intword2(value)}`.padStart(9)
                    },
                    fontFamily: 'monospace',
                    fontSize: 14,
                },
                min: 'dataMin',
                max: 'dataMax',
                axisPointer: {
                    label: {
                        formatter: data => numberFilter(data.value, 1)
                    }
                },
                minInterval: 1,
                rich: {
                    label: {
                        width: 80
                    }
                },
                type: 'value',
                gridIndex: 0
            }
        ]
        if (searchConfig.allowFairChart) {
            for (let i = 0; i < data.length; i++) {
                axisList.push({
                    gridIndex: i + 1,
                    type: 'value',
                    axisLabel: {show: false},
                    axisLine: {show: false},
                    axisTick: {show: false},
                    splitLine: {show: false},
                    axisPointer: {show: false},
                    show: data.length > 1,
                    name: data[i]?.heatmapLabel ?? `${i18n?.chart?.volume_of_marked_up_in_compare} ${i + 1}*`,
                    nameGap: 0,
                    nameLocation: 'start',
                    nameTextStyle: {
                        align: 'right',
                        verticalAlign: 'bottom',
                        padding: [0, 5, 9, 0],
                        fontSize: 11,
                        fontFamily: 'Noto Sans, sans-serif',
                        color: '#1C1C1C',
                        lineHeight: 1,
                    }
                })
            }
        }

        return axisList
    }
    const getXAxis = () => {
        const axisList = [
            {
                minInterval: (3600 * 24 * 1000),
                //splitNumber: 15,
                //maxInterval: 50,
                min: 'dataMin',
                max: 'dataMax',
                axisLabel: {
                    showMinLabel: true,
                    showMaxLabel: true,
                    alignMaxLabel: 'right',
                    alignMinLabel: 'left',
                    fontFamily: 'monospace',
                    fontSize: 14,
                    hideOverlap: true,
                    formatter: '{dd}.{MM}.{yyyy}',
                },
                type: 'time',
                axisPointer: {
                    label: {
                        formatter: (data) => {
                            if (data.value !== undefined) {
                                return format(new Date(data.value), differenceCase[unit].labelFormatter)
                            } else {
                                return ''
                            }
                        }
                    }
                },
                gridIndex: 0
            }]

        if (unit === TemporalUnit.YEAR) {
            axisList[0].minInterval = axisList[0].minInterval * 365
            axisList[0].axisLabel.formatter = (value) => {
                const date = new Date(value)
                if (date.getMonth() === 0) return date.getFullYear().toString()
                return ''
            }
        }
        if (unit === TemporalUnit.MONTH) {
            axisList[0].minInterval = axisList[0].minInterval * 30
            axisList[0].axisLabel.formatter = (value) => {
                const date = new Date(value)
                if (date.getDate() === 1) return `${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`
            }
        }

        if (searchConfig.allowFairChart) {
            for (let i = 0; i < data.length; i++) {
                let currentTimeline = [...differenceCase[unit].each({start: left, end: right})].map(x => x.getTime())
                if (currentTimeline.length === 1) {
                    currentTimeline = [
                        sub(currentTimeline[0], {[differenceCase[unit].name]: 1}),
                        ...currentTimeline,
                        add(currentTimeline[0], {[differenceCase[unit].name]: 1}),
                    ]
                }

                axisList.push({
                    gridIndex: i + 1,
                    type: 'category',
                    min: 'dataMin',
                    max: 'dataMax',
                    axisLabel: {show: false},
                    axisLine: {show: false},
                    axisTick: {show: false},
                    splitLine: {show: false},
                    axisPointer: {show: false},
                    name: i18n?.chart?.volume_of_marked_up,
                    nameGap: 0,
                    nameLocation: 'start',
                    nameTextStyle: {
                        align: 'left',
                        verticalAlign: 'top',
                        padding: [7, 0, 0],
                        fontSize: 11,
                        fontFamily: 'Noto Sans, sans-serif',
                        color: '#1C1C1C',
                        lineHeight: 1,
                    },
                    show: data.length === 1,
                    silent: true,
                    data: currentTimeline
                })
            }
        }
        return axisList
    }

    if (searchConfig.allowFairChart) {
        grid[0].bottom = (data.length * (heatmapChartHeight + 10)) + 60

        for (let i = 0; i < data.length; i++) {
            grid.push({
                id: `heatmap-${i}`,
                left: 120,
                right: 15,
                bottom: grid[0].bottom - i * (heatmapChartHeight) - (i * 5) - 25,
                height: heatmapChartHeight,
                top: 'auto',
                containLabel: true,
                borderWidth: 0,
                show: false,
            })
        }
    }

    const options = {
        legend: Object.assign({
            top: 0,
            orient: 'vertical',
            padding: [5, 10],
            backgroundColor: '#ffffff',
            itemGap: 0,
            textStyle: {
                lineHeight: 20,
                fontFamily: 'Noto Sans, sans-serif',
            },
            selectedMode: data.length > 1,
            tooltip: {
                show: true,
                formatter: (params) => {
                    const target = instance.getOption().series.find(x => x.name === params.name)
                    if (target.explains) {
                        return `<b>${target.name}</b><br>` + Object.values(target.explains).filter(x => x.length).map(section => section.map(items => items.map(x => x.short).join('<br/>'))).join('<hr/>')
                    } else {
                        return null
                    }
                },
                trigger: 'item',
            },
        }, computeLegendOptions()),
        grid: grid,
        tooltip: {
            formatter: (params) => {
                return params.map(param => `${param.seriesName}<br><strong>${format(new Date(param.value[0]), differenceCase[unit].labelFormatter)}</strong>: ${numberFilter(param.value[1], 2)}`).join("<hr>")
            },
            trigger: 'axis',
            textStyle: {
                fontSize: 13,
                fontFamily: 'Noto Sans, sans-serif',
                lineHeight: 16,
            },
            extraCssText: 'max-width: 300px; min-width: 170px; white-space: unset;',
            appendToBody: true,
            axisPointer: {
                type: 'cross',
                label: {
                    precision: 0
                },
            },
            position: (pos, params, el, elRect, size) => {
                const obj = {top: 10};
                obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 40;
                return obj;
            },
        },
        yAxis: getYAxis(),
        xAxis: getXAxis(),
        series: [],
        color: smoothing > 0 ? makeDarkerColors(colors) : colors,
        dataZoom: [
            {
                id: 'dataZoomX',
                type: 'slider',
                //filterMode: 'none',
                backgroundColor: 'rgba(217, 217, 217, .2)',
                borderRadius: 0,
                height: 20,
                left: 120,
                right: 15,
                //maxValueSpan: 3600 * 24 * 1000 * 1000,
                rangeMode: ['value', 'value'],
                moveHandleSize: 5,
                moveHandleStyle: {
                    color: '#A2C6DE',
                    opacity: 0.5
                },
                labelPrecision: 'auto',
                labelFormatter: (val) => {
                    return format(new Date(val), differenceCase[unit].labelFormatter)
                },
                xAxisIndex: [0, ...new Array(data.length).fill(0).map((x, i) => i + 1)],
                realtime: true,
                minSpan: differenceCase[unit].minSpan
            },
            {
                id: 'dataZoomY',
                type: 'slider',
                yAxisIndex: [0],
                //filterMode: 'none',
                left: 15,
                orient: 'vertical',
                width: 20,
                top: 5,
                bottom: grid[0].bottom + 20,
                //minSpan: 10,
                labelPrecision: 0,
                backgroundColor: 'rgba(217, 217, 217, .2)',
                dataBackground: {
                    lineStyle: {
                        opacity: 0
                    },
                    areaStyle: {
                        opacity: 0,
                    }
                },
                selectedDataBackground: {
                    lineStyle: {
                        opacity: 0
                    },
                    areaStyle: {
                        opacity: 0
                    }
                },
                moveHandleSize: 5,
                moveHandleStyle: {
                    color: '#A2C6DE',
                    opacity: 0.5
                },
            }
        ],
    }

    if (datasetIsOverInitialZoom) {
        options.dataZoom[0].endValue = min([right, differenceCase[unit].initialDataZoomRange ? add(left, {[differenceCase[unit].name]: differenceCase[unit].initialDataZoomRange}) : null].filter(x => x !== null))
    }

    const not_smoothing_str = window.gettext('без сглаживания');

    for (let [i, row] of data.entries()) {
        const dataset = new Map([...row.dataset.entries()].filter(entry => isWithinInterval(new Date(entry[0]), {
            start: left,
            end: right
        })));

        /* AVERAGE (smoothing) */
        const smoothed = {
            name: row['query'],
            type: 'line',
            symbol: 'none',
            symbolSize: 4,
            lineStyle: {
                width: 2,
            },
            itemStyle: {
                opacity: 0
            },
            data: [],
            explains: row.explains,
            yAxisIndex: 0,
            xAxisIndex: 0,
            sampling: 'lttb'
        }

        await Promise.all(differenceCase[unit].each({start: left, end: right}).map(async date => {
            /* Обработка сглаживания */
            let average = 0.0;
            const start = max([sub(date, {[differenceCase[unit].name]: smoothing}), left]);
            const finish = min([add(date, {[differenceCase[unit].name]: smoothing}), right]);

            // Обработка каждого элемента асинхронно
            await Promise.all(differenceCase[unit].each({start: start, end: finish}).map(async smoothingDate => {
                const timestamp = smoothingDate.getTime();

                if (dataset.has(timestamp)) {
                    average += dataset.get(timestamp)[averageByMil];
                }
            }));

            average /= (differenceCase[unit].diff(finish, start) + 1);
            average = Math.round(average * 100000.0) / 100000.0;
            smoothed.data.push([date.getTime(), average]);
        }));

        if (smoothed.data.length === 1) {
            smoothed.data = [
                [sub(smoothed.data[0][0], {[differenceCase[unit].name]: 1}), 0],
                ...smoothed.data,
                [add(smoothed.data[0][0], {[differenceCase[unit].name]: 1}), 0]
            ]
        }

        options.series.push(smoothed);

        if (smoothing > 0) {
            /* PLOTS */
            const series = {
                name: `${row['query']} (${not_smoothing_str})`,
                type: 'scatter',
                symbolSize: 4,
                data: [...dataset.entries()].map(entry => [entry[0], parseFloat(entry[1][averageByMil])]),
                explains: row.explains,
                yAxisIndex: 0,
                xAxisIndex: 0,
            }

            options.series.push(series);
        }
    }

    if (searchConfig.allowFairChart) {

        for (let [i, row] of data.entries()) {
            let min = Infinity;
            let max = -Infinity;

            const dataset = new Map([...row.dataset.entries()].filter(entry => isWithinInterval(new Date(entry[0]), {
                start: left,
                end: right
            })));

            [...dataset.values()].forEach(entry => {
                const mid = entry.averageDocCount / (docCountRange.max - docCountRange.min)
                if (min > mid) min = Math.min(min, mid)
                if (max < mid) max = Math.max(max, mid)
                return entry
            })

            const baseColor = data.length > 1 ? colors[i] : 'rgb(157,84,47)'

            const colorPalette = [...generateTints(baseColor, 10, -10).reverse()]
            const emptyColor = '#f8f8f8';

            const heatmapSeries = {
                type: 'bar',
                data: [],
                barCategoryGap: 0,
                barGap: 0,
                explains: row.explains,
                yAxisIndex: i + 1,
                xAxisIndex: i + 1,
                key: `${window.gettext('Запрос')} ${i + 1}`,
                sampling: 'lttb'
            }


            heatmapSeries.data = differenceCase[unit].each({start: left, end: right}).map(date => {
                const entry = dataset.get(date.getTime())

                return {
                    value: 1,
                    itemStyle: {
                        color: entry && entry.averageDocCount ? colorPalette[findSegmentIndex([min, max], colorPalette.length, entry.averageDocCount / (docCountRange.max - docCountRange.min))] : emptyColor
                    }
                };
            })

            if (heatmapSeries.data.length === 1) {
                heatmapSeries.data = [
                    {value: 1, itemStyle: {color: emptyColor}},
                    heatmapSeries.data[0],
                    {value: 1, itemStyle: {color: emptyColor}}
                ]
            }

            options.series.push(heatmapSeries)
        }
    }

    return options
}


/**
 * @param {FrontendSearchState} searchState
 * @param {SearchConfig} searchConfig
 * @param {{lower: Date, upper: Date, lowerWithCount: Date, upperWithCount: Date}} boundaries
 * @param {DateRangeConditionValue|DateRange} subCorpusBoundaries
 * @returns {GraphicFilterParams}
 */
export const initGraphicParams = (searchState, searchConfig, boundaries, subCorpusBoundaries) => {
  const stateParams = searchState.graphic;
  let begin = DatePB.create();
  let end = DatePB.create();
  let smoothing = 0;
  const defaultSmoothing = searchConfig.graphicFilterParams.hasOwnProperty('smoothing') ? searchConfig.graphicFilterParams.smoothing : 3

  if (stateParams) {
    /* Поддержка старого формата */
    if (stateParams.lowerBoundary) {
      begin = DatePB.create({
        year: Number(stateParams.lowerBoundary),
        month: 1,
        day: 1
      })
    } else {
      begin = stateParams.boundaries.start
    }

    if (stateParams.upperBoundary) {
      end = DatePB.create({
        year: Number(stateParams.upperBoundary),
        month: 12,
        day: 31
      })
    } else {
      end = stateParams.boundaries.end
    }
    smoothing = stateParams.smoothing ?? defaultSmoothing

  } else {
    begin = nativeDateAsProto(max([
      boundaries.lowerWithCount,
      (searchConfig.graphicFilterParams.hasOwnProperty('boundaries') && searchConfig.graphicFilterParams.boundaries.hasOwnProperty('start') ? protoDateAsNative(searchConfig.graphicFilterParams.boundaries.start) : null),
      (subCorpusBoundaries && subCorpusBoundaries.begin ? protoDateAsNative(subCorpusBoundaries.begin) : null)
    ].filter(x => x !== null)))

    end = nativeDateAsProto(min([
      boundaries.upperWithCount,
      (searchConfig.graphicFilterParams.hasOwnProperty('boundaries') && searchConfig.graphicFilterParams.boundaries.hasOwnProperty('end') ? protoDateAsNative(searchConfig.graphicFilterParams.boundaries.end) : null),
      (subCorpusBoundaries && subCorpusBoundaries.end ? protoDateAsNative(subCorpusBoundaries.end) : null)
    ].filter(x => x !== null)))

    smoothing = defaultSmoothing
  }

  return GraphicFilterParams.create({
    boundaries: DateRange.create({
      begin: begin,
      end: end
    }),
    smoothing: smoothing
  });
}
