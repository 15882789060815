<template>
  <div class="para-concordance">
    <div class="para-concordance__title" v-if="!compact">
      <concordance-title :doc-info="docInfo" :doc-source="docSource" :index="index"/>
      <div v-if="payload.info.showAllExamples"
           class="para-concordance__all-results">
        <a :href="allExamplesLink">{{ $gettext('Все примеры') }} — {{ payload.info.allExamplesNum }}</a>
      </div>
    </div>
    <para-concordance-snippet v-for="(snippetGroup, index) in snippetsGroups"
                              @toggle-expand-context="toggleContext(snippetGroup, index, $event)"
                              @open-word-modal="handleOpenWordModal($event, index)"
                              @copy-context="handleCopyContext"
                              @open-speaker-info="handleOpenSpeakerInfo($event, index)"
                              :key="snippetGroup.uuid"
                              :expand="snippetGroup.contextIsExpanded"
                              :data="snippetGroup"
                              :word-source="wordSource"
                              :compact="compact"
                              :loading="snippetGroup.loading"
                              :doc-source="snippetGroupSource"/>
  </div>
</template>

<script>
import {computed, inject, onMounted, ref} from "vue";
import Icon from "../ui/Icon";
import {
  DiapSource,
  DocSource,
  ExplainQuery,
  ExplainResultType, FrontendPreviousParams, PaginationParams, SearchParams,
  SearchQuery,
  SearchResult,
  SearchResultType,
  SnippetGroup,
  WordSource
} from "@ruscorpora/ruscorpora_api";
import ResultActions from "../ui/ResultActions";
import {IS_SMALL_RESOLUTION, useRootStore} from "../../stores/root";
import { CURRENT_CORPUS, NO_DIACRITIC, QUERY, SEARCH_STATE, useSearchStore } from "../../stores/search";
import ConcordanceTitle from "./components/ConcordanceTitle.vue";
import {prepareSnippetGroup} from "../../utils/search";
import cloneDeep from "lodash/cloneDeep";
import Word from "./Word.vue";
import {DefaultPagination} from "../../utils/structure";
import {adjustToWindow, openInNewTab, prefixUrl} from "../../utils/helper";
import {bufferToBase64, buildQueryUrl, encodeSearchQuery, getModulePath} from "../../utils/http";
import ParaConcordanceSnippet from "./ParaConcordanceSnippet.vue";
import {useModalStore} from "../../stores/modal";
import {clipboardWriteRichText} from "../../../utils/clipboard";
import { reachGoal } from "../../utils/yandex-metrika";


export default {
  name: "ParaConcordanceItem",
  components: {
    ParaConcordanceSnippet,
    Word,
    ConcordanceTitle,
    Icon,
    SearchResultType,
    ResultActions,
  },
  methods: {},
  props: {
    queryResults: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: Number,
    },
    payload: {
      type: Object
    },
    compact: {
      type: Boolean,
      default: false
    },
    searchParams: {
      type: SearchParams
    },
    searchQuery: {
      type: [Object, SearchQuery],
      required: false
    },
  },
  setup(props) {
    const rootStore = useRootStore()
    const searchStore = useSearchStore()
    const modalStore = useModalStore()
    rootStore.init()
    const isSmallResolution = computed(() => rootStore[IS_SMALL_RESOLUTION])

    const request = inject('request')

    const docSource = computed(() => searchStore[SEARCH_STATE].docSource?.docId)
    const docInfo = computed(() => props.payload.info)
    const wordSource = computed(() => searchStore[SEARCH_STATE].wordSource)

    const snippetsGroups = ref([])

    onMounted(() => {
      if (!props.payload.snippetGroups) return []

      snippetsGroups.value = prepareSnippetGroup(props.payload.snippetGroups.map(x => {
        return SnippetGroup.create(SnippetGroup.toObject(x))
      }))
    })

    const toggleContext = (group, index, snippetUuid) => {
      const targetSnippet = group.snippets.find(x => x.uuid === snippetUuid)

      if (targetSnippet.expandedNewPage) {
        const originQuery = searchStore.query;

        const searchQuery = SearchQuery.fromObject({
          ...originQuery,
          resultType: [SearchResultType.FULL_TEXT],
          params: {
            ...originQuery.params,
            expdiap: DiapSource.create({
              docSource: DocSource.fromObject({...targetSnippet.source.docSource}),
              ...adjustToWindow(targetSnippet.source.start, targetSnippet.source.end)
            }),
            docSource: null,
            pageParams: PaginationParams.create()
          }
        })

        return openInNewTab(buildQueryUrl(getModulePath('full-text'), {search: encodeSearchQuery(searchQuery)}))
      }

      if (!group.contextIsExpanded) {
        const originQuery = searchStore.query

        const query = SearchQuery.fromObject({
          ...originQuery,
          resultType: originQuery.resultType,
          corpus: originQuery.corpus,
          subcorpus: originQuery.subcorpus,
          params: {
            ...originQuery.params,
            docSource: null,
            expdiap: targetSnippet.source,
            pageParams: DefaultPagination()
          }
        })

        snippetsGroups.value[index].loading = true
        console.info('Expand request', searchStore.cleanUpSearchQuery(query))
        request(prefixUrl(`/api/search?query=${encodeURIComponent(bufferToBase64(SearchQuery.encode(searchStore.cleanUpSearchQuery(query)).finish()))}&output=pb`, true))
          .then(response => SearchResult.decode(new Uint8Array(response)))
          .then(response => {
            const newGroups = response.queryResults[0].concordanceData.groups[0].docs[0].snippetGroups

            snippetsGroups.value[index].snippets = prepareSnippetGroup(newGroups, true).map(x => x.snippets).flat()
            snippetsGroups.value[index].contextIsExpanded = true
          })
          .finally(() => {
            snippetsGroups.value[index].loading = false
          })
      } else {
        snippetsGroups.value = prepareSnippetGroup(cloneDeep(props.payload.snippetGroups))
      }
    }

    const snippetGroupSource = computed(() => {
      return props.payload.info.source
    })

    const noDiacriticParam = computed(() => {
      if (props.searchParams) return props.searchParams.noDiacritic || false
      return searchStore[NO_DIACRITIC]
    })

    const query = computed(() => {
      return SearchQuery.decode(SearchQuery.encode(props.searchQuery ?? searchStore.query).finish())
    })

    const getParamsForModal = (expanded = false, diapSource = null) => {
      const searchQuery = SearchQuery.fromObject({...query.value.toJSON()})

      if (expanded) {
        searchQuery.params.expdiap = diapSource
        searchQuery.params.docSource = null
        searchQuery.params.pageParams = DefaultPagination()
      }

      return {
        searchQuery,
        homonymyStatus: props.payload.info.homonymyStatus,
        explainResult: null
      }
    }

    const handleOpenWordModal = ({wordSourceParams, isExpanded}, index) => {
      const explainQuery = ExplainQuery.create({
        corpus: searchStore[CURRENT_CORPUS],
        resultType: ExplainResultType.WORD_INFO,
        wordSource: WordSource.create({
          ...wordSourceParams,
          docSource: props.payload.info.source
        }),
        noDiacritic: noDiacriticParam.value
      })

      modalStore.showWordModalV2({
        ...getParamsForModal(isExpanded, props.payload.snippetGroups[index].snippets[0].source),
        explainQuery,
      })
    }

    const handleCopyContext = (domEl) => {
      const snippetContent = domEl.cloneNode(true);
      snippetContent.querySelectorAll('.result-actions').forEach(x => x.remove())
      snippetContent.querySelectorAll('.no-copy').forEach(x => x.remove())

      clipboardWriteRichText(snippetContent, [`[${docInfo.value.title}]`], false, true)

      reachGoal('TARGET_СOPY')
    }

    const handleOpenSpeakerInfo = ({speakerInfo, isExpanded}, index) => {
      modalStore.showSpeakerInfo({
        ...getParamsForModal(isExpanded, props.payload.snippetGroups[index].snippets[0].source),
        explainQuery: ExplainQuery.create({docSource: props.payload.info.source}),
        speakerInfo
      })
    }

    const allExamplesLink = computed(() => {
      if (!props.payload.info.showAllExamples) return ''
      const originQuery = searchStore[SEARCH_STATE].query;

      const query = SearchQuery.fromObject({
        ...originQuery,
        resultType: originQuery.resultType,
        corpus: originQuery.corpus,
        subcorpus: originQuery.subcorpus,
        params: {
          ...originQuery.params,
          docSource: DocSource.create({
            docId: props.payload.info.source.docId
          }),
          pageParams: DefaultPagination()
        }
      })

      return buildQueryUrl(getModulePath('results'), {
        search: encodeSearchQuery(
          query, FrontendPreviousParams.create({searchParams: originQuery.params}
          ))
      })
    })

    return {
      isSmallResolution,
      rootStore,

      docSource,
      docInfo,
      wordSource,
      snippetsGroups,
      snippetGroupSource,
      handleOpenWordModal,
      handleCopyContext,
      handleOpenSpeakerInfo,

      toggleContext,
      allExamplesLink,
    };

  },
};
</script>

<style scoped lang="scss">
.para-concordance {
  margin-bottom : rem-calc(40);
  border-radius : 10px;
  border        : 1px solid var(--blue);

  &__title {
    border-bottom  : 1px solid var(--blue);
    padding        : 10px 20px;
    display        : flex;
    flex-direction : column;
    gap            : 15px;

    .concordance-title {
      overflow    : visible !important;
      font-weight : 600 !important;
      line-height : 1.2;
    }

    @include breakpoint(medium up) {
      padding-left    : 20px;
      padding-right   : 20px;
      flex-direction  : row;
      gap             : 40px;
      justify-content : space-between;
      align-items     : flex-start;
      min-height      : 60px;
    }
  }

  &__all-results {
    flex-shrink : 0;
    height      : 100%;
    font-size   : rem-calc(16);
    white-space : nowrap;

    a {
      color   : var(--black);
      opacity : .5;

      &:hover {
        text-decoration : underline;
        opacity         : .7;
      }
    }

    @include breakpoint(medium up) {
      font-size   : rem-calc(18);
      padding-top : 4px;
    }
  }
}

.icon-c-arrow {
  width  : rem-calc(18);
  height : rem-calc(8);
}

.overflow-hidden {
  display : none;
}
</style>
<style lang="scss">

.para-concordance {
  .v-select {
    width         : rem-calc(410);
    display       : none;
    margin-top    : -7px;
    margin-bottom : 9px;
    height        : 30px;
    max-width     : 100%;

    @include breakpoint(medium up) {
      display : inline-block;
    }
  }

  .homonymy-list {
    margin-top : -.5rem;
  }
}


</style>
