<template>
  <table aria-label="" class="the-table" ref="table" :class="{'fixed-row-height': fixedRowHeight}">
    <colgroup>
      <col v-for="(column, i) in columns" :key="i" />
    </colgroup>
    <thead>
    <slot name="extra-header" />
    <slot name="header" :activeSort="activeSortColumn" :sortDirection="sortDirection">
      <tr>
        <th v-for="(column, i) in columns" :key="`head_${i}`" :colspan="column.colspan || 1" @click="setSort(column)">
          <div :class="{'sorted-column': enabledSort && column.sort}">
            <a v-if="column.externalUrl" :href="column.externalUrl" v-html="column.text" />
            <span v-else v-html="column.text" />

            <span v-if="column.sort" class="sorted-column__indicator" :class="{
          'is-active': activeSortColumn === column.key,
          'ascent': sortDirection === 'ascent'}">
            <icon name="c-sort" />
          </span>
          </div>
        </th>
      </tr>
    </slot>
    </thead>
    <tbody @mouseleave="mouseLeaveHandler" @mousemove="mouseMoveHandler">
    <tr v-for="(row, row_index) in sortedRows" :key="row.uuidKey">
      <td v-for="(column, i) in colRange" :key="`${row.uuidKey}_col_${i}`"
          :class="{[columns[i].externalCellClass]: columns[i].externalCellClass, [row[`${columns[i]['key']}_font`]]: Boolean(row[`${columns[i]['key']}_font`])}">
        <template v-if="columns[i]['type'] === 'text'">
          <the-tooltip v-if="row[`${columns[i]['tooltip']}`]" :title="row[`${columns[i]['tooltip']}`].toString()"
                       :mouseEnterDelay=".3">
            <component :is="row[`${columns[i]['key']}_view`]" />
          </the-tooltip>
          <template v-else>
            <component :is="row[`${columns[i]['key']}_view`]" />
          </template>
        </template>
        <a v-else-if="columns[i]['type'] === 'link'" :href="row[columns[i]['url']]" target="_blank">
          {{ row[columns[i]['key']] }}
        </a>
        <span v-else-if="columns[i]['type'] === 'index'">{{ row_index + 1 }}</span>
        <span v-else-if="columns[i]['type'] === 'histogram'" class="histogram">
          <span class="histogram-inner" :style="{width: `${row[columns[i]['key']]}%`}"></span>
        </span>
        <component v-else-if="columns[i]['type'] === 'cross'"
                   :is="row[`${columns[i]['tooltip']}`] ? 'the-tooltip': 'template'"
                   :title="row[columns[i]['tooltip']].toString()" :mouseEnterDelay=".3">
          <div class="cross">
            <div class="cross__blue" :style="{width: `${row[columns[i]['key']][0]}%`}" />
            <div class="cross__red"
                 :style="{left: `${row[columns[i]['key']][1]}%`, right: `${row[columns[i]['key']][2]}%`}" />
            <div class="cross__scale">
              <span></span> <span></span> <span></span><span></span> <span></span>
            </div>
          </div>
          <div class="cross-raw-vals" v-text="row[columns[i]['key']][3]" />
        </component>
      </td>
    </tr>
    <tr v-if="sortedRows.length === 0 && showEmptySlot">
      <td :colspan="columns.length">
        <fail :message="$gettext('По вашему запросу ничего не найдено')" :status="404" />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import Icon from "./Icon";
import TheTooltip from "./form/TheTooltip.vue";
import { onMounted, ref, Fragment } from "vue";
import clone from "lodash/clone"
import { v4 as uuid } from 'uuid';
import Fail from "../results/Fail.vue";

const ASCENT = 'ascent'
const DESCENT = 'descent'

export default {
  name: "TheTable",
  components: {Fail, TheTooltip, Icon},
  props: {
    columns: {
      type: Array
    },
    rows: {
      type: Array
    },
    defaultSort: {
      type: String
    },
    defaultSortDir: {
      type: String
    },
    asyncSort: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: Infinity
    },
    offset: {
      type: Number,
      default: 0
    },
    fixedRowHeight: {
      type: Boolean,
      default: false
    },
    showEmptySlot: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      table: [],
      sortColumn: '',
      sortDirection: this.defaultSortDir || DESCENT, //ascent, descent
    }
  },
  emits: ['change-sort'],
  methods: {
    externalSort({key, direction}) {
      this.sortColumn = key
      this.sortDirection = direction
    },
    setSort(column) {
      if (!this.enabledSort) return

      if (!column.sort) return;
      let sortDirection = ""
      // Переключение на новую вкладку для сортировки
      if (this.activeSortColumn !== column.key) {
        sortDirection = DESCENT
      } else {
        if (this.sortDirection === ASCENT) {
          sortDirection = DESCENT
        } else {
          sortDirection = ASCENT
        }

        if (column.sort instanceof Array && !column.sort.includes(sortDirection)) {
          sortDirection = column.sort[0]
        }
      }
      this.sortDirection = sortDirection
      this.sortColumn = column.key;
      this.$emit('change-sort', {
        direction: this.sortDirection,
        key: this.sortColumn
      })
    },
    stringToSortedValue(str) {
      if (!isNaN(Number(str))) return Number(str)

      if (str.includes('-')) {
        const intRange = str.split('-')
        if (intRange.length == 2 && !isNaN(Number(intRange[0])) && !isNaN(Number(intRange[1]))) return Number(intRange[0])
      }

      return str.toLowerCase()
    },
    sortArray(items) {
      return items.sort((a, b) => {
        let aCol = clone(a[this.activeSortColumn])
        let bCol = clone(b[this.activeSortColumn])
        let sortWeight = 0

        if (typeof aCol === 'string') aCol = this.stringToSortedValue(aCol)
        if (typeof bCol === 'string') bCol = this.stringToSortedValue(bCol)

        if (aCol > bCol) sortWeight = -1
        else if (bCol > aCol) sortWeight = 1

        if (this.sortDirection === ASCENT) sortWeight = sortWeight * -1

        return sortWeight
      })
    }
  },
  computed: {
    items() {
      return this.rows.map(item => {
        const res = {
          uuidKey: uuid()
        }
        Object.keys(item)
          .filter(x => {
            return !x.endsWith('_render') && !x.endsWith('_extra')
          })
          .forEach(key => {
            const viewKey = `${key}_view`
            if (item.hasOwnProperty(`${key}_render`)) {
              res[viewKey] = (<Fragment>{item[`${key}_render`](item[key])}</Fragment>)
            } else if (item.hasOwnProperty(`${key}_extra`)) {
              res[viewKey] = (<span vHtml={`${item[key]}&nbsp;${item[`${key}_extra`]}`} />)
            } else {
              res[viewKey] = (<span vText={item[key]} />)
            }
            res[key] = item[key]
          })
        return res
      })
    },
    colRange() {
      return Array(this.columns.length).fill(0);
    },
    activeSortColumn() {
      return this.sortColumn || this.defaultSort
    },
    sortedColumns() {
      return this.columns.filter(x => x.hasOwnProperty('sort'))
    },
    enabledSort() {
      return this.sortedColumns.length > 0
    },
    sortedRows() {
      if (this.enabledSort && !this.asyncSort) {
        if (this.columns.find(x => x.key === this.activeSortColumn).hasOwnProperty('sort_func')) {
          /* Сортировка отдельной колонки по методу из родительского компонента */
          return this.columns
            .find(x => x.key === this.activeSortColumn)
            .sort_func(this.items, this.sortDirection)
            .slice(this.offset, this.offset + this.limit)
        } else {
          return this.sortArray(this.items).slice(this.offset, this.offset + this.limit)
        }
      } else {
        return this.items.slice(this.offset, this.offset + this.limit)
      }
    },
  },
  setup() {
    const table = ref(null);
    const cols = ref([]);
    let latestTarget = null

    const mouseLeaveHandler = () => {
      cols.value.forEach(x => x.classList.remove('highlight'))
      latestTarget = null
    }
    const mouseMoveHandler = (event) => {
      const element = event.target

      if (element.tagName === 'TD' && (latestTarget !== element || latestTarget === null)) {
        latestTarget = element
        table.value.querySelectorAll('col.highlight').forEach(x => x.classList.remove('highlight'))

        const parent = element.parentNode;
        cols.value[Array.prototype.indexOf.call(parent.children, element)].classList.add('highlight')
      }
    }

    onMounted(() => {
      cols.value = table.value.querySelectorAll('col')
    })

    return {
      table,

      mouseMoveHandler,
      mouseLeaveHandler
    }
  }
}
</script>

<style scoped lang="scss">
.the-table {
  font-size : rem-calc(16);

  .histogram {
    width    : rem-calc(220);
    height   : rem-calc(16);
    position : relative;
    display  : block;

    &-inner {
      overflow : hidden;
      position : absolute;
      height   : 100%;
      left     : 0;
      top      : 0;

      &::before {
        position   : absolute;
        width      : rem-calc(220);
        content    : '';
        height     : 100%;
        left       : 0;
        background : linear-gradient(90deg, #9EC7DE 0%, #D1E4F1 100%);
      }
    }
  }

  .cross-raw-vals {
    white-space : nowrap;
  }

  .cross {
    position  : relative;
    display   : flex;
    max-width : 100%;
    height    : rem-calc(15);
    width     : rem-calc(99);

    &__blue {
      z-index          : 2;
      position         : absolute;
      height           : 100%;
      width            : 70%;
      background-color : rgba(1, 158, 224, 0.9);
      mix-blend-mode   : multiply;
    }

    &__red {
      top              : 50%;
      transform        : translateY(-50%);
      position         : absolute;
      height           : 40%;
      background-color : rgba(227, 60, 148);
      mix-blend-mode   : multiply;
    }

    &__scale {
      position       : relative;
      height         : 100%;
      width          : 100%;
      mix-blend-mode : multiply;

      span {
        z-index          : 5;
        position         : absolute;
        height           : 100%;
        background-color : #D9D9D9;
        color            : #D9D9D9;
        width            : rem-calc(1);
        left             : 0;

        &:nth-child(2) {
          left : 25%;
        }

        &:nth-child(3) {
          left : 50%;
        }

        &:nth-child(4) {
          left : 75%;
        }

        &:nth-child(5) {
          left : 100%;
        }
      }
    }
  }

  col.highlight {
    background-color : var(--table-hight-light) !important;
  }
}

.sorted-column {
  cursor        : pointer;
  position      : relative;
  padding-right : rem-calc(22);
  display       : inline-flex;

  &__indicator {
    position  : absolute;
    color     : #6173A6;
    transform : rotate(180deg) translateY(50%);
    right     : 0;
    top       : 50%;
    opacity   : .7;

    &.is-active {
      color   : var(--table-color);
      opacity : 1;

      &.ascent {
        transform : rotate(0) translateY(-50%);
      }
    }
  }
}

.fixed-row-height td {
  height : 31px;
}
</style>
