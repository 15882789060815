<template>
  <search-form-tab
    @change-search-mode="handleChangeSearchMode"
    :search-mode="para_lex_gramm"
    :active-search-mode="searchMode"
    :title="searchForm.title"
    :help-text="searchForm.helpText"
    :subtitle="searchForm.subtitle"
    domId="parallel-lex-gramm-search-form">

    <template v-for="(item, index) in model" :key="item.langId">
      <div class="lex-gramm-language">
        <span>{{ searchForm.forms[index].title }}</span>
        <round-button v-if="searchForm.forms[index].helpText"
                      @click="showHelpText(searchForm.forms[index].helpText)"
                      color="dark"
                      size="small"
                      :aria-label="$gettext('Подсказка')">
          <icon name="c-question" />
        </round-button>
      </div>

      <lex-gramm
        :params="item.words"
        :cards="searchForm.forms[index].cards"
        :repeated="searchForm.forms[index].repeated"
        :word-label="searchForm.forms[index].wordLabel"
        :class="{[item.langId]: true}"
        @delete-word="(val) => handleDeleteWord(val, item.langId)"
        @add-word="(val) => handleAddWord(val, item.langId)"
        @update-field="(val) => handleUpdateCondition(val, item.langId)"
        @delete-condition="(val) => handleDeleteConditionFromWord(val, item.langId)"
        @add-condition="(val) => handleAddConditionToWord(val, index, item.langId)" />
    </template>

    <div class="to-search-wrap">
      <the-button type="colored" size="medium" @click.stop="handleSubmit">
        {{ $gettext('Искать') }}
      </the-button>

      <the-button type="dashed" size="medium" @click="handleResetFormValue">
        {{ $gettext('Сбросить') }}
      </the-button>
    </div>
  </search-form-tab>
</template>

<script>
/* Форма параллельного лексико-грамматического поиска */

import RoundButton from "../ui/RoundButton.vue";
import LexGramm from "../ui/form/LexGramm.vue";
import Icon from "../ui/Icon.vue";
import TheButton from "../ui/TheButton.vue";
import SearchFormTab from "./SearchFormTab.vue";
import { computed, inject, nextTick } from "vue";
import { useModalStore } from "../../stores/modal";
import { configToForm, getDefaultFieldValue } from "../../utils/form";
import { FrontendSearchMode } from "@ruscorpora/ruscorpora_api";

export default {
  name: "ParallelLexGramm",
  components: {SearchFormTab, TheButton, Icon, LexGramm, RoundButton},
  props: {
    searchMode: {
      type: Number
    },
    searchForm: {
      type: Object
    },
    langs: {
      type: Array,
      default: () => ["rus", "eng"]
    },
    modelValue: {
      type: Object,
    },
  },
  emits: ["change-search-mode", "submit", "update:modelValue"],
  setup(props, {emit}) {
    const modalStore = useModalStore()
    const message = inject('message')
    const $gettext = inject('gettext')

    const model = computed({
      get() {
        return props.modelValue
      },
      set(val) {
        emit('update:modelValue', val)
      }
    })

    const handleChangeSearchMode = (searchMode) => {
      emit("change-search-mode", searchMode)
    }

    const handleSubmit = () => {
      emit("submit")
    }

    const handleResetFormValue = () => {
      model.value = props.searchForm.forms.map((form, index) => {
        const extra = {}

        form.beforeCards.forEach(condition => {
          extra[condition.name] = getDefaultFieldValue(condition)
        })

        return {
          words:
            [configToForm(form.cards, false).filter(card => {
              return !card.hasOwnProperty('dist')
            })],
          extra: extra,
          langId: `lang-${index}`
        }
      })
    }

    const verifyWordListAsToDistCondition = (showNoty = true, lang) => {
      model.value.forEach(item => {
        if (item.langId !== lang) return

        item.words[0].forEach(formCard => {
          Object.keys(formCard).forEach(fieldName => {
            if (fieldName === 'dist') {
              // Delete Dist condition when it was added to first word (after delete previous word)
              handleDeleteConditionFromWord({word: 0, cardId: formCard['cardId']}, lang)
              if (showNoty) message.info($gettext('Условие «расстояние» для первого слова было удалено (не соответствует условию)'), 3)
            }
          })
        })
      })
    }

    const handleAddWord = (payload, lang) => {
      const newWord = configToForm(payload.cards, false);

      model.value = model.value.map(({...item}) => {
        if (item.langId === lang) {
          if (payload.index === item.words.length - 1 || !item.words.length) {
            item.words.push(newWord)
          } else {
            item.words.splice(payload.index + 1, 0, newWord)
          }
        }
        return item
      })

      nextTick(() => {
        const parallelFormDOM = document.getElementById('parallel-lex-gramm-search-form')
        if (!parallelFormDOM) return

        Array.from(parallelFormDOM.querySelectorAll(`.lex-words.${lang} .lex-word`))[payload.index + 1]
          .querySelector('.lex-condition input').focus()
      })
    }

    const handleDeleteWord = (index, lang) => {
      model.value = model.value.map(({...item}) => {
        if (item.langId === lang) {
          item.words.splice(index, 1)
        }
        return item
      })
      verifyWordListAsToDistCondition(true, lang)
      /* TODO: Удаление сохраненных значений по индексу поля */
      //searchStore.deleteOptionsWord(index)

      nextTick(() => {
        const parallelFormDOM = document.getElementById('parallel-lex-gramm-search-form')
        if (!parallelFormDOM) return

        Array.from(parallelFormDOM.querySelectorAll(`.lex-words.${lang} .lex-word`))[Math.max(index - 1, 0)]
          .querySelector('.lex-condition input').focus()
      })
    }

    const handleAddConditionToWord = (payload, search_form_index, lang) => {
      const newCondition = configToForm([payload['card']], true)[0]
      const newConditionPosition = props.searchForm.forms[search_form_index].cards.findIndex(card => card.cardId === payload.card.cardId)

      model.value = model.value.map(({...item}) => {
        if (item.langId === lang) {
          item.words[payload.word].splice(newConditionPosition, 0, newCondition)
        }

        return item
      })
    }

    const handleDeleteConditionFromWord = (payload, lang) => {
      model.value = model.value.map(({...item}) => {
        if (item.langId === lang) {
          item.words[payload.word] = item.words[payload.word].filter(x => x['cardId'] !== payload['cardId'])
        }

        return item
      })
    }

    const handleUpdateCondition = (payload, lang) => {
      model.value = model.value.map(({...item}) => {
        if (item.langId === lang) {
          item.words[payload.word].find(x => x['cardId'] === payload['cardId'])[payload.name] = payload.value
        }

        return item
      })
    }


    return {
      model,

      /* Обработчики событий */
      handleChangeSearchMode,
      handleSubmit,
      handleResetFormValue,
      handleAddWord,
      handleDeleteWord,
      handleAddConditionToWord,
      handleDeleteConditionFromWord,
      handleUpdateCondition,


      showHelpText: modalStore.showHelpText,
      initForm: () => handleResetFormValue(),
      para_lex_gramm: FrontendSearchMode.PARA_LEX_GRAMM
    }
  }
}
</script>


<style scoped lang="scss">
.lex-gramm-language {
  display       : flex;
  gap           : 10px;
  font-size     : 16px;
  margin-bottom : 10px;
  margin-top    : 10px;
  align-items   : center;
}

.to-search-wrap {
  margin-top     : rem-calc(40);
  width          : 100%;
  display        : flex;
  gap            : rem-calc(20);
  flex-direction : column;

  @include breakpoint(small up) {
    margin-top     : rem-calc(80);
    flex-direction : row;
    .button {
      width : rem-calc(272);
    }
  }
}
</style>
