<script setup>

import AudioPlayer from "./AudioPlayer.vue";
import VideoPlayer from "./VideoPlayer.vue";
import {
  MediaContent,
  MediaContentType
} from "@ruscorpora/ruscorpora_api";
import { computed } from "vue";

const props = defineProps({
  mediaContent: {
    type: Object
  },
  small: {
    type: Boolean,
    default: true
  }
})

const mediaContentType = MediaContentType
const mediaContentProcessed = computed(() => MediaContent.fromObject(props.mediaContent))

const fileId = computed(() => {
  const chunks = mediaContentProcessed.value.url.split('/')
  return chunks[chunks.length - 1]
})

const fileName = computed(() => {
  return mediaContentProcessed.value.name ? mediaContentProcessed.value.name : fileId.value
})

const fileIdWithoutExt = computed(() => {
  return fileId.value.replace('.mp4', '').replace('.mp3', '')
})

const downloadUrl = computed(() => {
  return `https://media.ruscorpora.ru/download/${fileIdWithoutExt.value}/?name=${fileName.value}`
})

</script>

<template>
  <template v-if="mediaContentProcessed.type === mediaContentType.VIDEO">
    <div :class="{'small-video-container': small, 'modal-video-container': !small}">
      <video-player :media-content="mediaContentProcessed" class="video-player"/>
      <a :href="downloadUrl" download="" class="link"
         :class="{'small-video-container__download': small, 'modal-video-container__download': !small}">
        {{ $gettext('скачать') }}
      </a>
    </div>
  </template>
  <template v-if="mediaContentProcessed.type === mediaContentType.AUDIO">
    <audio-player :src="mediaContentProcessed.url"
                  :class="{'small-audio-container': small, 'modal-audio-container': !small}">
      <template #download>
        <a :href="downloadUrl" download="" class="link" :class="{'small-audio-container__download': small}">
          {{ $gettext('скачать') }}
        </a>
      </template>
    </audio-player>
  </template>
</template>

<style scoped lang="scss">
.small-video-container {
  display        : flex;
  gap            : 7px;
  flex-direction : column;
  align-items    : flex-start;

  @include breakpoint(small down) {
    margin-bottom : 10px;
  }

  .concordance-item__body:not(.inline-media) &, .para-concordance-item & {
    margin-bottom : 10px;

    @include breakpoint(medium up) {
      margin-bottom : 20px;
    }

    @include breakpoint(small up) {
      flex-direction : row;
      gap            : 18px;
      align-items    : flex-end;
    }
  }

  .video-player {
    max-width : 100%;
    width     : 320px;
  }

  &__download {
    font-size   : 12px;
    line-height : 1.2;
    @include breakpoint(medium up) {
      font-size : 14px;
    }
  }
}

.small-audio-container {
  @include breakpoint(small down) {
    margin-bottom : 10px;
  }

  .concordance-item__body:not(.inline-media) &, .para-concordance-item & {
    margin-bottom : 10px;
    @include breakpoint(medium up) {
      margin-bottom : 20px;
    }
  }
}

.modal-video-container {
  &__download {
    padding   : 15px 20px;
    font-size : 14px;
    display   : inline-block;
  }
}
.modal-audio-container {
  width: unset;
}
</style>
