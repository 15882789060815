<template>
  <tooltip :overlay-class-name="classObj" v-bind="bindAttrs" :title="null" @visible-change="onOpenChangeHandler"
           :get-popup-container="getPopupContainer">
    <template #title>
      <span v-html="realTitle" />
    </template>
    <slot ref="tooltipDom" />
  </tooltip>
</template>

<script>
import { Tooltip } from 'ant-design-vue';
import 'ant-design-vue/es/tooltip/style/index.css';
import isNull from "lodash/isNull";
import { ref, watch } from "vue";

export default {
  name: "TheTooltip",
  emits: ['visible-change'],
  props: {
    title: {
      type: [String, Function], // функция вернёт промис asyncGettext. другого не ожидается
      required: true
    },
    light: {
      type: Boolean,
      default: false
    },
    visible: {
      type: [Boolean, null],
      default: null
    },
    mouseEnterDelay: {
      type: Number,
      default: 0
    },
    placement: {
      type: String,
      default: () => 'topLeft'
    },
    appendTo: {
      type: [Element, undefined],
      default: undefined
    },
    overZindex: {
      type: Boolean
    }
  },
  components: {
    Tooltip,
  },
  setup(props, {emit}) {
    const computedPlacementVal = ref(props.placement)
    const tooltipDom = ref()
    const setPlacement = () => {
      const rect = tooltipDom.value.getBoundingClientRect()
      const align = window.innerWidth - rect.right > 200 || rect.left < 50 ? 'left' : 'right';
      if (align === 'left') {
        if (props.placement === 'right') computedPlacementVal.value = 'left'
        else if (props.placement === 'topRight') computedPlacementVal.value = 'topLeft'
        else if (props.placement === 'bottomRight') computedPlacementVal.value = 'bottomLeft'
        else computedPlacementVal.value = props.placement
      } else if (align === 'right') {
        if (props.placement === 'left') computedPlacementVal.value = 'right'
        else if (props.placement === 'topLeft') computedPlacementVal.value = 'topRight'
        else if (props.placement === 'bottomLeft') computedPlacementVal.value = 'bottomRight'
        else computedPlacementVal.value = props.placement
      }
    }
    const prepareTitle = (val) => {
      if (val instanceof Function) {
        val().then(r => realTitle.value = r)
      } else {
        realTitle.value = val
      }
    }
    const onOpenChangeHandler = (visible) => {
      if (visible) {
        prepareTitle(props.title)
        if (tooltipDom.value) {
          setPlacement()
        }
      }
      emit('visible-change', visible)
    }

    const getPopupContainer = (target) => {
      tooltipDom.value = target
      setPlacement()
      if (props.appendTo) return props.appendTo
      return document.body
    }

    const realTitle = ref("...")
    watch(
      () => props.title,
      () => prepareTitle(props.title)
    )
    return {
      onOpenChangeHandler,
      computedPlacementVal,
      tooltipDom,
      getPopupContainer,
      realTitle
    }
  },
  computed: {
    classObj() {
      const classList = {
        'ant-tooltip--light': this.light,
        'ant-tooltip--z-index-over': this.overZindex,
      }

      return Object.keys(classList).filter(key => classList[key]).join(' ')
    },
    bindAttrs() {
      const attrs = {
        mouseEnterDelay: this.mouseEnterDelay,
        placement: this.computedPlacementVal,
        autoAdjustOverflow: true,
        arrowPointAtCenter: true
      }
      if (!isNull(this.visible)) attrs.visible = this.visible
      return attrs
    }
  }
}
</script>

