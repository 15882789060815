<template>
  <div>
    <vue-final-modal v-model="visibility"
                     class="modal-container"
                     content-class="modal-content"
                     @click-outside="store.hideModal">
      <div class="help-text-wrap">
        <div class="help-text">
          <div class="help-text__header">
            <round-button size="large" :outline="true" :aria-label="$gettext('Закрыть')" class="help-text__close"
                          @click="store.hideModal">
              <icon name="cross" />
            </round-button>
          </div>
          <div class="help-text__body">
            <a-skeleton :loading="isLoading">
              <div class="typography" v-html="$gettext(body)" />
            </a-skeleton>
          </div>
        </div>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
import { computed } from "vue";
import { useModalStore, MODAL_HELP_TEXT } from "../../stores/modal";
import Icon from "../ui/Icon";
import RoundButton from "./RoundButton";
import Loader from "./Loader";
import { storeToRefs } from 'pinia';
import { Skeleton } from "ant-design-vue";
import { VueFinalModal } from "vue-final-modal";

export default {
  name: "HelpTextModal",
  components: {VueFinalModal, Loader, RoundButton, Icon, 'a-skeleton': Skeleton},
  setup() {
    const store = useModalStore();
    const visibility = computed({
      get() {
        return store.isActive && store.type === MODAL_HELP_TEXT
      },
      set() {
        store.hideModal()
      }
    });

    const {body, isLoading} = storeToRefs(store);

    return {
      store,
      visibility,
      body,
      isLoading
    }
  }
}
</script>

<style scoped lang="scss">
.help-text {
  padding          : 0;
  background-color : var(--beige);
  width            : 100vw;
  max-width        : 100vw;
  position         : relative;
  height           : 100vh;
  height           : var(--window-height);
  margin-left      : auto;
  margin-right     : auto;
  max-height       : 100vh;
  box-sizing       : border-box;

  &__header {
    position        : sticky;
    height          : rem-calc(60);
    display         : flex;
    justify-content : flex-end;
    align-items     : center;
    border-bottom   : 1px solid var(--black);
    top             : 0;
    padding-right   : rem-calc(15);

    @include breakpoint(small up) {
      border-bottom : 0;
      margin-bottom : rem-calc(-60);
      margin-left   : rem-calc(-40);
      margin-right  : rem-calc(-40);
      padding-right : rem-calc(20);
      align-items   : flex-start;
    }
  }

  &__close {
    @include breakpoint(small up) {
      margin-top : rem-calc(-7);
    }
  }

  &__body {
    font-size   : rem-calc(18);
    line-height : 1.5;
    max-height  : calc(100% - #{rem-calc(60)});
    overflow-y  : auto;
    padding     : rem-calc(0 20);

    .typography {
      padding : 2rem 0;
    }


    @include breakpoint(small up) {
      padding-right : rem-calc(20);
      padding-left  : 0;
      overflow-y    : visible;
      max-height    : unset;

      .typography {
        padding : 0;
      }
    }

  }

  &__loading {
    pointer-events  : none;
    position        : absolute;
    left            : 0;
    top             : 0;
    right           : 0;
    bottom          : 0;
    display         : flex;
    align-items     : center;
    justify-content : center;
  }

  @include breakpoint(small up) {
    border-radius : rem-calc(10);
    width         : rem-calc(600);
    padding       : rem-calc(30 40);
    min-height    : rem-calc(200);
    height        : auto;
    max-height    : 80vh;
    overflow-y    : auto;
  }
}
</style>
