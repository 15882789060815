<template>
  <li class="concordance-item" :class="{'concordance-item--compact': compact}">
    <div class="concordance-item__head" v-if="!compact">
      <concordance-title :doc-info="payload.info" :doc-source="docSource" :index="index">
        <template v-if="availableFullTextMode" #extra-actions>
          <the-tooltip :title="$gettext('Открыть текст документа')" :light="true">
            <router-link :to="fullTextQueryParams" target="_blank">
              <span class="show-full-text">
                <icon name="context"/>
              </span>
            </router-link>
          </the-tooltip>

        </template>
      </concordance-title>

      <div v-if="payload.info.showAllExamples"
           class="concordance-item__all-results">
        <a :href="allExamplesLink">{{ $gettext('Все примеры') }} — {{ payload.info.allExamplesNum }}</a>
      </div>
    </div>
    <div class="concordance-item__body" v-if="snippetsExists" :class="{'inline-media': inlineMedia}">
      <div v-if="payload.info.hasOwnProperty('mediaContent')">
        <media-content :media-content="payload.info.mediaContent"/>
      </div>
      <div class="concordance-item__body-text">
        <div v-for="(group, group_index) in snippetsGroups" :key="group.uuid" ref="context"
             class="concordance-item__group"
             :class="{
        'is-active': group.contextIsExpanded || group.loading,
        'poem-layout': group.isPoemLayout,
        'poem-layout--compact': group.isPoemLayout && poemNoAnyLineInfo,
        'no-diacritic': noDiacriticParam}">
          <div class="snippet" v-for="(snippet, snippetIndex) in group.snippets" :key="snippet.uuid">
            <div v-if="snippet.hasOwnProperty('mediaContent')">
              <media-content :media-content="snippet.mediaContent"/>
            </div>
            <div class="sequences">
              <template v-for="(sequence, sequenceIndex) in snippet.sequences" :key="sequence.uuid">
                <div v-if="group.isPoemLayout"
                     class="poem-layout__line-info"
                     :class="{'offset': snippet.previousSnippetContinuation && sequenceIndex === 0}">
              <span v-if="!noDiacriticParam">
                <br v-if="(sequence.words[0]?.text ?? '').startsWith('<br/>')"/>
                {{ sequence.lineInfo?.processed || ' ' }}
              </span>
                </div>
                <p :class="{
               'concordance-item__blockquote': sequence.blockquote,
               'seq-with-actions': sequence.showSelfActions && !payload.info.gestures?.length,
               'concordance-sequence--is-prose': sequence.isProse,
               'concordance-sequence--prev-continuate': snippet.previousSnippetContinuation && sequenceIndex === 0,
             }" class="concordance-sequence">
                  <sequence
                    :sequence="sequence"
                    :sequence-index="sequenceIndex"
                    :show-context-expand-action="showContextExpandAction(snippet)"
                    :lang-info="snippet.langInfo"
                    :show-language="snippet.showLanguage"
                    :previous-snippet-continuation="snippet.previousSnippetContinuation"
                    :source="payload.info.source"
                    :word-source="wordSource"
                    :enable-result-actions="sequence.showSelfActions && !payload.info.gestures?.length > 0"
                    :syntax-structure="snippet.syntaxStructure"
                    @copy-context="handleCopyContext(group_index)"
                    @open-word-modal="handleOpenWordModal($event, group.contextIsExpanded, payload.snippetGroups[group_index].snippets[snippetIndex].source)"
                    @open-speaker-info="handleOpenSpeakerInfo(sequence.speakerInfo, group.contextIsExpanded, payload.snippetGroups[group_index].snippets[snippetIndex].source)"
                    @open-readability-info="handleOpenReadabilityInfo(sequence.readabilityInfo, group.contextIsExpanded, payload.snippetGroups[group_index].snippets[snippetIndex].source, $event)"
                    @toggle-context="toggleContext(group, group_index, snippetIndex)"
                  />
                </p>
              </template>
            </div>
          </div>
          <template
            v-if="payload.info.gestures?.length && group.snippets[0].sequences[group.snippets[0].sequences.length - 1].showSelfActions">
            <gestures :gestures="payload.info.gestures"/>
            <result-actions
              @copy-context="handleCopyContext(group_index)"
              @toggle-context="toggleContext(group, group_index, 0)"
              :show-expand-action="showContextExpandAction(group.snippets[0])"/>
          </template>
          <loader v-if="group.loading" class="concordance-item__loader" size="small"/>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import ResultActions from "../ui/ResultActions";
import Icon from "../ui/Icon";
import RoundButton from "../ui/RoundButton";
import Loader from "../ui/Loader";
import TheButton from "../ui/TheButton";
import TheTooltip from "../ui/form/TheTooltip";
import {
  DocSource,
  WordSource,
  SearchQuery,
  FrontendPreviousParams,
  SearchResult,
  ExplainQuery,
  ExplainResultType,
  SearchResultType,
  MediaContentType,
  SearchParams,
  DiapSource,
  PaginationParams,
  Corpus,
  CorpusType,
} from '@ruscorpora/ruscorpora_api';
import Word from "../results/Word"
import { useModalStore } from "../../stores/modal";
import { useSearchStore, SEARCH_STATE, CURRENT_CORPUS, RESULT_TYPE, QUERY, NO_DIACRITIC } from "../../stores/search";
import { clipboardWriteRichText } from "../../../utils/clipboard";
import { DefaultPagination } from "../../utils/structure";
import { bufferToBase64, buildQueryUrl, encodeSearchQuery, getModulePath } from "../../utils/http";
import { computed, inject, nextTick, onMounted, ref } from "vue";
import ConcordanceTitle from "./components/ConcordanceTitle";
import { adjustToWindow, openInNewTab, prefixUrl } from "../../utils/helper";
import { prepareSnippetGroup } from "../../utils/search";
import cloneDeep from "lodash/cloneDeep";
import AudioPlayer from "../ui/AudioPlayer.vue";
import VideoPlayer from "../ui/VideoPlayer.vue";
import Gestures from "./Gestures.vue";
import Sequence from "./components/Sequence.vue";
import MediaContent from "../ui/MediaContent.vue";
import { reachGoal } from "../../utils/yandex-metrika";

export default {
  name: "ConcordanceItem",
  components: {
    MediaContent,
    Sequence,
    Gestures,
    VideoPlayer,
    AudioPlayer,
    ConcordanceTitle,
    TheTooltip,
    TheButton,
    ResultActions,
    Icon,
    Loader,
    RoundButton,
    Word,
  },
  props: {
    index: {
      type: [Number, String, null],
    },
    payload: {
      type: Object
    },
    forceShowTranslates: {
      type: Boolean,
      default: false
    },
    availableFullTextMode: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    searchParams: {
      type: SearchParams
    },
    corpus: {
      type: Corpus
    },
    searchQuery: {
      type: SearchQuery,
      required: false
    }
  },
  data() {
    return {
      attachedElement: false,
      snippet: {}
    }
  },
  setup(props) {
    const modalStore = useModalStore()
    const searchStore = useSearchStore()
    const request = inject('request')
    const message = inject('message')
    const interpolate = inject('interpolate')
    const isMetaResult = computed(() => searchStore[RESULT_TYPE] === SearchResultType.META)
    const isFullText = computed(() => searchStore[RESULT_TYPE] === SearchResultType.FULL_TEXT)
    const inlineMedia = computed(() => [CorpusType.MURCO, CorpusType.MULTIPARC_RUS, CorpusType.MULTIPARC].includes(usageCorpus.value.type))

    const wordSource = computed(() => searchStore[SEARCH_STATE].wordSource)
    const docSource = computed(() => searchStore[SEARCH_STATE].docSource?.docId)
    const snippetsGroups = ref([])
    const noDiacriticParam = computed(() => {
      if (props.searchParams) {
        return props.searchParams.noDiacritic || false
      }
      return searchStore[QUERY].params?.noDiacritic || false
    })
    const usageCorpus = computed(() => {
      if (props.corpus) {
        return props.corpus
      }
      return searchStore[CURRENT_CORPUS]
    })

    const query = computed(() => {
      return SearchQuery.decode(SearchQuery.encode(props.searchQuery ?? searchStore.query).finish())
    })

    const processSnippetsGroups = (snippetsGroups) => {
      snippetsGroups.forEach(snippetsGroup => {
        snippetsGroup.snippets.forEach(snippet => {
          snippet.sequences.forEach((sequence, sequenceIndex) => {
            sequence.showSelfActions = sequenceIndex + 1 === snippet.sequences.length && checkShowSequenceActions(sequence)

            if (sequence.lineInfo && (sequence.lineInfo.size || sequence.lineInfo.formula)) {
              sequence.lineInfo.processed = [sequence.lineInfo.size, sequence.lineInfo.formula].join(' ')
            }
          })
        })

        snippetsGroup.isPoemLayout = snippetsGroup.snippets.some(snippet => snippet.isVerse)

        if (snippetsGroup.isPoemLayout) {
          snippetsGroup.anyLineInfo = snippetsGroup.snippets.some(snippet => snippet.sequences.some(sequence => {
            return sequence.lineInfo && (sequence.lineInfo.size || sequence.lineInfo.formula)
          }))
        }

        return snippetsGroup
      })
      return snippetsGroups
    }

    const poemNoAnyLineInfo = computed(() => {
      return snippetsGroups.value.every(group => !group.anyLineInfo)
    })

    onMounted(async () => {
      if (!props.payload.snippetGroups) return []

      snippetsGroups.value = processSnippetsGroups(prepareSnippetGroup(cloneDeep(props.payload.snippetGroups), false, props.forceShowTranslates))
      await nextTick()
      const poemSnippets = document.querySelectorAll('.poem-layout:not(.no-diacritic)')

      if (poemSnippets) {
        poemSnippets.forEach(poem => {
          poem.querySelector('p').scrollIntoView({inline: "start", block: 'nearest'})
        })
      }
    })

    const getParamsForModal = (expanded = false, diapSource = null) => {
      const searchQuery = SearchQuery.fromObject({...query.value.toJSON()})

      if (expanded) {
        searchQuery.params.expdiap = diapSource
        searchQuery.params.docSource = null
        searchQuery.params.pageParams = DefaultPagination()
      }

      return {
        searchQuery,
        homonymyStatus: props.payload.info.homonymyStatus,
        explainResult: null
      }
    }

    const handleOpenWordModal = (wordSourceParams, isExpanded = false, diapSource = null) => {
      const explainQuery = ExplainQuery.create({
        corpus: usageCorpus.value,
        resultType: ExplainResultType.WORD_INFO,
        wordSource: WordSource.create({
          ...wordSourceParams,
          docSource: props.payload.info.source
        }),
        noDiacritic: noDiacriticParam.value
      })

      modalStore.showWordModalV2({
        ...getParamsForModal(isExpanded, diapSource),
        explainQuery,
      })
    }

    const fullTextQueryParams = computed(() => {
      if (!isMetaResult.value) return null
      const clonedQuery = searchStore[QUERY].toJSON()
      const searchQuery = SearchQuery.fromObject({
        ...clonedQuery,
        lexGramm: null,
        exactForm: null,
        paraLexGramm: null,
        collocation: null,
        resultType: [SearchResultType.FULL_TEXT],
        params: {
          ...clonedQuery.params,
          expdiap: DiapSource.create({
            docSource: props.payload.info.source,
            start: 0,
            end: 100
          }),
          pageParams: {
            page: 0
          }
        }
      })

      return {
        name: 'full-text',
        query: {
          search: encodeSearchQuery(searchQuery, {
            searchResultType: SearchResultType.META,
            searchParams: searchStore[QUERY].params
          })
        }
      }
    })

    const checkShowSequenceActions = (sequence) => {
      return Boolean(!props.compact && !sequence.blockquote)
    }

    const showContextExpandAction = (snippet) => {
      return !isFullText.value && !snippet.completeContext
    }

    const handleOpenSpeakerInfo = (speakerInfo, isExpanded = false, diapSource = null) => {
      modalStore.showSpeakerInfo({
        ...getParamsForModal(isExpanded, diapSource),
        explainQuery: ExplainQuery.create({docSource: props.payload.info.source}),
        speakerInfo,
      })
    }

    const handleOpenReadabilityInfo = (readabilityInfo, isExpanded = false, diapSource = null, customTitle) => {
      modalStore.showReadabilityInfo({
        ...getParamsForModal(isExpanded, diapSource),
        explainQuery: ExplainQuery.create({docSource: props.payload.info.source}),
        readabilityInfo,
        customTitle,
      })
    }

    return {
      modalStore,
      searchStore,
      request,
      wordSource,
      docSource,
      snippetsGroups,
      handleOpenWordModal,
      prepareSnippetGroup,
      processSnippetsGroups,
      message,
      interpolate,
      isMetaResult,
      isFullText,
      fullTextQueryParams,
      showContextExpandAction,
      mediaContentType: MediaContentType,
      handleOpenSpeakerInfo,
      handleOpenReadabilityInfo,
      noDiacriticParam,
      poemNoAnyLineInfo,
      inlineMedia,
    }
  },
  methods: {
    removeBlockquote(snippetGroups) {
      /** Удаляется цитаты из группы сниппетов и после фильтруются группы, если в нх не осталось ни одного сниппета или
       * последовательности */
      return snippetGroups.map(snippetGroup => {
        snippetGroup.snippets = snippetGroup.snippets.map(snippet => {
          snippet.sequences = snippet.sequences.filter(x => !x.blockquote)
          return snippet
        }).filter(snippet => snippet.sequences.length)

        return snippetGroup
      }).filter(snippetGroup => snippetGroup.snippets.length)
    },
    toggleContext(group, index, snippetIndex) {
      if (group.snippets[snippetIndex].expandedNewPage) {
        const originQuery = this.searchStore.query;

        const searchQuery = SearchQuery.fromObject({
          ...originQuery,
          resultType: [SearchResultType.FULL_TEXT],
          params: {
            ...originQuery.params,
            expdiap: DiapSource.create({
              docSource: DocSource.fromObject({...group.snippets[snippetIndex].source.docSource}),
              ...adjustToWindow(group.snippets[snippetIndex].source.start, group.snippets[snippetIndex].source.end)
            }),
            docSource: null,
            pageParams: PaginationParams.create()
          }
        })

        return openInNewTab(buildQueryUrl(getModulePath('full-text'), {search: encodeSearchQuery(searchQuery)}))
      }
      if (!group.contextIsExpanded) {
        const originQuery = this.searchStore.query;

        const query = SearchQuery.fromObject({
          ...originQuery,
          resultType: originQuery.resultType,
          corpus: originQuery.corpus,
          subcorpus: originQuery.subcorpus,
          params: {
            ...originQuery.params,
            docSource: null,
            expdiap: group.snippets[0].source,
            pageParams: DefaultPagination()
          }
        })

        this.snippetsGroups[index].loading = true
        this.request(prefixUrl(`/api/search?query=${encodeURIComponent(bufferToBase64(SearchQuery.encode(this.searchStore.cleanUpSearchQuery(query)).finish()))}`, true))
          .then(response => SearchResult.fromObject(response))
          .then(response => {
            const newGroups = this.removeBlockquote(response.queryResults[0].concordanceData.groups[0].docs[0].snippetGroups)
            /*const newGroup = bufferToBase64(WordSequence.encode(newGroups[0].snippets[0].sequences[0]).finish())
            const currentGroups = bufferToBase64(WordSequence.encode(this.payload.snippetGroups[index].snippets[0].sequences[0]).finish())

            if (newGroups.length === 1 && newGroup === currentGroups) {
              this.snippetsGroups[index].contextIsExpanded = false
              this.snippetsGroups[index].loading = false
              this.message.error(this.$gettext('Данные для расширенного контекста отсутствуют'))
              return
            }*/
            this.snippetsGroups[index].snippets = this.processSnippetsGroups(this.prepareSnippetGroup(newGroups, true)).map(x => x.snippets).flat()
            this.snippetsGroups[index].contextIsExpanded = true
          })
          .finally(() => {
            this.snippetsGroups[index].loading = false
          })
      } else {
        this.snippetsGroups = this.processSnippetsGroups(
          this.snippetsGroups.map((snippetGroup, snippetGroupIndex) => {
            if (snippetGroupIndex === index) {
              snippetGroup.snippets = this.prepareSnippetGroup([cloneDeep(this.payload.snippetGroups[snippetGroupIndex])]).map(x => x.snippets).flat()
              snippetGroup.contextIsExpanded = false
            }
            return snippetGroup
          })
        )
      }
    },
    handleCopyContext(index) {
      const snippetContent = this.$refs.context[index].cloneNode(true);
      snippetContent.querySelectorAll('.result-actions').forEach(x => x.remove())

      clipboardWriteRichText(snippetContent, [`[${this.payload.info.title}]`])
      reachGoal('TARGET_СOPY')
    },
  },
  computed: {
    snippetsExists() {
      return this.payload.hasOwnProperty('snippetGroups') && this.payload.snippetGroups.length
    },
    allExamplesLink() {
      if (!this.payload.info.showAllExamples) return ''
      const originQuery = this.searchStore[SEARCH_STATE].query;

      const query = SearchQuery.fromObject({
        ...originQuery,
        resultType: originQuery.resultType,
        corpus: originQuery.corpus,
        subcorpus: originQuery.subcorpus,
        params: {
          ...originQuery.params,
          docSource: DocSource.create({
            docId: this.payload.info.source.docId
          }),
          pageParams: DefaultPagination()
        }
      })

      return buildQueryUrl(getModulePath('results'), {
        search: encodeSearchQuery(
          query, FrontendPreviousParams.create({searchParams: originQuery.params}
          ))
      })
    }
  }
}
</script>

<style scoped lang="scss">
.concordance-item {
  line-height   : 1.4;
  margin-bottom : rem-calc(32);
  position      : relative;
  @include breakpoint(medium up) {
    line-height   : 1.5;
    margin-bottom : rem-calc(57);
  }

  &--compact {
    margin-bottom : rem-calc(18);

    &:last-child {
      margin-bottom : 0;
    }
  }

  &__head {
    display         : flex;
    justify-content : space-between;
    flex-direction  : column;
    margin-bottom   : rem-calc(16);
    @include breakpoint(medium up) {
      flex-direction : row;
      margin-bottom  : rem-calc(20);
      gap            : rem-calc(80);
    }
  }

  &__title {
    font-size   : rem-calc(18);
    font-weight : 700;
    font-family : var(--main-font);
    position    : relative;
    display     : flex;
    align-items : flex-start;

    @include breakpoint(medium up) {
      display : block;
    }

    .phrase {
      display : flex;
      @include breakpoint(medium up) {
        display : inline;
      }

      &.highlight {
        background : var(--tooltop-color);
        color      : var(--black) !important;
      }
    }

    .homonymy-status {
      transform : translateX(rem-calc(12));
    }

    @include breakpoint(medium up) {
      font-size   : rem-calc(24);
      line-height : 1.3;

      .homonymy-status {
        transform : translateX(rem-calc(12)) translateY(rem-calc(4));
      }
    }
  }

  &__loader {
    position  : absolute;
    margin    : 0;
    z-index   : 1;
    transform : translate(-50%, -50%);
    left      : 50%;
    top       : 50%;
  }

  &__group {
    position      : relative;
    margin-bottom : rem-calc(24);

    &:last-child {
      margin-bottom : 0;
    }

    &::after {
      position         : absolute;
      z-index          : -1;
      content          : "";
      left             : 0;
      right            : 0;
      top              : rem-calc(-5);
      bottom           : rem-calc(-5);
      opacity          : 0;
      background-color : var(--light-mint);
      transition       : ease-in .2s;
      pointer-events   : none;
      border-radius    : rem-calc(5);
    }

    &.is-active {
      &::after {
        opacity : 1;

        @include breakpoint(medium up) {
          left  : rem-calc(-15);
          right : rem-calc(-15);
        }
      }

      padding : rem-calc(5 15);

      @include breakpoint(medium up) {
        padding : 0;
      }
    }

    &.poem-layout {
      .sequences {
        display               : grid;
        grid-template-columns : minmax(min-content, max-content) auto;
        gap                   : 0 8px;
        white-space           : nowrap;
        overflow-x            : auto;
        overflow-y            : hidden;

        &::after {
          top    : 0;
          bottom : 0;
        }

        span.plain {
          white-space : inherit;
        }

        .result-actions {
          overflow : hidden;
        }

        @include breakpoint(large up) {
          overflow : unset;

          .result-actions {
            overflow : visible;
          }
        }

        p {
          padding-left  : 30px;
          margin-bottom : 0;
        }
      }

      &--compact {
        gap : 0;

        p {
          padding-left : 0;
        }

        .poem-layout__line-info {
          width        : 0;
          margin-right : 0;
        }
      }

      .poem-layout {
        &__line-info {
          opacity      : .5;
          margin-right : -30px;

          &.offset {
            padding-top : 10px;
          }

          &.first-line-offset {
            padding-top : 1.5em;

            &.offset {
              padding-top : calc(28px + 1.5em);
            }
          }


          @include breakpoint(large up) {
            max-width : 110px;
          }

          span {
            display     : block;
            direction   : rtl;
            white-space : nowrap;
            text-align  : right;
          }
        }
      }

      &.no-diacritic {
        gap : 0;

        p {
          padding-left : 0;
        }

        .poem-layout {
          &__line-info {
            margin-right : 0;
          }
        }
      }
    }
  }

  &__body {
    font-size : rem-calc(16);
    max-width : 100%;

    &-text {
      min-width : 0;
      width     : 100%;
    }

    p {
      margin-bottom : rem-calc(6);
      position      : relative;

      &:last-child {
        margin-bottom : 0;
      }

      .result-actions {
        margin-left : rem-calc(-57);
      }

      &.seq-with-actions {
        span:nth-last-child(2) {
          padding-right : rem-calc(66);

          .concordance-item--compact & {
            padding-right : 0;
          }
        }
      }
    }

    @include breakpoint(medium up) {
      font-size    : rem-calc(18);
      padding-left : 50px;

      p:hover {
        .result-actions {
          opacity : 1;
        }
      }

      &.inline-media {
        display      : flex;
        padding-left : 0;
        gap          : 20px;
        @include breakpoint(large up) {
          margin-right : -20%;
          max-width    : 120%;
        }
      }
    }

    .end-multidocs-group & {
      border-bottom  : 1px solid var(--gray);
      padding-bottom : rem-calc(32px);
      @include breakpoint(medium up) {
        padding-bottom : rem-calc(57px);
      }

      &.concordance-item--compact {
        padding-bottom : 18px;
      }
    }
  }

  &__all-results {
    font-size   : rem-calc(16);
    margin-top  : rem-calc(14);
    white-space : nowrap;
    flex-shrink : 0;
    @include breakpoint(medium up) {
      font-size  : rem-calc(18);
      margin-top : rem-calc(5);
    }

    a {
      color   : var(--black);
      opacity : .5;

      &:hover {
        text-decoration : underline;
        opacity         : .7;
      }
    }
  }

  @include breakpoint(medium up) {
    &__group:not(.is-active) {
      .concordance-item {
        &__readability-info {
          position    : absolute;
          left        : auto;
          right       : calc(100% + rem-calc(7));
          top         : rem-calc(4);
          transform   : none;
          margin      : 0;
          font-size   : rem-calc(12);
          white-space : nowrap;
        }
      }
    }
  }
}

.show-full-text {
  color : var(--gray);
}

.concordance-sequence {
  &--is-prose {
    white-space : initial;
  }
}

.snippet {
  &:not(:last-child) {
    margin-bottom : 20px;
  }

  .inline-media & {
    display        : flex;
    flex-direction : column;
    @include breakpoint(medium up) {
      flex-direction : row;
      gap            : 20px;
    }
  }
}

.sequences {
  flex-basis : 100%;
}
</style>

<style lang="scss">
.concordance-item {
  &__blockquote {
    position     : relative;
    padding-left : 30px;

    &:before {
      position   : absolute;
      content    : "";
      width      : 5px;
      top        : 0;
      bottom     : 0;
      left       : 0;
      background : #DFE6E4;
    }

    span[data-v-61fb04ca]:nth-last-child(2) {
      padding-right : 0 !important;
    }

    @include breakpoint(medium up) {
      margin-left  : -50px;
      padding-left : 21px;
    }
  }
}
</style>
