<script>
import TheButton from "../ui/TheButton";
import Icon from "../ui/Icon";
import {
  COUNTERS,
  IS_SMALL_RESOLUTION,
  ROUTE,
  THE_HEADER_IS_COMPACT_KEY,
  useRootStore
} from "../../stores/root";
import { numberFilter } from "../../filters/number";
import { computed, ref, watchEffect, inject } from "vue";
import { encodeSearchQuery } from "../../utils/http";
import { QUERY_EXPLAINS, SEARCH_STATE, RESULT_TYPE, useSearchStore, QUERY } from "../../stores/search";
import { checkConditionMixin } from "../../mixins/headers";
import TheTooltip from "../ui/form/TheTooltip";
import { CorpusType, SearchResultType } from "@ruscorpora/ruscorpora_api";
import { RIGHT_CONTEXT_SORTING } from "../../constatns";
import { allowShowCollocation } from "../../utils/search";
import QueryExplains from "./Components/QueryExplains.vue";
import { CURRENT_CORPUS, usePortraitStore } from "../../stores/portrait";

export default {
  name: "QueryHeader",
  components: {QueryExplains, TheTooltip, Icon, TheButton},
  props: {
    staticCounters: {
      type: [Object, null],
      default: null
    },
    backSearchUrl: {
      type: [String, Object, null],
      default: ''
    },
    backSearchLabel: {
      type: [String, null],
      default: ''
    },
    title: {
      type: [String, null],
      default: ''
    }
  },
  emits: ['change-kwpos', 'back-from-all-results'],
  setup(props, {emit}) {
    const rootStore = useRootStore();
    const searchStore = useSearchStore();
    const portraitStore = usePortraitStore();
    const compactMode = computed(() => rootStore[THE_HEADER_IS_COMPACT_KEY]);
    const isSmallResolution = computed(() => rootStore[IS_SMALL_RESOLUTION])
    const counters = computed(() => rootStore[COUNTERS]);
    const isSearchRoute = computed(() => rootStore[ROUTE] === 'search');
    const wrapper = ref(null)
    const $gettext = inject('gettext')
    const $ngettext = inject('ngettext')
    const $interpolate = inject('interpolate')
    const backToSearchLabel = computed(() => {
      return isSmallResolution.value ? $gettext('К поиску') : $gettext('Вернуться к поиску')
    })
    const backToSearchUrl = computed(() => encodeSearchQuery(searchStore[SEARCH_STATE].query))

    const queryCounters = computed(() => {
      if (props.staticCounters) return props.staticCounters
      // const samplesCount = counters.value.query.wordUsageSampledCount || counters.value.query.wordUsageCount

      const result = []

      if (counters.value.query.hasOwnProperty('textCount') && counters.value.query.textCount > 0) {
        result.push({
          count: counters.value.query.textCount,
          label: $interpolate(
            $ngettext('%(count)s документ', '%(count)s документа', counters.value.query.textCount),
            {count: numberFilter(counters.value.query.textCount)},
            true
          )
        })
      }

      if (counters.value.query.hasOwnProperty('sentenceCount') && [CorpusType.SYNTAX].includes(portraitStore[CURRENT_CORPUS].type)) {
        const sentenceCount = counters.value.query.sentenceCount

        result.push({
          count: sentenceCount,
          label: $interpolate(
            $ngettext('%(count)s sentence', '%(count)s sentences', sentenceCount),
            {count: numberFilter(sentenceCount)},
            true
          )
        })
      }

      if (counters.value.query.hasOwnProperty('wordUsageCount') && counters.value.query.wordUsageCount > 0) {
        const samplesCount = counters.value.query.wordUsageCount
        result.push({
          count: samplesCount,
          label: $interpolate(
            $ngettext('%(count)s пример', '%(count)s примера', samplesCount),
            {count: numberFilter(samplesCount)},
            true
          )
        })
      }
      if (counters.value.query.hasOwnProperty('ngramCount') && counters.value.query.ngramCount > 0) {
        const ngramCount = counters.value.query.ngramCount
        result.push({
          count: ngramCount,
          label: $interpolate(
            $ngettext('%(count)s ngram_count', '%(count)s ngram_count', ngramCount),
            {count: numberFilter(ngramCount)},
            true
          )
        })
      }
      if (counters.value.query.hasOwnProperty('collocationCount') && counters.value.query.collocationCount > 0 && allowShowCollocation(counters.value)) {
        const collocationCount = counters.value.query.collocationCount
        result.push({
          count: collocationCount,
          label: $interpolate(
            $ngettext('%(count)s collocation_count', '%(count)s collocations_count', collocationCount),
            {count: numberFilter(collocationCount)},
            true
          )
        })
      }
      if (counters.value.query.hasOwnProperty('clipCount') && counters.value.query.clipCount > 0) {
        const clipCount = counters.value.query.clipCount
        result.push({
          count: clipCount,
          label: $interpolate(
            $ngettext('%(count)s clip', '%(count)s clips', clipCount),
            {count: numberFilter(clipCount)},
            true
          )
        })
      }

      return result
    })
    const queryExplains = computed(() => searchStore[QUERY_EXPLAINS][0])

    const queryIsKWIC = computed(() => {
      return searchStore[RESULT_TYPE] === SearchResultType.KWIC
    })
    const KWICPosition = computed(() => {
      // See more in search store
      let kwpos = 0
      if (searchStore.query.params.hasOwnProperty('kwpos')) {
        kwpos = searchStore[QUERY].params.kwpos
      } else {
        kwpos = RIGHT_CONTEXT_SORTING.includes(searchStore[QUERY].params.sort) ?
          queryExplains.value.sectionExplanations[0].subsectionExplanations.length - 1 : 0
      }
      return kwpos
    })

    const handleChangeKWICPosition = (index) => {
      if (!queryIsKWIC.value) return
      emit('change-kwpos', index)
    }

    watchEffect(() => {
      checkConditionMixin(isSmallResolution.value, wrapper.value)
    })

    const resultType = computed(() => searchStore[RESULT_TYPE])

    return {
      compactMode,
      counters,
      isSearchRoute,
      numberFilter,
      backToSearchLabel,
      backToSearchUrl,
      wrapper,
      queryCounters,
      queryExplains,
      queryIsKWIC,
      KWICPosition,
      isSmallResolution,
      handleChangeKWICPosition,
      checkConditionReqNoty: () => {
        checkConditionMixin(isSmallResolution.value, wrapper.value)
      },
      resultType,
    }
  }
}
</script>

<template>
  <transition @after-enter="checkConditionReqNoty">
    <div v-if="!compactMode && !isSearchRoute" class="the-header the-header--alt the-header--query" ref="wrapper">
      <div class="container">
        <div class="the-header__wrapper">
          <div class="the-header__name">{{ title ? title : $gettext('Запрос') }}</div>
          <div class="the-header__actions">
            <div class="the-header__actions-main">
              <router-link v-if="backSearchUrl && typeof backSearchUrl === 'object'"
                           :to="backSearchUrl"
                           @click.native="$emit('back-from-all-results')">
                <the-button :aria-label="$gettext('Вернуться к поиску')" type="colored" size="medium-large">
                  {{ $gettext(backSearchLabel) }}
                </the-button>
              </router-link>
              <the-button v-else-if="backSearchUrl && typeof backSearchUrl === 'string'" :href="backSearchUrl"
                          :aria-label="$gettext('Вернуться к поиску')" type="colored" size="medium-large">
                {{ $gettext(backSearchLabel) }}
              </the-button>
              <router-link v-else :to="{name: 'forms', query: {search: backToSearchUrl}}">
                <the-button :aria-label="$gettext('Вернуться к поиску')" type="colored" size="medium-large">
                  {{ backToSearchLabel }}
                </the-button>
              </router-link>
            </div>
            <slot name="compare-actions" class="compare-actions-hidden"></slot>
          </div>
          <div class="the-header__stats">
            <ul class="the-header__stats-counter">
              <li v-for="counter in queryCounters">{{ counter.label }}</li>
            </ul>
          </div>
          <div class="the-header__last">
            <slot name="compare-actions-mobile"></slot>
            <template v-if="!compactMode">
              <query-explains
                @change-kwic-pos="handleChangeKWICPosition"
                :query-explains="queryExplains"
                :kwic-position="KWICPosition"
                :is-small-resolution="isSmallResolution"
                :result-type="resultType"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.the-header {
  &__last {
    flex-direction : column;
    align-items    : flex-start;
    gap            : rem-calc(8);
  }

  &__stats {
    @include breakpoint(medium up) {
      padding-right : 75px;
    }
  }
}
</style>
