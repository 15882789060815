<template>
  <div class="lex-words">
    <div class="lex-word" v-for="(word, word_index) of filledForm" :key="`word_${word_index}`">
      <div class="lex-condition__header">
        <span>
          {{ computedWordLabel }} {{ repeated ? word_index + 1 : '' }}
        </span>

        <div class="lex-condition__actions">
          <round-button v-if="filledForm.length > 1 && repeated" :aria-label="$gettext('Удалить слово')"
                        @click="deleteWord(word_index)"
                        color="dark" size="medium">
            <icon name="c-cross"></icon>
          </round-button>
          <round-button v-if="repeated" :aria-label="$gettext('Добавить слово')" @click="addWord(word_index)"
                        color="dark"
                        size="medium">
            <icon name="c-plus"></icon>
          </round-button>
        </div>
      </div>
      <div class="lex-condition" v-for="(condition, cond_index) of word"
           :key="`cond_${word_index}_${cond_index}`">
        <div v-if="condition.label" class="card-title">
          <strong v-text="condition.label"/>
          <div class="card-title__actions">
            <round-button v-if="condition.helpText" :aria-label="$gettext('Подсказка')" color="dark" size="small"
                          @click="showHelpText(condition.helpText)">
              <icon name="c-question"/>
            </round-button>
            <round-button :aria-label="$gettext('Удалить условие на слово')" color="dark" size="small"
                          @click="deleteCondition(word_index, condition.cardId)">
              <icon name="c-cross"/>
            </round-button>
          </div>
        </div>

        <div v-for="(field, field_index) of condition.fields"
             :key="`field_${word_index}_${cond_index}_${field_index}`">
          <the-input v-bind="field"
                     :model-value="field.value.text.v"
                     v-if="field.type === 'text'"
                     :label-style="field_index === 0 && !condition.label ? 'strong': ''"
                     :special-word-key="word_index"
                     @set-meaning="meaningHandler(word_index, $event)"
                     @update:modelValue="updateTextField({cardId: condition.cardId, value: $event, name: field.name, word: word_index, condition: field.value})">
            <template v-slot:additional-control v-if="field_index === 0 && !condition.label && word.length > 1">
              <round-button :aria-label="$gettext('Удалить условие на слово')" color="dark" size="small"
                            @click="deleteCondition(word_index, condition.cardId)">
                <icon name="c-cross"/>
              </round-button>
            </template>
          </the-input>

          <checkbox v-else-if="field.type === 'check-group'" :model-value="field.value.text.v" :options="field.options"
                    :label-style="field_index === 0 ? 'strong': ''" layout="inline" v-bind="field"
                    @update:modelValue="updateTextField({cardId: condition.cardId, value: $event, name: field.name, word: word_index, field: field, condition: field.value})"/>

          <radio v-else-if="field.type === 'radio'" :model-value="field.value" :options="field.options"
                 :label-style="field_index === 0 ? 'strong': ''" v-bind="field" :inline="false"
                 @update:modelValue="updateTextField({cardId: condition.cardId, value: $event.text.v, name: field.name, word: word_index, field: field, condition: field.value})"/>

          <int-range v-else-if="field.type === 'int-range'" :model-value="field.value.intRange" v-bind="field"
                     :label-style="field_index === 0 ? 'strong': ''"
                     @validate="validateHandler(word_index, condition.cardId, $event)"
                     @update:modelValue="updateIntRangeField({cardId: condition.cardId, value: $event, name: field.name, word: word_index, condition: field.value})">
            <template v-slot:additional-control v-if="field_index === 0">
              <round-button :aria-label="$gettext('Удалить слово')" color="dark" size="small"
                            @click="deleteCondition(word_index, condition.cardId, cond_index)">
                <icon name="c-cross"/>
              </round-button>
            </template>
          </int-range>
          <computable-word-select
            v-else-if="field.type === 'computable-word-select'"
            v-bind="field"
            v-model="field.value.int"
            @validate="validateHandler(word_index, condition.cardId, $event)"
            :current-word="word_index">

          </computable-word-select>
        </div>

        <v-select v-if="cond_index === word.length - 1 && hasAvailableCards(word_index)"
                  :options="availableCards" label="title"
                  @open="setAvailableCards(word_index)"
                  :clearable="false"
                  :placeholder="$gettext('добавить условие')"
                  class="vs--fluid custom-v-select"
                  :append-to-body="true"
                  @option:selected="addCondition(word_index, $event.cardId)">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon name="c-dropdown-indicator"/>
            </span>
          </template>
        </v-select>
      </div>

      <div class="lex-condition" v-if="!word.length && hasAvailableCards(word_index)">
        <v-select :options="availableCards" label="title"
                  @open="setAvailableCards(word_index)"
                  :clearable="false"
                  :placeholder="$gettext('добавить условие')"
                  class="vs--fluid custom-v-select"
                  :append-to-body="true"
                  @option:selected="addCondition(word_index, $event.cardId)">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <icon name="c-dropdown-indicator"/>
            </span>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import TheInput from "./TheInput";
import Checkbox from "./Checkbox";
import RoundButton from "../RoundButton";
import IntRange from "./IntRange";
import Icon from "../Icon";
import { fillForm } from "../../../utils/form";
import { computed, inject, readonly, ref, provide } from "vue";
import set from "lodash/set";
import isObject from "lodash/isObject";
import Radio from "./Radio.vue";
import { useModalStore } from "../../../stores/modal";
import { SEARCH_ACTIONS, useSearchStore } from "../../../stores/search";
import ComputableWordSelect from "./ComputableWordSelect.vue";
import { cardIsValidForFirstWord } from "../../../utils/search";

const meaningFieldName = 'meaning'



export default {
  name: "LexGramm",
  emits: ['add-word', 'delete-word', 'update-field', 'delete-condition', 'add-condition', 'validate'],
  props: {
    params: {
      type: Object,
      default: () => {
        return {}
      }
    },
    cards: {
      type: Array
    },
    repeated: {
      type: Boolean,
      default: true
    },
    wordLabel: {
      type: String,
    },
  },
  components: {ComputableWordSelect, Radio, TheInput, Icon, RoundButton, Checkbox, IntRange},
  setup(props, {emit}) {
    const errors = []
    const availableCards = ref([])
    const lexConfig = computed(() => {
      if (!props.cards.length) return null
      return readonly(props.cards)
    })
    const searchStore = useSearchStore()

    const filledForm = computed(() => {
      if (!lexConfig.value) return []
      return props.params.map(params => {
        return fillForm(params, lexConfig.value)
      })
    })

    const updateTextField = (payload) => {
      // Особенная обработка текстовых полей
      payload.condition.text.v = payload.value
      payload.value = payload.condition
      emit('update-field', payload)
    }

    const addCondition = (word, cardId) => {
      emit('add-condition', {
        word: word, card: lexConfig.value.find(x => x.cardId === cardId)
      })
    }

    const meaningHandler = (wordIndex, meaning) => {
      const meaningField = lexConfig.value.map(card => card.fields).flat().find(field => field.name === meaningFieldName)

      if (!meaningField) return

      if (!filledForm.value[wordIndex].find(card => card.fields.find(field => field.name === meaningFieldName))) {
        /** если поле не добавлено в слово, восстанавливаем его */
        addCondition(wordIndex, lexConfig.value.find(card => card.fields.find(field => field.name === meaningFieldName)).cardId)
      }
      /* ищем добавленное поле в форме */
      const card = filledForm.value[wordIndex].find(card => card.fields.find(field => field.name === meaningFieldName))

      updateTextField({
        cardId: card.cardId,
        value: meaning,
        name: meaningFieldName,
        word: wordIndex,
        condition: card.fields.find(x => x.name === meaningFieldName).value
      })
    }

    const modalStore = useModalStore()
    const gettext = inject('gettext')

    const computedWordLabel = computed(() => {
      return props.wordLabel ? props.wordLabel : gettext('Слово')
    })

    const getWordCount = () => filledForm.value.length

    provide('lexGrammForm', {getWordCount})

    return {
      lexConfig,
      availableCards,
      errors,

      filledForm,
      meaningHandler,
      updateTextField,
      addCondition,
      showHelpText: modalStore.showHelpText,
      computedWordLabel,
      searchStore
    }
  },
  methods: {
    getAvailableCards(word) {
      return this.lexConfig
        .filter(card => !this.params[word].find(x => x.cardId === card.cardId))
        .filter(card => {
          if (word > 0) return true
          return cardIsValidForFirstWord(card)[0]
        })
        .map(x => {
          return {
            cardId: x.cardId,
            title: x.label || x.fields[0].label
          }
        })
    },
    hasAvailableCards(word = 0) {
      return !!this.getAvailableCards(word).length
    },
    setAvailableCards(word = 0) {
      this.availableCards = [...this.getAvailableCards(word)]
    },
    addWord(index) {
      this.$emit('add-word', {
        cards: this.lexConfig,
        index: index
      })
      this.searchStore[SEARCH_ACTIONS.LEX_GRAMM_ADD_WORD](index)
    },
    deleteWord(index) {
      this.errors.splice(index, 1)
      this.checkFormValidate()
      this.$emit('delete-word', index)
      this.searchStore[SEARCH_ACTIONS.LEX_GRAMM_DELETE_WORD](index)
    },
    updateIntRangeField(payload) {
      payload.condition.intRange = {...payload.condition.intRange, ...payload.value}
      payload.value = payload.condition
      this.$emit('update-field', payload)
    },
    deleteCondition(word, cardId) {
      set(this.errors, `[${word}].${cardId}`, true)
      this.checkFormValidate()
      this.$emit('delete-condition', {
        word: word, cardId: cardId
      })
    },
    checkFormValidate() {
      let isValid = true
      this.errors.filter(x => isObject(x)).forEach(word => {
        Object.values(word).forEach(x => {
          if (!x) isValid = false
        })
      })
      this.$emit('validate', !isValid)
    },
    validateHandler(wordId, cardId, state) {
      set(this.errors, `[${wordId}].${cardId}`, state)
      this.checkFormValidate()
    }
  }
}
</script>
<style scoped lang="scss">
.lex-words {
  display        : flex;
  gap            : rem-calc(18);
  flex-direction : column;
  @include breakpoint(small up) {
    flex-direction : row;
    overflow-x     : auto;
    padding-top    : rem-calc(10);
    padding-bottom : rem-calc(10);
  }
}

.lex-word {
  display        : flex;
  flex-direction : column;
  gap            : rem-calc(8);
}

.lex-condition {
  background     : var(--light-mint);
  border-radius  : rem-calc(10);
  padding        : rem-calc(24 12);
  width          : var(--real-viewport);
  display        : flex;
  flex-direction : column;
  gap            : rem-calc(8);
  max-width      : 100%;

  @include breakpoint(small up) {
    padding : rem-calc(24);
    width   : rem-calc(390);
  }

  &__header {
    font-size       : 16px;
    font-weight     : 700;
    display         : flex;
    justify-content : space-between;

    @include breakpoint(small up) {
      font-size   : 22px;
      font-weight : 400;
    }
  }

  &__actions {
    display : flex;
    gap     : rem-calc(8);
  }
}

.lex-condition-item {

}

.card-title {
  display         : flex;
  justify-content : space-between;
  min-height      : 14px;
  align-items     : center;
  font-size       : 12px;
  margin-bottom   : .8rem;

  &__actions {
    display : flex;
    gap     : 6px;
  }
}
</style>
