import { request } from "./request"
import { getCurrentInstance } from "vue";

export function RequestPiniaPlugin() {
    const instance = getCurrentInstance()

    return {
        request: request.bind(instance.appContext.config.globalProperties),
        requestManager: instance.appContext.config.globalProperties.$requestManager
    }
}
