<script setup>
import { inject, nextTick, ref, watch, watchEffect } from "vue";
import { ymapLoader } from "../../../plugins/yandex-map";

const props = defineProps({
  points: {type: Array, default: () => []},
  modelValue: {type: Array, default: () => []},
})
const domId = ref('ymap-selector')
const $gettext = inject('gettext')
let instance = null

const getMapInstance = () => {
  return new Promise(resolve => {
    if (instance !== null) return resolve(instance)

    instance = new ymaps.Map(domId.value, {
      center: [54.702462, 37.612741],
      controls: ['zoomControl'],
      zoom: 3
    }, {
      suppressMapOpenBlock: true
    })

    resolve(instance)
  })
}
const emit = defineEmits(['update:modelValue'])
const select = (detail) => {
  if (!props.modelValue.find(x => x === props.points[detail].name)) {
    emit('update:modelValue', [...props.modelValue, props.points[detail].name])
  } else {
    emit('update:modelValue', [...props.modelValue.filter(x => x !== props.points[detail].name)])
  }
}
let collection = undefined;

ymapLoader({apiKey: 'c4c9a5fc-94ac-432e-b4bc-6b3ee98e5fca', 'lang': window.App?.LOCALE ?? 'ru'})
    .then(async () => {
      const myMap = await getMapInstance()
      collection = new ymaps.GeoObjectCollection();
      const selectText = $gettext('выбрать')
      const deselectText = $gettext('убрать')
      const errorMsg = $gettext('сообщить об ошибке')

      const BalloonContentLayout = ymaps.templateLayoutFactory.createClass(
          '<div class="map-my-balloon">' +
          '<h4>{{properties.point.name}}</h4>' +
          '<div class="map-my-balloon__btns">' +
          '<button class="button button--color-colored" id="select-on-map-button">{{ properties.btnText }}</button>' +
          '<button class="link" id="error-from-map-button" style="display: none">{{ properties.errorMsg }}</button>' +
          '</div>' +
          '</div>', {

            // Переопределяем функцию build, чтобы при создании макета начинать
            // слушать событие click на кнопке-счетчике.
            build: function () {
              // Сначала вызываем метод build родительского класса.
              BalloonContentLayout.superclass.build.call(this);
              // А затем выполняем дополнительные действия.
              document.getElementById('select-on-map-button').addEventListener('click', this.onSelectClick.bind(this))
              document.getElementById('error-from-map-button').addEventListener('click', this.openErrorMessage.bind(this))
            },

            // Аналогично переопределяем функцию clear, чтобы снять
            // прослушивание клика при удалении макета с карты.
            clear: function () {
              // Выполняем действия в обратном порядке - сначала снимаем слушателя,
              // а потом вызываем метод clear родительского класса.
              document.getElementById('select-on-map-button').removeEventListener('click', this.onSelectClick.bind(this))
              document.getElementById('error-from-map-button').removeEventListener('click', this.openErrorMessage.bind(this))
              BalloonContentLayout.superclass.clear.call(this);
            },

            onSelectClick: async function () {
              const data = this.getData()
              select(data.properties._data.id)
              await nextTick()
              data.properties._data.btnText = props.modelValue.includes(data.properties._data.point.name) ? deselectText : selectText
              this.setData(data)
            },

            openErrorMessage: async function () {

            }
          });

      const markedCollection = new ymaps.GeoObjectCollection()
      for (let i = 0; i < props.points.length; i++) {
        const marked = props.modelValue.includes(props.points[i].name)
        const placemark = new ymaps.Placemark(
            props.points[i].coords,
            {
              point: props.points[i],
              id: i,
              btnText: '',
              errorMsg: errorMsg
            },
            {
              openEmptyBalloon: true,
              balloonContentLayout: BalloonContentLayout,
              iconColor: marked ? '#FF9470' : '#2D3B60'
            },
        )

        placemark.events.add('balloonopen', function () {
          placemark.properties.set('btnText', props.modelValue.includes(props.points[i].name) ? deselectText : selectText);
        })

        placemark.events.add('balloonclose', function () {
          placemark.options.set('iconColor', props.modelValue.includes(props.points[i].name) ? '#FF9470' : '#2D3B60');
        })

        collection.add(placemark);
      }

      myMap.geoObjects.add(collection)
    })

watch(() => props.modelValue, (newValue) => {
  if (!newValue.length && collection) {
    collection.each(placemark => {
      placemark.options.set('iconColor', '#2D3B60');
    })
  }
})
</script>

<template>
  <div class="ymap">
    <div :id="domId" class="ymap__instance" />
  </div>
</template>
<style lang="scss">
.map-my-balloon {
  min-width      : 260px;

  h4 {
    font-weight   : 600;
    margin-bottom : 10px;
  }

  &__btns {
    display         : flex;
    justify-content : space-between;
    gap             : 30px;
  }

  padding-bottom : 2px;
}
</style>
<style scoped lang="scss">
.ymap {
  width  : 100%;
  height : 50vh;
  @include breakpoint(small up) {
    height : 500px;
  }

  &__instance {
    width      : 100%;
    height     : 100%;
    background : var(--white);
  }
}
</style>
