import { isFireFox } from "../app/utils/helper";
import clone from "lodash/clone";

/**
 * @param {Array} messages
 * @return {Promise}
 */
const writeToClipboard = async (messages) => {
    if (!isFireFox()) {
        const payload = {}

        messages.forEach(message => {
            payload[message.type] = typeof message.value === "string" ? new Blob([message.value], {type: message.type}) : message.value
        })

        return navigator.clipboard.write([
            new ClipboardItem(payload)
        ])
    } else {
        messages = await Promise.all(
            messages.map(async message => {
                message.value = typeof message.value === "string" ? message.value : await message.value.then(blob => blob.text())

                return message
            })
        )
        const listener = async function (ev) {
            ev.preventDefault();

            messages.forEach(message => {
                ev.clipboardData.setData(message.type, message.value);
            })
        };
        document.addEventListener('copy', listener);
        document.execCommand('copy');
        document.removeEventListener('copy', listener);
    }
}

export const clipboardWrite = async function (message) {
    return writeToClipboard(
        [
            {type: 'text/plain', value: message},
        ]
    )
}

const replaceCharts = (message) => {
    let res = clone(message)
    res = res.replaceAll('‐', '') // UNICODE 8208, not hyphen
    return res
}

const WordFontFamily = 'Calibri, Arial, sans-serif';
/**
 * @param data
 * @param {('text/plain'|'text/html'|'text/table_html'| 'text/ascii')} format
 */
export const arrayToReadyForCopy = (data, format = 'text/plain') => {
    if (format === 'text/plain') {
        let clipboardData = ""
        data.forEach(row => {
            const rowValues = Array.from(row)
                .map(cell => {
                    if (typeof cell.val === 'string') return cell.val
                    return cell.val.map(x => replaceCharts(x[1])).join('').trim()
                });
            clipboardData += rowValues.join("\t") + "\n";
        })

        return clipboardData
    } else if (format === 'text/table_html') {
        const clipboardTable = document.createElement('table')
        const clipboardBody = document.createElement('tbody')

        data.forEach(row => {
            const clipboardRow = document.createElement('tr')

            Array.from(row)
                .map((cell, index) => {
                    const clipboardTd = document.createElement('td')
                    clipboardTd.style.verticalAlign = 'top'
                    if (cell.style && typeof cell.style === 'object') {
                        Object.keys(cell.style).forEach(key => {
                            clipboardTd.style[key] = cell.style[key]
                        })
                    }
                    const clipboardParagraph = document.createElement('p')
                    const childStyle = {
                        'font-family': WordFontFamily,
                        'font-size': '11pt',
                        'font-style': 'normal',
                        ...cell.childStyle ?? {},
                    }
                    Object.keys(childStyle).forEach(key => {
                        clipboardParagraph.style[key] = childStyle[key]
                    })
                    if (typeof cell.val === 'string') {
                        clipboardParagraph.innerHTML = cell.val
                    } else {
                        clipboardParagraph.innerHTML = cell.val.map(x => replaceCharts(x[0])).join('')
                    }
                    clipboardTd.appendChild(clipboardParagraph)
                    clipboardRow.appendChild(clipboardTd)
                });

            clipboardBody.appendChild(clipboardRow)
        })

        clipboardTable.appendChild(clipboardBody)

        return clipboardTable

    } else if (format === 'text/html') {
        /* Грязное форматирование HTML в строку, при возможности улучшить */
        return replaceCharts(data.map(x => x[0].val.map(x => x[0]).join('')).join(''))
    } else if (format === 'text/ascii') {
        if (!Array.isArray(data) || data.length === 0) {
            console.log("Invalid data provided.");
            return;
        }

        // Определите максимальную ширину для каждого столбца
        const columnWidths = data[0].map((_, colIndex) =>
            Math.max(...data.map(row => (row[colIndex].val + '').length))
        );

        // Создайте верхнюю границу таблицы
        const headerRow = '+' + columnWidths.map(width => '-'.repeat(width + 2)).join('+') + '+';

        // Создайте строки данных таблицы
        const tableRows = data.map(row => {
            return '| ' + row.map((cell, colIndex) =>
                (cell.val + ' ').padEnd(columnWidths[colIndex] + 1)
            ).join('| ') + '|';
        });

        // Соберите всю таблицу в одну строку
        return [headerRow, ...tableRows, headerRow].join('\n');
    } else {
        console.warn('unsiupported format')
    }
}

export const clipboardWriteRichText = (node, extra = [], replaceNewLine = false, saveOriginNewLines = false) => {
    const newLinesMarkers = []
    node.querySelectorAll('.new-line-wrap').forEach(x => newLinesMarkers.push(x.innerText))

    let plainText = (replaceNewLine ? node.innerText.replaceAll('\n', ' ') : node.innerText).replace(/\s\s+/g, ' ').replace(/\u00a0/g, ' ');
    newLinesMarkers.forEach(x => plainText = plainText.replace(x, `\n${x}\n`))
    plainText = plainText.trim().replaceAll('\n ', '\n')

    const joinedExtra = extra.join(' ')

    const targetCopyElement = []

    const isPoemLayout = node.classList.contains('poem-layout')
    const isParaLayout = node.classList.contains('snippet')

    const insertToCopy = (content, index = 0) => {
        const toAppend = typeof content === "string" ? [content, content] : [...content]

        if (isPoemLayout) {
            if (targetCopyElement[index] === undefined) {
                if (Array.from(node.querySelectorAll('.poem-layout__line-info'))[index].innerText) {
                    targetCopyElement.push(
                        [
                            {
                                val: [
                                    [
                                        Array.from(node.querySelectorAll('.poem-layout__line-info'))[index].innerHTML,
                                        Array.from(node.querySelectorAll('.poem-layout__line-info'))[index].innerText
                                    ]
                                ],
                                style: {
                                    paddingRight: '15px',
                                },
                                childStyle: {
                                    color: 'gray'
                                }
                            },
                            {
                                val: []
                            }
                        ]
                    )
                } else {
                    targetCopyElement.push(
                        [
                            {
                                val: []
                            }
                        ]
                    )
                }
            }

            targetCopyElement[index][targetCopyElement[index].length - 1].val.push(toAppend)
        } else {
            if (targetCopyElement[index] === undefined) {
                targetCopyElement.push(
                    [
                        {
                            val: []
                        }
                    ]
                )
            }
            targetCopyElement[index][targetCopyElement[index].length - 1].val.push(toAppend)
        }
    }
    const paragraphSelectors = isParaLayout ? [node] : node.querySelectorAll('p')
    const tdSelectors = node.querySelectorAll('td')
    Array.from(paragraphSelectors.length ? paragraphSelectors : tdSelectors)
        .forEach((paragraph, index) => {
            paragraph.querySelectorAll('span:not(.copy-ignore),button[copy-content]')
                .forEach((word) => {
                    const newWord = document.createElement('span')
                    /* Подмена контента для копирования */
                    newWord.innerHTML = word.hasAttribute('copy-content') ? decodeURIComponent(word.getAttribute('copy-content')) : (word.querySelector('i') ? word.querySelector('i').innerHTML : word.innerHTML);

                    if (word.classList.contains('hit')) {
                        if (!word.classList.contains('ignore-cursive')) {
                            newWord.style.fontStyle = 'italic'
                        }
                        newWord.style.fontWeight = '600'
                        insertToCopy([newWord.outerHTML, newWord.innerText], index)
                    } else {
                        if (word.classList.contains('new-line-wrap')) {
                            insertToCopy([`<br>${newWord.innerText}<br>`, `\n${newWord.innerText}\n`], index)
                        } else if (word.classList.contains('from-new-line')) {
                            insertToCopy([`<br>${newWord.innerText}`, `\n${newWord.innerText}`], index)
                        } else if (saveOriginNewLines && word.querySelector('br')) {
                            /*Хак для БГ, для сохранения оригинального форматирования грамоты*/
                            insertToCopy([word.innerHTML, word.innerText], index)
                        } else {
                            if (isPoemLayout) {
                                insertToCopy([word.querySelector('i').innerHTML, word.innerText], index)
                            } else {
                                const innerHtml = word.hasAttribute('copy-html-content') ? word.getAttribute('copy-html-content') : newWord.innerHTML
                                newWord.innerHTML = newWord.innerHTML.replace('<br>', '\n')

                                insertToCopy([innerHtml, newWord.innerText], index)
                            }
                        }
                    }
                })
        })


    const REGEX_LIST = {
        default: /&(nbsp|amp|quot|lt|gt);/g,
        poem: /&(amp|quot);/g
    }

    if (isPoemLayout) {
        return writeToClipboard(
            [
                {
                    type: 'text/plain',
                    value: [arrayToReadyForCopy(targetCopyElement, 'text/plain'), joinedExtra].join('\n')
                },
                {
                    type: 'text/html',
                    value: [arrayToReadyForCopy(targetCopyElement, 'text/table_html').outerHTML, `<p style="font-family: ${WordFontFamily}; font-size: 11pt; font-style: normal">${joinedExtra}</p>`].join('').replace(REGEX_LIST.poem, " ")
                },
            ]
        )
    } else {
        return writeToClipboard(
            [
                {
                    type: 'text/plain',
                    value: [replaceCharts(arrayToReadyForCopy(targetCopyElement, 'text/plain')), joinedExtra].join(' ')
                },
                {
                    type: 'text/html',
                    value: [`<p style="font-family: ${WordFontFamily}; font-size: 11pt; font-style: normal">`, arrayToReadyForCopy(targetCopyElement, 'text/html'), ` ${joinedExtra}`, '</p>'].join('').replace(REGEX_LIST.default, " ")
                },
            ]
        )
    }
}
